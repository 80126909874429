import StrcutureProblemTable from "../components/structureProblemTable";
import { strcuctureProblemValidations } from "../helpers/structureProblemForm.validtions";
import { FIELD_IDS } from "./structureProblemForm.fieldId";

export const STRUCTURE_PROBLEM = {
  id: FIELD_IDS.STRUCTURE_PROBLEM,
  renderer: StrcutureProblemTable,
  renderOptions: {
     required: true,
     validations: strcuctureProblemValidations
  }
};
