import QuestionnaireFormReaders from "../../questionnaireForm/readers";

export const getPayloadFromFormValues = (formValues, questionId) => {
  const serialNumber = QuestionnaireFormReaders.serialNumber(formValues);
  const question = QuestionnaireFormReaders.question(formValues);
  const questionSegment = QuestionnaireFormReaders.questionSegment(formValues);
  const surveyObjectiveId =
    QuestionnaireFormReaders.surveyObjective(formValues);
  const structureTypeId = QuestionnaireFormReaders.structureType(formValues);
  const surveyorRankId = QuestionnaireFormReaders.surveyorRank(formValues);
  const answerPattern = QuestionnaireFormReaders.answerPattern(formValues);
  const assessmentGuidelineId =
    QuestionnaireFormReaders.assessmentGuideline(formValues);
  const answers = QuestionnaireFormReaders.answers(formValues);
  const helps = QuestionnaireFormReaders.help(formValues);
  const mandatory = QuestionnaireFormReaders.mandatory(formValues);

  return {
    serialNumber,
    question,
    questionSegment,
    surveyObjectiveId,
    structureTypeId,
    surveyorRankId,
    answerPattern,
    assessmentGuidelineId,
    answers,
    helps,
    isRequired: mandatory,
    questionId
  };
};
