import React from "react";

import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable";
import useFetchUsers from "../../../cortexSurvey-business/hooks/useFetchUsers";

import UsersOverView from "./components/usersOverview/UsersOverView";

const Users = () => {
  const { data, loading, fetchUsers } = useFetchUsers();
  return (
    <HeaderWithTable
      title="Users"
      data={data}
      loading={loading}
      overviewComponent={UsersOverView}
      onReload={fetchUsers}
      showReload
    />
  );
};

export default Users;
