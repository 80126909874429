import CountryDataIndex from "../../../../../../cortexSurvey-business/appServices/country/constants/dataIndex/country";

const COLUMNS = [
  {
    title: "Country Id",
    dataIndex: CountryDataIndex.countryId,
    key: "countryCode",
    width: "20rem",
  },
  {
    title: "Country",
    dataIndex: CountryDataIndex.country,
    key: "country",
    width: "20rem",
  },
];

export default COLUMNS;
