import { FIELD_IDS } from "./notificationForm.fieldId";

import {
  NOTIFICATION_TYPE,
  NOTIFICATION_IMAGE,
  NOTIFICATION_VIDEO,
  NOTIFICATION_TITLE,
} from "./notificationForm.fields";

export const FORM_CONFIG = {
  [FIELD_IDS.NOTIFICATION_IMAGE]: NOTIFICATION_IMAGE,
  [FIELD_IDS.NOTIFICATION_VIDEO]: NOTIFICATION_VIDEO,
  [FIELD_IDS.NOTIFICATION_TYPE]: NOTIFICATION_TYPE,
  [FIELD_IDS.NOTIFICATION_TITLE]: NOTIFICATION_TITLE,
};
