const stateId = "stateId";
const state = "stateName";
const country = "country";
const countryName="countryName"
const countryId="countryId"

const DATA_INDEX = {
  stateId,
  state,
  country,
  countryId,
  countryName
};

export default DATA_INDEX;
