import React, { memo } from "react";

import _noop from "lodash";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable";

import CountryOverview from "./components/countryOverview";
import { ACTION_TYPES } from "./constants/country.actionTypes";
import { CREATE_BUTTON_LABEL, HEADER_TITLE } from "./constants/country.general";
import ACTION_HANDLERS from "./helpers/country.actionHandlers";

const Country = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchCountry = _noop,
  } = props;
  return (
    <HeaderWithTable
      data={data}
      loading={loading}
      title={HEADER_TITLE}
      onAction={onAction}
      overviewComponent={CountryOverview}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      onReload={fetchCountry}
      fetchData={fetchCountry}
      showReload
    />
  );
};

Country.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default compose(memo, withActions({}, ACTION_HANDLERS))(Country);
