import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_STRUCTUTE_TYPE: "/structure-data",
  CREATE_STRUCTUTE_TYPE: "/structure-add",
  EDIT_STRUCTUTE_TYPE: "/structure-update",
  EDIT_STRUCTURE_PROBLEM: "/structure-problem-update",
  EDIT_STRUCTURE_COMPONENT: "/structure-component-update",
  EDIT_STRUCTURE_HELP: "/structure-help-update",
  DELETE_STRUCTUTE_TYPE: "/is-active",
};

const fetchStructureType = (payload) =>
  HttpClient.post(API_PATHS.FETCH_STRUCTUTE_TYPE, payload);

const createStructureType = (payload) =>
  HttpClient.post(API_PATHS.CREATE_STRUCTUTE_TYPE, payload);

const editStructureType = (payload) =>
  HttpClient.post(API_PATHS.EDIT_STRUCTUTE_TYPE, payload);

const editStructureProblem = (payload) => {
  HttpClient.post(API_PATHS.EDIT_STRUCTURE_PROBLEM, payload);
};

const editStructureComponent = (payload) => {
  HttpClient.post(API_PATHS.EDIT_STRUCTURE_COMPONENT, payload);
};

const editStructureHelp = (payload) => {
  HttpClient.post(API_PATHS.EDIT_STRUCTURE_HELP, payload);
};

const deleteStructureType = (payload) =>
  HttpClient.post(API_PATHS.DELETE_STRUCTUTE_TYPE, payload);

const StructureTypeAPI = {
  fetchStructureType,
  createStructureType,
  editStructureType,
  deleteStructureType,
  editStructureProblem,
  editStructureComponent,
  editStructureHelp,
};

export default StructureTypeAPI;
