import _property from "lodash/property";
import MicrozonationDataIndex from "../constants/dataIndex/microzonation";

const assessmentGuideline = _property(
  MicrozonationDataIndex.assessmentGuideline
);
const assessmentGuidelineId = _property(
  MicrozonationDataIndex.assessmentGuidelineId
);
const surveyObjective = _property(MicrozonationDataIndex.surveyObjective);
const surveyObjectiveId = _property(MicrozonationDataIndex.surveyObjectiveId);
const level = _property(MicrozonationDataIndex.level);
const microzonationId = _property(MicrozonationDataIndex.microzonationId);
const surveyObjectiveCount = _property(
  MicrozonationDataIndex.surveyObjectiveCount
);
const value = _property(MicrozonationDataIndex.value);

const assesmentGuidelineActiveStatus = _property(
  MicrozonationDataIndex.assesmentGuidelineActiveStatus
);

const READERS = {
  assessmentGuideline,
  assessmentGuidelineId,
  surveyObjective,
  surveyObjectiveId,
  level,
  microzonationId,
  surveyObjectiveCount,
  value,
  assesmentGuidelineActiveStatus,
};

export default READERS;
