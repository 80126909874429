import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./header.module.scss";

const Header = (props) => {
  const { title, width, className } = props;

  return (
    <div
      className={`${styles.header} ${className}`}
      style={{ '--header-width': width }}
    >
      {title}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

Header.defaultProps = {
  title: undefined,
  width: "20rem",
  className: undefined,
};

export default memo(Header);
