import _noop from "lodash/noop";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

import { ACTION_TYPES } from "../constants/cityCreateForm.actionTypes";
import {
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../constants/cityCreateForm.general";

import CityAPI from "../../../../../../cortexSurvey-business/appServices/city/services/city";

import { getPayloadFromFormValues } from "./cityCreateForm.payload";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (error) return;

  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues);
    await CityAPI.createCity(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
    onClose();
  } catch (err) {
    errorHandler(err, setState, ERROR_MESSAGE);
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
