import React, { memo, useCallback } from "react";

import PropTypes from "prop-types";
import _noop from "lodash/noop";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";

import { SECTIONS } from "./constants/imageProcessingForm.section";
import { FORM_CONFIG } from "./constants/imageProcessingForm.formConfig";

const ImageProcessingForm = (props) => {
  const { initialValues = {}, onSubmit = _noop, formId, view = false } = props;

  const getFormConfig = useCallback(() => FORM_CONFIG, []);

  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={view}
      tabEnabled
    />
  );
};

ImageProcessingForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  view: PropTypes.bool,
};

export default memo(ImageProcessingForm);
