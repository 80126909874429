import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";
import SurveyObjectiveOverview from "./components/surveyObjectiveOverview";

import { ACTION_TYPES } from "./constants/surveyObjective.actionTypes";
import {
  CREATE_BUTTON_LABEL,
  HEADER_TITLE,
} from "./constants/surveyObjective.general";
import { INITIAL_STATE } from "./constants/surveyObjective.initialStates";
import ACTION_HANDLERS from "./helpers/surveyObjective.actionHandlers";

const SurveyObjective = (props) => {
  const {
    data = [],
    loading = false,
    onAction = _noop,
    fetchSurveyObjective = _noop,
  } = props;

  return (
    <HeaderWithTable
      loading={loading}
      data={data}
      onAction={onAction}
      title={HEADER_TITLE}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      overviewComponent={SurveyObjectiveOverview}
      onReload={fetchSurveyObjective}
      fetchData={fetchSurveyObjective}
      showReload
    />
  );
};

SurveyObjective.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchSurveyObjective: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(SurveyObjective);
