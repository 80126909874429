import _property from "lodash/property";
import CityDataIndex from "../constants/dataIndex/city";

const city = _property(CityDataIndex.cityName);
const cityId = _property(CityDataIndex.cityId);
const country = _property(CityDataIndex.country);
const countryId = _property(CityDataIndex.countryId);
const stateId = _property(CityDataIndex.stateId);
const soil = _property(CityDataIndex.soil);
const countryName = _property(CityDataIndex.countryName);
const stateName = _property(CityDataIndex.stateName);
const state = _property(CityDataIndex.state);

const READERS = {
  city,
  cityId,
  countryId,
  stateId,
  soil,
  countryName,
  stateName,
  country,
  state,
};

export default READERS;
