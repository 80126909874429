import React from "react";

import StrcutureTypeReaders from "../../../../../../cortexSurvey-business/appServices/structureType/readers/structureType";

export const renderRowExpand = ({
  expandedRowRender: (record) => (
    <p
      style={{
        margin: 0,
      }}
    >
      {StrcutureTypeReaders.structureDetails(record)}
    </p>
  ),
  rowExpandable: StrcutureTypeReaders.structureDetails,
});
