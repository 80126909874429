import React, { memo } from "react";
import DashboardUserReaders from "../../../../../../../cortexSurvey-business/appServices/dashboardUser/readers/user";
import PropTypes from "prop-types";
import Tag from "../../../../../../../cortexSurvey-components/tag";

const InActiveAndActiveStatus = (props) => {
  const { rowData = {} } = props;
  const status=DashboardUserReaders.status(rowData);

  if (status==='Active') return <Tag color="success">Active</Tag>;

  if (status==='Pending') return <Tag color="processing">Pending</Tag>;

  return <Tag color="error">InActive</Tag>;
};

InActiveAndActiveStatus.propTypes = {
  rowData: PropTypes.object,
};

export default memo(InActiveAndActiveStatus);
