import React from "react";
import _map from "lodash/map";

import NavigateButton from "../NavigateButton";

import styles from "./navigationContainer.module.scss";

const NavigationContainer = (props) => {
  const { onClose, config, setQuickNavigation } = props;

  const componentSectionRenderer = (values) => {
    return <NavigateButton onClose={onClose} {...values} setQuickNavigation={setQuickNavigation} config={values}/>;
  };

  const navigationSectionRenderer = (configValue) => {
    const { sectionName, components, icon: Icon } = configValue;

    return (
      <div>
        <div className={styles.sectionName} key={sectionName}>
          <Icon style={{ fontSize: '24px', marginRight: '10px' }}/>
          <div>{sectionName}</div>
        </div>
        <div className={styles.sectionContainer}>
          {_map(components, componentSectionRenderer)}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {_map(config, navigationSectionRenderer)}
    </div>
  );
};

export default NavigationContainer;
