import _noop from "lodash/noop";

import EmptyData from "../../emptyData";
import { SELECT_MANDATORY_PROPS } from "../constants/select.props";

export const getSelectProps = ({
  isMulti,
  selectAllLabel,
  handleOptionsOnMenuOpen = _noop,
}) => {
  return {
    isMulti,
    closeMenuOnSelect: !isMulti,
    noOptionsMessage: EmptyData,
    selectAllLabel,
    onMenuOpen: handleOptionsOnMenuOpen,
    ...SELECT_MANDATORY_PROPS,
  };
};
