import { FIELD_IDS } from "../../structureTypeForm/constants/structureTypeForm.fieldId";
import StructureTypeReaders from "../../../../../../cortexSurvey-business/appServices/structureType/readers/structureType";

export const makeInitialValues = (formValues) => {
  return {
    [FIELD_IDS.STRUCTURE_TYPE]: StructureTypeReaders.structureName(formValues),
    [FIELD_IDS.STRUCTURE_TYPE_IMAGE]:
      StructureTypeReaders.structureImage(formValues),
  };
};
