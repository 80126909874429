import StructureTypeAPI from "../../../../../../cortexSurvey-business/appServices/structureType/services/structureType";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/structureProblemForm.actionTypes";
import { getPayloadFromFormValues } from "./structureProblemForm.payload";
  
  const handleEditFormSubmission = async ({ getState, params, setState }) => {
    const { id, onClose, onSubmitCb } = getState();
    const { formValues } = params;
  
    const { error } = formValues;

    setState({ error });
  
    if (error) return;
  
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(formValues, id); 
      await StructureTypeAPI.editStructureProblem(payload);
      onSubmitCb();
      toaster(TOASTER_TYPE.SUCCESS, "Structure Type Problem edited Successfully");
      onClose();
    } catch (err) {
      errorHandler(err, setState,  "Failed to Edit Structure Type Problem. Please try again.")
    } finally {
      setState({ isSubmitting: false });
    }
  };
  
  const ACTION_HANDLERS = {
    [ACTION_TYPES.STRUCTURE_PROBLEM_FORM_SUBMISSION]: handleEditFormSubmission,
  };
  
  export default ACTION_HANDLERS;
  