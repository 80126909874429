import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

import Table from "../../../../../cortexSurvey-components/table/Table";

import QuestionnaireReaders from "../../../../../cortexSurvey-business/appServices/questionnaire/readers/questionnaire";

import { SEARCH_KEYS } from "./constants/questionaireOverview.table";
import COLUMNS from "./constants/questionnaireOverview.columns";
import { getRowActions } from "./helpers/questionnaireOverview.rowActions";
import { INACTIVE_ROW_ACTIONS } from "./constants/questionnaireOverview.rowActions";


const QuestionnaireOverview = (props) => {
  const {
    onAction = _noop,
    loading = false,
    data = [],
    disabled = false,
    fetchData = _noop,
  } = props;

  const rowActions = useMemo(() => getRowActions(disabled), [disabled]);

  return (
    <Table
      rowKey={QuestionnaireReaders.id}
      dataSource={data}
      columns={COLUMNS}
      loading={loading}
      rowActions={rowActions}
      fetchData={fetchData}
      onAction={onAction}
      searchKeys={SEARCH_KEYS}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

QuestionnaireOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fetchData: PropTypes.func,
};

export default memo(QuestionnaireOverview);
