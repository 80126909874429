import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";

import Table from "../../../../../cortexSurvey-components/table/Table";

import { COLUMN_CONFIG } from "./constants/imageProcessingOverview.columnConfig";
import {
  INACTIVE_ROW_ACTIONS,
  ROW_ACTIONS,
} from "./constants/imageProcessingOverview.rowActions";
import { SEARCH_KEYS } from "./constants/imageProcessingOverview.table";

const ImageProcessingOverview = (props) => {
  const {
    data = [],
    loading = false,
    onAction = _noop,
    fetchData = _noop,
  } = props;

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={COLUMN_CONFIG}
      rowActions={ROW_ACTIONS}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      onAction={onAction}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      showFilter
    />
  );
};

ImageProcessingOverview.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onAction: PropTypes.func,
};

export default memo(ImageProcessingOverview);
