import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import { ACTION_TYPES } from "../../../constants/country.actionTypes";

// const EDIT = {
//   id: ACTION_TYPES.EDIT,
//   label: "Edit Country",
//   icon: <EditOutlined />,
// };

const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate Country",
  icon: <DeleteOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVE,
  label: "Activate Country",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
