import React from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable";

import MicroZonationOverview from "./components/microZonationOverview";
import { ACTION_TYPES } from "./constants/microZonation.actionTypes";
import {
  BANNER_CONFIG,
  CREATE_BUTTON_LABEL,
  HEADER_TITLE,
} from "./constants/microZonation.general";
import { INITIAL_STATE } from "./constants/microZonation.initialState";
import ACTION_HANDLERS from "./helpers/microZonation.actionHandlers";

const MicroZonation = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    surveyObjective = [],
    onReload = _noop,
    fetchMicroZonation = _noop,
    microZonationNotSynced = false,
  } = props;

  return (
    <HeaderWithTable
      title={HEADER_TITLE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={MicroZonationOverview}
      createActionType={ACTION_TYPES.CREATE}
      surveyObjective={surveyObjective}
      disabled={loading}
      showBanner={microZonationNotSynced}
      onReload={onReload}
      bannerConfig={BANNER_CONFIG}
      fetchData={fetchMicroZonation}
      showReload
    />
  );
};

MicroZonation.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  surveyObjective: PropTypes.array,
  fetchMicroZonation: PropTypes.func,
  microZonationNotSynced: PropTypes.bool,
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(MicroZonation);
