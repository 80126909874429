import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../../cortexSurvey-base/utils/isAuthenticated";

const AuthRedirection = (props) => {
  const { children } = props;
  if (isAuthenticated()) {
    return <Navigate to="/survey" replace />;
  }

  return children;
};

export default AuthRedirection;
