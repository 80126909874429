import CityDataIndex from "../../../../../../cortexSurvey-business/appServices/city/constants/dataIndex/city";
import ActiveColumn from "../../../../../../cortexSurvey-components/activeColumn/ActiveColumn";

import CityReaders from "../../../../../../cortexSurvey-business/appServices/city/readers/city";

const COLUMNS = [

  {
    title: "City",
    dataIndex: "cityName",
    key: ["city", "isoCode"],
    width: "20rem",
  },
  {
    title: "State",
    dataIndex: CityDataIndex.state,
    key: ["state", "stateCode"],
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityReaders.stateName} />
    ),
  },
  {
    title: "Country",
    dataIndex: CityDataIndex.country,
    key: ["country", "countryCode"],
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityReaders.countryName} />
    ),
  },
  {
    title: "Soil",
    dataIndex: "soil",
    key: "soil",
    width: "20rem",
  },
];

export default COLUMNS;
