import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/questionnaireEditForm.actionTypes";

import { getPayloadFromFormValues } from "./questionnaireEditForm.payload";

import QuestionnaireAPI from "../../../../../../cortexSurvey-business/appServices/questionnaire/services/questionnaire";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { onClose, onSubmitCb, questionId } = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (error) return;

  setState({ isSubmitting: true });

  try {
    console.log(formValues);
    const payload = getPayloadFromFormValues(formValues, questionId);
    await QuestionnaireAPI.editQuestionnaire(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Question Edited Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to edit Question. Please try again.");
  } finally {
    setState({ isSubmitting: false });
    onClose();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
