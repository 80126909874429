const BANNER_TYPE = "error";

const BANNER_MESSAGE =
  "Data Sync Issue : Data is not synchronized with active MicroZonation due to an inactive Assessment Guideline or a newly added Survey Objective.";

export const BANNER_CONFIG = {
  type: BANNER_TYPE,
  message: BANNER_MESSAGE,
};

export const HEADER_TITLE = "Microzonation";

export const CREATE_BUTTON_LABEL = "Add Microzonation";
