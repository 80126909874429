import React, { memo} from "react";
import PropTypes from "prop-types";

import Tag from "../../../../../../../cortexSurvey-components/tag";

const StatusTag = (props) => {
  const { status } = props;
 
  if (status==='Active') return <Tag color="success">Active</Tag>;

  if (status==='Pending') return <Tag color="processing">Pending</Tag>;

  return <Tag color="error">InActive</Tag>;
};

StatusTag.propTypes = {
  status: PropTypes.string,
};

export default memo(StatusTag);
