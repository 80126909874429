import _property from "lodash/property";
import { FIELD_IDS } from "../constants/cityIndexMappingForm.fieldId";

const countryId = _property(FIELD_IDS.COUNTRY);
const stateId = _property(FIELD_IDS.STATE);
const cityId = _property(FIELD_IDS.CITY);
const soil = _property(FIELD_IDS.SOIL);
const surveyObjectiveId = _property(FIELD_IDS.SURVEY_OBJECTIVE);
const microzonationId = _property(FIELD_IDS.MICROZONATION);
const assessmentGuidelineId = _property(FIELD_IDS.ASSESSMENT_GUIDELINE);

const READERS = {
  countryId,
  stateId,
  cityId,
  soil,
  surveyObjectiveId,
  microzonationId,
  assessmentGuidelineId,
};

export default READERS;
