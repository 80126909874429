import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";

const createMenuItem = (onAction, rowData) => (rowAction) => {
  const { id, label, icon } = rowAction;

  const handleItemClick = () => {
    onAction({ type: id, payload: { rowData } });
  };

  return { key: id, label, icon, onClick: handleItemClick };
};

export const getMenuItems = (
  rowActions,
  onAction,
  rowData,
  inActiveRowActions
) => {
  const { isActive } = rowData;
  if (isActive === 0 && !_isEmpty(inActiveRowActions))
    return _map(inActiveRowActions, createMenuItem(onAction, rowData));
  return _map(rowActions, createMenuItem(onAction, rowData));
};
