import { isMobile } from "react-device-detect";
import ImageProcessingDataIndex from "../../../../../../cortexSurvey-business/appServices/imageProcessing/constants/dataIndex/imageProcessing";
import AudioPlayer from "../../../../../../cortexSurvey-components/audioPlayer/AudioPlayer";

import ReferenceImage from "../components/referenceImageColumn";

export const COLUMN_CONFIG = [
  {
    title: "Item Code",
    dataIndex: ImageProcessingDataIndex.itemCode,
    key: ImageProcessingDataIndex.itemCode,
    width: "12rem",
    fixed: !isMobile && "left",
  },
  {
    title: "Item Search Code",
    dataIndex: ImageProcessingDataIndex.itemSearchCode,
    key: ImageProcessingDataIndex.itemSearchCode,
    width: "15rem",
  },
  {
    title: "Distress Pattern",
    dataIndex: ImageProcessingDataIndex.distressPattern,
    key: ImageProcessingDataIndex.distressPattern,
    width: "18rem",
  },
  {
    title: "Vulnerability Scale",
    dataIndex: ImageProcessingDataIndex.vulnerabilityScale,
    key: ImageProcessingDataIndex.vulnerabilityScale,
    width: "18rem",
  },
  {
    title: "Score Reference",
    dataIndex: ImageProcessingDataIndex.scoreReference,
    key: ImageProcessingDataIndex.scoreReference,
    width: "16rem",
  },
  {
    title: "Numerical Score",
    dataIndex: ImageProcessingDataIndex.numericalScore,
    key: ImageProcessingDataIndex.numericalScore,
    width: "16rem",
  },
  {
    title: "Captured With CameraType",
    dataIndex: ImageProcessingDataIndex.capturedWithCameraType,
    key: ImageProcessingDataIndex.capturedWithCameraType,
    width: "22rem",
  },
  {
    title: "Light And Weather Condition",
    dataIndex: ImageProcessingDataIndex.lightAndWeatherCondition,
    key: ImageProcessingDataIndex.lightAndWeatherCondition,
    width: "24rem",
  },
  {
    title: "Capturing Distance",
    dataIndex: ImageProcessingDataIndex.capturingDistance,
    key: ImageProcessingDataIndex.capturingDistance,
    width: "18rem",
  },
  {
    title: "Audio",
    dataIndex: ImageProcessingDataIndex.audio,
    key: ImageProcessingDataIndex.audio,
    width: "32rem",
    render: (value) => <AudioPlayer url={value} />,
  },
  {
    title: "CreatedBy",
    dataIndex: ImageProcessingDataIndex.createdBy,
    key: ImageProcessingDataIndex.createdBy,
    width: "16rem",
  },
  {
    title: "UpdatedBy",
    dataIndex: ImageProcessingDataIndex.updatedBy,
    key: ImageProcessingDataIndex.updatedBy,
    width: "16rem",
  },
  {
    title: "Reference Images",
    dataIndex: ImageProcessingDataIndex.referenceImages,
    key: ImageProcessingDataIndex.referenceImages,
    width: "14rem",
    render: (value) => <ReferenceImage value={value} />,
    fixed: !isMobile && "right",
  },
];
