import Fuse from "fuse.js";
import _map from "lodash/map";
import _property from "lodash/property";

const createFuseOptions = (keys = []) => ({
  keys,
  includeScore: true,
  threshold: 0.2,
  ignoreLocation: true,
});

export const getSearchedData = (searchString, data, keys) => {
  const fuseOptions = createFuseOptions(keys);
  const fuse = new Fuse(data, fuseOptions);
  const fusedSearch = fuse.search(searchString);
  return _map(fusedSearch, _property("item"));
};
