import React, { memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import Modal from "../../../../../cortexSurvey-components/modal/Modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import ACTION_HANDLERS from "./helpers/surveyorRankDeleteModal.actionHandlers";
import { INITIAL_STATE } from "./contants/surveorRankDeleteModal.initialState";
import {
  MODAL_TITLE,
  SAVE_TEXT,
} from "./contants/surveyorRankDeleteModal.general";
import { ACTION_TYPES } from "./contants/surveyorRankDeleteModal.actionTypes";

const SurveyorRankDeleteModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    surveyorRank,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  };

  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to deActivate ${surveyorRank} Surveyor Rank.`}
    </Modal>
  );
};

SurveyorRankDeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  surveyObjective: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(SurveyorRankDeleteModal);
