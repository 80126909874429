import _map from "lodash/map";
import _flatMap from "lodash/flatMap";

import MicroZonationFormReaders from "../../microZonationForm/readers";

const createMicrozonationValue = (surveyObjectiveId) => (value) => {
  const { microzonationId, level } = value;
  return {
    surveyObjectiveId: Number(surveyObjectiveId),
    level,
    microzonationId,
  };
};

const getMicroZonationValues = (microZonationValue, key) => {
  return _map(microZonationValue, createMicrozonationValue(key));
};

const createMicroZonationValues = (microZonation) => {
  const microzonationValues = _map(microZonation, getMicroZonationValues);
  return _flatMap(microzonationValues);
};

export const getPayloadFromFormValues = (formValues) => {
  const assessmentGuidelineId =
    MicroZonationFormReaders.assessmentGuideline(formValues);
  const microZonation = MicroZonationFormReaders.microZonation(formValues);
  const microZonationValues = createMicroZonationValues(microZonation);

  return {
    assessmentGuidelineId,
    surveyorObjective: microZonationValues,
  };
};
