import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { FIELD_VALUE_MAPPER } from "./constants/cityForm.fieldValueMapper";
import { SECTIONS } from "./constants/cityForm.sections";
import { getFormConfig } from "./helpers/cityForm.formConfig";

const CityForm = (props) => {
  const { initialValues, onSubmit, formId, countryData,stateData,disabled } = props;

  const formConfig = useCallback(
    (formValues) => getFormConfig(countryData, stateData, disabled,formValues),
    [countryData, stateData, disabled]
  );
 
  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      fieldValueMapper={FIELD_VALUE_MAPPER}
    />
  );
};

CityForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
};

CityForm.defaultProps = {
  initialValues: {},
  onSubmit: _noop,
  formId: undefined,
  disabled: false,
};

export default CityForm;
