import MicrozonationReaders from "../../../../cortexSurvey-business/appServices/microZonation/readers/microzonation";
import _map from "lodash/map";
import _flatMap from "lodash/flatMap";

const getMicrozonationIdsFromSurveyObjective = (surveyObjective) => {
  const value = MicrozonationReaders.value(surveyObjective);
  return _map(value, MicrozonationReaders.microzonationId);
};

export const extractMicrozonationIdsFromRowData = (rowData) => {
  const surveyObjective = MicrozonationReaders.surveyObjective(rowData);
  const microzonationIds = _map(
    surveyObjective,
    getMicrozonationIdsFromSurveyObjective
  );
  return _flatMap(microzonationIds);
};
