import React, { useEffect } from "react";

import APP_ROUTES from "./cortexSurvey-base/constants/routes/appRoutes";
import { combineRoutes } from "./cortexSurvey-base/helpers/routes/combineRoutes";

import styles from "./app.module.scss";

function App() {
  useEffect(() => {
    function setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    setVh();

    window.addEventListener("resize", setVh);
    return () => window.removeEventListener("resize", setVh);
  }, []);

  return <div className={styles.appContainer}>{combineRoutes(APP_ROUTES)}</div>;
}

export default App;
