import React from "react";

import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";
import Notification from "./Notification";
import NotificationCreateForm from "./components/notificationCreateForm/NotificationCreateForm";
import NotificationEditForm from "./components/notificationEditForm/NotificationEditForm";
import useFetchNotice from "../../../cortexSurvey-business/appServices/notification/hooks/useFetchNotification";
import NotificationReaders from "../../../cortexSurvey-business/appServices/notification/readers/notofication";
import NotificationActiveModal from "./components/notificationActiveModal";
import NotificationDeleteModal from "./components/notificationDeleteModal";

const NotificationContainer = () => {
  const { loading, data, fetchNotice } = useFetchNotice();
  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditForm,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isNotificationModalVisible,
    showModal: showNotificationDeletionModal,
    closeModal: closeNotificationDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isNotificationActiveModalVisible,
    showModal: showNotificationActiveModal,
    closeModal: closeNotificationActiveModal,
  } = useModalVisibility({});

  return (
    <div>
      <Notification
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        loading={loading}
        data={data}
        fetchNotice={fetchNotice}
        showDeleteModal={showNotificationDeletionModal}
        showActiveModal={showNotificationActiveModal}
      />

      <NotificationCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchNotice}
      />
      <NotificationEditForm
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        formData={selectedEditForm}
        onSubmitCb={fetchNotice}
        noticeId={NotificationReaders.noticeId(selectedEditForm)}
      />
      <NotificationDeleteModal
        isVisible={isNotificationModalVisible}
        onCancel={closeNotificationDeletionModal}
        onSubmitCb={fetchNotice}
        noticeId={NotificationReaders.noticeId(selectedDeleteData)}
        notificationTitle={NotificationReaders.title(selectedDeleteData)}
      />

      <NotificationActiveModal     
       isVisible={isNotificationActiveModalVisible}
        onCancel={closeNotificationActiveModal}
        onSubmitCb={fetchNotice}
        noticeId={NotificationReaders.noticeId(selectedActiveData)}
        notificationTitle={NotificationReaders.title(selectedActiveData)}/>
    </div>
  );
};

export default NotificationContainer;
