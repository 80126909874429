import { useCallback, useMemo } from "react";

import useFetchAssesmentGuideline from "../../../assessmentGuideline/hooks/useFetchAssesmentGuideline";
import useFetchSurveyObjective from "../../../surveyObjective/hooks/useFetchSurveyObjective";
import useFetchMicroZonation from "../useFetchMicroZonation/useFetchMicroZonation";

const useFetchMicroZonationWithRequiredData = () => {
  const {
    loading: assessmentGuidelineLoading,
    data: assssmentGuidelineData,
    fetchAssesmentGuideline,
  } = useFetchAssesmentGuideline();

  const {
    loading: surveyObjectiveLoading,
    data: surveyObjectiveData,
    fetchSurveyObjective,
  } = useFetchSurveyObjective();

  const {
    loading: microZonationLoading,
    data: microZonationData,
    fetchMicroZonation,
  } = useFetchMicroZonation();

  const handleFetchMicroZonationData = useCallback(
    (active) => {
      fetchAssesmentGuideline(active);
      fetchSurveyObjective(active);
      fetchMicroZonation(active);
    },
    [fetchAssesmentGuideline, fetchSurveyObjective, fetchMicroZonation]
  );

  const loading = useMemo(
    () =>
      assessmentGuidelineLoading ||
      surveyObjectiveLoading ||
      microZonationLoading,
    [assessmentGuidelineLoading, surveyObjectiveLoading, microZonationLoading]
  );

  return {
    loading,
    assssmentGuidelineData,
    surveyObjectiveData,
    microZonationData,
    fetchMicroZonation,
    fetchMicroZonationWithRequiredField: handleFetchMicroZonationData,
  };
};

export default useFetchMicroZonationWithRequiredData;
