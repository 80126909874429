import { useCallback, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

import { getErrorMessage } from "../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../cortexSurvey-components/notificationWrapper";
import useFetchPayload from "../../../../hooks/useFetchPayload";
import StructureTypeAPI from "../../services/structureType";

const useFetchStructureType = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchStructureType = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await StructureTypeAPI.fetchStructureType(payload);
        setData(response?.data);
      } catch (err) {
        toaster(
          TOASTER_TYPE.ERROR,
          getErrorMessage("Failed to fetch Structure Type")
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchStructureType = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchStructureType, 1000);
      debounceFunction(isActive);
    },
    [fetchStructureType, setLoading]
  );

  useEffect(() => {
    fetchStructureType();
  }, [fetchStructureType]);

  return {
    loading,
    data,
    fetchStructureType: handleDebounceFetchStructureType,
  };
};

export default useFetchStructureType;
