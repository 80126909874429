import React from "react";
import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const NotificationWrapper = () => {
  return (
    <ToastContainer
      transition={Slide}
      className="toast-notification"
      toastClassName="toast-notification-body"
      hideProgressBar
      pauseOnHover
      position="top-right"
      autoClose={3000}
      closeButton
      closeOnClick
    />
  );
};

export default NotificationWrapper;
