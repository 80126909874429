import React, { memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import Input from "../../input";

const InputFieldRenderer = (props) => {
  const { onChange=_noop, ...restProps } = props;

  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };

  return <Input {...restProps} onChange={handleChange} />;
};

InputFieldRenderer.propTypes = {
  onChange: PropTypes.func,
};

export default memo(InputFieldRenderer);
