import { Routes, Route, Navigate } from "react-router-dom";
import _entries from "lodash/entries";
import _map from "lodash/map";
import _isFunction from "lodash/isFunction";
import ProtectedRoute from "../../../cortexSurvey-components/protectedRoute/ProtectedRoute";
import AuthRedirection from "../../../cortexSurvey-components/authRedirection/AuthRedirection";

const getWrappedRouteELement = (Wrapper, Component) => {
  return _isFunction(Wrapper) ? (
    <Wrapper>
      <Component />
    </Wrapper>
  ) : (
    <Component />
  );
};

const getRouteElement = (Wrapper, Component, isProtected, isAuthRoute) => {
  const WrappedComponent = getWrappedRouteELement(Wrapper, Component);

  if (isProtected) return <ProtectedRoute>{WrappedComponent}</ProtectedRoute>;
  if (isAuthRoute) return <AuthRedirection>{WrappedComponent}</AuthRedirection>;
  return WrappedComponent;
};

const renderRouteWithElement =
  (Wrapper, isProtected, isAuthRoute) => (children, index) => {
    const { path, element: Component } = children;
    const routeElement = getRouteElement(
      Wrapper,
      Component,
      isProtected,
      isAuthRoute
    );
    return (
      <Route path={path} element={routeElement} key={`${path}_${index}`} />
    );
  };

const renderChildrenRoute = (Wrapper, children, isProtected, isAuthRoute) =>
  _map(children, renderRouteWithElement(Wrapper, isProtected, isAuthRoute));

const renderRoute = ([key, route]) => {
  const { path, wrapper: Wrapper, children, isProtected, isAuthRoute } = route;
  return (
    <Route key={key} path={path}>
      {renderChildrenRoute(Wrapper, children, isProtected, isAuthRoute)}
    </Route>
  );
};

export const combineRoutes = (routes) => {
  const routesWithMap = _entries(routes);

  const authToken = localStorage.getItem("AUTH_TOKEN");

  const navigateTo = authToken ? "/survey" : "/auth/login";

  return (
    <Routes>
      {_map(routesWithMap, renderRoute)}
      <Route index element={<Navigate to={navigateTo} replace />} />
    </Routes>
  );
};
