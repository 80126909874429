import { SELECT_COMPONENTS } from "./select.components";
import { SELECT_CLASSNAMES } from "./select.style";

export const SELECT_MANDATORY_PROPS = {
  hideSelectedOptions: false,
  isClearable: false,
  components: SELECT_COMPONENTS,
  classNames: SELECT_CLASSNAMES,
  maxMenuHeight: 200,
};
