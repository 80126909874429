import _size from "lodash/size";

import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_TABLE_HEIGHT,
  DEFAULT_TABLE_HEIGHT_ON_BANNER,
  DEFAULT_TABLE_HEIGHT_ON_PAGINATION,
  DEFAULT_TABLE_HEIGHT_ON_PAGINATION_AND_BANNER,
} from "../constants/table.general";

export const isDataWithPagination = (dataSource) => {
  const dataSize = _size(dataSource);

  return dataSize >= DEFAULT_PAGE_SIZE;
};

export const getTableHeight = (
  dataSource,
  tableHeight = DEFAULT_TABLE_HEIGHT,
  isBanner
) => {
  const isPagination = isDataWithPagination(dataSource);

  if (!isPagination && !isBanner) return tableHeight;

  if (!isPagination && isBanner) return DEFAULT_TABLE_HEIGHT_ON_BANNER;

  if (isBanner && isPagination)
    return DEFAULT_TABLE_HEIGHT_ON_PAGINATION_AND_BANNER;

  return DEFAULT_TABLE_HEIGHT_ON_PAGINATION;
};
