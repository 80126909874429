import React, { memo } from "react";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import ReferenceImageReaders from "../../../../../../../cortexSurvey-business/appServices/imageProcessing/readers/referenceImage";

import Button, {
  TYPES,
} from "../../../../../../../cortexSurvey-components/button";
import Drawer, {
  useDrawerVisibility,
} from "../../../../../../../cortexSurvey-components/drawer";
import Table from "../../../../../../../cortexSurvey-components/table/Table";

import { COLUMN_CONFIG } from "./constants/referenceImageColumn.columnConifg";
import { expandable } from "./helpers/referenceImageColumn.components";

const ReferenceImageColumn = (props) => {
  const { value = [] } = props;

  const { isDrawerVisible, showDrawer, closeDrawer } = useDrawerVisibility();

  if (_isEmpty(value)) return <div>Not Added</div>;

  return (
    <div>
      <Button type={TYPES.SECONDARY} onClick={showDrawer}>
        View
      </Button>
      <Drawer
        title="Reference Image"
        width="100rem"
        open={isDrawerVisible}
        onClose={closeDrawer}
        maskClosable={false}
        destroyOnClose
      >
        <Table
          rowKey={ReferenceImageReaders.referenceImagesId}
          dataSource={value}
          columns={COLUMN_CONFIG}
          expandable={expandable}
        />
      </Drawer>
    </div>
  );
};

ReferenceImageColumn.propTypes = {
  value: PropTypes.array,
};

export default memo(ReferenceImageColumn);
