import SurveyObjectiveDataIndex from "../../../../../../cortexSurvey-business/appServices/surveyObjective/constants/dataIndex/surveyObjective";

export const SEARCH_KEYS = [SurveyObjectiveDataIndex.surveyObjective];

export const EXPORT_CONFIG = {
  fileName: "suveyObjective",
  exportColumns: [
    { key: SurveyObjectiveDataIndex.id, label: "Survey Objective ID" },
    {
      key: SurveyObjectiveDataIndex.surveyObjective,
      label: "Survey Objective",
    },
  ],
};
