import React, { memo, useState, useEffect, useCallback } from "react";
import _isEmpty from "lodash/isEmpty";
import cx from "classnames";

import _head from "lodash/head";
import { renderTabs } from "./helpers/tabs.components";

import styles from "./tab.module.scss";
import { isMobile } from "react-device-detect";

const Tab = (props) => {
  const { tabs, renderer, renderOptions } = props;

  const [tab, setTab] = useState({});

  const handleTabChange = useCallback(
    (tabConfig) => {
      const { label, error, ...restTab } = _head(tabs) || {};
      const updatedTabConfig = tabConfig || restTab;
      setTab(updatedTabConfig);
    },
    [tabs, setTab]
  );

  useEffect(() => {
    if (_isEmpty(tab)) {
      handleTabChange();
    }
  }, [tabs, handleTabChange, tab]);

  return (
    <div>
      <div>{renderTabs(tabs, handleTabChange, tab)}</div>
      <div
        className={cx(styles.tabComponent, {
          [styles.mobileComponent]: isMobile,
        })}
      >
        {renderer(tab, renderOptions, styles.renderer)}
      </div>
    </div>
  );
};

export default memo(Tab);
