import React, { memo, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import { Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { getPreviewComponent } from "./helpers/previewComponent.components";

const PreviewComponent = (props) => {
  const {
    renderer: Component,
    renderProps,
    onDelete,
    src,
    index,
    previewClassName,
    type,
  } = props;

  const [visiblePreview, setVisiblePreview] = useState(false);

  const handleOnDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);

  const handlePreviewVisible = useCallback(() => {
    setVisiblePreview(true);
  }, [setVisiblePreview]);

  const handlePreviewClose = useCallback(() => {
    setVisiblePreview(false);
  }, [setVisiblePreview]);

  const previewComponent = useMemo(
    () =>
      getPreviewComponent({
        type,
        renderer: Component,
        previewClassName,
        src,
        renderProps,
        visiblePreview,
        handlePreviewClose,
        handleOnDelete,
        handlePreviewVisible,
      }),
    [
      type,
      Component,
      previewClassName,
      src,
      renderProps,
      visiblePreview,
      handlePreviewClose,
      handleOnDelete,
      handlePreviewVisible,
    ]
  );

  if (src) return previewComponent;

  return (
    <div className={previewClassName}>
      <div>
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </div>
    </div>
  );
};

PreviewComponent.propTypes = {
  renderer: PropTypes.any,
  renderProps: PropTypes.object,
  onDelete: PropTypes.func,
};

PreviewComponent.defaultProps = {
  renderer: _noop,
  renderProps: {},
  onDelete: _noop,
};

export default memo(PreviewComponent);
