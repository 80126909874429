import React, { memo } from "react";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import PropTypes from "prop-types";
import Alert from "../alert";
import Header from "../Header";

import styles from "./headerWithTable.module.scss";

const HeaderWithTable = (props) => {
  const {
    showBanner = false,
    bannerConfig = {},
    overviewComponent: OverViewComponent,
    ...restProps
  } = props;

  const { type, message } = bannerConfig;

  return (
    <div
      className={cx(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      <div className={cx(styles.header, { [styles.mobileheader]: isMobile })}>
        <Header {...restProps} />
      </div>
      {showBanner && (
        <div className={styles.banner}>
          <Alert showIcon type={type} message={message} />
        </div>
      )}
      <div className={cx(styles.content, { [styles.mobileContent]: isMobile })}>
        <OverViewComponent {...restProps} isBanner={showBanner} />
      </div>
    </div>
  );
};

HeaderWithTable.propTypes = {
  overviewComponent: PropTypes.any,
  showBanner: PropTypes.bool,
  bannerConfig: PropTypes.object,
};

export default memo(HeaderWithTable);
