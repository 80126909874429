import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard";

import Notification from "../pages/notification";

const notificationRoutes = {
  path: "survey",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "notifications",
      element: Notification,
    },
  ],
};

export default notificationRoutes;
