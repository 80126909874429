import React from "react";

import useFetchImageProcessing from "../../../cortexSurvey-business/appServices/imageProcessing/hooks/useFetchImageProcessing";
import ImageProcessingReaders from "../../../cortexSurvey-business/appServices/imageProcessing/readers/imageProcessing";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";

import ImageProcessingActiveModal from "./components/imageProcessingActiveModal";
import ImageProcessingCreateForm from "./components/imageProcessingCreateForm";
import ImageProcessingDeleteModal from "./components/imageProcessingDeleteModal";
import ImageProcessingEditForm from "./components/imageProcessingEditForm/ImageProcessingEditForm";
import ImageProcessing from "./ImageProcessing";

const ImageProcessingContainer = () => {
  const { data, loading, fetchImageProcessing } = useFetchImageProcessing();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isDrawerVisible: isDeleteModalVisible,
    showDrawer: showDeleteModal,
    closeDrawer: closeDeleteModal,
  } = useDrawerVisibility();

  const {
    selectedData: selectedActiveData,
    isDrawerVisible: isActiveModalVisible,
    showDrawer: showActiveModal,
    closeDrawer: closeActiveModal,
  } = useDrawerVisibility();

  return (
    <div>
      <ImageProcessing
        data={data}
        loading={loading}
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showDeleteModal}
        showActiveModal={showActiveModal}
        fetchImageProcessing={fetchImageProcessing}
      />

      <ImageProcessingCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchImageProcessing}
      />

      <ImageProcessingEditForm
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        formValues={selectedEditData}
        onSubmitCb={fetchImageProcessing}
        imageProcessingId={ImageProcessingReaders.imageProcessingId(
          selectedEditData
        )}
      />

      <ImageProcessingDeleteModal
        isVisible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        itemCode={ImageProcessingReaders.itemCode(selectedDeleteData)}
        onSubmitCb={fetchImageProcessing}
        imageProcessingId={ImageProcessingReaders.imageProcessingId(
          selectedDeleteData
        )}
      />

      <ImageProcessingActiveModal
        isVisible={isActiveModalVisible}
        onCancel={closeActiveModal}
        itemCode={ImageProcessingReaders.itemCode(selectedActiveData)}
        onSubmitCb={fetchImageProcessing}
        imageProcessingId={ImageProcessingReaders.imageProcessingId(
          selectedActiveData
        )}
      />
    </div>
  );
};

export default ImageProcessingContainer;
