import { getSurveyObjectiveOptions } from "../../surveyObjective/helpers/surveyObjectiveOptions";
import { getMicroZonisedAssessmentGuidelineOptions } from "./assesmentGuideline";
import { getMicrozonationOptions } from "./microzonationOptions";

export const getAssessmentGuidelineSurveyObjectiveMicroZonationOptionsFormMicroZonationData =
  (
    microZonationData,
    surveyObjectiveData,
    assessmentGuidelineId,
    surveyObjectiveId
  ) => {
    const assessmentGuidelineOptions =
      getMicroZonisedAssessmentGuidelineOptions(microZonationData);
    const surveyObjectiveOptions =
      getSurveyObjectiveOptions(surveyObjectiveData);
    const microzonationOptions = getMicrozonationOptions(
      microZonationData,
      assessmentGuidelineId,
      surveyObjectiveId
    );

    return {
      assessmentGuidelineOptions,
      surveyObjectiveOptions,
      microzonationOptions,
    };
  };
