import React, { useCallback, memo, useMemo } from "react";
import { Radio } from "antd";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

const RadioButton = (props) => {
  const {options=[], value, onChange=_noop } = props;

  const handleOnChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const renderOptions = useCallback((option) => {
    return <Radio value={option.value} key={option.value}>{option.label}</Radio>;
  }, []);

  const memoizedOptions = useMemo(
    () => options?.map(renderOptions),
    [options, renderOptions]
  );

  return (
    <Radio.Group value={value} onChange={handleOnChange}>
      {memoizedOptions}
    </Radio.Group>
  );
};

RadioButton.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
};


export default memo(RadioButton);
