import React from "react";
import { Input } from "antd";
import Label from "../label";
import cx from "classnames";

import styles from "./textArea.module.scss";

const { TextArea: AntdTextArea } = Input;

const TextArea = (props) => {
  const { className, label, required, invalid, ...restProps } = props;
  return (
    <div className={styles.textAreaContainer}>
      {label && <Label label={label} required={required} />}
      <AntdTextArea
        {...restProps}
        className={cx(styles.textArea, className, {
          [styles.disabled]: restProps.disabled,
        })}
      />
    </div>
  );
};

export default TextArea;
