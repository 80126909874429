import { FIELD_IDS } from "../constants/notificationForm.fieldId";
import _get from "lodash/get";

const getFieldType = (formState) => {
  const fieldType = _get(formState, FIELD_IDS.NOTIFICATION_TYPE);
  return fieldType;
};

const getMediaRow = (formState) => {
  const type = getFieldType(formState);
  if (type === "Image") return [{ columns: [FIELD_IDS.NOTIFICATION_IMAGE] }];

  return [{ columns: [FIELD_IDS.NOTIFICATION_VIDEO] }];
};

export const getSections = (formState) => [
  {
    rows: [
      { columns: [FIELD_IDS.NOTIFICATION_TITLE] },
      { columns: [FIELD_IDS.NOTIFICATION_TYPE] },
      ...getMediaRow(formState),
    ],
  },
];
