import { FIELD_IDS } from "./cityIndexMappingForm.fieldId";

export const SECTIONS = [
  {
    label: "MicroZonation Mapping",
    rows: [
      {
        columns: [
          FIELD_IDS.ASSESSMENT_GUIDELINE,
          FIELD_IDS.SURVEY_OBJECTIVE,
          FIELD_IDS.MICROZONATION,
        ],
      },
    ],
  },
  {
    label: "City And Soil Mapping",
    rows: [
      { columns: [FIELD_IDS.COUNTRY, FIELD_IDS.STATE, FIELD_IDS.CITY] },

      { columns: [FIELD_IDS.SOIL] },
    ],
  },
];
