import { isMobile } from "react-device-detect";
import StructureTypeDataIndex from "../../../../../../cortexSurvey-business/appServices/structureType/constants/dataIndex/structureType";
import Image from "../../../../../../cortexSurvey-components/image";
import TextRenderer from "../../../../../../cortexSurvey-components/textRenderer/TextRenderer";

import StructureComponents from "../components/structureComponents/StructureComponents";
import StructureImages from "../components/structureImages/StructureImages";
import StructureVideo from "../components/structureVideo";


const STRUCTURE_TYPE_CODE = {
  title: "Structure Type Code",
  dataIndex: StructureTypeDataIndex.id,
  key: StructureTypeDataIndex.id,
  width: "18rem",
  render:(value)=><TextRenderer value={value}/>
};

const STRUCTURE_TYPE = {
  title: "Structure Type Name",
  dataIndex: StructureTypeDataIndex.structureName,
  key: StructureTypeDataIndex.structureName,
  width: "18rem",
  fixed: !isMobile && "left",
  render:(value)=><TextRenderer value={value}/>
};

const STRUCTURE_IMAGE = {
  title: "Structure Type Image",
  dataIndex: StructureTypeDataIndex.structureImage,
  key: StructureTypeDataIndex.structureImage,
  render: (value) => <Image value={value} width="5rem" />,
  width: "16rem",
};

const STRUCTURE_DESCRIPTION = {
  title: "Structure Type Description",
  dataIndex: StructureTypeDataIndex.structureDetails,
  key: StructureTypeDataIndex.structureDetails,
  ellipsis: true,
  width: "39rem",
};

const STRUCTURE_IMAGES = {
  title: "Structure Type Images",
  dataIndex: StructureTypeDataIndex.structureImages,
  key: StructureTypeDataIndex.structureImages,
  render: (value) => <StructureImages imageLinks={value} />,
  width: "20rem",
};

const STRUCTURE_VIDEO = {
  title: "Structure Type Video",
  dataIndex: StructureTypeDataIndex.structureVideo,
  key: StructureTypeDataIndex.structureVideo,
  render: (value) => <StructureVideo videoLink={value} />,
  width: "20rem",
};

const STRUCTURE_COMPONENTS = {
  title: "Structure Type Components",
  dataIndex: StructureTypeDataIndex.structureComponents,
  key: StructureTypeDataIndex.structureComponents,
  render: (value) => <StructureComponents value={value} />,
  width: "22rem",
};

export const COLUMNS = [
  STRUCTURE_TYPE_CODE,
  STRUCTURE_TYPE,
  STRUCTURE_IMAGE,
  STRUCTURE_DESCRIPTION,
  STRUCTURE_IMAGES,
  STRUCTURE_VIDEO,
  STRUCTURE_COMPONENTS,
];
