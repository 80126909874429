import { useCallback, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";

import QuestionnaireAPI from "../services/questionnaire";
import useFetchPayload from "../../../hooks/useFetchPayload";

const useFetchQuestionnaire = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchQuestionnaire = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await QuestionnaireAPI.fetchQuestionnaire(payload);
        setData(response?.data);
      } catch (err) {
        toaster(
          TOASTER_TYPE.ERROR,
          getErrorMessage("Failed to fetch Questionnaire")
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchQuestionnaire = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchQuestionnaire, 1000);
      debounceFunction(isActive);
    },
    [fetchQuestionnaire, setLoading]
  );

  useEffect(() => {
    fetchQuestionnaire();
  }, [fetchQuestionnaire]);

  return {
    loading,
    data,
    fetchQuestionnaire: handleDebounceFetchQuestionnaire,
  };
};

export default useFetchQuestionnaire;
