import React, { memo, useCallback, useMemo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import SurveyObjectiveForm from "../surveyObjectiveForm";
import { ACTION_TYPES } from "./constants/surveyObjectiveEditForm.actionTypes";
import { FORM_ID } from "./constants/surveyObjectiveEditForm.form";
import { INITIAL_STATE } from "./constants/surveyObjectiveEditForm.initialState";
import {
  DRAWER_TITLE,
  DRAWER_WIDTH,
} from "./constants/surveyObjectveEditForm.general";
import ACTION_HANDLERS from "./helpers/surveyObjectiveEditForm.actionHandlers";
import { makeInitialValues } from "./helpers/surveyObjectiveEditForm.initialValues";

const SurveyObjectiveEditForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    formData = {},
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const initialValues = useMemo(() => makeInitialValues(formData), [formData]);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.EDIT_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title={DRAWER_TITLE}
      maskClosable={false}
      destroyOnClose
      width={DRAWER_WIDTH}
      loading={isSubmitting}
      onSubmit={handleSave}
    >
      <SurveyObjectiveForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        view={isSubmitting}
      />
    </Drawer>
  );
};

SurveyObjectiveEditForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  formData: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default compose(memo)(
  withActions(INITIAL_STATE, ACTION_HANDLERS)(SurveyObjectiveEditForm)
);
