import React, { memo, useCallback, useMemo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import WithActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer/Drawer";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import { FORM_ID } from "./constants/dashboardUserProfile.form";
import { DRAWER_WIDTH } from "./constants/dashboardUserProfile.general";
import { SECTIONS } from "./constants/dashboardUserProfileForm.sections";
import { ACTION_TYPES } from "./constants/dashbordUserProfile.actionTypes";
import ACTION_HANDLERS from "./helpers/dashboardUserProfileForm.actionHandlers";
import getFormConfig from "./helpers/dashboardUserProfileForm.formConfig";
import makeInitialValues from "./helpers/dashboardUserProfileForm.makeInitialValues";

const DashboardUserProfileForm = (props) => {
  const {
    data = {},
    view = false,
    isVisible = false,
    isSubmitting = false,
    onClose = _noop,
    onAction = _noop,
  } = props;

  const initialValues = useMemo(() => makeInitialValues(data), [data]);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.SUBMIT,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      maskClosable={false}
      onClose={onClose}
      width={DRAWER_WIDTH}
      loading={isSubmitting}
      onSubmit={handleSave}
      destroyOnClose
    >
      <FormWithSubmission
        formId={FORM_ID}
        formConfig={getFormConfig}
        initialValues={initialValues}
        sections={SECTIONS}
        onSubmit={handleSubmission}
        view={view}
      />
    </Drawer>
  );
};

DashboardUserProfileForm.propTypes = {
  data: PropTypes.object,
  view: PropTypes.bool,
  isVisible: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  WithActions({}, ACTION_HANDLERS)
)(DashboardUserProfileForm);
