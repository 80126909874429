import _noop from "lodash/noop";

import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import CountryAPI from "../../../../../../cortexSurvey-business/appServices/country/services/country";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../contants/countryDeleteModal.actionTypes";

import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../contants/countryDeleteModal.general";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { countryId, onCancel, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload={countryId, isActive: 0 };
    await CountryAPI.deleteCountry(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err,ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
