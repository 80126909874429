import React, { memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Upload from "../upload";
import Image from "../image";
import { getImagePayload } from "./imageUpload.helper";

import styles from "./imageUpload.module.scss";

const ImageUpload = (props) => {
  const { width, height, className } = props;
  
  return (
    <Upload
      uploadClassName={cx(styles.upload, className)}
      previewClassName={cx(styles.previewClassName, className)}
      {...props}
      type="image"
      renderer={Image}
      renderProps={{ style: { width, height } }}
      formPayload={getImagePayload}
    />
  );
};

ImageUpload.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
};

ImageUpload.defaultProps = {
  width: "20rem",
  height: "20rem",
};

export default memo(ImageUpload);
