import React from "react";
// import { WarningTwoTone } from "@ant-design/icons";

import SaveComponent from "../../saveComponent";

import styles from "../drawer.module.scss";

export const renderSaveComponent = (
  handleSave,
  handleCancel,
  loading,
  error
) => {
  return (
    <div className={styles.saveComponent}>
      <SaveComponent
        showCancel
        handleCancel={handleCancel}
        handleSave={handleSave}
        loading={loading}
        disabled={loading}
      />

      {/* {error && (
        <div className={styles.error}>
          <div>
            <WarningTwoTone twoToneColor="#d11010" />
          </div>
          <div>{error}</div>
        </div>
      )} */}
    </div>
  );
};
