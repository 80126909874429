import React from "react";
import { isMobile } from "react-device-detect";
import { AppstoreOutlined } from "@ant-design/icons";
import cx from "classnames";

import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import { IMAGE_LINK } from "../../cortexSurvey-base/assets/images/imageLink";
import { HOME_PAGE } from "../../cortexSurvey-business/helpers/appHelpers/navigation";
import { Image } from "antd";
import DashboardUserProfile from "../../cortexSurvey-dashboardUsers/pages/dashboardUserProfile";

import styles from "./dashboardtemplate.module.scss";
import Button from "../../cortexSurvey-components/button/Button";
import { TYPES } from "../../cortexSurvey-components/button";
import { navigationEmitter } from "../../cortexSurvey-components/surveyDashboard/SurveyDashboard";

const DashboardTemplate = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate(HOME_PAGE);
  };

  return (
    <div className={cx(styles.contentContainer)}>
      <div className={styles.header}>
        {isMobile && (
          <div className={styles.iconContainer}>
            <Button
              className={styles.leftIcon}
              onClick={() => navigationEmitter.emit("open")}
              type={TYPES.TERTIARY}
            >
              <AppstoreOutlined
                style={{ fontSize: "26px", color: "white", padding: "1rem" }}
              />
            </Button>
          </div>
        )}

        <div className={styles.left}>
          <Image
            src={IMAGE_LINK.LOGO_BG}
            preview={false}
            onClick={redirectToHome}
            width={100}
            height={40}
          />
        </div>

        <div className={styles.right}>
          <DashboardUserProfile />
        </div>
      </div>
      <div className={cx(styles.content, { [styles.mobileContent]: isMobile })}>
        {children}
      </div>
    </div>
  );
};

DashboardTemplate.propTypes = {
  children: PropTypes.any,
  displayName: PropTypes.string,
};

export default DashboardTemplate;
