const id = "scaleMappingId";
const assessmentGuideline = "assessmentGuideline";
const assessmentGuidelineId = "assessmentGuidelineId";
const cityId = "cityId";
const cityName = "cityName";
const countryId = "countryId";
const countryName = "countryName";
const level = "level";
const microzationId = "microzonationId";
const microzonation="microzonation"
const soil = "soil";
const stateId = "stateId";
const stateName = "stateName";
const surveyObjective = "surveyObjective";
const surveyObjectiveId = "surveyObjectiveId";
const country = "country";
const state = "state";
const city = "city";

const DATA_INDEX = {
  id,
  assessmentGuideline,
  assessmentGuidelineId,
  cityId,
  cityName,
  countryId,
  countryName,
  level,
  microzationId,
  soil,
  stateId,
  stateName,
  surveyObjective,
  surveyObjectiveId,
  country,
  state,
  city,
  microzonation
};

export default DATA_INDEX;
