import { getErrorMessage } from "../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../cortexSurvey-components/notificationWrapper";

export const errorHandler = (err, setState, mesaage) => {
  const errorMessage = getErrorMessage(err, getErrorMessage(err, mesaage));

  setState({ error: errorMessage});

  toaster(TOASTER_TYPE.ERROR, errorMessage);
};
