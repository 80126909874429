import _property from "lodash/property";
import CityIndexMappingDataIndex from "../constants/dataIndex/cityIndexMapping";

const id = _property(CityIndexMappingDataIndex.id);
const assessmentGuideline = _property(
  CityIndexMappingDataIndex.assessmentGuideline
);
const assessmentGuidelineId = _property(
  CityIndexMappingDataIndex.assessmentGuidelineId
);
const cityId = _property(CityIndexMappingDataIndex.cityId);
const cityName = _property(CityIndexMappingDataIndex.cityName);
const city = _property(CityIndexMappingDataIndex.city);
const countryId = _property(CityIndexMappingDataIndex.countryId);
const countryName = _property(CityIndexMappingDataIndex.countryName);
const country = _property(CityIndexMappingDataIndex.country);
const microzonation=_property(CityIndexMappingDataIndex.microzonation);
const level = _property(CityIndexMappingDataIndex.level);
const microzationId = _property(CityIndexMappingDataIndex.microzationId);
const soil = _property(CityIndexMappingDataIndex.soil);
const stateId = _property(CityIndexMappingDataIndex.stateId);
const state = _property(CityIndexMappingDataIndex.state);
const stateName = _property(CityIndexMappingDataIndex.stateName);
const surveyObjective = _property(CityIndexMappingDataIndex.surveyObjective);
const surveyObjectiveId = _property(
  CityIndexMappingDataIndex.surveyObjectiveId
);

const READERS = {
  id,
  assessmentGuideline,
  assessmentGuidelineId,
  cityId,
  cityName,
  countryId,
  countryName,
  level,
  microzationId,
  soil,
  stateId,
  stateName,
  surveyObjective,
  surveyObjectiveId,
  state,
  country,
  city,
  microzonation
};

export default READERS;
