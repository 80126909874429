import React from "react";
import cx from "classnames";
import _isEqual from "lodash/isEqual";

import _map from "lodash/map";

import styles from "../tab.module.scss";
import { WarningTwoTone } from "@ant-design/icons";

const renderTab = (handleTabChange, activeTab) => (tab) => {
  const { label, error, ...restTab } = tab;

  const isActive = _isEqual(activeTab, restTab);

  const onTabChange = () => {
    handleTabChange({ ...restTab });
  };

  return (
    <div
      className={cx(styles.tab, { [styles.tab__active]: isActive })}
      onClick={onTabChange}
    >
      <div>{label}</div>
      {error && (
        <div>
          <WarningTwoTone twoToneColor="#d11010" />
        </div>
      )}
    </div>
  );
};

export const renderTabs = (tabs, handleTabChange, activeTab) => {
  return (
    <div className={styles.tabsContainer}>
      {_map(tabs, renderTab(handleTabChange, activeTab))}
    </div>
  );
};
