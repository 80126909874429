import _property from 'lodash/property';

import { FIELD_IDS } from "../constants/surveyObjectiveForm.fieldId";

const surveyObjective=_property(FIELD_IDS.SURVEY_OBJECTIVE);

const READERS={
    surveyObjective
}

export default READERS;