import React from "react";
import { Button } from "antd";
import {
  DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import {
  generateExcel,
  generatePdf,
  validateExportConfig,
} from "./helpers/tableExportButton.general";
import { TOASTER_TYPE, toaster } from "../../../notificationWrapper";
import _isEmpty from "lodash/isEmpty";
import { TYPES } from "../../../button";
import Dropdown from "../../../dropdown";

const TableExportButton = ({ data, exportConfig }) => {
  const { fileName, exportColumns } = exportConfig;

  const handleExcelDownload = () => {
    if (_isEmpty(data) || !validateExportConfig(exportColumns)) {
      toaster(TOASTER_TYPE.ERROR, "Something Went Wrong to Generate Report.");
      return;
    }

    generateExcel(data, exportColumns, fileName);
    toaster(
      TOASTER_TYPE.SUCCESS,
      "Excel file created and downloaded successfully."
    );
  };

  const handlePDFDownload = () => {
    if (_isEmpty(data) || !validateExportConfig(exportColumns)) {
      toaster(TOASTER_TYPE.ERROR, "Something Went Wrong to Generate Report.");
      return;
    }

    generatePdf(data, exportColumns, fileName);
    toaster(
      TOASTER_TYPE.SUCCESS,
      "Excel file created and downloaded successfully."
    );
  };

  const items = [
    {
      key: "Excel",
      label: (
        <Button
          type={TYPES.TERTIARY}
          onClick={handleExcelDownload}
          icon={<FileExcelOutlined />}
        >
          Download Excel
        </Button>
      ),
    },
    {
      key: "PDF",
      label: (
        <Button
          type={TYPES.TERTIARY}
          onClick={handlePDFDownload}
          icon={<FilePdfOutlined />}
        >
          Download PDF
        </Button>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button type={TYPES.TERTIARY} icon={<DownloadOutlined />}>
        Download Reports
      </Button>
    </Dropdown>
  );
};

export default TableExportButton;
