import StateFormReaders from "../../stateForm/readers";

export const getPayloadFromFormValues = (formValues) => {
  const countryId = StateFormReaders.country(formValues);
  const stateName = StateFormReaders.state(formValues);
  return {
    countryId,
    stateName,
  };
};
