import React from "react";

import { Modal as AntdModal } from "antd";

const Modal = (props) => {
  const { children, ...restProps } = props;
  return <AntdModal {...restProps}>{children}</AntdModal>;
};

export default Modal;
