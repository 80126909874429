import _map from "lodash/map";
import _range from "lodash/range";

import { PAGE_SIZE_OPTIONS } from "../constants/pagination,general";

const createPAgeSizeMenuItem = (onClick) => (pageSizeOption) => {
  const { label, value } = pageSizeOption;
  const handleOnClick = () => onClick(value);

  return { label, key: value, onClick: handleOnClick };
};

export const getPageSizeMenuItems = (onClick) =>
  _map(PAGE_SIZE_OPTIONS, createPAgeSizeMenuItem(onClick));

export const getVisiblePageNumbers = (currentPage, total, pageSize) => {
  const totalPages = Math.ceil(total / pageSize);

  if (totalPages <= 10) {
    return _range(1, totalPages + 1);
  } else if (currentPage <= 5) {
    return [..._range(1, 6), "...", ..._range(totalPages - 3, totalPages + 1)];
  } else if (currentPage > totalPages - 5) {
    return [..._range(1, 3), "...", ..._range(totalPages - 6, totalPages + 1)];
  } else {
    return [
      1,
      "...",
      ..._range(currentPage - 1, currentPage + 5),
      "...",
      ..._range(totalPages - 1, totalPages + 1),
    ];
  }
};
