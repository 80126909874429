import React from "react";

import _isEmpty from "lodash/isEmpty";

import StructureTypeDataIndex from "../../../../../../cortexSurvey-business/appServices/structureType/constants/dataIndex/structureType";
import Button from "../../../../../../cortexSurvey-components/button";
import { ACTION_TYPES } from "../../../constants/structureType.actionTypes";

import { COLUMNS } from "../constants/structureTypeOverview.columns";

import { isMobile } from "react-device-detect";

const renderStructureProblem = (onAction) => (value, rowData) => {
  if (_isEmpty(value)) return <div>No Defect</div>;

  return (
    <Button
      onClick={() => {
        onAction({
          type: ACTION_TYPES.VIEW_STRUCTURE_PROBLEM,
          payload: { rowData },
        });
      }}
    >
      View Problem
    </Button>
  );
};

const getStructureProblemColumn = (onAction) => ({
  title: "Structure Problem",
  dataIndex: StructureTypeDataIndex.structureProblem,
  key: "structurePoblem",
  render: renderStructureProblem(onAction),
  width: "14rem",
  fixed: !isMobile && "right",
});

export const getColumnConfig = (onAction) => {
  const STRUCTURE_PROBLEM_COLUMN = getStructureProblemColumn(onAction);

  return [...COLUMNS, STRUCTURE_PROBLEM_COLUMN];
};
