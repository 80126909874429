import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { Image as AntdImage } from "antd";

import { getSrc } from "../../cortexSurvey-base/helpers/upload/general";
import { FALLBACK_IMAGE } from "./constants/image.general";

const Image = (props) => {
  const { value, src, url, ...restProps } = props;
  const srcUrl = useMemo(() => getSrc(value || url || src), [value, url, src]);
  return <AntdImage {...restProps} src={srcUrl} fallback={FALLBACK_IMAGE} />;
};

Image.propTypes = {
  value: PropTypes.string,
  src: PropTypes.string,
  url: PropTypes.string,
};

export default memo(Image);
