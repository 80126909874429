import {
  DELETE,
  ROW_ACTIONS,
} from "../constants/cityIndexMappingOverview.rowActions";

import CityIndexMappingReaders from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/readers/cityIndexMapping";

export const getRowActions = (rowData,disabled) => {
  // const country = CityIndexMappingReaders.country(rowData);
  const state = CityIndexMappingReaders.state(rowData);
  const city = CityIndexMappingReaders.city(rowData);
  const assessmentGuideline =
    CityIndexMappingReaders.assessmentGuideline(rowData);
  const surveyObjective = CityIndexMappingReaders.surveyObjective(rowData);
  const microZonation = CityIndexMappingReaders.microzonation(rowData);

  // const { isActive: isCountryActive } = country || {};
  const { isActive: isStateActive } = state || {};
  const { isActive: isCityActive } = city || {};
  const { isActive: isSurveyObjectiveActive } = surveyObjective || {};
  const { isActive: isAssessmentGuidelineActive } = assessmentGuideline || {};
  const { isActive: isMicrozonationActive } = microZonation || {};

  if (
    // isCountryActive &&
    isStateActive &&
    isCityActive &&
    isAssessmentGuidelineActive &&
    isSurveyObjectiveActive &&
    isMicrozonationActive && !disabled
  )
    return ROW_ACTIONS;

  return [DELETE];
};
