import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";
import isEmail from "../../../../../../cortexSurvey-components/formWithSubmissionV2/validators/isEmail";

import { FIELD_ID } from "./forgotPassword.fieldId";

export const FORGOT_EMAIL = {
  id: FIELD_ID.FORGOT_EMAIL,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Email",
    type: "email",
    placeholder: "email",
    required: true,
    validations: isEmail
  },
};
