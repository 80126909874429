import React from "react";
import DashboardUsers from "./DashboardUsers";
import DashboardUserReaders from "../../../cortexSurvey-business/appServices/dashboardUser/readers/user";
import useFetchDashboardUsers from "../../../cortexSurvey-business/appServices/dashboardUser/hooks/useFetchDashboardUsers";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import DashboardUsersCreateForm from "./components/dashboardUsersCreateForm";
import DashboardUserEditForm from "./components/dashboardUsersEditForm/DashboardUserEditForm";
import DashboardUsersDeleteModal from "./components/dashboardUsersDeleteModal.js/DashboardUsersDeleteModal";
import DashboardUsersActiveModal from "./components/dashboardUsersActiveModal/DashboardUsersActiveModal";
import DashboardUsersVerificationModal from "./components/dashboardUsersVerificationModal/DashboardUsersVerificationModal";

const DashboardUsersContainer = () => {
  const { data, loading, fetchDashboardUsers } = useFetchDashboardUsers();

  const {
    isDrawerVisible: isAddUserFormVisible,
    showDrawer: showAddUserForm,
    closeDrawer: closeAddUserForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditUserFormData,
    isDrawerVisible: isEditUserFormVisible,
    showDrawer: showEditUserForm,
    closeDrawer: closeEditUserForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isDeleteUserModalVisible,
    showModal: showDeleteUserModal,
    closeModal: closeDeleteUserModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isActiveUserModalVisible,
    showModal: showActiveUserModal,
    closeModal: closeActiveUserModal,
  } = useModalVisibility({});

  const {
    selectedData,
    isModalVisible: isVerificationModalVisible,
    showModal: showVerificationUserModal,
    closeModal: closeVerificationModal,
  } = useModalVisibility({});

  return (
    <div>
      <DashboardUsers
        data={data}
        loading={loading}
        showCreateForm={showAddUserForm}
        showDeleteModal={showDeleteUserModal}
        showEditForm={showEditUserForm}
        showActiveModal={showActiveUserModal}
        showVerificationModal={showVerificationUserModal}
        fetchDashboardUsers={fetchDashboardUsers}
      />
      <DashboardUsersCreateForm
        isVisible={isAddUserFormVisible}
        onClose={closeAddUserForm}
        onSubmitCb={fetchDashboardUsers}
      />

      <DashboardUserEditForm
        formData={selectedEditUserFormData}
        isVisible={isEditUserFormVisible}
        onClose={closeEditUserForm}
        onSubmitCb={fetchDashboardUsers}
        userId={DashboardUserReaders.userId(selectedEditUserFormData)}
      />

      <DashboardUsersDeleteModal
        isVisible={isDeleteUserModalVisible}
        onCancel={closeDeleteUserModal}
        onSubmitCb={fetchDashboardUsers}
        userId={DashboardUserReaders.userId(selectedDeleteData)}
        email={DashboardUserReaders.email(selectedDeleteData)}
      />

      <DashboardUsersActiveModal
        isVisible={isActiveUserModalVisible}
        onCancel={closeActiveUserModal}
        onSubmitCb={fetchDashboardUsers}
        userId={DashboardUserReaders.userId(selectedActiveData)}
        email={DashboardUserReaders.email(selectedActiveData)}
      />

      <DashboardUsersVerificationModal
        isVisible={isVerificationModalVisible}
        onCancel={closeVerificationModal}
        onSubmitCb={fetchDashboardUsers}
        userId={DashboardUserReaders.userId(selectedData)}
        email={DashboardUserReaders.email(selectedData)}
      />
    </div>
  );
};

export default DashboardUsersContainer;
