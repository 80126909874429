import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_IMAGE_PROCESSING: "/image-processing-data",
  CREATE_IMAGE_PROCESSING: "/image-processing-add",
  EDIT_IMAGE_PROCESSING: "/image-processing-update",
  DELETE_IMAGE_PROCESSING: "/is-active",
};

const fetchImageProcessing = (payload) =>
  HttpClient.post(API_PATHS.FETCH_IMAGE_PROCESSING, payload);

const createImageProcessing = (payload) =>
  HttpClient.post(API_PATHS.CREATE_IMAGE_PROCESSING, payload);

const editImageProcessing = (payload) =>
  HttpClient.post(API_PATHS.EDIT_IMAGE_PROCESSING, payload);

const deleteImageProcessing = (payload) =>
  HttpClient.post(API_PATHS.DELETE_IMAGE_PROCESSING, payload);

const ImageProcessingAPI = {
  fetchImageProcessing,
  createImageProcessing,
  editImageProcessing,
  deleteImageProcessing,
};

export default ImageProcessingAPI;
