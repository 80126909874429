import AssessmentGuidelineFormReaders from "../../assesmentGuidelineForm/readers";

export const getPayloadFromFormValues = (formValues, assessmentGuidelineId) => {
  const assessmentGuideline =
    AssessmentGuidelineFormReaders.assessmentGuideline(formValues);
  return {
    assessmentGuidelineId,
    assessmentGuideline,
  };
};
