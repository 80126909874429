import React, { memo } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import _noop from "lodash/noop";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";

import CityIndexOverview from "./components/cityIndexMappingOverview";
import { ACTION_TYPES } from "./constants/cityIndexMapping.actionTypes";
import { INITIAL_STATE } from "./constants/cityIndexMapping.initialStates";
import ACTION_HANDLERS from "./helpers/cityIndexMapping.actionHandlers";

const CityIndexMapping = (props) => {
  const {
    onAction = _noop,
    data,
    loading = false,
    onReload = _noop,
    fetchData = _noop,
    isCityIndexMappingNotSync = false,
    bannerConfig
  } = props;

  return (
    <HeaderWithTable
      title="City Index Mapping"
      createButtonLabel="Add City Index Mapping"
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={CityIndexOverview}
      createActionType={ACTION_TYPES.CREATE}
      onReload={onReload}
      fetchData={fetchData}
      showBanner={isCityIndexMappingNotSync}
      bannerConfig={bannerConfig}
      disabled={isCityIndexMappingNotSync}
      showReload
    />
  );
};

CityIndexMapping.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onReload: PropTypes.func,
  fetchData: PropTypes.func,
  isCityIndexMappingNotSync: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CityIndexMapping);
