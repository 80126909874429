import React, { useState } from "react";

import useFetchStructureType from "../../../cortexSurvey-business/appServices/structureType/hooks/useFetchStructureType";
import StructureTypeReaders from "../../../cortexSurvey-business/appServices/structureType/readers/structureType";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import StructureTypeActiveModal from "./components/structureTypeActiveModal";
import StructureComponentForm from "./components/structureComponentForm";
import StructureHelpForm from "./components/structureHelpForm";
import StructureProblemForm from "./components/structureProblemForm";
import StructureTypeCreateForm from "./components/structureTypeCreateForm";
import StructureTypeDeleteModal from "./components/structureTypeDeleteModal";
import StructureTypeEditForm from "./components/structureTypeEditForm";

import StructureType from "./StructureType";

const StructureTypeContainer = () => {
  const [disableEditForm, setDisableEditForm] = useState(false);

  const { loading, data, fetchStructureType } = useFetchStructureType();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditForm,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedStructureProblemData,
    isDrawerVisible: isStructureProblemFormVisible,
    showDrawer: showStructureProblemForm,
    closeDrawer: closeStructureProblemForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedHelpFormData,
    isDrawerVisible: isStructureHelpFormVisible,
    showDrawer: showStructureHelpForm,
    closeDrawer: closeStructureHelpForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedComponentData,
    isDrawerVisible: isStructureComponentFormVisible,
    showDrawer: showStructureComponentForm,
    closeDrawer: closeStructureComponentForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isStructureTypeDeletionModalVisible,
    showModal: showStructureTypeDeletionModal,
    closeModal: closeStructureTypeDeletionModal,
  } = useModalVisibility();

  const {
    selectedData: selectedActiveData,
    isModalVisible: isStructureTypeActiveModalVisible,
    showModal: showStructureTypeActiveModal,
    closeModal: closeStructureTypeActiveModal,
  } = useModalVisibility();

  return (
    <div>
      <StructureType
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showStructureTypeDeletionModal}
        showActiveModal={showStructureTypeActiveModal}
        showStructureProblem={showStructureProblemForm}
        showStructureHelp={showStructureHelpForm}
        showStructureComponent={showStructureComponentForm}
        setDisableEditForm={setDisableEditForm}
        fetchStructureType={fetchStructureType}
        data={data}
        loading={loading}
      />

      <StructureTypeCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchStructureType}
      />

      <StructureTypeEditForm
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        formData={selectedEditForm}
        onSubmitCb={fetchStructureType}
        id={StructureTypeReaders.id(selectedEditForm)}
      />

      <StructureTypeDeleteModal
        isVisible={isStructureTypeDeletionModalVisible}
        onCancel={closeStructureTypeDeletionModal}
        id={StructureTypeReaders.id(selectedDeleteData)}
        structureType={StructureTypeReaders.structureName(selectedDeleteData)}
        onSubmitCb={fetchStructureType}
      />

      <StructureTypeActiveModal
        isVisible={isStructureTypeActiveModalVisible}
        onCancel={closeStructureTypeActiveModal}
        id={StructureTypeReaders.id(selectedActiveData)}
        structureType={StructureTypeReaders.structureName(selectedActiveData)}
        onSubmitCb={fetchStructureType}
      />

      <StructureProblemForm
        isVisible={isStructureProblemFormVisible}
        onClose={closeStructureProblemForm}
        formValues={selectedStructureProblemData}
        view={disableEditForm}
        onSubmitCb={fetchStructureType}
        id={StructureTypeReaders.id(selectedStructureProblemData)}
      />

      <StructureHelpForm
        isVisible={isStructureHelpFormVisible}
        onClose={closeStructureHelpForm}
        formValues={selectedHelpFormData}
        onSubmitCb={fetchStructureType}
        id={StructureTypeReaders.id(selectedHelpFormData)}
      />

      <StructureComponentForm
        isVisible={isStructureComponentFormVisible}
        onClose={closeStructureComponentForm}
        formValues={selectedComponentData}
        id={StructureTypeReaders.id(selectedComponentData)}
        onSubmitCb={fetchStructureType}
      />
    </div>
  );
};

export default StructureTypeContainer;
