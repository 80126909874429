import DashboardUsersFormReaders from "../../dashboardUsersForm/readers";

export const getPayloadFromFormValues = (formValues) => {
  const firstName = DashboardUsersFormReaders.firstName(formValues);
  const lastName = DashboardUsersFormReaders.lastName(formValues);
  const email = DashboardUsersFormReaders.email(formValues);
  const role = DashboardUsersFormReaders.role(formValues);

  return { firstName, lastName, email, role };
};
