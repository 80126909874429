//componets
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/dashboardUsersCreateForm.actionTypes";
import { getPayloadFromFormValues } from "./dashboardUsers.payload";
import DashboardUserAPI from "../../../../../../cortexSurvey-business/appServices/dashboardUser/services/dashboardUsers";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose, onSubmitCb } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (error) return;

  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues);
    await DashboardUserAPI.createDashboardUser(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Dashboard User Created Successfully");
    onClose();
  } catch (err) {
    errorHandler(
      err,
      setState,
      "Failed to create Dashboard User. Please try again."
    );
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
