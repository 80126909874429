import CityReaders from "../../../../../../cortexSurvey-business/appServices/city/readers/city";

import CityDataIndex from "../../../../../../cortexSurvey-business/appServices/city/constants/dataIndex/city";

import { FIELD_IDS } from "../../cityForm/constants/cityForm.fieldId";

import _get from "lodash/get";

export const makeInitialValues = (formData) => {
  return {
    [FIELD_IDS.COUNTRY]: CityReaders.countryId(
      _get(formData, CityDataIndex.country)
    ),
    [FIELD_IDS.STATE]: CityReaders.stateId(_get(formData, CityDataIndex.state)),
    [FIELD_IDS.CITY]: CityReaders.city(formData),
    [FIELD_IDS.SOIL]: CityReaders.soil(formData),
  };
};
