import React, { useCallback, useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";

import cx from "classnames";
import PropTypes from "prop-types";

import _map from "lodash/map";
import _noop from "lodash/noop";

import Button, { TYPES } from "../button";
import Dropdown from "../dropdown/Dropdown";
import {
  getPageSizeMenuItems,
  getVisiblePageNumbers,
} from "./helpers/pagination.general";

import styles from "./pagination.module.scss";

const Pagination = (props) => {
  const { total, pageSize, currentPage, onPageSizeChange, onPageChange } =
    props;

  const handlePageSizeChange = useCallback(
    (value) => {
      onPageSizeChange(value);
      onPageChange(1);
    },
    [onPageChange, onPageSizeChange]
  );

  const items = useMemo(
    () => getPageSizeMenuItems(handlePageSizeChange),
    [handlePageSizeChange]
  );

  const visiblePageNumbers = useMemo(
    () => getVisiblePageNumbers(currentPage, total, pageSize),
    [currentPage, total, pageSize]
  );

  const renderVisiblePageNumber = useCallback(
    (value) => (
      <Button
        className={cx(styles.pageNumButton, {
          [styles.active]: value === currentPage,
        })}
        type={TYPES.TERTIARY}
        onClick={() => onPageChange(value)}
        disabled={value === "..."}
      >
        {value}
      </Button>
    ),
    [onPageChange, currentPage]
  );

  useEffect(() => {
    onPageSizeChange(25);
    onPageChange(1);
  }, [onPageSizeChange, onPageChange]);

  return (
    <div className={styles.container}>
      <div className={styles.pageSize}>
        {!isMobile ? (
          <Dropdown
            menu={{ items, selectable: true, defaultSelectedKeys: [pageSize] }}
            trigger={["click"]}
          >
            <div className={styles.dropDown}>{pageSize}</div>
          </Dropdown>
        ) : (
          <div>{pageSize}</div>
        )}

        <div>Results per Page</div>
      </div>

      <div className={styles.pageNum}>
        {isMobile && <div>currPage: {currentPage}</div>}
        
        <Button
          type={TYPES.TERTIARY}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </Button>
        {!isMobile && _map(visiblePageNumbers, renderVisiblePageNumber)}
        <Button
          type={TYPES.TERTIARY}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(total / pageSize)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  onPageSizeChange: PropTypes.func,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
  pageSize: 25,
  onPageSizeChange: _noop,
  currentPage: 1,
  onPageChange: _noop,
};

export default Pagination;
