import ResetPasswordFormReader from "../components/resetPasswordForm/readers";
import queryString from "query-string";
import _get from "lodash/get";

const getCodeFromLocation = (location) => {
  const parsed = queryString.parse(location.search);
  const data = _get(parsed, "data");
  return data;
};

export const getPayloadFromFormValues = (formValues, location) => {
  const code = getCodeFromLocation(location);
  const password = ResetPasswordFormReader.password(formValues);
  return {
    password,
    code,
  };
};
