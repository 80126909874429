import React, { useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import MicroZonationForm from "../microZonationForm";
import { ACTION_TYPES } from "./constants/microZonationEditForm.actionTypes";
import { FORM_ID } from "./constants/microZonationEditForm.form";
import {
  DRAWER_TITLE,
  DRAWER_WIDHT,
} from "./constants/microZonationEditForm.general";
import { INITIAL_STATE } from "./constants/microZonationEditForm.initialState";
import ACTION_HANDLERS from "./helpers/microZonationEditForm.actionHandlers";
import { makeInitialValues } from "./helpers/microZonationEditForm.initialValues";

const MicroZonationEditForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    formData = {},
    onAction = _noop,
    assesmentGuidelineOptions = [],
    microZonationData = [],
    surveyObjective,
    isSubmitting = false,
  } = props;

  const initialValues = useMemo(
    () => makeInitialValues(formData, surveyObjective),
    [formData, surveyObjective]
  );

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.EDIT_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title={DRAWER_TITLE}
      maskClosable={false}
      width={DRAWER_WIDHT}
      loading={isSubmitting}
      onSubmit={handleSave}
      destroyOnClose
    >
      <MicroZonationForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        assesmentGuidelineOptions={assesmentGuidelineOptions}
        disabled={true}
        microZonationData={microZonationData}
        surveyObjective={surveyObjective}
        view={isSubmitting}
      />
    </Drawer>
  );
};

MicroZonationEditForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  formData: PropTypes.object,
  onAction: PropTypes.func,
  assesmentGuidelineOptions: PropTypes.array,
  isSubmitting: PropTypes.bool,
};

export default withActions(
  INITIAL_STATE,
  ACTION_HANDLERS
)(MicroZonationEditForm);
