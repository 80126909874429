import React from "react";

import ReferenceImageReaders from "../../../../../../../../cortexSurvey-business/appServices/imageProcessing/readers/referenceImage";

export const renderExpandable = (record) => {
  const description = ReferenceImageReaders.description(record);
  return <p style={{ margin: 0 }}>{description}</p>;
};

export const expandable = {
  expandedRowRender: renderExpandable,
};
