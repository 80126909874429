import {
  TOASTER_TYPE,
  toaster,
} from "../../../../cortexSurvey-components/notificationWrapper";

import AuthApi from "../../../services/auth";

import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";

import { ACTION_TYPES } from "../constants/forgotPassword.actionTypes";
import { getPayloadFromFormValues } from "./forgotPassword.payload";

const handleFormSubmission = async ({ params, setState, getState }) => {
  const { formValue } = params;
  const { showModal } = getState();
  const { error } = formValue;

  if (error) return;

  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValue);
    const response = await AuthApi.forgotPassword(payload)
    console.log(response)
    const { errors } = response;
    console.log(errors)
    if(!error) {
      showModal();
      return;
    }
      toaster(
        TOASTER_TYPE.ERROR,`${errors[0]}. Failed to send reset Link.`
      )
    }
    catch (err) {
    toaster(
      TOASTER_TYPE.ERROR,
      getErrorMessage(err, "Failed to send reset Link")
    );
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.FORGOT_PASSWORD_FORM_SUBMISSION]: handleFormSubmission,
};

export default ACTION_HANDLERS;
