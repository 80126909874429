import StructureProblemFormReaders from "../readers";
import compose from "recompose/compose";
import { addStructureTypeIdToStructures } from "../../../helpers/structureType.general";

export const getPayloadFromFormValues = (formValues, id) => {
  const structureProblems = compose(
    addStructureTypeIdToStructures(id),
    StructureProblemFormReaders.structureProblem
  )(formValues);

  return structureProblems;
};
