import React, { memo } from "react";

import PropTypes from "prop-types";

import _isEmpty from "lodash/isEmpty";
import _size from "lodash/size";

import Button, {
  TYPES,
} from "../../../../../../../cortexSurvey-components/button";
import Popover from "../../../../../../../cortexSurvey-components/popover";

import { renderContent } from "./helpers/structureComponents.components";

const StructureComponents = (props) => {
  const { value = [] } = props;

  if (_isEmpty(value)) return <div>No Component Added</div>;

  return (
    <div>
      <Popover
        trigger="hover"
        content={renderContent(value)}
        placement="bottom"
        title="Structure Components"
      >
        <Button type={TYPES.LINK}>{_size(value)} Structure component</Button>
      </Popover>
    </div>
  );
};

StructureComponents.propTypes = {
  value: PropTypes.array,
};

export default memo(StructureComponents);
