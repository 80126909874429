import _property from "lodash/property";

import UserDataIndex from "../constants/dataIndex/user";

const createdBy = _property(UserDataIndex.createdBy);
const email = _property(UserDataIndex.email);
const firstName = _property(UserDataIndex.firstName);
const lastName = _property(UserDataIndex.lastName);
const image = _property(UserDataIndex.image);
const phone = _property(UserDataIndex.phone);
const role = _property(UserDataIndex.role);
const roleId = _property(UserDataIndex.roleId);
const status = _property(UserDataIndex.status);
const updatedBy = _property(UserDataIndex.updatedBy);
const userId = _property(UserDataIndex.userId);

const READERS = {
  createdBy,
  email,
  firstName,
  lastName,
  image,
  phone,
  role,
  roleId,
  status,
  updatedBy,
  userId,
};

export default READERS;
