import React from "react";

import _map from "lodash/map";

import SortableItem from "../../sortableItem/SortableItem";

const renderSortableItem = (onDelete, onPreview) => (data, index) => {
  const handleDelete = () => {
    onDelete(index);
  };
  return (
    <SortableItem
      data={data}
      onDelete={handleDelete}
      index={index}
      onPreview={onPreview}
    />
  );
};

export const renderSortableList = (data, onDelete, onPreview) =>
  _map(data, renderSortableItem(onDelete, onPreview));
