import Image from "../../../../../../../../cortexSurvey-components/image/Image";


export const COLUMN_CONFIG = [
    {
      title: "Help Input",
      dataIndex: "help",
      key: "help",
      width: "15rem",
      ellipsis: true,
     
    },
    {
      title: "Help Image",
      dataIndex: "helpImage",
      key: "helpImage",
      width: "10rem",
      render: (value) => <Image src={value} width={100} />,
    },
  ];