import { FIELD_IDS } from "./questionnaireForm.fieldId";

export const SECTIONS = [
  {
    label: "Question Details",
    rows: [
      { columns: [FIELD_IDS.SERIAL_NUMBER] },
      { columns: [FIELD_IDS.MANDATORY] },
      { columns: [FIELD_IDS.ASSESSMENT_GUIDELINE, FIELD_IDS.SURVEY_OBJECTIVE] },
      { columns: [FIELD_IDS.SURVEYOR_RANK, FIELD_IDS.STRUCTURE_TYPE] },
      { columns: [FIELD_IDS.QUESTION_SEGMENT, FIELD_IDS.ANSWER_PATTERN] },
      { columns: [FIELD_IDS.QUESTION] },
      { columns: [FIELD_IDS.ANSWER] },
    ],
  },
  {
    label: "Question Help",
    rows: [{ columns: [FIELD_IDS.HELP] }],
  },
];
