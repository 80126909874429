import React, { memo, useCallback } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Modal from "../../../../../cortexSurvey-components/modal/Modal";

import { ACTION_TYPES } from "./constants/countryActiveModal.actionTypes";
import {
  MODAL_TITILE,
  SAVE_TEXT,
} from "./constants/countryActiveModal.general";
import { INITIAL_STATE } from "./constants/countryActiveModal.initialState";
import ACTION_HANDLERS from "./helpers/countryActiveModal.actionHandlers";

const CountryActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    country,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  }, [onAction]);

  return (
    <Modal
      title={MODAL_TITILE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to make ${country} Country active?`}
    </Modal>
  );
};

CountryActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  assessmentGuideline: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CountryActiveModal);
