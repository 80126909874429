import { compose } from "recompose";

import AssessmentGuidelineReaders from "../../../../../../cortexSurvey-business/appServices/assessmentGuideline/readers/assessmentGuideline";
import StructureTypeReaders from "../../../../../../cortexSurvey-business/appServices/structureType/readers/structureType";
import SurveyObjectiveReaders from "../../../../../../cortexSurvey-business/appServices/surveyObjective/readers/surveyObjective";
import SurveyorRankReaders from "../../../../../../cortexSurvey-business/appServices/surveyorRank/readers/surveyorRank";
import { getOptionsValueForMultiSelect } from "../../../../../../cortexSurvey-business/helpers/optionsValue";
import QuestionnaireReaders from "../../../../../../cortexSurvey-business/appServices/questionnaire/readers/questionnaire";
import { FIELD_IDS } from "../../questionnaireForm/constants/questionnaireForm.fieldId";


const getAnswerPatternInitialValue=(value)=>{
  if(value==="Radio Button") return "radiobutton";
  if(value==="Dropdown") return "dropdown";
  return value;
}

const getOptions = (formValues, readers, selectReader) => {
  return compose(
    getOptionsValueForMultiSelect(selectReader),
    readers
  )(formValues);
};

export const makeInitialValues = (formValues) => {
  return {
    [FIELD_IDS.SERIAL_NUMBER]: QuestionnaireReaders.serialNumber(formValues),
    [FIELD_IDS.QUESTION]: QuestionnaireReaders.question(formValues),
    [FIELD_IDS.QUESTION_SEGMENT]:
      QuestionnaireReaders.questionSegment(formValues),
    [FIELD_IDS.SURVEY_OBJECTIVE]: getOptions(
      formValues,
      QuestionnaireReaders.surveyObjectives,
      SurveyObjectiveReaders.surveyObjectiveId
    ),
    [FIELD_IDS.STRUCTURE_TYPE]: getOptions(
      formValues,
      QuestionnaireReaders.structureTypes,
      StructureTypeReaders.id
    ),
    [FIELD_IDS.SURVEYOR_RANK]: getOptions(
      formValues,
      QuestionnaireReaders.surveyOrRanks,
      SurveyorRankReaders.surveyorRankId
    ),
    [FIELD_IDS.ASSESSMENT_GUIDELINE]: getOptions(
      formValues,
      QuestionnaireReaders.assessmentGuidelines,
      AssessmentGuidelineReaders.assessmentGuidelineId
    ),
    [FIELD_IDS.ANSWER]: QuestionnaireReaders.answers(formValues),
    [FIELD_IDS.HELP]: QuestionnaireReaders.help(formValues),
    [FIELD_IDS.ANSWER_PATTERN]: getAnswerPatternInitialValue(QuestionnaireReaders.answerPattern(formValues)),
    [FIELD_IDS.MANDATORY]: QuestionnaireReaders.mandatory(formValues),
  };
};
