import _property from 'lodash/property';

import ReferenceImageDataIndex from '../constants/dataIndex/referenceImage';

const description = _property(ReferenceImageDataIndex.description);
const image = _property(ReferenceImageDataIndex.image);
const referenceImagesId = _property(ReferenceImageDataIndex.referenceImagesId)

const DATA_INDEX = {
  description,
  image,
  referenceImagesId,
};

export default DATA_INDEX;
