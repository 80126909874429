import React from "react";
import Modal, {
  useModalVisibility,
} from "../../../../../../../cortexSurvey-components/modal";
import Button, {
  TYPES,
} from "../../../../../../../cortexSurvey-components/button";
import VideoPlayer from "../../../../../../../cortexSurvey-components/videoPlayer";
import { PlayCircleOutlined } from "@ant-design/icons";
import cx from 'classnames';

const StructureVideo = (props) => {
  const { videoLink } = props;
  const { isModalVisible, showModal, closeModal } = useModalVisibility();

  if (!videoLink) return <div>No Video Available</div>;

  return (
    <div>
      <Button type={TYPES.PRIMARY} onClick={showModal}>
        <PlayCircleOutlined /> Play
      </Button>
      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}
        centered
        width="70rem"
        title="Structure Video"
        maskClosable={false}
        destroyOnClose
      > 
        <VideoPlayer url={videoLink} controls={true} />
      </Modal>
    </div>
  );
};

export default StructureVideo;
