export const CAMERA_TYPE_OPTIONS = [
  { label: "Camera with Macro Sensor", value: "Camera with Macro Sensor" },
  { label: "Camera with Tof Sensor", value: "Camera with Tof Sensor" },
  { label: "Camera with Depth Sensor", value: "Camera with Depth Sensor" },
  {
    label: "Camera with Telephoto Sensor",
    value: "Camera with Telephoto Sensor",
  },
  { label: "Camera with LiDAR Sensor", value: "Camera with LiDAR Sensor" },
  {
    label: "Camera with Ultra-wide Sensor",
    value: "Camera with Ultra-wide Sensor",
  },
  {
    label: "Camera with Monochrome Sensor",
    value: "Camera with Monochrome Sensor",
  },
  { label: "Camera with PDFA Sensor", value: "Camera with PDFA Sensor" },
];

export const WEATHER_CONDITION_OPTIONS = [
  { label: "Confined Space", value: "Confined Space" },
  { label: "Dark", value: "Dark" },
  { label: "Sunlight", value: "Sunlight" },
  { label: "Bright light", value: "Bright Light" },
  { label: "Shadow Light in Day", value: "Shadow Light in Day" },
  { label: "Cloudy Daytime", value: "Cloudy Daytime" },
  { label: "Exposed Sunlight", value: "Exposed Sunlight" },
];
