import React, { memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Table from "../../../../../cortexSurvey-components/table";

import COLUMNS from "./constants/dashboardUsersOverview.columns";

import { ROW_ACTIONS } from "./constants/dashboardUsersOverview.rowActions";
import { SEARCH_KEYS } from "./constants/dashboardUsers.table";

import styles from "./dashboardUsersOverview.modules.scss";
import { FILTER_OPTIONS, FILTER_VALUES } from "./constants/dashboardUserOverview.filter";
import { getRowActions } from "./helpers/dashboardUsersOverview.rowActions";

const DashboardUsersOverview = (props) => {
  const { onAction=_noop, data=[], loading=false, fetchData=_noop } = props;

  return (
    <Table
      dataSource={data}
      columns={COLUMNS}
      className={styles.container}
      rowActions={getRowActions}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      filterOptions={FILTER_OPTIONS}
      initialFilter={FILTER_VALUES.ACTIVE}
      showFilter
    />
  );
};

DashboardUsersOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
};



export default memo(DashboardUsersOverview);
