import _map from "lodash/map";
import _filter from "lodash/filter";

import _get from "lodash/get";
import CityReaders from "../readers/city";
import CityDataIndex from "../constants/dataIndex/city";

const createCityOptions = (city) => {
  const cityId = CityReaders.cityId(city);
  const cityName = CityReaders.city(city);

  return { label: cityName, value: cityId };
};

const isSamesCountryAndState = (countryId, stateId) => (city) => {
  return (
    CityReaders.countryId(_get(city, CityDataIndex.country)) === countryId &&
    CityReaders.stateId(_get(city, CityDataIndex.state)) === stateId
  );
};

export const getCityOptions = (cityData, countryId, stateId) => {
  if (!countryId || !stateId) return [];
  const filteredCities = _filter(
    cityData,
    isSamesCountryAndState(countryId, stateId)
  );
  const cityOptions = _map(filteredCities, createCityOptions);

  return cityOptions;
};
