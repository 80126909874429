import _noop from "lodash/noop";

import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import SurveyObjectiveAPI from "../../../../../../cortexSurvey-business/appServices/surveyObjective/services/surveyObjective";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/surveyObjectiveActiveModal.actionTypes";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/surveyObjectiveActiveModal.general";

export const handleActiveSubmission = async ({ getState, setState }) => {
  const { surveyObjectiveId, onCancel, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    await SurveyObjectiveAPI.deleteSurveyObjective({
      surveyObjectiveId,
      isActive: 1,
    });
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ACTIVE_SUBMISSION]: handleActiveSubmission,
};

export default ACTION_HANDLERS;
