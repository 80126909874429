import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard";

import ImageProcessing from "../pages/imageProcessing";

const imageProcessingRoutes = {
  path: "survey",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "imageProcessing",
      element: ImageProcessing,
    },
  ],
};

export default imageProcessingRoutes;
