import { getCityOptions } from "./cityOptions";
import { getCountryOptions } from "./countryOptions";
import { getStateOptions } from "./stateOptions";

export const getCountryStateAndCityOptionsFormCityData = (
  cityData,
  countryId,
  stateId
) => {
  const countryOptions = getCountryOptions(cityData);
  const stateOptions = getStateOptions(cityData, countryId);
  const cityOptions = getCityOptions(cityData, countryId, stateId);

  return { countryOptions, stateOptions, cityOptions };
};
