import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import { ACTION_TYPES } from "../../../constants/assesmentGuideline.actionTypes";

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit Assessment Guideline",
  icon: <EditOutlined />,
};

const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate Assessment Guideline",
  icon: <DeleteOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVE,
  label: "Activate Assessment Guideline",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
