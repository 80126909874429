import React from "react";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { getFormConfig } from "./helpers/resetPasswordForm.formConfig";
import { SECTION } from "./constants/resetPasswordForm.section";

const ResetPasswordForm = (props) => {
  const { onSubmit, formId } = props;
  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTION}
      formId={formId}
      onSubmit={onSubmit}
    />
  );
};

export default ResetPasswordForm;
