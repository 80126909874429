import React, { useCallback, memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";

import { FORM_CONFIG } from "./constants/dashboardUsersForm.formConfig";
import { SECTIONS } from "./constants/dashboardUsersForm.sections";

const DashboardUsersForm = (props) => {
  const { initialValues={}, onSubmit=_noop, formId, view=false } = props;

  const formConfig = useCallback(() => FORM_CONFIG, []);

  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={view}
    />
  );
};

DashboardUsersForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  view:PropTypes.bool
};


export default memo(DashboardUsersForm);
