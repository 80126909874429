import _map from "lodash/map";
import SurveyorRankReaders from "../readers/surveyorRank";

const createSurveyorRankOption = (option) => {
  const surveyorRank = SurveyorRankReaders.surveyorRank(option);

  const surveyorRankId = SurveyorRankReaders.surveyorRankId(option);

  return {
    label: surveyorRank,
    value: surveyorRankId,
  };
};

export const getSurveyorRankOptions = (surveyorRankData) =>
  _map(surveyorRankData, createSurveyorRankOption);
