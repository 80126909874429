import React, { memo, useCallback} from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";

import Table from "../../../../../cortexSurvey-components/table";

import COLUMNS from "./constants/cityIndexMappingOverview.columns";
import { SEARCH_KEYS } from "./constants/cityIndexMappingOverview.table";
import { getRowActions } from "./helpers/cityIndexMappingOverview.rowActions";
import { INACTIVE_ROW_ACTIONS } from "./constants/cityIndexMappingOverview.rowActions";


const CityIndexMappingOverview = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    disabled = false,
    fetchData = _noop,
  } = props;

  const rowActions = useCallback((rowData) => getRowActions(rowData,disabled), [disabled]);

  return (
    <Table
      dataSource={data}
      columns={COLUMNS}
      onAction={onAction}
      loading={loading}
      rowActions={rowActions}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      inActiveRowActions={INACTIVE_ROW_ACTIONS} 
      showFilter
    />
  );
};

CityIndexMappingOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(CityIndexMappingOverview);
