import _property from "lodash/property";

import { FIELD_ID } from "../constants/forgotPassword.fieldId";

const email = _property(FIELD_ID.FORGOT_EMAIL);

const reader = {
  email,
};

export default reader;
