import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import _slice from "lodash/slice";
import _cloneDeep from "lodash/cloneDeep";
import _some from "lodash/some";
import EventEmitter from "events";
import cx from "classnames";

import DashboardTemplate from "../../cortesSurvey-template/dashboardTemplate/DashboardTemplate";
import Sidebar from "./components/sidebar/Sidebar";
import DashboardNavigationPanel from "../dashboardNavigationPanel/DashboardNavigationPanel";

import styles from "./surveyDashboard.module.scss";

// Create a global event emitter
export const navigationEmitter = new EventEmitter();

const SurveyDashboard = (props) => {
  const { children } = props;

  const [quickNavigation, setQuickNavigation] = useState(null);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  useEffect(() => {
    const handleInitQuickNavigate = () => {
      const quickNavigate =
        JSON.parse(localStorage.getItem("quickNavigation")) || [];
      setQuickNavigation(quickNavigate);
    };

    handleInitQuickNavigate();
  }, []);

  useEffect(() => {
    const handleOpen = () => {
      setIsNavigationOpen(true);
    };

    const handleClose = () => {
      setIsNavigationOpen(false);
    };

    navigationEmitter.on("open", handleOpen);
    navigationEmitter.on("close", handleClose);

    return () => {
      navigationEmitter.off("open", handleOpen);
      navigationEmitter.off("close", handleClose);
    };
  }, []);

  const handleQuickNavigation = (navigationConfig) => {
    let quickNavigate =
      JSON.parse(localStorage.getItem("quickNavigation")) || [];

    quickNavigate = _cloneDeep(quickNavigate);

    const isDuplicate = _some(quickNavigate, { label: navigationConfig.label });

    if (!isDuplicate) {
      const updatedQuickNavigate = [navigationConfig, ...quickNavigate];
      quickNavigate = _slice(updatedQuickNavigate, 0, 8);

      localStorage.setItem("quickNavigation", JSON.stringify(quickNavigate));
    }

    setQuickNavigation(quickNavigate);
  };

  const renderTemplate = useMemo(
    () => <DashboardTemplate>{children}</DashboardTemplate>,
    [children]
  );

  const renderNavigationPanel = useMemo(
    () => (
      <DashboardNavigationPanel
        onClose={() => navigationEmitter.emit("close")}
        setQuickNavigation={handleQuickNavigation}
      />
    ),
    []
  );

  return (
    <div
      className={cx(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      {!isMobile && (
        <div className={styles.sidebarContainer}>
          <Sidebar
            navigationOpen={isNavigationOpen}
            handleOpen={() => navigationEmitter.emit("open")}
            handleClose={() => navigationEmitter.emit("close")}
            quickNavigation={quickNavigation}
          />
        </div>
      )}

      <div
        className={cx(styles.contentContainer, {
          [styles.mobileContentContainer]: isMobile,
        })}
      >
        {isNavigationOpen ? renderNavigationPanel : renderTemplate}
      </div>
    </div>
  );
};

export default SurveyDashboard;
