import React, { memo, useCallback } from "react";
import Row from "../row";
import PropTypes from "prop-types";
import _map from "lodash/map";
import Divider from "../../../divider";

import styles from './section.module.scss';


const Section = (props) => {
  const { label, rows=[], className, shouldShowDivider=false, ...restProps } = props;

  const renderRow = useCallback(
    (row, index) => <Row key={`Row_${index}`} {...row} {...restProps} />,
    [restProps]
  );

  return (
    <div>
      {label && <div className={styles.sectionLabel}>{label}</div>}
      <div className={className}>{_map(rows, renderRow)}</div>
      {shouldShowDivider && <Divider />}
    </div>
  );
};

Section.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.array,
  className: PropTypes.string,
  shouldShowDivider: PropTypes.bool,
};

export default memo(Section); 
