import EventEmitter from "eventemitter3";

const formEmitter = new EventEmitter();

export const triggerSubmit = (formId) => {
  formEmitter.emit(`submit_${formId}`);
};

export const subscribeSubmit = (formId, onSubmit) => {
  formEmitter.on(`submit_${formId}`, onSubmit);
};

export const unSubscribeSubmit = (formId, handleSubmit) => {
  formEmitter.removeListener(`submit_${formId}`, handleSubmit);
};

export const triggerCancel = (formId) => {
  formEmitter.emit(`cancel_${formId}`);
};

export const subscribeCancel = (formId, handleCancel) => {
  formEmitter.on(`cancel_${formId}`, handleCancel);
};

export const unSubscribeCancel = (formId, handleCancel) => {
  formEmitter.removeListener(`cancel_${formId}`, handleCancel);
};
