import _property from "lodash/property";
import { FIELD_IDS } from "../constants/questionnaireForm.fieldId";

const serialNumber = _property(FIELD_IDS.SERIAL_NUMBER);
const question = _property(FIELD_IDS.QUESTION);
const questionSegment = _property(FIELD_IDS.QUESTION_SEGMENT);
const surveyObjective = _property(FIELD_IDS.SURVEY_OBJECTIVE);
const structureType = _property(FIELD_IDS.STRUCTURE_TYPE);
const surveyorRank = _property(FIELD_IDS.SURVEYOR_RANK);
const answerPattern = _property(FIELD_IDS.ANSWER_PATTERN);
const assessmentGuideline = _property(FIELD_IDS.ASSESSMENT_GUIDELINE);
const answers = _property(FIELD_IDS.ANSWER);
const help = _property(FIELD_IDS.HELP);
const mandatory = _property(FIELD_IDS.MANDATORY);

const READERS = {
  serialNumber,
  question,
  questionSegment,
  surveyObjective,
  structureType,
  surveyorRank,
  answerPattern,
  assessmentGuideline,
  answers,
  help,
 mandatory,
};

export default READERS;
