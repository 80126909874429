import _property from "lodash/property";

import { FIELD_IDS } from "../constants/surveyorRankForm.fieldId";

const surveyorRank = _property(FIELD_IDS.SURVEYOR_RANK);

const READERS = {
  surveyorRank,
};

export default READERS;
