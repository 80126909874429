import React, { useMemo } from "react";
import _isFunction from "lodash/isFunction";
import { EllipsisOutlined } from "@ant-design/icons";

import Dropdown from "../../../dropdown";
import { getMenuItems } from "./helpers/rowAction.components";

import styles from "../../table.module.scss";

const RowAction = (props) => {
  const { rowActions, onAction, rowData, inActiveRowActions, activeFilter } =
    props;

  const menuRowAction = useMemo(() => {
    if (_isFunction(rowActions)) return rowActions(rowData, activeFilter);
    return rowActions;
  }, [rowActions, rowData, activeFilter]);

  const items = getMenuItems(
    menuRowAction,
    onAction,
    rowData,
    inActiveRowActions
  );

  return (
    <Dropdown
      className={styles.actionIcon}
      menu={{ items }}
      trigger={["click"]}
    >
      <EllipsisOutlined />
    </Dropdown>
  );
};

export default RowAction;
