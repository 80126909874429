import _some from "lodash/some";
import _differnce from "lodash/difference";
import _map from "lodash/map";
import _size from "lodash/size";

import MicrozonationReaders from "../readers/microzonation";

import SurveyObjectiveReaders from "../../surveyObjective/readers/surveyObjective";

export const getSurveyObjectiveIds = (surveyObjectives, reader) =>
  _map(surveyObjectives, reader);

export const isMicrozonationDataNotSynced =
  (surveyObjectiveData) => (microzonationData) => {
    const surveyObjectives =
      MicrozonationReaders.surveyObjective(microzonationData);

    const surveyObjectiveDataIds = getSurveyObjectiveIds(
      surveyObjectiveData,
      SurveyObjectiveReaders.surveyObjectiveId
    );
    const surveyObjectiveIds = getSurveyObjectiveIds(
      surveyObjectives,
      MicrozonationReaders.surveyObjectiveId
    );

    const idsDiffrence = _differnce(surveyObjectiveDataIds, surveyObjectiveIds);

    const idsDiffrenceSize = _size(idsDiffrence);

    const assesmentGuidelineActiveStatus =
      MicrozonationReaders.assesmentGuidelineActiveStatus(microzonationData);

    return idsDiffrenceSize!==0 || !assesmentGuidelineActiveStatus;

  };

export const isMicrozonationNotSynced = (
  microzonationData,
  surveyObjectiveData
) => {
  const isNotSynced = _some(
    microzonationData,
    isMicrozonationDataNotSynced(surveyObjectiveData)
  );
  return isNotSynced;
};
