export const addRenderOptions = (field, additionalOptions) => {
  const { renderOptions, ...restFields } = field;
  return {
    ...restFields,
    renderOptions: {
      ...renderOptions,
      ...additionalOptions,
    },
  };
};
