import _property from "lodash/property";
import { FIELD_IDS } from "../constants/dashboardUserProfileForm.fieldId";

const firstName = _property(FIELD_IDS.FIRST_NAME);
const lastName = _property(FIELD_IDS.LAST_NAME);
const email = _property(FIELD_IDS.EMAIL);
const phoneNumber = _property(FIELD_IDS.PHONE_NUMBER);

const READERS = {
  firstName,
  lastName,
  email,
  phoneNumber,
};

export default READERS;
