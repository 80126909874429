import _noop from "lodash/noop";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import { ACTION_TYPES } from "../constants/surveyorRankCreateForm.actionTypes";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../constants/surveyorRankCreateForm.general";

import { getPayloadFromFormValues } from "./surveyorRankCreateForm.payload";

import SurveyorRankAPI from "../../../../../../cortexSurvey-business/appServices/surveyorRank/services/surveyorRank";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const {onClose = _noop, onSubmitCb = _noop} = getState();
  const { formValues } = params;

  const { error } = formValues;

 if(!error){
  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValues);
    await SurveyorRankAPI.createSurveyorRank(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
    onClose();
  } catch (err) {
    toaster(
      TOASTER_TYPE.ERROR,
      getErrorMessage(err, ERROR_MESSAGE)
    );
  } finally {
    setState({ isSubmitting: false });
  }

 }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
