import React from "react";
import _map from "lodash/map";
import Header from "../components/header/Header";

import styles from '../tableHeader.module.scss'

const renderHeaderComponent = (configValue) => {
  const { headerName, width } = configValue;
  return <Header title={headerName} width={width}className={styles.headerContainer}/>;
};

export const renderHeader = (config) => _map(config, renderHeaderComponent);
