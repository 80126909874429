import React from "react";

import useFetchAssesmentGuideline from "../../../cortexSurvey-business/appServices/assessmentGuideline/hooks/useFetchAssesmentGuideline/useFetchAssesmentGuideline";
import AssessmentGuidelineReaders from "../../../cortexSurvey-business/appServices/assessmentGuideline/readers/assessmentGuideline";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import AssesmentGuidelineCreateForm from "./components/assesmentGuidelineCreateForm";
import AssesmentGuidelineDeleteModal from "./components/assesmentGuidelineDeleteModal";
import AssesmentGuidelineEditForm from "./components/assesmentGuidelineEditForm";
import AssesmentGuidelineActiveModal from "./components/assessmentGuidelineActiveModal/AssesmentGuidelineActiveModal";

import AssesmentGuideline from "./AssesmentGuideline";

const AsessmentGuidelineContainer = () => {
  const { loading, data, fetchAssesmentGuideline } =
    useFetchAssesmentGuideline();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditFormData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isAssessmentGuidelineModalVisible,
    showModal: showAssessmentGuidelineDeletionModal,
    closeModal: closeAssessmentGuidelineDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isAssessmentGuidelineActiveModalVisible,
    showModal: showAssessmentGuidelineActiveModal,
    closeModal: closeAssessmentGuidelineActiveModal,
  } = useModalVisibility({});

  return (
    <div>
      <AssesmentGuideline
        loading={loading}
        data={data}
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showAssessmentGuidelineDeletionModal}
        showActiveModal={showAssessmentGuidelineActiveModal}
        fetchAssesmentGuideline={fetchAssesmentGuideline}
      />

      <AssesmentGuidelineCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchAssesmentGuideline}
      />

      <AssesmentGuidelineEditForm
        formData={selectedEditFormData}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={fetchAssesmentGuideline}
        assessmentGuidelineId={AssessmentGuidelineReaders.assessmentGuidelineId(
          selectedEditFormData
        )}
      />

      <AssesmentGuidelineDeleteModal
        isVisible={isAssessmentGuidelineModalVisible}
        onCancel={closeAssessmentGuidelineDeletionModal}
        onSubmitCb={fetchAssesmentGuideline}
        assessmentGuidelineId={AssessmentGuidelineReaders.assessmentGuidelineId(
          selectedDeleteData
        )}
        assessmentGuideline={AssessmentGuidelineReaders.assessmentGuideline(
          selectedDeleteData
        )}
      />

      <AssesmentGuidelineActiveModal
        isVisible={isAssessmentGuidelineActiveModalVisible}
        onCancel={closeAssessmentGuidelineActiveModal}
        onSubmitCb={fetchAssesmentGuideline}
        assessmentGuidelineId={AssessmentGuidelineReaders.assessmentGuidelineId(
          selectedActiveData
        )}
        assessmentGuideline={AssessmentGuidelineReaders.assessmentGuideline(
          selectedActiveData
        )}
      />
    </div>
  );
};

export default AsessmentGuidelineContainer;
