import { FIELD_IDS } from "./microZonationForm.fieldId";

import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/SelectFieldRenderer";
import MicroZonationInput from "../../microZonationInput/MicroZonationInput";
import { validateMicroZonationInput } from "../helpers/microZonationForm.validations";

export const ASSESSMENT_GUIDELINE = {
  id: FIELD_IDS.ASSESSMENT_GUIDELINE ,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Assesment Guideline",
    placeholder: "Assesment Guideline",
    required: true,
  },
};

export const MICROZONATION = {
  id: FIELD_IDS.MICORZONATION,
  renderer: MicroZonationInput,
  renderOptions: {
    label: "MicroZonation",
    placeholder: "MicroZonation",
    required: true,
    validations: validateMicroZonationInput
  },
};
