import _noop from "lodash/noop";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/questionnaireCreateForm.actionType";

import { getPayloadFromFormValues } from "./questionnaireCreateForm.payload";

import QuestionnaireAPI from "../../../../../../cortexSurvey-business/appServices/questionnaire/services/questionnaire";

import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../constants/questionnaireCreateForm.general";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;
  setState({ error });
  if (error) return;

  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues);
    await QuestionnaireAPI.createQuestionnaire(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
    onClose();
  } catch (err) {
    errorHandler(err, setState,ERROR_MESSAGE);
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
