import React, { useCallback, useMemo, memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import DashboardUsersForm from "../dashboardUsersForm";

import { ACTION_TYPES } from "./constants/dashboardUsersCreateForm.actionTypes";
import { FORM_ID } from "./constants/dashboardUsersCreateForm.form";
import { INITIAL_STATE } from "./constants/dashboardUsersCreateFrom.initalState";

import ACTION_HANDLERS from "./helpers/dashboardUsersCreateForm.actionHandlers";
import { makeInitialValues } from "./helpers/dashboardUserCreateForm.initialValues";

const DashboardUsersCreateForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    error=false
  } = props;

  const initialValues = useMemo(() => makeInitialValues(), []);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title="Add Dashboard User"
      maskClosable={false}
      width="60rem"
      onSubmit={handleSave}
      loading={isSubmitting}
      error={error}
      destroyOnClose
    >
      <DashboardUsersForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        view={isSubmitting}
        initialValues={initialValues}
      />
    </Drawer>
  );
};

DashboardUsersCreateForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(DashboardUsersCreateForm);
