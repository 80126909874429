import React from "react";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordModal from "./components/forgotPassword/forgotPasswordModal/ForgotPasswordModal";
import useModalVisibility from "../../../cortexSurvey-components/modal/hooks/useModalVisibility";

import styles from "./forgotPassowrd.module.scss";

const ForgotPasswordContainer = () => {
  const {
    isModalVisible: isForgotPasswordModalVisible,
    showModal: showForgotPasswordModal,
    closeModal: closeForgotPasswordModal,
  } = useModalVisibility();

  return (
    <div className={styles.container}>
        
      <ForgotPassword showModal={showForgotPasswordModal} />

      <ForgotPasswordModal
        isVisible={isForgotPasswordModalVisible}
        onCancel={closeForgotPasswordModal}
      />
    </div>
  );
};

export default ForgotPasswordContainer;
