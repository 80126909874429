import _pullAt from "lodash/pullAt";
import _cloneDeep from "lodash/cloneDeep";

export const getUpdatedTableDataOnDelete = (tableData, index) => {
  const updatedTableData = _cloneDeep(tableData);
  _pullAt(updatedTableData, index);
  return updatedTableData;
};

export const getUpdatedTableDataOnChange = ({
  rowData,
  dataIndex,
  value,
  tableData,
  index,
}) => {
  const updatedRowData = { ...rowData, [dataIndex]: value };
  const updatedTableData = _cloneDeep(tableData);
  updatedTableData[index] = updatedRowData;
  return updatedTableData;
};
