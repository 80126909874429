import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import Button, { TYPES } from "../../../cortexSurvey-components/button";
import { triggerSubmit } from "../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import LoginForm from "./components/loginForm";
import { ACTION_TYPES } from "./constants/login.actionTypes";
import { FORM_ID } from "./constants/login.form";
import { INITIAL_STATE } from "./constants/login.initialState";
import ACTION_HANDLERS from "./helpers/login.actionHandler";

import styles from "./login.module.scss";

const Login = (props) => {
  const { onAction, isSubmitting } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const handlelogin = () => {
    triggerSubmit(FORM_ID);
  };

  const handleLoginSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.LOGIN_FORM_SUBMISSION,
        payload: { formValues, navigate, location },
      });
    },
    [onAction, location, navigate]
  );

  const onForgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  return (
    <div className={styles.container}>
      <LoginForm
        formId={FORM_ID}
        onSubmit={handleLoginSubmission}
        view={isSubmitting}
      />
      <div className={styles.forgotPasswordContainer}>
        <Button
          className={styles.forgotPassword}
          onClick={onForgotPassword}
          disabled={isSubmitting}
        >
          Forgot Password ?
        </Button>
      </div>

      <Button
        type={TYPES.PRIMARY}
        onClick={handlelogin}
        className={styles.button}
        loading={isSubmitting}
        disabled={isSubmitting}
      >
        LOGIN
      </Button>
    </div>
  );
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(Login);
