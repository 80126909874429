import styles from "../select.module.scss";

export const SELECT_CLASSNAMES = {
  container: () => styles.container,
  control: () => styles.control,
  indicatorsContainer: () => styles.indicatorsContainer,
  input: () => styles.input,
  menuList: () => styles.menuList,
  option: () => styles.option,
  dropdownIndicator: (props) => {
    const { selectProps } = props;
    const { menuIsOpen } = selectProps || {};
    return menuIsOpen ? styles.dropdownIndicatorOpen : styles.dropdownIndicator;
  },
};
