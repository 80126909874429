import { ACTION_TYPES } from "../../../constants/questionnaire.actionTypes";

import {
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
} from "@ant-design/icons";

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit Questionnaire",
  icon: <EditOutlined />,
};

export const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate Questionnaire",
  icon: <DeleteOutlined />,
};

const SCORE = {
  id: ACTION_TYPES.SCORE,
  label: "Edit Score",
  icon: <EditOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVE,
  label: "Activate Assessment Guideline",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, SCORE, DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
