import React from "react";
import cx from 'classnames';
import _isEmpty from "lodash/isEmpty";
import _size from "lodash/size";
import Button, {
  TYPES,
} from "../../../../../../../cortexSurvey-components/button";
import Label from "../../../../../../../cortexSurvey-components/label";
import Modal, {
  useModalVisibility,
} from "../../../../../../../cortexSurvey-components/modal";
import { imageRenderer } from "./helpers/structureImages.component";
import styles from "./structureImages.module.scss";
import { isMobile } from "react-device-detect";

const StructureImages = (props) => {
  const { imageLinks } = props;

  const { isModalVisible, showModal, closeModal } = useModalVisibility();

  if (_isEmpty(imageLinks)) return <div>No Images Added</div>;

  return (
    <div>
      <Label>
        <Button type={TYPES.LINK} onClick={showModal}>
          {_size(imageLinks)} Images
        </Button>
      </Label>
      <Modal
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        centered
        title={`Structure Type Images (${_size(imageLinks)})`}
        width="65rem"
      >
        <div className={cx(styles.container,{[styles.mobileContainer]:isMobile})}>
          <div className={styles.imageContainer}>
            {imageRenderer(imageLinks)}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StructureImages;
