import _some from "lodash/some";
import CityIndexMappingReaders from "../readers/cityIndexMapping";

const isNotSync = (cityIndex) => {
  const country = CityIndexMappingReaders.country(cityIndex);
  const state = CityIndexMappingReaders.state(cityIndex);
  const city = CityIndexMappingReaders.city(cityIndex);
  const assessmentGuideline =
    CityIndexMappingReaders.assessmentGuideline(cityIndex);
  const surveyObjective = CityIndexMappingReaders.surveyObjective(cityIndex);
  const microZonation = CityIndexMappingReaders.microzonation(cityIndex);

//   const { isActive: isCountryActive } = country || {};
  const { isActive: isStateActive } = state || {};
  const { isActive: isCityActive } = city || {};
  const { isActive: isSurveyObjectiveActive } = surveyObjective || {};
  const { isActive: isAssessmentGuidelineActive } = assessmentGuideline || {};
  const { isActive: isMicrozonationActive } = microZonation || {};

  return (
    // !isCountryActive ||
    !isStateActive ||
    !isCityActive ||
    !isSurveyObjectiveActive ||
    !isAssessmentGuidelineActive ||
    !isMicrozonationActive
  );
};

export const isCityIndexNotSync = (cityIndexData) => {
  return _some(cityIndexData, isNotSync);
};
