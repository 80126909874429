import { EditOutlined, DeleteOutlined, ExportOutlined } from "@ant-design/icons";

import { ACTION_TYPES } from "../../../constants/microZonation.actionTypes";

export const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit MicroZonation",
  icon: <EditOutlined />,
};

export const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate MicroZonation",
  icon: <DeleteOutlined />,
};

export const ACTIVE = {
  id: ACTION_TYPES.ACTIVATE,
  label: "Activate MicroZonation",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVE];
