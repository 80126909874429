import { useCallback, useEffect, useMemo, useState } from "react";
import {
  subscribeSubmit,
  unSubscribeSubmit,
} from "../helpers/formWithSubmission.formAction";
import {
  getUiSectionsWithErrors,
  validateFormField,
} from "../helpers/formWithSubmission.validations";

const useFormSubmission = ({
  formState,
  formId,
  onSubmit,
  fields,
  view,
  disableSubmit,
  uiSections,
  tabEnabled,
  stateLoading,
}) => {
  const [fieldConfig, setFieldConfig] = useState(fields);

  const validateAndSubmit = useCallback(() => {
    const { updatedFieldConfig, hasError } = validateFormField(
      formState,
      fields
    );

    setFieldConfig(updatedFieldConfig);

    if (hasError) {
      onSubmit({ error: "Required Fields are not Filled or correct" });
      return;
    }

    if (stateLoading) {
      onSubmit({ error: "Media is Uploading" });
      return;
    }

    onSubmit(formState);
  }, [onSubmit, formState, fields, stateLoading]);

  const uiSectionsWithErrors = useMemo(() => {
    if (!tabEnabled) return uiSections;
    return getUiSectionsWithErrors(uiSections, fieldConfig);
  }, [uiSections, fieldConfig, tabEnabled]);

  const handleSubmit = useCallback(() => {
    if (!view || disableSubmit) {
      validateAndSubmit();
    }
  }, [view, validateAndSubmit, disableSubmit]);

  useEffect(() => {
    subscribeSubmit(formId, handleSubmit);
    return () => {
      unSubscribeSubmit(formId, handleSubmit);
    };
  }, [formId, handleSubmit]);

  useEffect(() => {
    setFieldConfig(fields);
  }, [fields]);

  return { fieldConfig, uiSectionsWithErrors };
};

export default useFormSubmission;
