import { ACTION_TYPES } from "../../../constants/state.actionTypes";
import { EditOutlined, DeleteOutlined,ExportOutlined } from "@ant-design/icons";

export const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit State",
  icon: <EditOutlined />,
};

export const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate State",
  icon: <DeleteOutlined />,
};

export const ACTIVATE = {
  id: ACTION_TYPES.ACTIVE,
  label: "Activate State",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
