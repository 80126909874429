import React, { memo } from "react";
import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";
import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";

import StructureTypeOverview from "./components/structureTypeOverview";
import { ACTION_TYPES } from "./constants/structureType.actionTypes";
import { INITIAL_STATE } from "./constants/structureType.initialStates";
import ACTION_HANDLERS from "./helpers/structureType.actionHandlers";

const StructureType = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchStructureType = _noop,
  } = props;

  return (
    <HeaderWithTable
      title="Structure Type"
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={StructureTypeOverview}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel="Create Structure Type"
      fetchData={fetchStructureType}
      onReload={fetchStructureType}
      showReload
    />
  );
};

StructureType.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchStructureType: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(StructureType);
