import React from "react";
import { List } from "antd";
import { Badge } from "antd";

const ShowAnswerComponent = (props) => {
  const { data } = props;

  const renderItem = (item) => {
    const { answer, value } = item;
    const status = value ? "success" : "error";
    return (
      <div>
        <List.Item>
          <Badge status={status} text={answer} />
        </List.Item>
      </div>
    );
  };

  return (
    <div>
      <List>{data?.map((item) => renderItem(item))}</List>
    </div>
  );
};

export default ShowAnswerComponent;
