import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _includes from "lodash/includes";
import _map from "lodash/map";
import _reduce from "lodash/reduce";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import _isEqual from "lodash/isEqual";

const getOptionsSortOrder = (selectedOptions) => (option) =>
  !_includes(selectedOptions, option);

export const getOptionsInSortOrderWithSelection = (selectedOptions, options) =>
  _sortBy(options, getOptionsSortOrder(selectedOptions));

const createOptionFromDataKey =
  ({ valueKey, labelKey }) =>
  (data) => {
    const value = _get(data, valueKey);
    const label = _get(data, labelKey);
    return { value, label };
  };

export const getOptionsFromData = ({ data, valueKey, labelKey }) =>
  _map(data, createOptionFromDataKey({ valueKey, labelKey }));

const getValueFromSelectedOption = (option) => {
  const { value } = option || {};
  return value;
};

export const extractValueFromSelectedOptions = (value) => {
  if (!_isArray(value)) return getValueFromSelectedOption(value);
  return _map(value, getValueFromSelectedOption);
};

const getSelectedOptionFromvalue = (values) => (result, option) => {
  const { value } = option || {};
  if (_includes(values, value)) {
    return [...result, option];
  }

  return result;
};

export const getSelectedOptionFromValues = (options, values) =>
  _reduce(options, getSelectedOptionFromvalue(values), []);

const findSelectedOptionFromSingleValue = (value) => (option) => {
  const { value: optionValue } = option;
  return _isEqual(value, optionValue);
};

export const getSelectedOptionForSingleValue = (options, value) =>
  _find(options, findSelectedOptionFromSingleValue(value));
