import _head from "lodash/head";
import _isArray from "lodash/isArray";
import _isEqual from "lodash/isEqual";
import _size from "lodash/size";

export const getFormatLabel = (option, selectedValues) => {
  if (!_isArray(selectedValues)) return selectedValues;

  const selectedValue = _head(selectedValues);
  const selectedValuesLength = _size(selectedValues);

  if (_isEqual(selectedValue, option)) {   
    const { label } = selectedValue || {};

    if (selectedValuesLength > 1)
      return `${label} +${selectedValuesLength - 1}`; 

    return label;
  }

  return null;    
};
