const description = "description";
const image = "image";
const referenceImagesId = "referenceImagesId";

const DATA_INDEX = {
  description,
  image,
  referenceImagesId,
};

export default DATA_INDEX;
