import React from "react";

import _map from "lodash/map";

import styles from "../structureComponents.module.scss";

const renderContentItem = (value) => {
  const { component } = value;

  return <div className={styles.contentItem}>{component}</div>;
};

export const renderContent = (value) => (
  <div className={styles.contentContainer}>
    {_map(value, renderContentItem)}
  </div>
);
