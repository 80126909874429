import { useCallback, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";

import useFetchPayload from "../../../hooks/useFetchPayload";

import CountryAPI from "../services/country";

const useFetchCountry = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchCountry = useCallback(async (active) => {
    setLoading(true);
    try {
      const payload = getFetchPayload(active);
      const response = await CountryAPI.fetchCountry(payload);
      setData(response?.data);
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage("Failed to fetch Country"));
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData,getFetchPayload]);

  const handleDebounceFetchCountry = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchCountry, 1000);
      debounceFunction(isActive);
    },
    [fetchCountry, setLoading]
  );

  useEffect(() => {
    fetchCountry();
  }, [fetchCountry]);

  return { loading, data, fetchCountry:handleDebounceFetchCountry };
};

export default useFetchCountry;
