import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useCallback, useMemo } from "react";

import { compose } from "recompose";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import QuestionnaireForm from "../questionnaireForm";

import { ACTION_TYPES } from "./constants/questionnaireEditForm.actionTypes";
import { FORM_ID } from "./constants/questionnaireEditForm.formId";
import { INITIAL_STATE } from "./constants/questionnaireEditForm.initialState";
import ACTION_HANDLERS from "./helpers/questionnaireEditForm.actionHandlers";
import { makeInitialValues } from "./helpers/questionnaireEditForm.initialValues";

const QuestionnaireEditForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    formValues = {},
    additional = {},
  } = props;

  console.log(formValues)

  const initialValues = useMemo(
    () => makeInitialValues(formValues),
    [formValues]
  );

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.EDIT_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title="Edit Question Setting"
      maskClosable={false}
      width="900px"
      loading={isSubmitting}
      onSubmit={handleSave}
      destroyOnClose
    >
      <QuestionnaireForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        additional={additional}
        view={isSubmitting}
        isEdit
      />
    </Drawer>
  );
};

QuestionnaireEditForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  additional: PropTypes.object,
  formValues: PropTypes.object,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(QuestionnaireEditForm);
