import { FIELD_IDS } from "./notificationForm.fieldId";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/InputFieldRenderer";
import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/SelectFieldRenderer";
import VideoUpload from "../../../../../../cortexSurvey-components/videoUpload/VideoUpload";
import ImageUpload from "../../../../../../cortexSurvey-components/imageUpload/ImageUpload";
import { NOTIFICATION_TYPE_OPTIONS } from "./notificationForm.general";


export const NOTIFICATION_IMAGE = {
  id: FIELD_IDS.NOTIFICATION_IMAGE,
  renderer: ImageUpload,
  renderOptions: {
    label: "Notification Image",
  },
};

export const NOTIFICATION_VIDEO = {
  id: FIELD_IDS.NOTIFICATION_VIDEO,
  renderer: VideoUpload,
  renderOptions: {
    label: "Notification Video",

  },
};

export const NOTIFICATION_TITLE = {
  id: FIELD_IDS.NOTIFICATION_TITLE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Notification Title",
    placeholder:"Notification Title",
    required: true,
  },
};

export const NOTIFICATION_TYPE = {
  id: FIELD_IDS.NOTIFICATION_TYPE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    options: NOTIFICATION_TYPE_OPTIONS,
    placeholder: "Type",
    label:"Notification Type",
    required: true,
  },
 
};
