import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo } from "react";

import CountryReaders from "../../../../../cortexSurvey-business/appServices/country/readers/country";

import Table from "../../../../../cortexSurvey-components/table";

import COLUMNS from "./constants/countryOverview.columns";

import {
  ROW_ACTIONS,
  INACTIVE_ROW_ACTIONS,
} from "./constants/countryOverview.rowActions";
import { SEARCH_KEYS } from "./constants/countryOverview.table";

const CountryOverview = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchData = _noop,
  } = props;

  return (
    <Table
      dataSource={data}
      columns={COLUMNS}
      rowActions={ROW_ACTIONS}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      rowKey={CountryReaders.countryId}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

CountryOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
};

export default memo(CountryOverview);
