import React, { useCallback, useMemo } from "react";

import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import useFetchCity from "../../../cortexSurvey-business/appServices/city/hooks/useFetchCity";
import CityReaders from "../../../cortexSurvey-business/appServices/city/readers/city";
import useFetchCountry from "../../../cortexSurvey-business/appServices/country/hooks/useFetchCountry";
import useFetchState from "../../../cortexSurvey-business/appServices/state/hooks/useFetchState";

import CityCreateForm from "./components/cityCreateForm/CityCreateForm";
import CityEditForm from "./components/cityEditForm";
import CityActiveModal from "./components/cityActiveModal";
import City from "./City";
import CityDeleteModal from "./components/cityDeleteModal";
import { isCityNotSync } from "../../../cortexSurvey-business/appServices/city/helpers/citySync";

const CityContainer = () => {
  const { data: countryData, fetchCountry } = useFetchCountry();

  const { data: stateData, fetchState } = useFetchState();

  const { loading, data, fetchCity } = useFetchCity();
  const { data: activeCityData, fetchCity: fetchActiveCity } = useFetchCity();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isCityDeletionModalVisible,
    showModal: showCityDeletionModal,
    closeModal: closeCityDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isCityActiveModalVisible,
    showModal: showCityActiveModal,
    closeModal: closeCityActiveModal,
  } = useModalVisibility({});

  const cityNotSynced = useMemo(
    () => isCityNotSync(activeCityData),
    [activeCityData]
  );

  const handleReload = useCallback(() => {
    fetchActiveCity();
    fetchCity();
    fetchState();
    fetchCountry();
  }, [fetchActiveCity,fetchCity,fetchCountry,fetchState]);

  return (
    <div>
      <City
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showCityDeletionModal}
        showActiveModal={showCityActiveModal}
        fetchCity={fetchCity}
        data={data}
        loading={loading}
        cityNotSynced={cityNotSynced}
        onReload={handleReload}
      />

      <CityCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchCity}
        countryData={countryData}
        stateData={stateData}
      />

      <CityEditForm
        formData={selectedEditData}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={handleReload}
        countryData={countryData}
        stateData={stateData}
        cityId={CityReaders.cityId(selectedEditData)}
      />

      <CityDeleteModal
        isVisible={isCityDeletionModalVisible}
        onCancel={closeCityDeletionModal}
        onSubmitCb={handleReload}
        cityId={CityReaders.cityId(selectedDeleteData)}
        city={CityReaders.city(selectedDeleteData)}
      />
      <CityActiveModal
        isVisible={isCityActiveModalVisible}
        onCancel={closeCityActiveModal}
        onSubmitCb={handleReload}
        cityId={CityReaders.cityId(selectedActiveData)}
        city={CityReaders.city(selectedActiveData)}
      />
    </div>
  );
};

export default CityContainer;
