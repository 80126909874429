import { ACTION_TYPES } from "../../../constants/cityIndexMapping.actionTypes";
import { EditOutlined, DeleteOutlined,ExportOutlined } from "@ant-design/icons";

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit City Index Mapping",
  icon: <EditOutlined />,
};

export const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "De-Activate City Index Mapping",
  icon: <DeleteOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVATE,
  label: "Activate City",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];
export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
