import React, { useMemo, memo } from "react";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { getFormConfig } from "./helpers/questionnaireForm.formConfig";
import { SECTIONS } from "./constants/questionnaireForm.sections";
import { FIELD_VALUE_MAPPER } from "./constants/questionnaireForm.fieldValueMapper";

const QuestionnaireForm = (props) => {
  const { additional, initialValues, onSubmit, formId, isEdit, view } = props;

  const formConfig = useMemo(
    () => getFormConfig(additional, isEdit),
    [additional, isEdit]
  );

  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      fieldValueMapper={FIELD_VALUE_MAPPER}
      view={view}
      tabEnabled
    />
  );
};

export default memo(QuestionnaireForm);
