import AssessmentGuidelineDataIndex from "../../../../../../cortexSurvey-business/appServices/assessmentGuideline/constants/dataIndex/assessmentGuideline";

export const COLUMNS = [
  {
    title: "Assesment Guideline Id",
    dataIndex: AssessmentGuidelineDataIndex.assessmentGuidelineId,
    key: AssessmentGuidelineDataIndex.assessmentGuidelineId,
  },
  {
    title: "Assesment Guideline",
    dataIndex: AssessmentGuidelineDataIndex.assessmentGuideline,
    key: AssessmentGuidelineDataIndex.assessmentGuidelineId,
  },
];
