import _some from "lodash/some";
import CityReaders from "../readers/city";

const isNotSync = (city) => {
  const country = CityReaders.country(city);
  const state = CityReaders.state(city);
  const { isActive: isCountryActive } = country || {};
  const { isActive: isStateActive } = state || {};

  return !isCountryActive || !isStateActive;
};

export const isCityNotSync = (cityData) => {
  return _some(cityData, isNotSync);
};
