import React, { memo } from "react";

import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Modal from "../../../../../cortexSurvey-components/modal";

import { ACTION_TYPES } from "./constants/cityActiveModal.actionTypes";
import { INITIAL_STATE } from "./constants/cityActiveModal.initialState";
import ACTION_HANDLERS from "./helpers/cityActiveModal.actionHandlers";
import { MODAL_TITLE, SAVE_TEXT } from "./constants/cityActiveModal.general";

const CityActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    city,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.ACTIVE_SUBMISSION });
  };
  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to make ${city} City Active?`}
    </Modal>
  );
};

CityActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  city: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CityActiveModal);
