import React from "react";
import styles from "./header.module.scss";

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>Images</div>
      <div className={styles.actionItem}>Action</div>
    </div>
  );
};

export default Header;
