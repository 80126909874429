import CityIndexMappingReaders from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/readers/cityIndexMapping";
import CityIndexMappingDataIndex from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/constants/dataIndex/cityIndexMapping";
import { FIELD_IDS } from "../../cityIndexMappingForm/constants/cityIndexMappingForm.fieldId";
import _get from "lodash/get";

export const makeInitialValues = (formData) => {
  return {
    [FIELD_IDS.ASSESSMENT_GUIDELINE]:
      CityIndexMappingReaders.assessmentGuidelineId(
        _get(formData, CityIndexMappingDataIndex.assessmentGuideline)
      ),
    [FIELD_IDS.SURVEY_OBJECTIVE]:
      CityIndexMappingReaders.surveyObjectiveId( _get(formData, CityIndexMappingDataIndex.surveyObjective)),
    [FIELD_IDS.MICROZONATION]: CityIndexMappingReaders.microzationId( _get(formData, CityIndexMappingDataIndex.microzonation)),
    [FIELD_IDS.COUNTRY]: CityIndexMappingReaders.countryId( _get(formData, CityIndexMappingDataIndex.country)),
    [FIELD_IDS.STATE]: CityIndexMappingReaders.stateId( _get(formData, CityIndexMappingDataIndex.state)),
    [FIELD_IDS.CITY]: CityIndexMappingReaders.cityId( _get(formData, CityIndexMappingDataIndex.city)),
    [FIELD_IDS.SOIL]: CityIndexMappingReaders.soil( _get(formData, CityIndexMappingDataIndex.city)),
  };
};
