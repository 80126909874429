import { useCallback, useEffect, useState } from "react";
import {
  toaster,
  TOASTER_TYPE,
} from "../../cortexSurvey-components/notificationWrapper";

import UserAPI from "../services/users";

const useFetchUsers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchUsers = useCallback(async() => {
    setLoading(true);
    try {
      const response = await UserAPI.fetchUsers({roleId:2})
      setData(response.data);
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, "Failed to fetch Users");
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return { loading, data, fetchUsers };
};

export default useFetchUsers;
