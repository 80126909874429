import { addRenderOptions } from "../../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.addRenderOptions";
import { getSurveySetupCountryOptions } from "../../../../../helpers/cortexSurveySetup.getCountryOptions";
import { COUNTRY } from "../constants/stateForm.field";
import { FIELD_IDS } from "../constants/stateForm.fieldId";
import { FORM_CONFIG } from "../constants/stateForm.formConfig";

export const getFormConfig = (country) =>{
    return {
        ...FORM_CONFIG,
        [FIELD_IDS.COUNTRY]: addRenderOptions(COUNTRY, {
            options: getSurveySetupCountryOptions(country)
        })
    }
};
