import React from "react";

import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";
import useFetchCountry from "../../../cortexSurvey-business/appServices/country/hooks/useFetchCountry";

import CountryReaders from "../../../cortexSurvey-business/appServices/country/readers/country";
import Country from "./Country";
import CountryCreateForm from "./components/countryCreateForm";
import CountryDeleteModal from "./components/countryDeleteModal";
import CountryEditForm from "./components/countryEditForm";
import CountryActiveModal from "./components/countryActiveModal/CountryActiveModal";

const CountryContainer = () => {
  const { loading, data, fetchCountry } = useFetchCountry();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isCountryDeletionModalVisible,
    showModal: showCountryDeletionModal,
    closeModal: closeCountryDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isCountryActiveModalVisible,
    showModal: showCountryActiveModal,
    closeModal: closeCountryActiveModal,
  } = useModalVisibility({});

  return (
    <div>
      <Country
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showCountryDeletionModal}
        showActiveModal={showCountryActiveModal}
        data={data}
        loading={loading}
        fetchCountry={fetchCountry}
      />

      <CountryCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchCountry}
      />

      <CountryEditForm
        formData={selectedEditData}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={fetchCountry}
        countryId={CountryReaders.countryId(selectedEditData)}
      />
      <CountryDeleteModal
        isVisible={isCountryDeletionModalVisible}
        onCancel={closeCountryDeletionModal}
        countryId={CountryReaders.countryId(selectedDeleteData)}
        country={CountryReaders.country(selectedDeleteData)}
        onSubmitCb={fetchCountry}
      />

      <CountryActiveModal
        isVisible={isCountryActiveModalVisible}
        onCancel={closeCountryActiveModal}
        onSubmitCb={fetchCountry}
        countryId={CountryReaders.countryId(selectedActiveData)}
        country={CountryReaders.country(selectedActiveData)}
      />
    </div>
  );
};

export default CountryContainer;
