import React, { useState, useCallback, useEffect, useMemo } from "react";
import cx from "classnames";
import SelectField from "react-select";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import Label from "../label";

import { getSelectProps } from "./config/select.props";
import {
  getOptionsInSortOrderWithSelection,
  getOptionsFromData,
  extractValueFromSelectedOptions,
  getSelectedOptionFromValues,
  getSelectedOptionForSingleValue,
} from "./config/helper/select.options";

import styles from "./select.module.scss";

const Select = (props) => {
  const {
    className,
    placeholder,
    isMulti,
    options,
    selectAllLabel,
    data,
    labelKey,
    valueKey,
    isRequired,
    label,
    value,
    onChange,
    required,
    ...restProps
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(null);
  const [customOptions, setCustomOptions] = useState(options);
  const [sortedOptions, setSortedOptions] = useState(options);

  const handleSelectedOption = (values) => {
    setSelectedOptions(values);
    const extractedValues = extractValueFromSelectedOptions(values);
    onChange(extractedValues);
  };

  const handleOptionsOnMenuOpen = useCallback(() => {
    if (isMulti) {
      const sortedoptionsWithSelection = getOptionsInSortOrderWithSelection(
        selectedOptions,
        customOptions
      );

      setSortedOptions(sortedoptionsWithSelection);
    } else {
      setSortedOptions(options);
    }
  }, [isMulti, setSortedOptions, customOptions, selectedOptions, options]);

  const selectProps = getSelectProps({
    isMulti,
    selectAllLabel,
    handleOptionsOnMenuOpen,
  });

  const handleOptionsOnload = useCallback(() => {
    const optionForExtractingValues = _isEmpty(data)
      ? options
      : getOptionsFromData({ data, valueKey, labelKey });

    setCustomOptions(optionForExtractingValues);

    if (isMulti) {
      const selectedOptionsFromValues = getSelectedOptionFromValues(
        optionForExtractingValues,
        value
      );
      setSelectedOptions(selectedOptionsFromValues);
    } else {
      const selectedOptionsFromValue = getSelectedOptionForSingleValue(
        optionForExtractingValues,
        value
      );
      setSelectedOptions(selectedOptionsFromValue);
    }
  }, [data, valueKey, labelKey, value, options, isMulti]);

  useEffect(() => {
    handleOptionsOnload();
  }, [value, options, handleOptionsOnload]);

  const selectValue = useMemo(() => {
    return value !== undefined ? selectedOptions : null;
  }, [value, selectedOptions]);

  return (
    <div className={styles.selectContainer}>
      {label && <Label required={required} label={label} />}
      <SelectField
        {...restProps}
        {...selectProps}
        className={cx(styles.select, className)}
        placeholder={placeholder}
        value={selectValue}
        options={sortedOptions}
        onChange={handleSelectedOption}
      />
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  selectAllLabel: PropTypes.string,
  data: PropTypes.array,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
};

Select.defaultProps = {
  className: undefined,
  placeholder: "",
  isMulti: false,
  options: [],
  selectAllLabel: "Select All",
  data: [],
  labelKey: undefined,
  valueKey: undefined,
};

export default Select;
