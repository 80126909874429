import React, { memo } from "react";

import PropTypes from "prop-types";

import Tag from "../../../../../../../cortexSurvey-components/tag";
import { isMicrozonationDataNotSynced } from "../../../../../../../cortexSurvey-business/appServices/microZonation/helpers/microZonationSync";

const SyncColumn = (props) => {
  const { surveyObjective = [], rowData = {} } = props;

  const isDataNotSynced =
    isMicrozonationDataNotSynced(surveyObjective)(rowData);

  if (isDataNotSynced) return <Tag color="error">Not Synced</Tag>;

  return <Tag color="success">Synced</Tag>;
};

SyncColumn.propTypes = {
  surveyObjective: PropTypes.array,
  value: PropTypes.array,
  rowData: PropTypes.object,
};

export default memo(SyncColumn);
