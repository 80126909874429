import { isMicrozonationNotSynced } from "../../../../cortexSurvey-business/appServices/microZonation/helpers/microZonationSync";
import { isCityNotSync } from "../../../../cortexSurvey-business/appServices/city/helpers/citySync";
import { isCityIndexNotSync } from "../../../../cortexSurvey-business/appServices/cityIndexMapping/helpers/cityIndexMappingSync";

const createBannerConfig = (description) => ({ type: "error", message:description });

const MICROZONATION_DESCIRPTION = "Data Sync Issue : City Index Mapping can't be created or edited because data is not synced with MicroZonation due to an inactive Assessment Guideline or new Survey Objective. Please update the fields by visiting MicroZonation page.";

const CITY_DESCIRPTION = "Data Sync Issue : City Index Mapping can't be created or edited because data is not synced with City due to an inactive Country, State or City. Please update the fields by visiting Country, State and City pages.";

const CITY_INDEX_MAPPING_DESCRIPTION = "Data Sync Issue : City Index Mapping can't be created or edited because data is not synced with proper fields. Please deActivate the fields which are inActive.";

export const getCityIndexMappingNotSynced = (
  microzonationData,
  syrveyObjective,
  cityData,
  cityIndexActiveData
) => {
  if (isMicrozonationNotSynced(microzonationData, syrveyObjective)) {
    const bannerConfig = createBannerConfig(MICROZONATION_DESCIRPTION);

    return { bannerConfig, isNotSynced: true };
  }
  if (isCityNotSync(cityData)) {
    const bannerConfig = createBannerConfig(CITY_DESCIRPTION);
    return { bannerConfig, isNotSynced: true };
  }

  if (isCityIndexNotSync(cityIndexActiveData)) {
    const bannerConfig = createBannerConfig(CITY_INDEX_MAPPING_DESCRIPTION);
    return { bannerConfig, isNotSynced: true };
  }
  return { isNotSynced: false };
};
