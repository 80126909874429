import _noop from "lodash/noop";

import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import QuestionnaireAPI from "../../../../../../cortexSurvey-business/appServices/questionnaire/services/questionnaire";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/questionnaireActiveModal.actionTypes";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/questionnaireActiveModal.general";

export const handleActiveSubmission = async ({ getState, setState }) => {
  const { questionId, onCancel = _noop, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload = { questionId, isActive: 1 };
    await QuestionnaireAPI.deleteQuestionnaire(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ACTIVE_SUBMISSION]: handleActiveSubmission,
};

export default ACTION_HANDLERS;
