export const HEADER_TITLE = "City";
export const CREATE_BUTTON_LABEL = "Create City with Soil Details";

const BANNER_TYPE = "error";

const BANNER_MESSAGE =
  "Data Sync Issue : Data is not synchronized with active City due to an inactive Country or State.";

export const BANNER_CONFIG = {
  type: BANNER_TYPE,
  message: BANNER_MESSAGE,
};