import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useCallback} from "react";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import StateForm from "../stateForm";

import { ACTION_TYPES } from "./constants/stateCreateForm.actionTypes";
import { FORM_ID } from "./constants/stateCreateForm.form";
import { INITIAL_STATE } from "./constants/stateCreateForm.initialStates";
import {
  DRAWER_TITLE,
  DRAWER_WIDTH,
} from "./constants/stateCreateForm.general";

import ACTION_HANDLERS from "./helpers/stateCreateForm.actionHandlers";

const StateCreateForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    country = [],
    error = false,
  } = props;

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      title={DRAWER_TITLE}
      width={DRAWER_WIDTH}
      loading={isSubmitting}
      open={isVisible}
      maskClosable={false}
      onSubmit={handleSave}
      onClose={onClose}
      error={error}
      destroyOnClose
    >
      <StateForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        country={country}
        disabled={isSubmitting}
      />
    </Drawer>
  );
};

StateCreateForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(StateCreateForm);
