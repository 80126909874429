import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_CITY_INDEX_MAPPING: "/scale-mapping-data",
  CREATE_CITY_INDEX_MAPPING: "/scale-mapping-add",
  EDIT_CITY_INDEX_MAPPING: "/scale-mapping-update",
  DELETE_CITY_INDEX_MAPPING: "/is-active",
};

const fetchCityIndexMapping = (payload) =>
  HttpClient.post(API_PATHS.FETCH_CITY_INDEX_MAPPING, payload);

const createCityIndexMapping = (payload) =>
  HttpClient.post(API_PATHS.CREATE_CITY_INDEX_MAPPING, payload);

const editCityIndexMapping = (payload) =>
  HttpClient.post(API_PATHS.EDIT_CITY_INDEX_MAPPING, payload);

const deleteCityIndexMapping = (payload) =>
  HttpClient.post(API_PATHS.DELETE_CITY_INDEX_MAPPING, payload);

const CityIndexMappingAPI = {
  fetchCityIndexMapping,
  createCityIndexMapping,
  editCityIndexMapping,
  deleteCityIndexMapping,
};

export default CityIndexMappingAPI;
