export const FIELD_IDS = {
  ITEM_CODE: "ITEM_CODE",
  ITEM_SEARCH_CODE: "ITEM_SEARCH_CODE",
  DISTRESS_PATTERN: "DISTRESS_PATTERN",
  SCORE_REFERENCE: "SCORE_REFERENCE",
  NUMERICAL_SCORE: "NUMERICAL_SCORE",
  CAPTURED_WITH_CAMERA_TYPE: "CAPTURED_WITH_CAMERA_TYPE",
  LIGHT_AND_WEATHER_CONDITION: "LIGHT_AND_WEATHER_CONDITION",
  CAPTURING_DISTANCE: "CAPTURING_DISTANCE",
  VULNERABILITY_SCALE: "VULNERABILITY_SCALE",
  AUDIO_CLIP: "AUDIO_CLIP",
  REFERENCE_IMAGE: "REFERENCE_IMAGE",
};
