import React, { memo, useMemo } from "react";

import { Drawer as AntdDrawer } from "antd";
import _noop from "lodash/noop";
import PropTypes from "prop-types";

import { renderSaveComponent } from "./helpers/drawer.components";

import styles from "./drawer.module.scss";

const Drawer = (props) => {
  const {
    children,
    loading = false,
    onClose = _noop,
    onSubmit = _noop,
    hideFooter = false,
    error = false,
    errorAction,
    // onAction = _noop,
    ...restProps
  } = props;

  const drawerFooter = useMemo(() => {
    if (hideFooter) return null;
    return renderSaveComponent(onSubmit, onClose, loading, error);
  }, [onSubmit, onClose, loading, hideFooter, error]);

  // useEffect(() => {
  //   onAction({
  //     type: errorAction,
  //   });
  // }, [errorAction, onAction]);

  return (
    <AntdDrawer
      {...restProps}
      rootClassName={styles.drawerContainer}
      onClose={onClose}
      footer={drawerFooter}
    >
      {children}
    </AntdDrawer>
  );
};

Drawer.prototype = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  hideFooter: PropTypes.bool,
};

export default memo(Drawer);
