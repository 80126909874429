import React from "react";

import _find from "lodash/find";
import _map from "lodash/map";

import MicroZonationReaders from "../../../../../../../../cortexSurvey-business/appServices/microZonation/readers/microzonation";

import styles from '../surveyObjectiveColumn.module.scss';

const renderMicroZonationValue = (value) => {
  const level = MicroZonationReaders.level(value);
  return <div className={styles.level}>{level}</div>;
};

export const renderMicrZonationValues = (rowData, surveyObjectiveId) => {
  const surveyObjectiveData = _find(rowData, { surveyObjectiveId });
  const micrZonationValues = MicroZonationReaders.value(surveyObjectiveData);
  return <div className={styles.microZonationValuesContainer}>{_map(micrZonationValues, renderMicroZonationValue)}</div>;
};
