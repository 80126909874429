import _map from "lodash/map";

import Image from "../../../../../../../../cortexSurvey-components/image";

const renderer = (imageLink) => {
  return <Image src={imageLink} width="12rem" height="12rem" />;
};

export const imageRenderer = (imagesLink) => {
  return _map(imagesLink, renderer);
};
