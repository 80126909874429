import React from "react";
import { useNavigate } from "react-router-dom";

import Button, { TYPES } from "../../../button";

import Avatar, { AVATAR_SHAPE } from "../../../avatar";

import styles from "./navigateButton.module.scss";

const NavigateButton = (props) => {
  const { label, navigateTo, onClose, setQuickNavigation, config } = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate(navigateTo);
    setQuickNavigation(config);
    onClose();
  };

  return (
    <div className={styles.container}>
      <Button type={TYPES.TERTIARY} onClick={onClick}>
        <div className={styles.button}>
          <Avatar displayName={label} shape={AVATAR_SHAPE.SQUARE} size={40} />
          <div className={styles.buttonLabel}>{label}</div>
        </div>
      </Button>
    </div>
  );
};

export default NavigateButton;
