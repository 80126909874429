import React from "react";

import { Image } from "antd";
import { isMobile } from "react-device-detect";
import cx from "classnames";

import { IMAGE_LINK } from "../../cortexSurvey-base/assets/images/imageLink";

import styles from "./authTemplate.module.scss";

const AuthTemplate = (props) => {
  const { children } = props;
  return (
    <div
      className={cx(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      {!isMobile && (
        <div className={styles.imageContainer}>
          <Image
            src={IMAGE_LINK.LOGIN_BANNER}
            width="100%"
            height="100%"
            preview={false}
          />
        </div>
      )}

      <div
        className={cx(styles.formContainer, {
          [styles.formMobileContainer]: isMobile,
        })}
      >
        <div className={styles.logo}>
          <Image src={IMAGE_LINK.LOGO} preview={false} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default AuthTemplate;
