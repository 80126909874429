import _isEmpty from "lodash/isEmpty";
import styles from "../table.module.scss";
import TableExportButton from "../components/tableExportButton";
import SearchBar from "../components/searchBar";
import TableFilter from "../components/tableFilter";
import Pagination from "../../pagination";
import _size from "lodash/size";
import { DEFAULT_PAGE_SIZE } from "../constants/table.general";
import { isMobile } from "react-device-detect";
import cx from 'classnames';

export const renderExport = (data, exportConfig) => {
  if (_isEmpty(exportConfig)) return null;

  return <TableExportButton data={data} exportConfig={exportConfig} />;
};

export const renderSearchBar = (
  data,
  setData,
  searchKeys,
  searchValue,
  setSearchValue
) => {
  if (_isEmpty(searchKeys)) return null;

  return (
    <div className={cx(styles.searchBar, {[styles.mobileSearchBar]: isMobile})}>
      <SearchBar
        className={styles.SearchBar}
        data={data}
        setData={setData}
        searchKeys={searchKeys}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </div>
  );
};

const renderFilter = (
  fetchData,
  setSearchValue,
  showFilter,
  filterOptions,
  initialFilter
) => {
  const handleChange = (value) => {
    setSearchValue();
    fetchData(value);
  };

  if (!showFilter) return null;
  return (
    <TableFilter
      onChange={handleChange}
      options={filterOptions}
      initialValue={initialFilter}
    />
  );
};

export const renderSubHeader = ({
  data,
  setData,
  exportConfig,
  searchKeys,
  filter,
  fetchData,
  searchValue,
  setSearchValue,
  showFilter,
  filterOptions,
  initialFilter,
}) => {
  return (
    <div className={cx(styles.subHeader, {[styles.mobileSubHeader]: isMobile})}>
      {renderSearchBar(data, setData, searchKeys, searchValue, setSearchValue)}
      {renderExport(data, exportConfig)}
      {renderFilter(
        fetchData,
        setSearchValue,
        showFilter,
        filterOptions,
        initialFilter
      )}
    </div>
  );
};

export const renderPagination = ({
  dataSource,
  onPageSizeChange,
  onPageChange,
  pageSize,
  currentPage,
  isPagination,
}) => {
  if (!isPagination || isMobile) return null;

  return (
    <div className={styles.pagination}>
      <Pagination
        total={_size(dataSource)}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        pageSize={pageSize}
        currentPage={currentPage}
      />
    </div>
  );
};
