import { addRenderOptions } from "../../../../../../cortexSurvey-components/formWithSubmission/utils/addRenderOptions";

import {
  ASSESSMENT_GIDELINE,
  CITY,
  COUNTRY,
  MICROZONATION,
  STATE,
  SURVEY_OBJECTIVE,
} from "../constants/cityIndexMappingForm.field";
import { FIELD_IDS } from "../constants/cityIndexMappingForm.fieldId";
import { FORM_CONFIG } from "../constants/cityIndexMappingForm.formConfig";
import CityIndexMappingFormReaders from "../readers";

export const getFormConfig = (
  getAssessmentGuidelineSurveyObjectiveMicroZonationOptions,
  getCountryStateAndCityOptions,
  formValues
) => {
  const assessmentGuidelineId =
    CityIndexMappingFormReaders.assessmentGuidelineId(formValues);
  const surveyObjectiveId =
    CityIndexMappingFormReaders.surveyObjectiveId(formValues);
  const countryId = CityIndexMappingFormReaders.countryId(formValues);
  const stateId = CityIndexMappingFormReaders.stateId(formValues);

  const {
    assessmentGuidelineOptions,
    surveyObjectiveOptions,
    microzonationOptions,
  } = getAssessmentGuidelineSurveyObjectiveMicroZonationOptions(
    assessmentGuidelineId,
    surveyObjectiveId
  );

  const { countryOptions, stateOptions, cityOptions } =
    getCountryStateAndCityOptions(countryId, stateId);

  return {
    ...FORM_CONFIG,
    [FIELD_IDS.ASSESSMENT_GUIDELINE]: addRenderOptions(ASSESSMENT_GIDELINE, {
      options: assessmentGuidelineOptions,
    }),
    [FIELD_IDS.SURVEY_OBJECTIVE]: addRenderOptions(SURVEY_OBJECTIVE, {
      options: surveyObjectiveOptions,
    }),
    [FIELD_IDS.MICROZONATION]: addRenderOptions(MICROZONATION, {
      options: microzonationOptions,
    }),
    [FIELD_IDS.COUNTRY]: addRenderOptions(COUNTRY, {
      options: countryOptions,
    }),
    [FIELD_IDS.STATE]: addRenderOptions(STATE, {
      options: stateOptions,
    }),
    [FIELD_IDS.CITY]: addRenderOptions(CITY, {
      options: cityOptions,
    }),
  };
};
