import CityIndexMapping from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/constants/dataIndex/cityIndexMapping";

import ActiveColumn from "../../../../../../cortexSurvey-components/activeColumn/ActiveColumn";

import CityIndexMappingReaders from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/readers/cityIndexMapping";

export const COLUMNS = [
  {
    title: "Country",
    dataIndex: CityIndexMapping.country,
    key: ["country", "countryCode"],
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityIndexMappingReaders.countryName} />
    ),
  },
  {
    title: "State",
    dataIndex: CityIndexMapping.state,
    key: ["country", "countryCode"],
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityIndexMappingReaders.stateName} />
    ),
  },
  {
    title: "City",
    dataIndex: CityIndexMapping.city,
    key: "cityName",
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityIndexMappingReaders.cityName} />
    ),
  },
  {
    title: "Survey Objective",
    dataIndex:CityIndexMapping.surveyObjective,
    key: "surveyObjective",
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityIndexMappingReaders.surveyObjective} />
    ),
  },
  {
    title: "Assessment Guideline",
    dataIndex: CityIndexMapping.assessmentGuideline,
    key: "assessmentGuideline",
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityIndexMappingReaders.assessmentGuideline} />
    ),
  },
  {
    title: "Microzonation",
    dataIndex: CityIndexMapping.microzonation,
    key: "level",
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={CityIndexMappingReaders.level} />
    ),
  },
  {
    title: "Soil",
    dataIndex: [CityIndexMapping.city, CityIndexMapping.soil],
    key: "soil",
    width: "20rem",
  },
];

export default COLUMNS;
