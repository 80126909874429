import React from "react";
import _map from "lodash/map";
import _get from "lodash/get";
import TableComponent from "../../tableComponent/TableComponent";

const renderComponent = (handleChange, rowData, restProps) => (configValue) => {
  const { dataIndex } = configValue;
  const value = _get(rowData, dataIndex);
  return (
    <TableComponent
      key={`tableComponent_${dataIndex}`}
      value={value}
      onChange={handleChange}
      {...configValue}
      {...restProps}
    />
  );
};

export const renderTableComponent = (
  config,
  handleChange,
  rowData,
  restProps
) => _map(config, renderComponent(handleChange, rowData, restProps));
