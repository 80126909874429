import React, { memo, useCallback } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import ImageProcessingForm from "../imageProcessingForm/ImageProcessingForm";
import { INITIAL_VALUES } from "./constants/imageProcessing.initalValues";
import { ACTION_TYPES } from "./constants/ImageProcessingCreateForm.actionTypes";
import { FORM_ID } from "./constants/ImageProcessingCreateForm.formId";
import { INITIAL_STATE } from "./constants/ImageProcessingCreateForm.initialState";
import ACTION_HANDLERS from "./helpers/imageProcessingCreateForm.actionHandlers";

const ImageProcessingCreateForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    error = false,
  } = props;

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title="Add Reference Image"
      maskClosable={false}
      width="90rem"
      onSubmit={handleSave}
      loading={isSubmitting}
      error={error}
      destroyOnClose
    >
      <ImageProcessingForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={INITIAL_VALUES}
        view={isSubmitting}
      />
    </Drawer>
  );
};

ImageProcessingCreateForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  error: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(ImageProcessingCreateForm);
