import React, { useCallback } from "react";

import Button from "../button";
import { useNavigate } from "react-router";
import { clearAuthToken } from "../../cortexSurvey-business/helpers/appHelpers/auth";
import { HOME_PAGE } from "../../cortexSurvey-business/helpers/appHelpers/navigation";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    clearAuthToken();
    navigate(HOME_PAGE);
  }, [navigate]);

  return <Button onClick={handleLogout}>Logout</Button>;
};

export default Logout;
