import { useState, useCallback } from "react";

const useDrawerVisibility = (initalValue) => {
  const [selectedData, setSelectedData] = useState(initalValue);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const showDrawer = useCallback(
    (value) => {
      setSelectedData(value);
      setIsDrawerVisible(true);
    },
    [setIsDrawerVisible, setSelectedData]
  );

  const closeDrawer = useCallback(() => {
    setIsDrawerVisible(false);
  }, [setIsDrawerVisible]);

  return { selectedData, isDrawerVisible, showDrawer, closeDrawer };
};

export default useDrawerVisibility;
