import React, { useCallback } from "react";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { FORM_CONFIG } from "./constants/loginForm.formConfig";
import { SECTIONS } from "./constants/loginForm.section";

const LoginForm = (props) => {
  const { formId, onSubmit, view } = props;
  const getFormConfig = useCallback(() => FORM_CONFIG, []);
  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      formId={formId}
      onSubmit={onSubmit}
      view={view}
    />
  );
};

export default LoginForm;
