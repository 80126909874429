import React from "react";
import { List } from "antd";
import PropTypes from "prop-types";
import _get from "lodash/get";
import Tag from "../../../../../../../cortexSurvey-components/tag";
import styles from "./tableColumnComponent.module.scss";

const TableColumnComponent = (props) => {
  const { data = [], id } = props;

  const renderStatusTag = (isActive) => {
    return isActive ? (
      <Tag color="success">Active</Tag>
    ) : (
      <Tag color="error">InActive</Tag>
    );
  };

  const renderItem = (item) => {
    const value = _get(item, id);
    const isActive = _get(item, "isActive");

    return (
      <List.Item key={item?.id}>
        <div className={styles.listItem}>
        <div>{value}</div>
        <div> {renderStatusTag(isActive)}</div>
        </div>
     
      </List.Item>
    );
  };

  return (
    <div className={styles.container}>
      <List>{data?.map((item) => renderItem(item))}</List>
    </div>
  );
};

TableColumnComponent.propTypes = {
  data: PropTypes.array,
  id: PropTypes.string,
};

export default TableColumnComponent;
