import React from "react";
import Section from "../components/section";

export const renderSection =
  (sectionProps, sectionSize) =>
  (section = {}, index) => {
    const showDivider = index + 1 !== sectionSize;

    return (
      <div>
        <Section
          key={`section_${index}`}
          {...section}
          shouldShowDivider={showDivider}
          {...sectionProps}
        />
      </div>
    );
  };

export const renderTabSections = (section = {}, renderOptions = {}, className) => {
  return <Section {...section} {...renderOptions} className={className}/>;
};
