import _get from "lodash/get";
import { AUTH_TOKEN } from "../../../../cortexSurvey-base/constants/authToken";
import {
  TOASTER_TYPE,
  toaster,
} from "../../../../cortexSurvey-components/notificationWrapper";
import { ACTION_TYPES } from "../constants/login.actionTypes";
import { getPayloadFromFormValues } from "./login.payload";
import { loginRedirectionOnSubmission } from "./login.redirection";

import AuthApi from "../../../services/auth";
import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";

const handleFormSubmission = async ({ params, setState }) => {
  const { formValues, location, navigate } = params;

  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(formValues);
      const response = await AuthApi.login(payload);
      const authToken = _get(response, "accessToken");
      localStorage.setItem(AUTH_TOKEN, authToken);
      const { email } = payload;
      localStorage.setItem("EMAIL", email);
      loginRedirectionOnSubmission(location, navigate);
      toaster(TOASTER_TYPE.SUCCESS, "Login Successfull");
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, "Failed to Login"));
    } finally {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.LOGIN_FORM_SUBMISSION]: handleFormSubmission,
};

export default ACTION_HANDLERS;
