import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import NotificationAPI from "../../../../../../cortexSurvey-business/appServices/notification/services/notification";

import { ACTION_TYPES } from "../constants/notificationCreateForm.actionTypes";
import { getPayloadFromFormValues } from "./notificationCreateForm.payload";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose, onSubmitCb } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (error) return;
  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues);
    await NotificationAPI.createNotice(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Notification Added Successfully");
  } catch (err) {
    toaster(
      TOASTER_TYPE.ERROR,
      "Failed to add Notification. Please try again."
    );
  } finally {
    setState({ isSubmitting: false });
    onClose();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
