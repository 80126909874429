import React from "react";
import Input from "../../../../../../../cortexSurvey-components/input";
import Button from "../../../../../../../cortexSurvey-components/button";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "../../microZonationInput.module.scss";

const MicroZonationInputField = (props) => {
  const { id, onChange, index, value, onDelete } = props;

  const handleChange = (event) => {
    const updatedValue = event.target.value;
    onChange(index, updatedValue, id);
  };

  const handleOnDelete = () => {
    onDelete(index);
  }

  return (
    <div className={styles.container}>
      <Input value={value} onChange={handleChange} />
      {index !== 0 && <Button onClick={handleOnDelete}> <DeleteOutlined /> </Button>}
    </div>
  );
};

export default MicroZonationInputField;
