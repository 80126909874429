import _map from "lodash/map";

import CountryReader from "../../cortexSurvey-business/appServices/country/readers/country";

const createSurveySetupCountryOption = (option) => {
  const country = CountryReader.country(option);
  const countryId = CountryReader.countryId(option);

  return {
    label: country,
    value: countryId,
  };
};

export const getSurveySetupCountryOptions = (countryData) =>
  _map(countryData, createSurveySetupCountryOption);
