import React, { memo, useCallback } from "react";
import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Modal from "../../../../../cortexSurvey-components/modal/Modal";

import { ACTION_TYPES } from "./constants/questionnaireDeleteModal.actionTypes";
import {
  MODAL_TITLE,
  SAVE_TEXT,
} from "./constants/questionnaireDeleteModal.general";
import { INITIAL_STATE } from "./constants/questionnaireDeleteModal.initialState";
import ACTION_HANDLERS from "./helpers/questionnaireDeleteModal.actionHandlers";

const QuestionnaireDeleteModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    question,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  }, [onAction]);

  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to deActivate " ${question} " Question.`}
    </Modal>
  );
};

QuestionnaireDeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  question: PropTypes.string,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(QuestionnaireDeleteModal);
