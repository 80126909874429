import { addRenderOptions } from "../../../../../../cortexSurvey-components/formWithSubmission/utils/addRenderOptions";
import { getSurveySetupCountryOptions } from "../../../../../helpers/cortexSurveySetup.getCountryOptions";
import { getSurveySetupStateOptions } from "../../../../../helpers/cortexSurveySetup.getStateOptions";
import { COUNTRY, STATE } from "../constants/cityForm.field";
import { FIELD_IDS } from "../constants/cityForm.fieldId";
import { FORM_CONFIG } from "../constants/cityForm.formConfig";
import CountryFormReaders from "../readers";

export const getFormConfig = (countryData, stateData, disabled, formValues) => {
  const countryId = CountryFormReaders.countryId(formValues);
  return {
    ...FORM_CONFIG,
    [FIELD_IDS.COUNTRY]: addRenderOptions(COUNTRY, {
      options: getSurveySetupCountryOptions(countryData),
      isDisabled: disabled,
    }),
    [FIELD_IDS.STATE]: addRenderOptions(STATE, {
      options: getSurveySetupStateOptions(stateData, countryId),
      isDisabled: disabled,
    }),
  };
};
