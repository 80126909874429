import NotificationFormReaders from "../../notificationForm/readers";

const getUrl = (type, formValues) => {
  if (type === "Image")
    return NotificationFormReaders.notificationImage(formValues);
  return NotificationFormReaders.notificationVideo(formValues);
};

export const getPayloadFromFormValues = (formValues) => {
  const title = NotificationFormReaders.notificationTitle(formValues);
  const type = NotificationFormReaders.notificationType(formValues);
  const url = getUrl(type, formValues);

  return {
    title,
    media: url,
  };
};
