import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_DASHBOARD_USER_PROFILE: "/user-data",
  UPDATE_DASHBOARD_USER_PROFILE: "/user-update",
};

const fetchDashboardUserProfile = (payload) =>
  HttpClient.post(API_PATHS.FETCH_DASHBOARD_USER_PROFILE, payload);

const updateDashboardUserProfile = (payload) =>
  HttpClient.post(API_PATHS.UPDATE_DASHBOARD_USER_PROFILE, payload);

const DashboardUserAPI = {
  fetchDashboardUserProfile,
  updateDashboardUserProfile,
};

export default DashboardUserAPI;
