import { useCallback, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

import { getErrorMessage } from "../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../cortexSurvey-components/notificationWrapper";
import useFetchPayload from "../../../../hooks/useFetchPayload";
import AssessmentGuidelineAPI from "../../services/assessmentGuideline";

const useFetchAssesmentGuideline = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchAssesmentGuideline = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await AssessmentGuidelineAPI.fetchAssessmentGuideline(
          payload
        );
        setData(response?.data);
      } catch (err) {
        toaster(
          TOASTER_TYPE.ERROR,
          getErrorMessage("Failed to fetch Assessment Guideline")
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchAssessmentGuideline = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchAssesmentGuideline, 1000);
      debounceFunction(isActive);
    },
    [fetchAssesmentGuideline, setLoading]
  );

  useEffect(() => {
    fetchAssesmentGuideline();
  }, [fetchAssesmentGuideline]);

  return {
    loading,
    data,
    fetchAssesmentGuideline: handleDebounceFetchAssessmentGuideline,
  };
};

export default useFetchAssesmentGuideline;
