import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import cx from "classnames";

import TableInputWithAdd from "../../../../../cortexSurvey-components/tableInputWithAdd";

import { ADD_INITIAL_STATE } from "./constants/helpTable.addInitialState";

import { getColumnConfig } from "./helpers/helpTable.columnConfig";
import { isMobile } from "react-device-detect";

import styles from "./helpTable.module.scss";

const HelpTable = (props) => {
  const {
    onChange = _noop,
    value = [],
    disabled = false,
    title,
    showTableAction = true,
  } = props;

  const columnConfig = useMemo(() => getColumnConfig(disabled), [disabled]);

  return (
    <TableInputWithAdd
      tableClassName={cx({ [styles.mobileTableClassName]: isMobile })}
      config={columnConfig}
      addInitialState={ADD_INITIAL_STATE}
      onChange={onChange}
      value={value}
      title={title}
      disabled={disabled}
      showAction={false}
      showTableAction={showTableAction}
    />
  );
};

HelpTable.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  showTableAction: PropTypes.bool,
};

export default memo(HelpTable);
