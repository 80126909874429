import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import NotificationWrapper from "./cortexSurvey-components/notificationWrapper/NotificationWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <App />
      <NotificationWrapper />
    </BrowserRouter>
    
  </React.StrictMode>
);
