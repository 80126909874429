import _toNumber from "lodash/toNumber";

import DashboardProfileFormReaders from "../readers/dashboardForm";

export const createPayloadFroUserProfile = (formValues, userId) => {
  const firstName = DashboardProfileFormReaders.firstName(formValues);
  const lastName = DashboardProfileFormReaders.lastName(formValues);
  const email = DashboardProfileFormReaders.email(formValues);
  const phone = DashboardProfileFormReaders.phoneNumber(formValues);

  return { firstName, lastName, email, phone: _toNumber(phone), userId };
};
