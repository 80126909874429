import _map from "lodash/map";
import _compact from "lodash/compact";

import { getAssessmentGuidelineSurveyObjectiveMicroZonationOptionsFormMicroZonationData } from "../../../../cortexSurvey-business/appServices/microZonation/helpers/assesmentGuidelineSurveyObjectiveMicroZonationOptions";
import { getStructureTypeOptions } from "../../../../cortexSurvey-business/appServices/structureType/helpers/structureTypeOptions";
import { getSurveyorRankOptions } from "../../../../cortexSurvey-business/appServices/surveyorRank/helpers/getSurveyorRankOptions";

import QuestionnaireReaders from "../../../../cortexSurvey-business/appServices/questionnaire/readers/questionnaire";
import SurveyObjectiveReaders from "../../../../cortexSurvey-business/appServices/surveyObjective/readers/surveyObjective";
import AssessmentGuidelineReaders from "../../../../cortexSurvey-business/appServices/assessmentGuideline/readers/assessmentGuideline";
import StructureTypeReaders from "../../../../cortexSurvey-business/appServices/structureType/readers/structureType";
import SurveyOrRankReaders from "../../../../cortexSurvey-business/appServices/surveyorRank/readers/surveyorRank";

const getInactiveOptions = (labelReader, valueReader) => (option) => {
  const { isActive } = option;

  if (!isActive) {
    return { label: labelReader(option), value: valueReader(option) };
  }

  return undefined;
};

const getUpdatedOptions = (
  options = [],
  extraOptions,
  labelReader,
  valueReader
) => {
  const OptionsWithInactive = _map(
    extraOptions,
    getInactiveOptions(labelReader, valueReader)
  );
  const compactOptions = _compact(OptionsWithInactive) || [];

  return [...options, ...compactOptions];
};

export const getQuestionnaireOptions = ({
  microzonationData = [],
  surveyObjective = [],
  structureType = [],
  surveyorRank = [],
  selectedEditData = {},
}) => {
  const { surveyObjectiveOptions, assessmentGuidelineOptions } =
    getAssessmentGuidelineSurveyObjectiveMicroZonationOptionsFormMicroZonationData(
      microzonationData,
      surveyObjective
    );

  const structureTypeOptions = getStructureTypeOptions(structureType);

  const surveyorRankOptions = getSurveyorRankOptions(surveyorRank);

  const updatedSurveyObjectiveOptions = getUpdatedOptions(
    surveyObjectiveOptions,
    QuestionnaireReaders.surveyObjectives(selectedEditData),
    SurveyObjectiveReaders.surveyObjective,
    SurveyObjectiveReaders.surveyObjectiveId
  );

  const updatedAssessmentGuidelineOptions = getUpdatedOptions(
    assessmentGuidelineOptions,
    QuestionnaireReaders.assessmentGuidelines(selectedEditData),
    AssessmentGuidelineReaders.assessmentGuideline,
    AssessmentGuidelineReaders.assessmentGuidelineId
  );
  const updatedstructureTypeOptions = getUpdatedOptions(
    structureTypeOptions,
    QuestionnaireReaders.structureTypes(selectedEditData),
    StructureTypeReaders.structureName,
    StructureTypeReaders.id
  );
  const updatedSurveyorRankOptions = getUpdatedOptions(
    surveyorRankOptions,
    QuestionnaireReaders.surveyOrRanks(selectedEditData),
    SurveyOrRankReaders.surveyorRank,
    SurveyOrRankReaders.surveyorRankId
  );

  return {
    surveyObjectiveOptions: updatedSurveyObjectiveOptions,
    assessmentGuidelineOptions: updatedAssessmentGuidelineOptions,
    structureTypeOptions: updatedstructureTypeOptions,
    surveyorRankOptions: updatedSurveyorRankOptions,
  };
};
