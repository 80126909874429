import _noop from "lodash/noop";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import CityAPI from "../../../../../../cortexSurvey-business/appServices/city/services/city";

import { ACTION_TYPES } from "../constants/cityDeleteModal.actionTypes";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "../constants/cityDeleteModal.general";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { cityId, onCancel=_noop, onSubmitCb = _noop } = getState();
  setState({ isSubmitting: true });
  try {
    const payload={ cityId, isActive: 0 }
    await CityAPI.deleteCity(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
