import {
  AppstoreOutlined,
  CloseOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import React from "react";

import useLogout from "../../../../cortexSurvey-base/hooks/useLogout";
import Button, { TYPES } from "../../../button";
import QuickNavigationButton from "./components/quickNavigationButton";

import styles from "./sidebar.module.scss";

const Sidebar = (props) => {
  const { navigationOpen, handleClose, handleOpen, quickNavigation } = props;

  const logout = useLogout();

  const menuIconRenderer = navigationOpen ? (
    <Button onClick={handleClose} type={TYPES.TERTIARY}>
      <CloseOutlined style={{ fontSize: "26px", color: "white" }} />
    </Button>
  ) : (
    <Button onClick={handleOpen} type={TYPES.TERTIARY}>
      <AppstoreOutlined style={{ fontSize: "26px", color: "white" }} />
    </Button>
  );

  const logoutIconRenderer = (
    <Button onClick={logout} type={TYPES.TERTIARY}>
      <LogoutOutlined style={{ fontSize: "26px", color: "white" }} />
    </Button>
  );

  return (
    <div className={styles.container}>
      <div className={styles.upper}>{menuIconRenderer}</div>
      {!navigationOpen && (
        <QuickNavigationButton quickNavigation={quickNavigation} />
      )}
      <div className={styles.lower}>{logoutIconRenderer}</div>
    </div>
  );
};

export default Sidebar;
