import { useState, useCallback } from "react";

const useDashboardUserPayload = (iniitalActiveState = "Active") => {
  const [isActive, setIsActive] = useState(iniitalActiveState);

  const getIsActivePayload = useCallback((active) => {
    if (active==="ALL") return {roleId:1};
    return { status: active, roleId:1 };
  }, []);

  const getFetchPayload = useCallback(
    (active) => {
      if (active!==0 && !active) {
        return getIsActivePayload(isActive);
      }

      setIsActive(active);
      return getIsActivePayload(active);
    },
    [isActive, setIsActive, getIsActivePayload]
  );

  return { getFetchPayload };
};

export default useDashboardUserPayload;
