import _noop from "lodash/noop";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import SurveyorRankAPI from "../../../../../../cortexSurvey-business/appServices/surveyorRank/services/surveyorRank";
import { ACTION_TYPES } from "../constants/surveyorRankEditForm.actionTypes";
import {
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../constants/surveyorRankEditForm.general";
import { getPayloadFromFormValues } from "./surveyorRankEditForm.payload";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";

const handleEditFormSubmission = ({ getState, params, setState }) => {
  const { surveyorRankId, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(formValues, surveyorRankId);
      SurveyorRankAPI.editSurveyorRank(payload);
      onSubmitCb();
      toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
      onClose();
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
    } finally {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
