import React, { memo, useCallback, useMemo } from "react";

import PropTypes from "prop-types";

import _noop from "lodash/noop";

import Drawer from "../../../../../cortexSurvey-components/drawer";
import FormWithSubmissionV2 from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import { FORM_ID } from "./constants/questionaireScoreForm.general";
import { SECTIONS } from "./constants/questionaireScoreForm.sections";
import { getFormConfig } from "./helpers/questionaireScoreForm.formConfig";
import { makeInitialValues } from "./helpers/questionaireScoreForm.initialValues";

const QuestionaireScoreForm = (props) => {
  const { isVisible = false, onCancel = _noop, scoreData = [] } = props;

  const initialValues = useMemo(
    () => makeInitialValues(scoreData),
    [scoreData]
  );

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleSubmit = useCallback((formValues) => {
    console.log(formValues);
  }, []);

  return (
    <Drawer
      title="Edit Score"
      width="120rem"
      open={isVisible}
      onClose={onCancel}
      onSubmit={handleSave}
      destroyOnClose
    >
      <FormWithSubmissionV2
        formId={FORM_ID}
        initialValues={initialValues}
        sections={SECTIONS}
        formConfig={getFormConfig}
        onSubmit={handleSubmit}
      />
    </Drawer>
  );
};

QuestionaireScoreForm.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  scoreData: PropTypes.array,
};

export default memo(QuestionaireScoreForm);
