import Menu from "../components/menu/Menu";
import MultiValue from "../components/multiValue/MultiValue";
import Options from "../components/options";

export const SELECT_COMPONENTS = {
  Option: Options,
  MultiValueContainer: MultiValue,
  Menu,
  IndicatorSeparator: null,
};
