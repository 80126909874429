import React, { memo } from "react";
import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";

import NotificationOverview from "./components/notificationOverview";
import {
  CREATE_BUTTON_LABEL,
  HEADER_TITLE,
} from "./constants/notification.general";

import { ACTION_TYPES } from "./constants/notification.actionTypes";
import ACTION_HANDLERS from "./helpers/notification.actionHandlers";

const Notification = (props) => {
  const {
    loading = false,
    data = [],
    onAction = _noop,
    fetchNotice = _noop,
  } = props;
  return (
    <HeaderWithTable
      title={HEADER_TITLE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      onAction={onAction}
      data={data}
      loading={loading}
      createActionType={ACTION_TYPES.CREATE}
      overviewComponent={NotificationOverview}
      onReload={fetchNotice}
      fetchData={fetchNotice}
      showReload
    />
  );
};

export default compose(memo, withActions({}, ACTION_HANDLERS))(Notification);
