import React, { useCallback, useState } from "react";
import _noop from "lodash/noop";
import MultiUpload from "./components/multiUpload";
import SingleUpload from "./components/singleUpload";
import Label from "../label";

import styles from "./upload.module.scss";

const Upload = (props) => {
  const {
    isMulti = false,
    label,
    required = false,
    setStateUploading = _noop,
  } = props;

  const [uploading, setUploading] = useState(false);

  const handleSetUploading = useCallback(
    (value) => {
      setUploading(value);
      setStateUploading(value);
    },
    [setStateUploading, setUploading]
  );

  if (isMulti)
    return (
      <div className={styles.container}>
        {label && <Label label={label} required={required} />}

        <MultiUpload
          {...props}
          uploading={uploading}
          setUploading={handleSetUploading}
        />
      </div>
    );

  return (
    <div className={styles.container}>
      {label && <Label label={label} required={required} />}
      <SingleUpload
        {...props}
        uploading={uploading}
        setUploading={handleSetUploading}
      />
    </div>
  );
};

export default Upload;
