import { DeleteOutlined,EditOutlined,SyncOutlined, ExportOutlined } from "@ant-design/icons";


import { ACTION_TYPES } from "../../../constants/dashboardUsers.actionTypes";
import { FILTER_VALUES } from "./dashboardUserOverview.filter";

const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "Revoke User Access",
  icon: <DeleteOutlined />,
};

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit User Details",
  icon: <EditOutlined />,
};

const RESEND_RESET_LINK = {
  id: ACTION_TYPES.RESEND,
  label: "Resend Verification Link",
  icon: <SyncOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVE,
  label: "Activate User",
  icon: <ExportOutlined/>,
};


 const ACTIVE_ROW_ACTIONS=[EDIT, DELETE];
 const PENDING_ROW_ACTIONS=[RESEND_RESET_LINK, EDIT, DELETE];
 const INACTIVE_ROW_ACTIONS=[ACTIVATE];

 export const ROW_ACTIONS={
  [FILTER_VALUES.ACTIVE]:ACTIVE_ROW_ACTIONS,
  [FILTER_VALUES.INACTIVE]:INACTIVE_ROW_ACTIONS,
  [FILTER_VALUES.PENDING]:PENDING_ROW_ACTIONS
 }