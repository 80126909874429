import React, { useMemo, memo, useEffect } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import cx from "classnames";

import TableInputWithAdd from "../../../../../cortexSurvey-components/tableInputWithAdd";

import { ADD_INITIAL_STATE } from "./constants/answerPatternTable.initialState";

import { getColumnConfig } from "./helpers/answerPatternTable.columnConfig";
import styles from "./answerPatternTable.module.scss";
import { isMobile } from "react-device-detect";

const AnswerPatternTable = (props) => {
  const { onChange=_noop, value, disabled=false, title, showTableAction, answerPattern } =
    props;

  const columnConfig = useMemo(() => getColumnConfig(disabled), [disabled]);

  // useEffect(()=>{
  // onChange([ADD_INITIAL_STATE])
  // },[answerPattern, onChange])

  if (!answerPattern)
    return (
      <div className={styles.answer}>
        Select Answer Pattern to Enable Answer Field
      </div>
    );

  if (answerPattern === "textbox")
    return (
      <div className={styles.answer}>
        Answer Field is Disabled for TextBox Answer Pattern
      </div>
    );

  return (
    <TableInputWithAdd
      tableClassName={cx({ [styles.mobileTableClassName]: isMobile })}
      config={columnConfig}
      addInitialState={ADD_INITIAL_STATE}
      onChange={onChange}
      value={value}
      title={title}
      disabled={disabled}
      showAction={false}
      showTableAction={showTableAction}
    />
  );
};

AnswerPatternTable.propTypes={

}

export default AnswerPatternTable;
