import React, { memo} from "react";
import PropTypes from "prop-types";

import Tag from "../../../../../../../cortexSurvey-components/tag";

const PaymentTag = (props) => {
  const { payment } = props;

  if (payment === "Paid") return <Tag color="success">Paid</Tag>;

  return <Tag color="error">UnPaid</Tag>;
};

PaymentTag.propTypes = {
  payment: PropTypes.string,
};

export default memo(PaymentTag);
