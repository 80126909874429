import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import _get from "lodash/get";
import _noop from "lodash/noop";
import _mapValues from "lodash/mapValues";
import _isFunction from "lodash/isFunction";

import styles from "./field.module.scss";

const Field = (props) => {
  const {
    renderer: Component,
    fieldId,
    formState,
    renderOptions,
    onChange,
    className,
    componentClassName,
    disabled,
    fieldValueMapper,
    setDisableSubmit,
    setStateLoading = _noop,
  } = props;

  const { error } = renderOptions;

  const value = useMemo(() => _get(formState, fieldId), [formState, fieldId]);

  const handleChange = useCallback(
    (value) => {
      onChange(fieldId, value);
      const fieldValueMapperId = _get(fieldValueMapper, fieldId);

      _mapValues(fieldValueMapperId, (fieldValue, key) => {
        if (_isFunction(fieldValue)) {
          const updatedFieldValue = fieldValue(value);
          onChange(key, updatedFieldValue);
        } else {
          onChange(key, fieldValue);
        }
      });
    },
    [fieldId, onChange, fieldValueMapper]
  );

  return (
    <div className={cx(styles.container, className)}>
      <Component
        className={componentClassName}
        disabled={disabled}
        {...renderOptions}
        value={value}
        onChange={handleChange}
        setDisableSubmit={setDisableSubmit}
        setStateLoading={setStateLoading}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

Field.propTypes = {
  renderer: PropTypes.any,
  fieldId: PropTypes.string,
  formState: PropTypes.object,
  renderOptions: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldValueMapper: PropTypes.object,
};

Field.defaultProps = {
  renderer: undefined,
  fieldId: undefined,
  formState: {},
  renderOptions: {},
  onChange: _noop,
  className: undefined,
  disabled: false,
  fieldValueMapper: {},
};

export default Field;
