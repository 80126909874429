import _property from "lodash/property";

import { FIELD_IDS } from "../constants/structureHelpForm.fieldId";

const structureImages = _property(FIELD_IDS.STRUCTURE_IMAGES);
const structureDescription = _property(FIELD_IDS.STRUCTURE_DESCRIPTION);
const structureVideo = _property(FIELD_IDS.STRUCTURE_VIDEO);

const READERS = {
  structureImages,
  structureDescription,
  structureVideo,
};

export default READERS;
