import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";
import SelectFieldRenderer from '../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/SelectFieldRenderer';
import { FIELD_IDS } from "./cityForm.fieldId";

export const COUNTRY = {
  id: FIELD_IDS.COUNTRY,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Country",
    placeholder: "Country",
    required: true,
  },
};

export const STATE = {
  id: FIELD_IDS.STATE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "State",
    placeholder: "State",
    required: true,
  },
};

export const CITY = {
  id: FIELD_IDS.CITY,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "City",
    placeholder: "City",
    required: true,
  },
};

export const SOIL = {
  id: FIELD_IDS.SOIL,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Soil",
    placeholder: "Soil",
    required: true,
  },
};
