import {
  STRUCTURE_DESCRIPTION,
  STRUCTURE_IMAGES,
  STRUCTURE_VIDEO,
} from "./structureHelpForm.field";
import { FIELD_IDS } from "./structureHelpForm.fieldId";

export const FORM_CONFIG = {
  [FIELD_IDS.STRUCTURE_DESCRIPTION]: STRUCTURE_DESCRIPTION,
  [FIELD_IDS.STRUCTURE_IMAGES]: STRUCTURE_IMAGES,
  [FIELD_IDS.STRUCTURE_VIDEO]: STRUCTURE_VIDEO,
};
