import _map from "lodash/map";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import Column from "../column";

import styles from "./row.module.scss";
import { isMobile } from "react-device-detect";

const Row = (props) => {
  const { columns = [], className, rowClassName, ...restProps } = props;
  const renderColumn = useCallback(
    (fieldId, index) => (
      <Column key={`column_${index}`} id={fieldId} {...restProps} />
    ),
    [restProps]
  );
  return (
    <div
      className={cx(styles.container, className, rowClassName, {
        [styles.mobileContainer]: isMobile,
      })}
    >
      {_map(columns, renderColumn)}
    </div>
  );
};

Row.propTypes = {
  columns: PropTypes.array,
  className: PropTypes.string,
};

export default memo(Row);
