import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { SECTIONS } from "./constants/stateForm.sections";
import { getFormConfig } from "./helpers/stateForm.formConfig";

const StateForm = (props) => {
  const { initialValues={}, onSubmit=_noop, formId, country, disabled=false } = props;

  const formConfig = useCallback(() => getFormConfig(country), [country]);

  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={disabled}
    />
  );
};

StateForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(StateForm);
