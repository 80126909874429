import { FIELD_IDS } from "./imageProcessingForm.fieldId";
import {
  ITEM_CODE,
  ITEM_SEARCH_CODE,
  DISTRESS_PATTERN,
  SCORE_REFERENCE,
  NUMERICAL_SCORE,
  CAPTURED_WITH_CAMERA_TYPE,
  LIGHT_AND_WEATHER_CONDITION,
  CAPTURING_DISTANCE,
  VULNERABILITY_SCALE,
  REFERENCE_IMAGE,
  AUDIO_CLIP,
} from "./imageProcessingForm.fields";

export const FORM_CONFIG = {
  [FIELD_IDS.ITEM_CODE]: ITEM_CODE,
  [FIELD_IDS.ITEM_SEARCH_CODE]: ITEM_SEARCH_CODE,
  [FIELD_IDS.DISTRESS_PATTERN]: DISTRESS_PATTERN,
  [FIELD_IDS.SCORE_REFERENCE]: SCORE_REFERENCE,
  [FIELD_IDS.NUMERICAL_SCORE]: NUMERICAL_SCORE,
  [FIELD_IDS.CAPTURED_WITH_CAMERA_TYPE]: CAPTURED_WITH_CAMERA_TYPE,
  [FIELD_IDS.LIGHT_AND_WEATHER_CONDITION]: LIGHT_AND_WEATHER_CONDITION,
  [FIELD_IDS.CAPTURING_DISTANCE]: CAPTURING_DISTANCE,
  [FIELD_IDS.VULNERABILITY_SCALE]: VULNERABILITY_SCALE,
  [FIELD_IDS.REFERENCE_IMAGE]: REFERENCE_IMAGE,
  [FIELD_IDS.AUDIO_CLIP]: AUDIO_CLIP,
};
