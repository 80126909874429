import React from "react";
import { Checkbox as CheckBoxField } from "antd";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import "./checkbox.module.scss";

const Checkbox = (props) => {
  const { onChange, ...restProps } = props;
  return <CheckBoxField {...restProps} onChange={onChange} />;
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: _noop,
};

export default Checkbox;
