import React from "react";
import Modal from "../../../../../../cortexSurvey-components/modal/Modal";

import styles from './forgotPassword.module.scss'

const ForgotPasswordModal = (props) => {
  const { isVisible, onCancel } = props;

  const handleRedirect = () => {
    const gmailUrl = "https://mail.google.com";
    window.open(gmailUrl, "_blank");
  };

  return (
    <Modal
      title="Forgot Password"
      open={isVisible}
      onCancel={onCancel}
      centered
      okText="Redirect to Gmail"
      onOk={handleRedirect}
    >
      <div className={styles.container}>Reset Password Link has been send to your registered email.</div>
    </Modal>
  );
};

export default ForgotPasswordModal;
