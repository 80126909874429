import _size from "lodash/size";
import _upperCase from "lodash/upperCase";
import _isEmpty from 'lodash/isEmpty';
import _words from 'lodash/words';

import styles from "../avatar.module.scss";

const generateDisplayNameForMultipleWords = ([firstWord, secondWord]) => {
  const firstLetter = _upperCase(firstWord[0]);
  const secondLetter = _upperCase(secondWord[0]);
  return `${firstLetter}${secondLetter}`;
};

const generateDisplayNameForSingleWord = (word) => {
  if(_isEmpty(word)) return;
  const spliceWord = word?.slice(0, 2);
  return _upperCase(spliceWord);
};

export const getDisplayName = (name) => {
  const words = _words(name);
  if (_size(words) > 1) return generateDisplayNameForMultipleWords(words);
  return generateDisplayNameForSingleWord(name);
};

export const getDisplayBackgroundColor = (isblack) => {

  if(isblack) 
  return styles.avatarBlack;

  return styles.avatarGray;


  //to get different colors logic
  // const firstLetter = (name || "").charAt(0).toUpperCase();
  // const className = `avatar${firstLetter}`;
  // return styles[className];
};
