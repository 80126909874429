export const STRUCTURE_PROBLEM_TYPE_OPTIONS = [
  {
    label: "History Of Earlier Damage",
    value: "History Of Earlier Damage",
  },
  {
    label: "Existing Damage Observed",
    value: "Existing Damage Observed",
  },
];
