import { ACTION_TYPES } from "../../../constants/structureType.actionTypes";

import {
  EditOutlined,
  DeleteOutlined,
  ContainerOutlined,
  ExportOutlined,
} from "@ant-design/icons";

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit Structure Type Details",
  icon: <EditOutlined />,
};

const EDIT_STRUCTURE_PROBLEM = {
  id: ACTION_TYPES.EDIT_STRUCTURE_PROBLEM,
  label: "Edit Structure Problem",
  icon: <EditOutlined />,
};

const EDIT_STRUCTURE_HELP = {
  id: ACTION_TYPES.EDIT_STRUCTURE_HELP,
  label: "Edit Structure Help",
  icon: <EditOutlined />,
};

const EDIT_STRUCTURE_COMPONENT = {
  id: ACTION_TYPES.EDIT_STRUCTURE_COMPONENT,
  label: "Edit Structure Components",
  icon: <ContainerOutlined />,
};

const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate Structure Type",
  icon: <DeleteOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVATE,
  label: "Activate Structure Type",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [
  EDIT,
  EDIT_STRUCTURE_HELP,
  EDIT_STRUCTURE_COMPONENT,
  EDIT_STRUCTURE_PROBLEM,
  DELETE,
];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
