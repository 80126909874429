import React, { useCallback } from "react";
import { Upload as AntdUpload, Spin } from "antd";
import _noop from "lodash/noop";
import { TOASTER_TYPE, toaster } from "../../../notificationWrapper";
import { LoadingOutlined } from "@ant-design/icons";

const BaseUpload = (props) => {
  const { onChange = _noop, uploadClassName, uploading = false, setUploading = _noop } = props;

  const authToken = localStorage.getItem("AUTH_TOKEN") || "";

  const customRequest = useCallback(
    async ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append("file", file);

      setUploading(true);

      try {
        const response = await fetch(
          process.env.REACT_APP_MEDIA_URL,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to upload");
        }

        const result = await response.json();
        const uuid = result.data?.file;
        onChange(uuid);
        toaster(TOASTER_TYPE.SUCCESS, "File uploaded successfully");
        onSuccess();
      } catch (error) {
        toaster(TOASTER_TYPE.ERROR, "Failed to upload");
        onError(error);
      } finally {
        setUploading(false);
      }
    },
    [onChange, authToken, setUploading]
  );

  return (
    <AntdUpload.Dragger
      className={uploadClassName}
      customRequest={customRequest}
      disabled={uploading}
      itemRender={_noop}
    >
      {uploading ? (
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      ) : (
        "Click or Drag to Upload"
      )}
    </AntdUpload.Dragger>
  );
};

export default BaseUpload;
