import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Table as AntdTable, ConfigProvider } from "antd";
import cx from "classnames";
import _noop from "lodash/noop";
import _size from 'lodash/size'
import PropTypes from "prop-types";

import { getColumns } from "./helpers/table.columns";
import { renderPagination, renderSubHeader } from "./helpers/table.components";
import { getUpdatedTableData } from "./helpers/table.tableData";

import styles from "./table.module.scss";
import {
  getTableHeight,
  isDataWithPagination,
} from "./helpers/table.general";
import { DEFAULT_PAGE_SIZE } from "./constants/table.general";

const Table = (props) => {
  const {
    dataSource = [],
    columns = [],
    rowActions = [],
    searchKeys = [],
    exportConfig = {},
    onAction = _noop,
    y,
    className,
    bordered = true,
    size = "small",
    fetchData,
    inActiveRowActions,
    showFilter,
    filterOptions,
    initialFilter=1,
    isBanner,
    ...restProps
  } = props;

  const [activeFilter, setActiveFilter] = useState(initialFilter);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchValue, setSearchValue] = useState();

  const [filterTableData, setFilterTableData]=useState([]);

  const handleActiveFilterChange = useCallback(
    (value) => {
      setActiveFilter(value);
      fetchData(value);
    },
    [setActiveFilter, fetchData]
  );

  const handleSetTableData = useCallback(() => {
    const updatedTableData = getUpdatedTableData(
      dataSource,
      pageSize,
      currentPage
    );
    setTableData(updatedTableData);
  }, [dataSource, currentPage, pageSize, setTableData]);

  const handleSubheaderClick=useCallback((data)=>{
  setTableData(data);
  setFilterTableData(data);
  setCurrentPage(1);
  },[setCurrentPage, setTableData, setFilterTableData])

  const tableColumns = useMemo(
    () =>
      getColumns({
        columns,
        rowActions,
        onAction,
        inActiveRowActions,
        activeFilter,
      }),
    [columns, rowActions, onAction, inActiveRowActions, activeFilter]
  );

  const tableHeight = useMemo(
    () => getTableHeight(dataSource, y,isBanner),
    [dataSource, y,isBanner]
  );

  const subHeader = useMemo(
    () =>
      renderSubHeader({
        data: dataSource,
        setData: handleSubheaderClick,
        exportConfig,
        searchKeys,
        fetchData: handleActiveFilterChange,
        searchValue,
        setSearchValue,
        showFilter,
        filterOptions,
        initialFilter
      }),
    [
      dataSource,
      handleSubheaderClick,
      exportConfig,
      searchKeys,
      handleActiveFilterChange,
      searchValue,
      setSearchValue,
      showFilter,
      filterOptions,
      initialFilter,
    ]
  );

  const pagination = useMemo(
    () =>
      renderPagination({
        dataSource:filterTableData,
        onPageSizeChange: setPageSize,
        onPageChange: setCurrentPage,
        pageSize,
        currentPage,
        isPagination:_size(dataSource)>=DEFAULT_PAGE_SIZE,
      }),
    [dataSource, pageSize, currentPage, setPageSize, setCurrentPage, filterTableData]
  );

  useEffect(() => {
    handleSetTableData();
  }, [handleSetTableData]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#EDEEF0",
            rowHoverBg: "#EBF4FF",
            borderColor: "#D4D5D6",
          },
        },
      }}
    >
      <div className={styles.tableBody}>
        <div className={styles.subHeader}>{subHeader}</div>
        <div
          className={cx(styles.tableWhole, {
            [styles.table]: !isDataWithPagination(dataSource),
          })}
        >
          <AntdTable
            {...restProps}
            className={cx(styles.customTable, className)}
            dataSource={tableData}
            columns={tableColumns}
            scroll={{ y: tableHeight }}
            pagination={false}
            bordered={bordered}
            size={size}
          />
        </div>
        {pagination}
      </div>
    </ConfigProvider>
  );
};

Table.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  rowActions: PropTypes.array,
  onAction: PropTypes.func,
  y: PropTypes.string,
  size: PropTypes.string,
  bordered: PropTypes.bool,
  searchKeys: PropTypes.array,
  exportConfig: PropTypes.object,
};

export default memo(Table);
