import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo } from "react";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";

import { getFormConfig } from "./helpers/surveyRankForm.formConfig";

import { SECTIONS } from "./constants/surveyorRankForm.sections";

const SurveyorRankForm = (props) => {
  const {
    initialValues = {},
    onSubmit = _noop,
    formId,
    disabled = false,
  } = props;
  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={disabled}
    />
  );
};

SurveyorRankForm.prototype = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(SurveyorRankForm);
