import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/dashboardUsersVerificationModal.actionTypes";

import DashboardUserAPI from "../../../../../../cortexSurvey-business/appServices/dashboardUser/services/dashboardUsers";

const handleVerificationSubmission = async ({ getState, setState }) => {
  const { userId, onCancel = _noop, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });
  try {
    const payload = {userId };
    await DashboardUserAPI.resendVerificationLink(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Verification Link Sent Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to Send Verification Link");
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.VERIFICATION_SUBMISSION]: handleVerificationSubmission,
};

export default ACTION_HANDLERS;
