import TextAreaFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/TextAreaFieldRenderer";

import ImageUpload from "../../../../../../cortexSurvey-components/imageUpload";

export const getColumnConfig = (disabled) => [
  {
    key: "help",
    dataIndex: "help",
    renderer: TextAreaFieldRenderer,
    width: "420px",
    headerName: "Help Input",
    renderProps: {
      autoSize: { minRows: 7, maxRows: 10 },
      disabled,
    },
  },
  {
    key: "helpImage",
    dataIndex: "helpImage",
    renderer: ImageUpload,
    width: "200px",
    headerName: "Help Image",
    renderProps: {
      disabled,
      width: '14.5rem',
      height: '14.5rem'
    },
  },
];
