import _property from "lodash/property";

import { FIELD_IDS } from "../constants/resetPasswordForm.fieldIds";

const password = _property(FIELD_IDS.PASSWORD);
const confirmPassword = _property(FIELD_IDS.CONFIRM_PASSWORD);

const reader = {
  password,
  confirmPassword,
};

export default reader;
