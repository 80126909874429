import { useState, useCallback } from "react";

const useModalVisibility = (initalValue) => {
  const [selectedData, setSelectedData] = useState(initalValue);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = useCallback(
    (value) => {
      setSelectedData(value);
      setIsModalVisible(true);
    },
    [setIsModalVisible, setSelectedData]
  );

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  return { selectedData, isModalVisible, showModal, closeModal };
};

export default useModalVisibility;
