import React, { memo } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import cx from "classnames";

import { getSrc } from "../../cortexSurvey-base/helpers/upload/general";
import { isMobile } from "react-device-detect";

import styles from "./videoPlayer.module.scss";

const VideoPlayer = (props) => {
  const { src, url, value, className } = props;

  const urlLink = src || url || value;

  if (!urlLink) return <div>No Video Available</div>;

  // if (isMobile)
  //   return <div>Video Player is not Supported on Mobile Devices</div>;

  return (
    <ReactPlayer
      {...props}
      className={cx(className, { [styles.container]: isMobile })}
      url={getSrc(urlLink)}
    />
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default memo(VideoPlayer);
