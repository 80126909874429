import _property from "lodash/property";

import { FIELD_IDS } from "../constants/notificationForm.fieldId";

const notificationTitle = _property(FIELD_IDS.NOTIFICATION_TITLE);
const notificationType = _property(FIELD_IDS.NOTIFICATION_TYPE);
const notificationImage = _property(FIELD_IDS.NOTIFICATION_IMAGE);
const notificationVideo = _property(FIELD_IDS.NOTIFICATION_VIDEO);

const READERS = {
  notificationImage,
  notificationTitle,
  notificationType,
  notificationVideo,
};

export default READERS;
