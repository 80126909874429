import StructureTypeProblemDataIndex from "../../../../../../../../cortexSurvey-business/appServices/structureType/constants/dataIndex/structureProblem";
import InputFieldRenderer from "../../../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";
import SelectFieldRenderer from "../../../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/SelectFieldRenderer";
import { STRUCTURE_PROBLEM_TYPE_OPTIONS } from "./structureproblemTable.general";

export const COLUMN_CONFIG = [
  {
    dataIndex: StructureTypeProblemDataIndex.structureProblem,
    key: StructureTypeProblemDataIndex.structureProblem,
    renderer: InputFieldRenderer,
    headerName: "Structure Problem",
    title: "Structure Problem",
    width: "30rem",
  },
  {
    dataIndex: StructureTypeProblemDataIndex.structureRange,
    key: StructureTypeProblemDataIndex.structureRange,
    renderer: InputFieldRenderer,
    headerName: "Structure Range",
    title: "Structure Range",
    width: "30rem",
  },
  {
    dataIndex: StructureTypeProblemDataIndex.stuctureProblemType,
    key: StructureTypeProblemDataIndex.stuctureProblemType,
    renderer: SelectFieldRenderer,
    renderProps: {
      options: STRUCTURE_PROBLEM_TYPE_OPTIONS,
    },
    headerName: "Structure Problem Type",
    title: "Structure Problem Type",
    width: "30rem",
  },
];


export const TABLE_COLUMN_CONFIG = [
  {
    dataIndex: StructureTypeProblemDataIndex.structureProblem,
    key: StructureTypeProblemDataIndex.structureProblem,
    renderer: InputFieldRenderer,
    headerName: "Structure Problem",
    title: "Structure Problem",
  },
  {
    dataIndex: StructureTypeProblemDataIndex.structureRange,
    key: StructureTypeProblemDataIndex.structureRange,
    renderer: InputFieldRenderer,
    headerName: "Structure Range",
    title: "Structure Range",
  },
  {
    dataIndex: StructureTypeProblemDataIndex.stuctureProblemType,
    key: StructureTypeProblemDataIndex.stuctureProblemType,
    renderer: SelectFieldRenderer,
    renderProps: {
      options: STRUCTURE_PROBLEM_TYPE_OPTIONS,
    },
    headerName: "Structure Problem Type",
    title: "Structure Problem Type",
  },
];

