import SurveyObjectiveDataIndex from "../../../../../../cortexSurvey-business/appServices/surveyObjective/constants/dataIndex/surveyObjective";

const COLUMNS = [
  {
    title: "Survey Objective ID",
    dataIndex: SurveyObjectiveDataIndex.surveyObjectiveId,
    key: SurveyObjectiveDataIndex.surveyObjectiveId,
  },
  {
    title: "Survey Objective",
    dataIndex: SurveyObjectiveDataIndex.surveyObjective,
    key: SurveyObjectiveDataIndex.surveyObjective,
  },
];

export default COLUMNS;
