import _property from "lodash/property";
import StateDataIndex from "../constants/dataIndex/state";

const state = _property(StateDataIndex.state);
const stateId = _property(StateDataIndex.stateId);
const statecode = _property("stateCode");
const country = _property(StateDataIndex.country);
const countryName = _property(StateDataIndex.countryName);
const countryId = _property(StateDataIndex.countryId);

const READERS = { state, stateId, statecode, country, countryId, countryName };

export default READERS;
