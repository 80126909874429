import React from "react";
import { InputNumber as AntdInputNumber } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import Label from "../label";

import styles from "./inputNumber.module.scss";

const InputNumber = (props) => {
  const { className, label, required, invalid, ...restProps } = props;
  return (
    <div className={styles.inputContainer}>
      {label && <Label label={label} required={required} />}
      <AntdInputNumber
        {...restProps}
        className={cx(styles.input, className, { [styles.invalid]: invalid })}
        required={required}
        fullWidth
      />
    </div>
  );
};

export default InputNumber;
