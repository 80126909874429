import React from "react";
import { SettingOutlined } from "@ant-design/icons";

import _compact from "lodash/compact";
import _isEmpty from "lodash/isEmpty";
import _isFunction from "lodash/isFunction";
import _map from "lodash/map";

import InactiveAndActiveStatus from "../components/inactiveAndActiveStatus";
import RowAction from "../components/rowAction/RowAction";

import styles from "../table.module.scss";
import { isMobile } from "react-device-detect";

const getRowActionColumn = (
  rowActions,
  onAction,
  inActiveRowActions = [],
  activeFilter
) => {
  if (_isEmpty(rowActions) && !_isFunction(rowActions)) return null;
  return {
    title: (
      <div className={styles.actionHeader}>
        <SettingOutlined />
      </div>
    ),
    key: "action",
    fixed: "right",
    width: isMobile ? "6rem" : "10rem",
    render: (_, rowData) => (
      <RowAction
        rowActions={rowActions}
        onAction={onAction}
        rowData={rowData}
        inActiveRowActions={inActiveRowActions}
        activeFilter={activeFilter}
      />
    ),
  };
};

const getUpdatedColumns = (column) => {
  const { title } = column;
  const updatedHeader = <div className={styles.tableHeader}>{title}</div>;
  return { ...column, title: updatedHeader };
};

const getStatusColumn = (isActive) => {
  if (isActive !== 2) return null;

  const statusColumn = {
    title: "Status",
    key: "action",
    fixed: !isMobile && "right",
    width: "10rem",
    render: (_, rowData) => <InactiveAndActiveStatus rowData={rowData} />,
  };

  return getUpdatedColumns(statusColumn);
};

export const getColumns = ({
  columns,
  rowActions,
  onAction,
  inActiveRowActions,
  activeFilter,
}) => {
  const updatedColumns = _map(columns, getUpdatedColumns);
  const rowActionColumn = getRowActionColumn(
    rowActions,
    onAction,
    inActiveRowActions,
    activeFilter
  );
  const statusColumn = getStatusColumn(activeFilter);
  const newColumns = [...updatedColumns, statusColumn, rowActionColumn];
  return _compact(newColumns);
};
