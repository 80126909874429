import _property from "lodash/property";
import { FIELD_IDS } from "../constants/structureComponentForm.fieldId";

const structureComponent = _property(FIELD_IDS.STRUCTURE_TYPE_COMPONENT);

const READERS = {
  structureComponent,
};

export default READERS;
