import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_MICROZONATION: "/microzonation-data",
  CREATE_MICROZONATION: "/microzonation-add",
  EDIT_MICROZONATION: "/microzonation-update",
  DELETE_MICROZONATION: "/is-active",
};

const fetchMicroZonation = (payload) =>
  HttpClient.post(API_PATHS.FETCH_MICROZONATION, payload);

const createMicroZonation = (payload) =>
  HttpClient.post(API_PATHS.CREATE_MICROZONATION, payload);

const editMicroZonation = (payload) =>
  HttpClient.post(API_PATHS.EDIT_MICROZONATION, payload);

const deleteMicroZonation = (payload) =>
  HttpClient.post(API_PATHS.DELETE_MICROZONATION, payload);

const MicroZonationAPI = {
  fetchMicroZonation,
  createMicroZonation,
  editMicroZonation,
  deleteMicroZonation,
};

export default MicroZonationAPI;
