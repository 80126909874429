import ImageProcessingAPI from "../../../../../../cortexSurvey-business/appServices/imageProcessing/services/imageProcessing";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import { ACTION_TYPES } from "../constants/ImageProcessingCreateForm.actionTypes";
import { getPayloadFronFormValues } from "./imageProcessingCreateForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose, onSubmitCb } = getState();
  const { formValues } = params;

  const { error } = formValues;

  setState({ error });

  if (!error) {
    setState({ isSubmitting: true });

    try {
      const payload = getPayloadFronFormValues(formValues);

      await ImageProcessingAPI.createImageProcessing(payload);
      onSubmitCb();
      toaster(
        TOASTER_TYPE.SUCCESS,
        "Reference Image Setting Created Successfully"
      );
      onClose();
    } catch (err) {
      errorHandler(err,setState, "Failed to Create Reference Image Setting.")
    } finally {
      setState({ isSubmitting: false });
     
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
