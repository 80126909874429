import React, { memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { SECTIONS } from "./constants/structureTypeForm.sections";
import { getFormConfig } from "./helpers/structureType.formConfig";

const StructureTypeForm = (props) => {
  const { formId, onSubmit = _noop, initialValues = {}, view = false } = props;

  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      formId={formId}
      onSubmit={onSubmit}
      initialValues={initialValues}
      view={view}
    />
  );
};

StructureTypeForm.propTypes = {
  formId: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  view: PropTypes.bool,
};

export default memo(StructureTypeForm);
