import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_COUNTRY: "/country-data",
  CREATE_COUNTRY: "/country-add",
  EDIT_COUNTRY: "/country-update",
  DELETE_COUNTRY: "/is-active",
};

const fetchCountry = (payload) =>
  HttpClient.post(API_PATHS.FETCH_COUNTRY, payload);

const createCountry = (payload) =>
  HttpClient.post(API_PATHS.CREATE_COUNTRY, payload);

const editCountry = (payload) =>
  HttpClient.post(API_PATHS.EDIT_COUNTRY, payload);

const deleteCountry = (payload) =>
  HttpClient.post(API_PATHS.DELETE_COUNTRY, payload);

const CountryAPI = {
  fetchCountry,
  createCountry,
  editCountry,
  deleteCountry,
};

export default CountryAPI;
