import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./label.module.scss";

const Label = (props) => {
  const { label, required=false, children, className } = props;

  return <div className={cx({ [styles.requireLabel]: required }, className)}>{label || children}</div>;
};

Label.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};


export default Label;
