import _some from "lodash/some";
import _isEmpty from "lodash/isEmpty";

import ReferenceImageReaders from "../../../../../../cortexSurvey-business/appServices/imageProcessing/readers/referenceImage";

const isRefernceInputEmpty = (inputValues) => {
  const image = ReferenceImageReaders.image(inputValues);
  const description = ReferenceImageReaders.description(inputValues);

  return _isEmpty(image) || _isEmpty(description);
};

export const validateReferenceTableInput = (value) => {
  const isEmptyRefernceTableInput = _some(value, isRefernceInputEmpty);
  return {
    value: !isEmptyRefernceTableInput,
    message: "Please Fill All the Refernce Table Fields",
  };
};
