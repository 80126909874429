import _noop from "lodash/noop";

import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import AssessmentGuidelineAPI from "../../../../../../cortexSurvey-business/appServices/assessmentGuideline/services/assessmentGuideline";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/assessmentGuidelineEditForm.actionTypes";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/assesmentGuidelineEditForm.general";
import { getPayloadFromFormValues } from "./assessmentGuidelineEditForm.payload";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const {
    assessmentGuidelineId,
    onClose = _noop,
    onSubmitCb = _noop,
  } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(
        formValues,
        assessmentGuidelineId
      );
      await AssessmentGuidelineAPI.editAssessmentGuideline(payload);
      onSubmitCb();
      toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
      onClose();
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
    } finally {
      setState({ isSubmitting: false });
     
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
