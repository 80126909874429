import { useCallback, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

import { getErrorMessage } from "../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../cortexSurvey-components/notificationWrapper";
import useFetchPayload from "../../../../hooks/useFetchPayload";
import SurveyObjectiveAPI from "../../services/surveyObjective";

const useFetchSurveyObjective = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchSurveyObjective = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await SurveyObjectiveAPI.fetchSurveyObjective(payload);
        setData(response?.data);
      } catch (err) {
        toaster(
          TOASTER_TYPE.ERROR,
          getErrorMessage("Failed to fetch Survey Objective")
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchSurveyObjective = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchSurveyObjective, 1000);
      debounceFunction(isActive);
    },
    [fetchSurveyObjective, setLoading]
  );

  useEffect(() => {
    fetchSurveyObjective();
  }, [fetchSurveyObjective]);

  return {
    loading,
    data,
    fetchSurveyObjective: handleDebounceFetchSurveyObjective,
  };
};

export default useFetchSurveyObjective;
