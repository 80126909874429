import _property from "lodash/property";
import DATA_INDEX from "../constants/dataIndex/notification";

const url = _property(DATA_INDEX.url);
const type = _property(DATA_INDEX.type);
const noticeId = _property(DATA_INDEX.noticeId);
const title = _property(DATA_INDEX.title);

const READERS = { url, type, noticeId, title };

export default READERS;
