import HttpClient from "../../cortexSurvey-base/utils/http";

const API_PATHS = {
    FETCH_USERS: "/user-data",
};

const fetchUsers = (payload) => HttpClient.post(API_PATHS.FETCH_USERS, payload);

const UserAPI = {
  fetchUsers,
};

export default UserAPI;
