import LoginFormReader from "../components/loginForm/readers";

export const getPayloadFromFormValues = (formValues) => {
  const email = LoginFormReader.email(formValues);
  const password = LoginFormReader.password(formValues);

  return {
    email,
    password,
  };
};
