import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import Button, { TYPES } from "../../../button";

import styles from "./mainHeader.module.scss";
import Label from "../../../label";

const MainHeader = (props) => {
  const {
    onChange = _noop,
    value = [],
    addInitialState = {},
    title,
    showTableAction = true,
    required = false,
  } = props;
  
  const handleAdd = useCallback(() => {
    const updatedValue = [...(value || []), addInitialState];
    onChange(updatedValue);
  }, [value, onChange, addInitialState]);

  return (
    <div className={styles.container}>
      <Label required={required}>{title}</Label>
      {showTableAction && (
        <Button type={TYPES.PRIMARY} onClick={handleAdd}>
          Add
        </Button>
      )}
    </div>
  );
};

MainHeader.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  addInitialState: PropTypes.object,
  title: PropTypes.string,
  showTableAction: PropTypes.bool,
};

export default memo(MainHeader);
