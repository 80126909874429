import React, { memo, useCallback } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Modal from "../../../../../cortexSurvey-components/modal/Modal";

import { ACTION_TYPES } from "./constants/notificationActiveModal.actionTypes";
import {
  MODAL_TITLE,
  SAVE_TEXT,
} from "./constants/notificationActiveModal.general";

import { INITIAL_STATE } from "./constants/notificationActiveModal.initialState";
import ACTION_HANDLERS from "./helpers/notificationActiveModal.actionHandlers";

const NotificationActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    notificationTitle,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ACTIVE_SUBMISSION });
  }, [onAction]);

  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to Activate ${notificationTitle} Notification.`}
    </Modal>
  );
};

NotificationActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  assessmentGuideline: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(NotificationActiveModal);
