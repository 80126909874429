import _some from "lodash/some";

import StrucutreProblemReaders from "../../../../../../cortexSurvey-business/appServices/structureType/readers/structureProblem";

const isStructureProblemEmptyWithFields = (value) => {
  const structureProblem = StrucutreProblemReaders.structureProblem(value);
  const structureRange = StrucutreProblemReaders.structureRange(value);
  const stuctureProblemType =
    StrucutreProblemReaders.stuctureProblemType(value);

  if (!structureProblem || !structureRange || !stuctureProblemType) return true;

  return false;
};

export const strcuctureProblemValidations = (values) => {
  const isStructureProblemEmpty = _some(
    values,
    isStructureProblemEmptyWithFields
  );

  return {
    value: !isStructureProblemEmpty,
    message: "Please Fill Empty Structure Problem Fields",
  };
};
