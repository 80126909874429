import { isMobile } from "react-device-detect";
import DashboardUserDataIndex from "../../../../../../cortexSurvey-business/appServices/dashboardUser/constants/dataIndex/user";
import TextRenderer from "../../../../../../cortexSurvey-components/textRenderer/TextRenderer";
import InActiveAndActiveStatus from "../components/inactiveAndActiveStatus/InActiveAndActiveStatus";

const COLUMNS = [
  {
    title: "First Name",
    dataIndex: DashboardUserDataIndex.firstName,
    key: "firstName",
    width: "20rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Last Name",
    dataIndex: DashboardUserDataIndex.lastName,
    key: "firstName",
    width: "20rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Email",
    dataIndex: DashboardUserDataIndex.email,
    key: "email",
    width: "30rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Phone Number",
    dataIndex: DashboardUserDataIndex.phone,
    key: "phoneNumber",
    width: "15rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Role",
    dataIndex: DashboardUserDataIndex.role,
    key: "role",
    width: "20rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Status",
    dataIndex: DashboardUserDataIndex.status,
    key: "status",
    render: (_, rowData) => <InActiveAndActiveStatus rowData={rowData} />,
    width: "15rem",
    fixed: !isMobile && "right"
  },
];

export default COLUMNS;
