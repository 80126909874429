import React, { memo, useCallback } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Modal from "../../../../../cortexSurvey-components/modal/Modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import { INITIAL_STATE } from "./constants/dashboardUsersActiveModal.initialState";
import { ACTION_TYPES } from "./constants/dashboardUsersActiveModal.actionTypes";

import ACTION_HANDLERS from "./helpers/DashboardUsersActiveModal.actionHandlers";

const DashboardUsersActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    email,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ACTIVE_SUBMISSION});
  }, [onAction]);

  return (
    <Modal
      title="Activate Dashboard User"
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText="Activate"
      centered
    >
      {`Do you want to make ${email} active?`}
    </Modal>
  );
};

DashboardUsersActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  email: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(DashboardUsersActiveModal);
