import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "../../cortexSurvey-base/utils/isAuthenticated";
import { getNavigationPath } from "./helper/protectedRoute.general";

const ProtectedRoute = (props) => {
  const { children } = props;
  const location = useLocation();

  if (!isAuthenticated()) {
    const navigationPath = getNavigationPath(location);
    return <Navigate to={navigationPath} replace />;
  }
  return children;
};

export default ProtectedRoute;
