import { FIELD_IDS } from "./structureHelpForm.fieldId";

export const SECTIONS = [
  {
    label: "Structure Description & Images",
    rows: [
      { columns: [FIELD_IDS.STRUCTURE_DESCRIPTION] },
      { columns: [FIELD_IDS.STRUCTURE_IMAGES] },
    ],
  },
  {
    label: "Structure Video",
    rows: [{ columns: [FIELD_IDS.STRUCTURE_VIDEO] }],
  },
];
