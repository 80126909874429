import { FIELD_IDS } from "./loginForm.fieldIds";

export const SECTIONS = [
  {
    rows: [
      { columns: [FIELD_IDS.LOGIN_EMAIL] },
      { columns: [FIELD_IDS.LOGIN_PASSWORD] },
    ],
  },
];
