import _get from "lodash/get";

import { FIELD_IDS } from "../constants/countryForm.fieldId";

const country = (state) => _get(state, FIELD_IDS.COUNTRY);

const READERS = {
  country,
};

export default READERS;
