import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";

import SurveyObjectivesReaders from "../../../../../cortexSurvey-business/appServices/surveyObjective/constants/dataIndex/surveyObjective";
import Table from "../../../../../cortexSurvey-components/table";

import COLUMNS from "./constants/surveyObjectiveOverview.columns";
import {
  INACTIVE_ROW_ACTIONS,
  ROW_ACTIONS,
} from "./constants/surveyObjectiveOverview.rowActions";
import { SEARCH_KEYS } from "./constants/surveyObjectiveOverview.table";

const SurveyObjectiveOverview = (props) => {
  const {
    loading = false,
    data = [],
    onAction = _noop,
    fetchData = _noop,
  } = props;
  return (
    <Table
      dataSource={data}
      columns={COLUMNS}
      rowActions={ROW_ACTIONS}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      rowKey={SurveyObjectivesReaders.surveyObjectiveId}
      fetchData={fetchData}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

SurveyObjectiveOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
};

export default memo(SurveyObjectiveOverview);
