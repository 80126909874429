import { FIELD_IDS } from "./structureTypeForm.fieldId";
import ImageUpload from "../../../../../../cortexSurvey-components/imageUpload";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/InputFieldRenderer";


export const STRUCTURE_TYPE = {
  id: FIELD_IDS.STRUCTURE_TYPE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Structure Type",
    Placeholder: "Structure Type",
    required: true,
  },
};

export const STRUCTURE_TYPE_IMAGE = {
  id: FIELD_IDS.STRUCTURE_TYPE_IMAGE,
  renderer: ImageUpload,
  renderOptions: {
    label: "Structure Type Image",
  },
};

