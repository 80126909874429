import AudioUpload from "../../../../../../cortexSurvey-components/audioUpload/AudioUpload";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/InputFieldRenderer";
import InputNumberFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputNumberFieldRenderer";
import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/SelectFieldRenderer";
import ReferenceImageTable from "../../referenceImageTable";
import { validateReferenceTableInput } from "../helpers/imageProcessingForm.validations";

import { FIELD_IDS } from "./imageProcessingForm.fieldId";
import {
  CAMERA_TYPE_OPTIONS,
  WEATHER_CONDITION_OPTIONS,
} from "./ImageProcessingForm.options";

export const ITEM_CODE = {
  id: FIELD_IDS.ITEM_CODE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Item Code",
    required: true,
  },
};

export const ITEM_SEARCH_CODE = {
  id: FIELD_IDS.ITEM_SEARCH_CODE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Item Search Code",
    required: true,
  },
};

export const DISTRESS_PATTERN = {
  id: FIELD_IDS.DISTRESS_PATTERN,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Distress Pattern",
    required: true,
  },
};

export const SCORE_REFERENCE = {
  id: FIELD_IDS.SCORE_REFERENCE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Score Reference",
    required: true,
  },
};

export const NUMERICAL_SCORE = {
  id: FIELD_IDS.NUMERICAL_SCORE,
  renderer: InputNumberFieldRenderer,
  renderOptions: {
    label: "Numerical Score",
    required: true,
    defaultValue: 0,
  },
};

export const CAPTURED_WITH_CAMERA_TYPE = {
  id: FIELD_IDS.CAPTURED_WITH_CAMERA_TYPE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    options: CAMERA_TYPE_OPTIONS,
    label: "Captured With Camera Type",
    required: true,
  },
};

export const LIGHT_AND_WEATHER_CONDITION = {
  id: FIELD_IDS.LIGHT_AND_WEATHER_CONDITION,
  renderer: SelectFieldRenderer,
  renderOptions: {
    options: WEATHER_CONDITION_OPTIONS,
    label: "Light & Weather Condition",
    required: true,
  },
};

export const CAPTURING_DISTANCE = {
  id: FIELD_IDS.CAPTURING_DISTANCE,
  renderer: InputNumberFieldRenderer,
  renderOptions: {
    label: "Capturing Distance (in meters)",
    required: true,
    precision: 2,
    defaultValue: 0,
  },
};

export const VULNERABILITY_SCALE = {
  id: FIELD_IDS.VULNERABILITY_SCALE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Vulnerability Scale",
    required: true,
  },
};

export const REFERENCE_IMAGE = {
  id: FIELD_IDS.REFERENCE_IMAGE,
  renderer: ReferenceImageTable,
  renderOptions: {
    title: "Reference Images",
    showAction: true,
    validations: validateReferenceTableInput,
  },
};

export const AUDIO_CLIP = {
  id: FIELD_IDS.AUDIO_CLIP,
  renderer: AudioUpload,
  renderOptions: {
    title: "Reference Audio",
  },
};
