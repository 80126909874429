import { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";
import useDashboardUserPayload from "./useDashboardUserPayload";
import DashboardUserAPI from "../services/dashboardUsers";

const useFetchDashboardUsers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { getFetchPayload } = useDashboardUserPayload();

  const fetchDashboardUsers = useCallback(async (active) => {
    setLoading(true);
    try {
      const payload = getFetchPayload(active)
      const response = await DashboardUserAPI.fetchDashboardUser(payload);
      setData(response?.data);
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, "Failed to fetch Dashboard User");
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData,getFetchPayload]);

  const handleDebounceFetchDashboardUser = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchDashboardUsers, 1000);
      debounceFunction(isActive);
    },
    [fetchDashboardUsers, setLoading]
  );

  useEffect(() => {
    fetchDashboardUsers();
  }, [fetchDashboardUsers]);

  return { loading, data, fetchDashboardUsers:handleDebounceFetchDashboardUser };
};

export default useFetchDashboardUsers;
