import { FORM_CONFIG } from "../constants/questionnaireForm.formConfig";

import { addRenderOptions } from "../../../../../../cortexSurvey-components/formWithSubmission/utils/addRenderOptions";

import {
  ANSWER,
  ANSWER_PATTERN,
  ASSESSMENT_GUIDELINE,
  QUESTION_SEGMENT,
  SERIAL_NO,
  STRUCTURE_TYPE,
  SURVEY_OBJECTIVE,
  SURVEYOR_RANK,
} from "../constants/questionnaireForm.fields";

import { FIELD_IDS } from "../constants/questionnaireForm.fieldId";
import QuestionFormReaders from "../readers";

import {
  getAnswerPatternOptions,
  getQuestionSegmentOptions,
} from "./getQuestionnaireFormFieldOptions";

export const getFormConfig = (additional, isEdit) => (formState) => {
  const {
    surveyObjectiveOptions,
    assessmentGuidelineOptions,
    structureTypeOptions,
    surveyorRankOptions,
  } = additional;

  const answerPattern = QuestionFormReaders.answerPattern(formState);

  return {
    ...FORM_CONFIG,
    [FIELD_IDS.SERIAL_NUMBER]: addRenderOptions(SERIAL_NO, {
      disabled: isEdit,
    }),
    [FIELD_IDS.ASSESSMENT_GUIDELINE]: addRenderOptions(ASSESSMENT_GUIDELINE, {
      options: assessmentGuidelineOptions,
    }),
    [FIELD_IDS.STRUCTURE_TYPE]: addRenderOptions(STRUCTURE_TYPE, {
      options: structureTypeOptions,
    }),
    [FIELD_IDS.SURVEYOR_RANK]: addRenderOptions(SURVEYOR_RANK, {
      options: surveyorRankOptions,
    }),
    [FIELD_IDS.SURVEY_OBJECTIVE]: addRenderOptions(SURVEY_OBJECTIVE, {
      options: surveyObjectiveOptions,
    }),
    [FIELD_IDS.ANSWER_PATTERN]: addRenderOptions(ANSWER_PATTERN, {
      options: getAnswerPatternOptions(),
    }),
    [FIELD_IDS.QUESTION_SEGMENT]: addRenderOptions(QUESTION_SEGMENT, {
      options: getQuestionSegmentOptions(),
    }),
    [FIELD_IDS.ANSWER]: addRenderOptions(ANSWER, {
      answerPattern,
    }),
  };
};
