import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import { SECTIONS } from "./constants/cityIndexMappingForm.section";
import { getFormConfig } from "./helpers/cityIndexMappingForm.formConfig";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { getFieldValueMapper } from "./helpers/cityIndexMappingForm.fieldValueMapper";

const CityIndexMappingForm = (props) => {
  const {
    initialValues,
    onSubmit,
    formId,
    getCountryStateAndCityOptions,
    getAssessmentGuidelineSurveyObjectiveMicroZonationOptions,
    cityData,
  } = props;

  const formConfig = useCallback(
    (formValues) =>
      getFormConfig(
        getAssessmentGuidelineSurveyObjectiveMicroZonationOptions,
        getCountryStateAndCityOptions,
        formValues
      ),
    [
      getCountryStateAndCityOptions,
      getAssessmentGuidelineSurveyObjectiveMicroZonationOptions,
    ]
  );

  const fieldValueMapper = useMemo(
    () => getFieldValueMapper(cityData),
    [cityData]
  );

  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      fieldValueMapper={fieldValueMapper}
      onSubmit={onSubmit}
      formId={formId}
    />
  );
};

CityIndexMappingForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
};

CityIndexMappingForm.defaultProps = {
  initialValues: {},
  onSubmit: _noop,
  formId: undefined,
};

export default CityIndexMappingForm;
