import { FIELD_IDS } from "./imageProcessingForm.fieldId";

import styles from "../imageProcessingForm.module.scss";

const DEFAULT_SECTION = {
  label: "Item Information",
  className: styles.defaultSection,
  rows: [
    {
      columns: [FIELD_IDS.ITEM_CODE, FIELD_IDS.ITEM_SEARCH_CODE],
      className: styles.defaultSectionContainerRow,
    },
    {
      columns: [FIELD_IDS.DISTRESS_PATTERN, FIELD_IDS.VULNERABILITY_SCALE],
      className: styles.defaultSectionContainerRow,
    },
    {
      columns: [FIELD_IDS.SCORE_REFERENCE, FIELD_IDS.NUMERICAL_SCORE],
      className: styles.defaultSectionContainerRow,
    },
    {
      columns: [
        FIELD_IDS.CAPTURED_WITH_CAMERA_TYPE,
        FIELD_IDS.LIGHT_AND_WEATHER_CONDITION,
      ],
      className: styles.defaultSectionContainerRow,
    },
    { columns: [FIELD_IDS.CAPTURING_DISTANCE] },
  ],
};

const AUDIO_AND_REFERENCE_IMAGE_SECTION = {
  label: "Reference Audio & Images",
  rows: [
    { columns: [FIELD_IDS.AUDIO_CLIP], className: styles.audio },
    { columns: [FIELD_IDS.REFERENCE_IMAGE] },
  ],
};

export const SECTIONS = [DEFAULT_SECTION, AUDIO_AND_REFERENCE_IMAGE_SECTION];
