import React from "react";
import PropTypes from "prop-types";
import _clone from "lodash/clone";
import _pullAt from "lodash/pullAt";
import _set from "lodash/set";
import _map from "lodash/map";

import Button from "../../../../../../../cortexSurvey-components/button";
import MicroZonationInputField from "../microZonationInputField";

import styles from "../../microZonationInput.module.scss";

const MicroZonationMultiInput = (props) => {
  const {
    surveyObjectiveId,
    surveyObjective,
    microZonationValue = [],
    onChange,
  } = props;

  const onAdd = () => {
    const addValue = { microzonationId: undefined, level: undefined };
    const updateValue = [...microZonationValue, addValue];
    onChange(surveyObjectiveId, updateValue);
  };

  const onDelete = (index) => {
    const updatedMicroZonationValue = _clone(microZonationValue);
    _pullAt(updatedMicroZonationValue, index);
    onChange(surveyObjectiveId, updatedMicroZonationValue);
  };

  const onValueChange = (index, value, id) => {
    const updatedMicroZonationValue = _clone(microZonationValue);
    const updatedValue = { microzonationId: id, level: value };
    _set(updatedMicroZonationValue, index, updatedValue);
    onChange(surveyObjectiveId, updatedMicroZonationValue);
  };

  const renderMultiInputField = (multiInputFieldValue, index) => {
    const { microzonationId, level } = multiInputFieldValue;
    return (
      <MicroZonationInputField
        id={microzonationId}
        value={level}
        onChange={onValueChange}
        onDelete={onDelete}
        index={index}
      />
    );
  };

  return (
    <div>
      <div className={styles.container}>
        <div>{surveyObjective}</div>
        <Button onClick={onAdd}>Add</Button>
      </div>
      <div>{_map(microZonationValue, renderMultiInputField)}</div>
    </div>
  );
};

MicroZonationMultiInput.propTypes = {
  microZonationValue: PropTypes.array,
};

export default MicroZonationMultiInput;
