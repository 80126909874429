import React from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Select from "../../select";

const SelectFieldRenderer = (props) => {
  const { onChange, ...restProps } = props;

  const handleChange = (values) => {
    onChange(values);
  };

  return <Select {...restProps} onChange={handleChange} />;
};

SelectFieldRenderer.propTypes = {
  onChange: PropTypes.func,
};

SelectFieldRenderer.defaultProps = {
  onChange: _noop,
};

export default SelectFieldRenderer;
