import React, { memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import _size from "lodash/size";

import MainHeader from "./components/mainHeader";
import TableInput from "./components/tableInput";

const TableInputWithAdd = (props) => {
  const {
    config = [],
    onChange = _noop,
    value = [],
    addInitialState = {},
    title,
    showTableAction = true,
    tableClassName,
    required,
    ...restProps
  } = props;

  return (
    <div>
      <MainHeader
        title={title}
        value={value}
        onChange={onChange}
        addInitialState={addInitialState}
        showTableAction={showTableAction}
        required={required}
      />
      {!!_size(value) && (
        <TableInput
          className={tableClassName}
          config={config}
          value={value}
          onChange={onChange}
          showTableAction={showTableAction}
          {...restProps}
        />
      )}
    </div>
  );
};

TableInputWithAdd.propTypes = {
  config: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  addInitialState: PropTypes.object,
  title: PropTypes.string,
  showTableAction: PropTypes.bool,
  tableClassName: PropTypes.string,
};

export default memo(TableInputWithAdd);
