import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import CityIndexMappingAPI from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/services/cityIndexMapping";

import { ACTION_TYPES } from "../constants/cityIndexMappingActiveModal.actionTypes";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { id, onCancel, onSubmitCb = _noop } = getState();
  setState({ isSubmitting: true });
  try {
    await CityIndexMappingAPI.deleteCityIndexMapping({
      scaleMappingId: id,
      isActive: 1,
    });
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "City Index Mapping Activated Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to Activate City Index Mapping");
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
