import React from "react";
import Tag from "../tag";

import styles from "./activeColumn.module.scss";

const ActiveColumn = (props) => {
  const { data, getDisplayName } = props;
  const { isActive } = data ||{};
  const displayName = getDisplayName(data);

  if (isActive)
    return (
      <div className={styles.container}>
        <div>{displayName}</div>
        <Tag color="success">Active</Tag>
      </div>
    );

  return (
    <div className={styles.container}>
      <div>{displayName}</div>
      <Tag color="error">InActive</Tag>
    </div>
  );
};

export default ActiveColumn;
