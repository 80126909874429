import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/cityIndexMappingEditForm.actionTypes";

import CityIndexMappingAPI from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/services/cityIndexMapping";
import { getPayloadFromFormValues } from "./cityIndexMappingEditForm.payload";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { id, onClose, onSubmitCb } = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload=getPayloadFromFormValues(formValues,id);
      await CityIndexMappingAPI.editCityIndexMapping(payload);
      onSubmitCb();
      toaster(TOASTER_TYPE.SUCCESS, "City Index Updated Successfully.");
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, "Failed to Update City Index. Please try again.");
    } finally {
      setState({ isSubmitting: false });
      onClose();
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
