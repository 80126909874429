import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";

import { SECTIONS } from "./constants/assesmentGuideline.sections";
import { getFormConfig } from "./helpers/assessmentGuidelineForm.formConfig";

const AssessmentGuidelineForm = (props) => {
  const {
    initialValues = {},
    onSubmit = _noop,
    formId,
    disabled = false,
  } = props;

  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={disabled}
    />
  );
};

AssessmentGuidelineForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(AssessmentGuidelineForm);
