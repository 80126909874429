import React from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";

import CityOverview from "./components/cityOverview/CityOverview";

import { ACTION_TYPES } from "./constants/city.actionTypes";
import ACTION_HANDLERS from "./helpers/city.actionHandlers";
import { HEADER_TITLE, CREATE_BUTTON_LABEL, BANNER_CONFIG } from "./constants/city.general";


const City = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchCity = _noop,
    onReload,
    cityNotSynced
  } = props;
  return (
    <HeaderWithTable
      title={HEADER_TITLE}
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={CityOverview}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      onReload={onReload}
      fetchData={fetchCity}
      showBanner={cityNotSynced}
      bannerConfig={BANNER_CONFIG}
      showReload
      
    />
  );
};

City.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  onAction: PropTypes.func,
  fetchCity: PropTypes.func,
};

export default withActions({}, ACTION_HANDLERS)(City);
