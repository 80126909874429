import React from "react";
import _map from "lodash/map";

import Avatar from "../../../../../avatar";
import { useNavigate } from "react-router";
import Button, { TYPES } from "../../../../../button";

import styles from "./quickNavigationButton.module.scss";
import Popover from "../../../../../popover";

const QuickNavigationButton = (props) => {
  const { quickNavigation } = props;

  const navigate = useNavigate();

  const renderQuickNaviation = (value) => {
    const { label, navigateTo } = value;

    const handleNavigation = () => {
      navigate(navigateTo);
    };

    return (
      <div
        key={label}
        onClick={handleNavigation}
        type="button"
        className={styles.button}
      >
        <Popover content={label} placement="right">
          <Avatar className={styles.button} displayName={label} isBlack />
        </Popover>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      {_map(quickNavigation, renderQuickNaviation)}
    </div>
  );
};

export default QuickNavigationButton;
