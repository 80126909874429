import React, { memo } from "react";

import { arrayMoveImmutable } from "array-move";
import PropTypes from "prop-types";

import _noop from "lodash/noop";

import Image from "../../../../../image";
import Modal, { useModalVisibility } from "../../../../../modal";

import Header from "./components/header";
import SortableList from "./components/sortableList";

import styles from "./multiUploadPreview.module.scss";

const MultiUploadPreview = (props) => {
  const { data = [], onDelete = _noop, onSort = _noop, tableClassName } = props;

  const { selectedData, isModalVisible, showModal, closeModal } =
    useModalVisibility(null);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedData = arrayMoveImmutable(data, oldIndex, newIndex);
    onSort(updatedData);
  };

  return (
    <div>
      <Header />
      <SortableList
        data={data}
        onSortEnd={onSortEnd}
        onDelete={onDelete}
        onPreview={showModal}
        tableClassName={tableClassName}
      />
      <Modal
        title="Image preview"
        onCancel={closeModal}
        open={isModalVisible}
        footer={null}
        centered
      >
        <Image
          src={selectedData}
          className={styles.imagePreview}
          preview={false}
        />
      </Modal>
    </div>
  );
};

MultiUploadPreview.propTypes = {
  data: PropTypes.array,
  onDelete: PropTypes.func,
  onSort: PropTypes.func,
  tableClassName: PropTypes.string,
};

export default memo(MultiUploadPreview);
