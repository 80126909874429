import React from "react";
import { useNavigate } from "react-router";
import { LeftOutlined } from "@ant-design/icons";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import Button, { TYPES } from "../../../cortexSurvey-components/button";
import { triggerSubmit } from "../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import ForgotPasswordForm from "./components/forgotPassword/ForgotPasswordForm";
import { ACTION_TYPES } from "./constants/forgotPassword.actionTypes";
import { FORM_ID } from "./constants/forgotPassword.formId";
import { INITIAL_STATE } from "./constants/forgotPassword.initialStates";
import ACTION_HANDLERS from "./helpers/forgotPassword.actionHandler";

import styles from "./forgotPassowrd.module.scss";

const ForgotPassword = (props) => {
  const { onAction, isSubmitting } = props;
  const navigate = useNavigate();

  const handleFormSubmission = (formValue) => {
    onAction({
      type: ACTION_TYPES.FORGOT_PASSWORD_FORM_SUBMISSION,
      payload: { formValue },
    });
  };

  const handleForgotPassword = () => {
    triggerSubmit(FORM_ID);
  };

  const redirectToLogin = () => navigate("/auth/login");

  return (
    <div>
      <ForgotPasswordForm formId={FORM_ID} onSubmit={handleFormSubmission} view={isSubmitting}/>

      <Button
        type={TYPES.PRIMARY}
        onClick={handleForgotPassword}
        className={styles.button}
        loading={isSubmitting}
        disabled={isSubmitting}
      >
        Submit
      </Button>

      <Button
        onClick={redirectToLogin}
        type={TYPES.TERTIARY}
        className={styles.button}
        icon={<LeftOutlined />}
        disabled={isSubmitting}
      >
        Back to Login
      </Button>
    </div>
  );
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(ForgotPassword);
