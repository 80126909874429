import React, { memo } from "react";

import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Modal from "../../../../../cortexSurvey-components/modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import { INITIAL_STATE } from "./constants/cityDeleteModal.initialState";
import { ACTION_TYPES } from "./constants/cityDeleteModal.actionTypes";
import { MODAL_TITLE, SAVE_TEXT } from "./constants/cityDeleteModal.general";
import ACTION_HANDLERS from "./helpers/cityDeleteModal.actionHandlers";

const CityDeleteModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    city,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  };
  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to deActivate ${city} City.`}
    </Modal>
  );
};

CityDeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  city: PropTypes.string,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CityDeleteModal);
