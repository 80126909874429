import React, { useCallback, useMemo } from "react";

import { getCountryStateAndCityOptionsFormCityData } from "../../../cortexSurvey-business/appServices/city/helpers/countryStateAndCityOptions";
import useFetchCity from "../../../cortexSurvey-business/appServices/city/hooks/useFetchCity";
import useFetchCityIndexMapping from "../../../cortexSurvey-business/appServices/cityIndexMapping/hooks/useFetchCityIndexMapping";
import { getAssessmentGuidelineSurveyObjectiveMicroZonationOptionsFormMicroZonationData } from "../../../cortexSurvey-business/appServices/microZonation/helpers/assesmentGuidelineSurveyObjectiveMicroZonationOptions";
import useFetchMicroZonation from "../../../cortexSurvey-business/appServices/microZonation/hooks/useFetchMicroZonation";
import useFetchSurveyObjective from "../../../cortexSurvey-business/appServices/surveyObjective/hooks/useFetchSurveyObjective";
import CityIndexMappingReaders from "../../../cortexSurvey-business/appServices/cityIndexMapping/readers/cityIndexMapping";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import CityIndexMappingActiveModal from "./components/cityIndexMappingActiveModal";
import CityIndexMappingCreateForm from "./components/cityIndexMappingCreateForm";
import CityIndexMappingDeleteModal from "./components/cityIndexMappingDeleteModal";
import CityIndexMappingEditForm from "./components/cityIndexMappingEditForm";

import CityIndexMapping from "./CityIndexMapping";
import { getCityIndexMappingNotSynced } from "./helpers/cityIndexMapping.general";

const CityIndexMappingContainer = () => {
  const { data: surveyObjective, fetchSurveyObjective } =
    useFetchSurveyObjective();
  const { data: microzonationData, fetchMicroZonation } =
    useFetchMicroZonation();
  const { data: cityData, fetchCity } = useFetchCity();

  const { loading, data, fetchCityIndexMapping } = useFetchCityIndexMapping();

  const {
    data: cityIndexActiveData,
    fetchCityIndexMapping: fetchActiveCityIndexMapping,
  } = useFetchCityIndexMapping();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditForm,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isCityIndexMappingDeletionModalVisible,
    showModal: showCityIndexMappingDeletionModal,
    closeModal: closeCityIndexMappingDeletionModal,
  } = useModalVisibility();

  const {
    selectedData: selectedActiveData,
    isModalVisible: isCityIndexMappingActiveModalVisible,
    showModal: showCityIndexMappingActiveModal,
    closeModal: closeCityIndexMappingActiveModal,
  } = useModalVisibility();

  const getAssessmentGuidelineSurveyObjectiveMicroZonationOptions = useCallback(
    (assesmentGuidelineId, surveyObjectiveId) =>
      getAssessmentGuidelineSurveyObjectiveMicroZonationOptionsFormMicroZonationData(
        microzonationData,
        surveyObjective,
        assesmentGuidelineId,
        surveyObjectiveId
      ),
    [microzonationData, surveyObjective]
  );

  const getCountryStateAndCityOptions = useCallback(
    (countryId, stateId) =>
      getCountryStateAndCityOptionsFormCityData(cityData, countryId, stateId),
    [cityData]
  );

  const handleReload = useCallback(() => {
    fetchSurveyObjective();
    fetchMicroZonation();
    fetchCity();
    fetchCityIndexMapping();
    fetchActiveCityIndexMapping();
  }, [
    fetchSurveyObjective,
    fetchMicroZonation,
    fetchCity,
    fetchCityIndexMapping,
    fetchActiveCityIndexMapping,
  ]);

  const { bannerConfig, isNotSynced } = useMemo(
    () =>
      getCityIndexMappingNotSynced(
        microzonationData,
        surveyObjective,
        cityData,
        cityIndexActiveData
      ),
    [microzonationData, surveyObjective, cityData, cityIndexActiveData]
  );

  return (
    <div>
      <CityIndexMapping
        showCreateForm={showCreateForm}
        data={data}
        showEditForm={showEditForm}
        showDeleteModal={showCityIndexMappingDeletionModal}
        showActiveModal={showCityIndexMappingActiveModal}
        loading={loading}
        onReload={handleReload}
        fetchData={fetchCityIndexMapping}
        isCityIndexMappingNotSync={isNotSynced}
        bannerConfig={bannerConfig}
      />

      <CityIndexMappingCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        getCountryStateAndCityOptions={getCountryStateAndCityOptions}
        getAssessmentGuidelineSurveyObjectiveMicroZonationOptions={
          getAssessmentGuidelineSurveyObjectiveMicroZonationOptions
        }
        cityData={cityData}
        onSubmitCb={fetchCityIndexMapping}
      />

      <CityIndexMappingEditForm
        formData={selectedEditForm}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={handleReload}
        getCountryStateAndCityOptions={getCountryStateAndCityOptions}
        getAssessmentGuidelineSurveyObjectiveMicroZonationOptions={
          getAssessmentGuidelineSurveyObjectiveMicroZonationOptions
        }
        id={CityIndexMappingReaders.id(selectedEditForm)}
        cityData={cityData}
      />

      <CityIndexMappingDeleteModal
        isVisible={isCityIndexMappingDeletionModalVisible}
        onCancel={closeCityIndexMappingDeletionModal}
        onSubmitCb={handleReload}
        id={CityIndexMappingReaders.id(selectedDeleteData)}
      />

      <CityIndexMappingActiveModal
        isVisible={isCityIndexMappingActiveModalVisible}
        onCancel={closeCityIndexMappingActiveModal}
        onSubmitCb={handleReload}
        id={CityIndexMappingReaders.id(selectedActiveData)}
      />
    </div>
  );
};

export default CityIndexMappingContainer;
