import SurveyObjectiveFormReaders from "../../surveyObjectiveForm/readers";

export const getPayloadFromFormValues = (formValues, surveyObjectiveId) => {
  const surveyObjective =
    SurveyObjectiveFormReaders.surveyObjective(formValues);
  return {
    surveyObjectiveId,
    surveyObjective,
  };
};
