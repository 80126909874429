import React, { memo } from "react";

import PropTypes from "prop-types";

import { renderMicrZonationValues } from "./helpers/surveyObjectiveColumn.components";

const SurveyObjectiveColumn = (props) => {
  const { rowData = {}, surveyObjectiveId } = props;

  return <div>{renderMicrZonationValues(rowData, surveyObjectiveId)}</div>;
};

SurveyObjectiveColumn.propTypes = {
  rowData: PropTypes.object,
  id: PropTypes.string,
};

export default memo(SurveyObjectiveColumn);
