import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/InputFieldRenderer";
import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/SelectFieldRenderer";
import isEmail from "../../../../../../cortexSurvey-components/formWithSubmissionV2/validators/isEmail";
import { ROLE_OPTIONS } from "./options";
import { FIELD_IDS } from "./dashboardUsersForm.fieldIds";

export const FIRST_NAME = {
  id: FIELD_IDS.FIRST_NAME,
  renderer: InputFieldRenderer,
  renderOptions: {
    placeholder: "First Name",
    label: "First Name",
    required: true,
  },
};

export const LAST_NAME = {
  id: FIELD_IDS.LAST_NAME,
  renderer: InputFieldRenderer,
  renderOptions: {
    placeholder: "Last Name",
    label: "Last Name",
    required: true,
  },
};

export const EMAIL = {
  id: FIELD_IDS.EMAIL,
  renderer: InputFieldRenderer,
  renderOptions: {
    placeholder: "Email",
    label: "Email",
    required: true,
    validations: isEmail,
  },
};

export const ROLE = {
  id: FIELD_IDS.ROLE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    options: ROLE_OPTIONS,
    label: "Role",
    required: true,
    placeholder: "Role",
  },
};
