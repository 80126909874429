import React, { memo } from "react";
import _noop from "lodash/noop";
import propTypes from "prop-types";

import Table from "../../../../../cortexSurvey-components/table";
import SurveyorRankReaders from "../../../../../cortexSurvey-business/appServices/surveyorRank/readers/surveyorRank";

import COLUMNS from "./constants/surveyorRankOverview.columns";
import {
  ROW_ACTIONS,
  INACTIVE_ROW_ACTIONS,
} from "./constants/surveyorRankOverview.rowActions";
import { SEARCH_KEYS } from "./constants/surveyorRankOverView.table";

const SurveyorRankOverview = (props) => {
  const {
    loading = false,
    data = [],
    onAction = _noop,
    fetchData = _noop,
  } = props;

  return (
    <Table
      dataSource={data}
      columns={COLUMNS}
      rowActions={ROW_ACTIONS}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      rowKey={SurveyorRankReaders.surveyorRankId}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

SurveyorRankOverview.propTypes = {
  onAction: propTypes.func,
  data: propTypes.array,
  loading: propTypes.bool,
};

export default memo(SurveyorRankOverview);
