import _noop from "lodash/noop";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import CityAPI from "../../../../../../cortexSurvey-business/appServices/city/services/city";
import { ACTION_TYPES } from "../constants/cityActiveModal.actionTypes";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../constants/cityActiveModal.general";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { cityId, onCancel=_noop, onSubmitCb = _noop } = getState();
  setState({ isSubmitting: true });
  try {
    const payload={ cityId, isActive: 1 }

    await CityAPI.deleteCity(payload);

    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ACTIVE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
