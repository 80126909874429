import CityFormReaders from "../../cityForm/readers";

export const getPayloadFromFormValues = (formValues,id) => {
  const countryId = CityFormReaders.countryId(formValues);
  const stateId = CityFormReaders.stateId(formValues);
  const city = CityFormReaders.city(formValues);
  const soil = CityFormReaders.soil(formValues);

  return {
    cityId:id,
    countryId,
    stateId,
    city,
    soil,
  };
};
