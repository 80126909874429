const cityName = "cityName";
const cityId = "cityId";
const countryId = "countryId";
const stateId = "stateId";
const soil = "soil";
const countryName = "countryName";
const stateName = "stateName";
const country = "country";
const state = "state";

const DATA_INDEX = {
  cityName,
  cityId,
  countryId,
  stateId,
  soil,
  countryName,
  stateName,
  country,
  state,
};

export default DATA_INDEX;
