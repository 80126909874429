import { FIELD_IDS } from "./cityForm.fieldId";

export const FIELD_VALUE_MAPPER = {
  [FIELD_IDS.COUNTRY]: {
    [FIELD_IDS.STATE]: undefined,
    [FIELD_IDS.CITY]: undefined,
    [FIELD_IDS.SOIL]: undefined,
  },
  [FIELD_IDS.STATE]: {
    [FIELD_IDS.CITY]: undefined,
    [FIELD_IDS.SOIL]: undefined,
  },
  [FIELD_IDS.CITY]: {
    [FIELD_IDS.SOIL]: undefined,
  },
};
