import _property from "lodash/property";
import QuestionnaireDataIndex from "../constants/dataIndex/questionnaire";

const questionId= _property(QuestionnaireDataIndex.questionId);
const help = _property(QuestionnaireDataIndex.help);
const surveyObjectives = _property(QuestionnaireDataIndex.surveyObjectives);
const structureTypes = _property(QuestionnaireDataIndex.structureTypes);
const surveyOrRanks = _property(QuestionnaireDataIndex.surveyOrRanks);
const assessmentGuidelines = _property(
  QuestionnaireDataIndex.assessmentGuidelines
);
const question = _property(QuestionnaireDataIndex.question);
const questionSegment = _property(QuestionnaireDataIndex.questionSegmentId);
const answers = _property(QuestionnaireDataIndex.answers);
const answerPattern = _property(QuestionnaireDataIndex.answerPattern);
const score = _property(QuestionnaireDataIndex.score);
const serialNumber = _property(QuestionnaireDataIndex.serialNumber);
const mandatory = _property(QuestionnaireDataIndex.mandatory);

const READERS = {
  questionId,
  help,
  surveyObjectives,
  structureTypes,
  surveyOrRanks,
  assessmentGuidelines,
  question,
  questionSegment,
  answers,
  answerPattern,
  score,
  serialNumber,
  mandatory,
};

export default READERS;
