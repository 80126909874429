import StructureTypeFormReaders from "../../structureTypeForm/readers";

export const getPayloadFromFormValues = (formValues, id) => {
  const structureType = StructureTypeFormReaders.structureType(formValues);
  const structureTypeImage =
    StructureTypeFormReaders.structureTypeImage(formValues);
  return {
    structure: structureType,
    structureId: id,
    image:structureTypeImage,
  };
};
