export const ANSWER_PATTER_OPTIONS = [
  {
    label: "Radio Button",
    value: "radiobutton",
  },
  {
    label: "Check Box",
    value: "Check Box",
  },
  {
    label: "Dropdown",
    value: "dropdown",
  },
  {
    label: "textbox",
    value: "textbox",
  },
];

export const QUESTION_SEGMENT_OPTIONS = [
  {
    label: "General Information",
    value: 1,
  },
  {
    label: "Technical Information",
    value: 2,
  },
  {
    label: "Disaster Question",
    value: 3,
  },
];
