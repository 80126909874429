import axios from "axios";
import _get from "lodash/get";
import { createAuthToken } from "./authToken";

// Function to get API URL based on environment
const getApiUrl = () => {
  const environment = process.env.NODE_ENV || "development";
  const apiUrl =
    environment === "production"
      ? process.env.REACT_APP_production_api_url
      : process.env.REACT_APP_development_api_url;
  return apiUrl || "";
};

class PreHttp {
  static apiUrl = getApiUrl();

  static async makeRequest(
    headers,
    method,
    url,
    data,
    queryParams = {},
    params = {}
  ) {
    try {
      const response = await axios({
        method,
        url: `${PreHttp.apiUrl}${url}`,
        data,
        params: queryParams,
        headers,
        ...params,
      });

      const responsedata = _get(response, "data");
      return responsedata;
    } catch (error) {
      const data = _get(error, "response.data", {});
      const statusCode = _get(error, "status");
      return { ...data, statusCode };
    }
  }

  static getHeaders = (data) => {

    const authToken=createAuthToken(data);

    return {
      Authorization: authToken,
      "Content-Type": "application/json",
    };
  };

  // static get = async (url, queryParams = {}, params = {}) => {
  //   const headers = await this.getHeaders();
  //   return await this.makeRequest(
  //     headers,
  //     "get",
  //     url,
  //     null,
  //     queryParams,
  //     params
  //   );
  // };

  static post = async (url, data, queryParams = {}, params = {}) => {
    const headers = this.getHeaders(data);
    return await this.makeRequest(
      headers,
      "post",
      url,
      data,
      queryParams,
      params
    );
  };

  // static put = async (url, data, queryParams = {}, params = {}) => {
  //   const headers = await this.getHeaders();
  //   return await this.makeRequest(
  //     headers,
  //     "put",
  //     url,
  //     data,
  //     queryParams,
  //     params
  //   );
  // };

  // static delete = async (url, queryParams = {}, params = {}) => {
  //   const headers = await this.getHeaders();
  //   return await this.makeRequest(
  //     headers,
  //     "delete",
  //     url,
  //     null,
  //     queryParams,
  //     params
  //   );
  // };
}

export default PreHttp;
