import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";
import { Input as InputField } from "antd";

import Label from "../label";
import styles from "./input.module.scss";

const Input = (props) => {
  const { className, label, required=false, invalid=false, ...restProps } = props;
  return (
    <div className={styles.inputContainer}>
      {label && <Label label={label} required={required} />}
      <InputField
        {...restProps}
        className={cx(styles.input, className, { [styles.invalid]: invalid })}
        required={required}
      />
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default Input;
