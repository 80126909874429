import ReferenceImageDataIndex from "../../../../../../../../cortexSurvey-business/appServices/imageProcessing/constants/dataIndex/referenceImage";
import Image from "../../../../../../../../cortexSurvey-components/image/Image";

export const COLUMN_CONFIG = [
  {
    title: "Image",
    dataIndex: ReferenceImageDataIndex.image,
    key: ReferenceImageDataIndex.image,
    width: 60,
    render: (value) => <Image value={value} width="5rem"/>,
  },
  {
    title: "Description",
    dataIndex: ReferenceImageDataIndex.description,
    key: ReferenceImageDataIndex.description,
    width: 300,
    ellipsis: true,
  },
];
