import axios from "axios";
import _get from "lodash/get";

// Function to get API URL based on environment
const getApiUrl = () => {
  const environment = process.env.NODE_ENV || "development";
  const apiUrl =
    environment === "production"
      ? process.env.REACT_APP_production_api_url
      : process.env.REACT_APP_development_api_url;
  return apiUrl || "";
};

// Function to get headers
const getHeaders = () => {
  const authToken = localStorage.getItem("AUTH_TOKEN") || "";
  return {
    Authorization: `Bearer ${authToken}`,
    "Content-Type": "application/json",
  };
};

class Http {
  static apiUrl = getApiUrl();

  static async makeRequest(method, url, data, queryParams = {}, params = {}) {
    try {
      const response = await axios({
        method,
        url: `${Http.apiUrl}${url}`,
        data,
        params: queryParams,
        headers: getHeaders(),
        ...params,
      });

      const responseData = _get(response, "data");
      return responseData;
    } catch (error) {
      throw error;
    }
  }

  static async get(url, queryParams = {}, params = {}) {
    return await this.makeRequest("get", url, null, queryParams, params);
  }

  static async post(url, data, queryParams = {}, params = {}) {
    return await this.makeRequest("post", url, data, queryParams, params);
  }

  static async put(url, data, queryParams = {}, params = {}) {
    return await this.makeRequest("put", url, data, queryParams, params);
  }

  static async delete(url, queryParams = {}, params = {}) {
    return await this.makeRequest("delete", url, null, queryParams, params);
  }
}

export default Http;
