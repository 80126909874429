import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Table from "../../../../../cortexSurvey-components/table";
import { FILTER_OPTIONS } from "./constants/microzonationOverview.filterOptions";
import { getColumns } from "./helpers/microZonation.columns";
import { SEARCH_KEYS } from "./constants/microzonationOverview.table";

import { getRowActions } from "./helpers/microzonation.rowActions";

const SurveyObjectiveOverview = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading,
    surveyObjective,
    fetchData = _noop,
  } = props;

  const columns = useMemo(() => getColumns(surveyObjective), [surveyObjective]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowActions={getRowActions}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      filterOptions={FILTER_OPTIONS}
      showFilter
    />
  );
};

SurveyObjectiveOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  surveyObjective: PropTypes.func,
  fetchData: PropTypes.func,
};

export default memo(SurveyObjectiveOverview);
