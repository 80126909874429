import React, { useCallback, memo, useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import cx from "classnames";

import Button from "../../../../../../../button";

import { renderTableComponent } from "./helpers/tableRow.component";
import {
  getUpdatedTableDataOnChange,
  getUpdatedTableDataOnDelete,
} from "./helpers/tableRow.general";

import styles from "./tableRow.module.scss";
import { isMobile } from "react-device-detect";

const TableRow = (props) => {
  const {
    rowData,
    index,
    tableData,
    onChange,
    config,
    showTableAction,
    ...restProps
  } = props;

  const handleRowDeletion = useCallback(() => {
    const updatedTableData = getUpdatedTableDataOnDelete(tableData, index);
    onChange(updatedTableData);
  }, [index, tableData, onChange]);

  const handleValueChange = useCallback(
    (value, dataIndex) => {
      const updatedTableData = getUpdatedTableDataOnChange({
        index,
        rowData,
        tableData,
        value,
        dataIndex,
      });
      onChange(updatedTableData);
    },
    [index, rowData, tableData, onChange]
  );

  const tableComponent = useMemo(
    () => renderTableComponent(config, handleValueChange, rowData, restProps),
    [config, handleValueChange, rowData, restProps]
  );

  return (
    <div
      className={cx(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      {tableComponent}
      {showTableAction && (
        <div className={styles.actionHeader}>
          <Button onClick={handleRowDeletion}>Delete</Button>
        </div>
      )}
    </div>
  );
};

TableRow.propTypes = {
  rowData: PropTypes.object,
  index: PropTypes.number,
  tableData: PropTypes.array,
  onChange: PropTypes.func,
  config: PropTypes.array,
};

TableRow.defaultProps = {
  rowData: {},
  index: 0,
  tableData: [],
  onChange: _noop,
  config: [],
};

export default memo(TableRow);
