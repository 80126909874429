import React, { useCallback, useMemo } from "react";

import { getFilteredAssesmentGuidelineOptionsFormMicorZonationData } from "../../../cortexSurvey-business/appServices/microZonation/helpers/assesmentGuideline";
import { isMicrozonationNotSynced } from "../../../cortexSurvey-business/appServices/microZonation/helpers/microZonationSync";
import useFetchMicroZonation from "../../../cortexSurvey-business/appServices/microZonation/hooks/useFetchMicroZonation";
import useFetchMicroZonationWithRequiredData from "../../../cortexSurvey-business/appServices/microZonation/hooks/useFetchMicroZonationWithRequiredData";
import MicrozonationReaders from "../../../cortexSurvey-business/appServices/microZonation/readers/microzonation";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import MicroZonationActiveModal from "./components/microZonationActiveModal";
import MicroZonationCreateFrom from "./components/microZonationCreateForm";
import MicrozonationDeleteModal from "./components/microZonationDeleteModal";
import MicroZonationEditForm from "./components/microZonationEditForm";

import MicroZonation from "./MicroZonation";
import { createOptions } from "./helpers/microZonation.createOptions";
import { extractMicrozonationIdsFromRowData } from "./helpers/microzonation.general";

const MicroZonationContainer = () => {
  const {
    assssmentGuidelineData,
    surveyObjectiveData,
    microZonationData,
    fetchMicroZonationWithRequiredField,
  } = useFetchMicroZonationWithRequiredData();

  const { loading, data, fetchMicroZonation } = useFetchMicroZonation();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditForm,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility({});

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isSurveyObjectDeletionModalVisible,
    showModal: showSurveyObjectiveDeletionModal,
    closeModal: closeSurveyObjectiveDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isSurveyObjectActiveModalVisible,
    showModal: showSurveyObjectiveActiveModal,
    closeModal: closeSurveyObjectiveActiveModal,
  } = useModalVisibility({});

  const microZonationNotSynced = useMemo(
    () => isMicrozonationNotSynced(microZonationData, surveyObjectiveData),
    [microZonationData, surveyObjectiveData]
  );

  const handleReload = useCallback(() => {
    fetchMicroZonationWithRequiredField();
    fetchMicroZonation();
  }, [fetchMicroZonationWithRequiredField, fetchMicroZonation]);

  return (
    <div>
      <MicroZonation
        loading={loading}
        data={data}
        surveyObjective={surveyObjectiveData}
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showSurveyObjectiveDeletionModal}
        showActiveModal={showSurveyObjectiveActiveModal}
        fetchMicroZonation={fetchMicroZonation}
        onReload={handleReload}
        microZonationNotSynced={microZonationNotSynced}
      />

      <MicroZonationCreateFrom
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchMicroZonation}
        assesmentGuidelineOptions={getFilteredAssesmentGuidelineOptionsFormMicorZonationData(
          microZonationData,
          assssmentGuidelineData
        )}
        surveyObjective={surveyObjectiveData}
      />

      <MicroZonationEditForm
        formData={selectedEditForm}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={handleReload}
        assesmentGuidelineOptions={createOptions(selectedEditForm)}
        surveyObjective={surveyObjectiveData}
        microZonationData={microZonationData?.data}
      />

      <MicrozonationDeleteModal
        isVisible={isSurveyObjectDeletionModalVisible}
        onCancel={closeSurveyObjectiveDeletionModal}
        microzonationIds={extractMicrozonationIdsFromRowData(
          selectedDeleteData
        )}
        assessmentGuideline={MicrozonationReaders.assessmentGuideline(
          selectedDeleteData
        )}
        onSubmitCb={handleReload}
      />

      <MicroZonationActiveModal
        isVisible={isSurveyObjectActiveModalVisible}
        onCancel={closeSurveyObjectiveActiveModal}
        microzonationIds={extractMicrozonationIdsFromRowData(
          selectedActiveData
        )}
        assessmentGuideline={MicrozonationReaders.assessmentGuideline(
          selectedActiveData
        )}
        onSubmitCb={handleReload}
      />
    </div>
  );
};

export default MicroZonationContainer;
