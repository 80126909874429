import _noop from "lodash/noop";
import SurveyObjectiveAPI from "../../../../../../cortexSurvey-business/appServices/surveyObjective/services/surveyObjective";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/surveyObjectiveCreateForm.actionTypes";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/surveyObjectiveCreateForm.general";
import { getPayloadFromFormValues } from "./surveyObjectiveCreateForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";


const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;
  setState({ error });
  if (error) return;

  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValues);
    await SurveyObjectiveAPI.createSurveyObjective(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
    onClose();
  } catch (err) {
    errorHandler(err, setState, ERROR_MESSAGE);
  } finally {
    setState({ isSubmitting: false });
  }
};

const handleClearError = ({ setState }) => {
  setState({ error: null });
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
  [ACTION_TYPES.CLEAR_ERROR]: handleClearError,
};

export default ACTION_HANDLERS;
