import _noop from "lodash/noop";

import ImageProcessingAPI from "../../../../../../cortexSurvey-business/appServices/imageProcessing/services/imageProcessing";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/ImageProcessingEditForm.actionTypes";
import { getPayloadFronFormValues } from "./imageProcessingEditForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { onClose = _noop, onSubmitCb = _noop, imageProcessingId } = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });

    try {
      const payload = getPayloadFronFormValues(formValues, imageProcessingId);
      await ImageProcessingAPI.editImageProcessing(payload);
      onSubmitCb();
      toaster(
        TOASTER_TYPE.SUCCESS,
        "Reference Image Setting Update Successfully"
      );
      onClose();
    } catch (err) {
      errorHandler(err, setState, "Failed to update Reference Image Setting");
    } finally {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
