import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";
import { isPasswordMatched } from "../helpers/resetPasswordForm.validators";
import { FIELD_IDS } from "./resetPasswordForm.fieldIds";

export const PASSWORD = {
  id: FIELD_IDS.PASSWORD,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Password",
    type: "password",
    placeholder: "password",
    required: true,
  },
};

export const CONFIRM_PASSWORD = {
  id: FIELD_IDS.CONFIRM_PASSWORD,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Confirm Password",
    type: "password",
    placeholder: "Confirm Password",
    required: true,
    validations: isPasswordMatched,
  },
};
