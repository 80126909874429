import structureComponentTable from "../components/structureComponentTable";
import { strcutureComponentValiations } from "../helpers/structureComponentForm.validations";
import { FIELD_IDS } from "./structureComponentForm.fieldId";

export const STRUCTURE_TYPE_COMPONENT = {
  id: FIELD_IDS.STRUCTURE_TYPE_COMPONENT,
  renderer: structureComponentTable,
  renderOptions: {
    label: "Structure Component",
    required: true,
    validations: strcutureComponentValiations,
  },
};
