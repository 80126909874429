import { FIELD_IDS } from "./dashboardUsersForm.fieldIds";

export const SECTIONS = [
  {
    rows: [
      { columns: [FIELD_IDS.FIRST_NAME] },
      { columns: [FIELD_IDS.LAST_NAME] },
      { columns: [FIELD_IDS.EMAIL] },
      { columns: [FIELD_IDS.ROLE] },
    ],
  },
];
