import { FIELD_IDS } from "./cityIndexMappingForm.fieldId";
import {
  COUNTRY,
  STATE,
  CITY,
  SURVEY_OBJECTIVE,
  MICROZONATION,
  SOIL,
  ASSESSMENT_GIDELINE,
} from "./cityIndexMappingForm.field";
export const FORM_CONFIG = {
  [FIELD_IDS.COUNTRY]: COUNTRY,
  [FIELD_IDS.STATE]: STATE,
  [FIELD_IDS.CITY]: CITY,
  [FIELD_IDS.ASSESSMENT_GUIDELINE]: ASSESSMENT_GIDELINE,
  [FIELD_IDS.MICROZONATION]: MICROZONATION,
  [FIELD_IDS.SURVEY_OBJECTIVE]: SURVEY_OBJECTIVE,
  [FIELD_IDS.SOIL]: SOIL,
};
