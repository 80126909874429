import { components } from "react-select";
import Checkbox from "../../../checkbox";
import Label from "../../../label";

import styles from "./option.module.scss";

const { Option } = components;

const Options = (props) => {
  const { label, isSelected, value } = props;

  return (
    <Option {...props} className={styles.option} isSelected={false}>
      <Checkbox checked={!!value && isSelected} />
      <Label label={label} />
    </Option>
  );
};

export default Options;
