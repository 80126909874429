import React, { memo, useCallback, useEffect} from "react";
import { SearchOutlined } from "@ant-design/icons";
import _isEmpty from "lodash/isEmpty";
import _noop from "lodash/noop";
import PropTypes from "prop-types";

import Input from "../../../input";
import { getSearchedData } from "./helpers/searchbar.general";

const SearchBar = (props) => {
  const {
    data = [],
    searchKeys = [],
    setData = _noop,
    searchValue,
    setSearchValue,
  } = props;

  const onChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChange = useCallback(() => {
    if (_isEmpty(searchValue)) {
      setData(data);
      return;
    }

    const searchedData = getSearchedData(searchValue, data, searchKeys);

    setData(searchedData);
  }, [data, searchKeys, setData, searchValue]);

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return (
    <Input
      placeholder="search"
      onChange={onChange}
      value={searchValue}
      prefix={
        <SearchOutlined style={{ fontSize: "2.4rem", color: "#a9a9a9" }} />
      }
    />
  );
};

SearchBar.propTypes = {
  data: PropTypes.array,
  searchKeys: PropTypes.array,
  setData: PropTypes.func,
};

export default memo(SearchBar);
