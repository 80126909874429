import { FIELD_IDS } from "../../imageProcessingForm/constants/imageProcessingForm.fieldId";

import ImageProcessingReaders from "../../../../../../cortexSurvey-business/appServices/imageProcessing/readers/imageProcessing";

export const makeInitialValues = (formValues) => {
  return {
    [FIELD_IDS.ITEM_CODE]: ImageProcessingReaders.itemCode(formValues),
    [FIELD_IDS.ITEM_SEARCH_CODE]:
      ImageProcessingReaders.itemSearchCode(formValues),
    [FIELD_IDS.DISTRESS_PATTERN]:
      ImageProcessingReaders.distressPattern(formValues),
    [FIELD_IDS.VULNERABILITY_SCALE]:
      ImageProcessingReaders.vulnerabilityScale(formValues),
    [FIELD_IDS.SCORE_REFERENCE]:
      ImageProcessingReaders.scoreReference(formValues),
    [FIELD_IDS.NUMERICAL_SCORE]:
      ImageProcessingReaders.numericalScore(formValues),
    [FIELD_IDS.CAPTURED_WITH_CAMERA_TYPE]:
      ImageProcessingReaders.capturedWithCameraType(formValues),
    [FIELD_IDS.LIGHT_AND_WEATHER_CONDITION]:
      ImageProcessingReaders.lightAndWeatherCondition(formValues),
    [FIELD_IDS.CAPTURING_DISTANCE]:
      ImageProcessingReaders.capturingDistance(formValues),
    [FIELD_IDS.AUDIO_CLIP]: ImageProcessingReaders.audio(formValues),
    [FIELD_IDS.REFERENCE_IMAGE]:
      ImageProcessingReaders.referenceImages(formValues),
  };
};
