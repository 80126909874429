import { useCallback, useEffect, useState } from "react";
import _head from "lodash/head";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";
import DashboardUserAPI from "../services/dashboardUserProfile";

const useFetchUserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchUserProfile = useCallback(async () => {
    setLoading(true);
    try {
      const response = await DashboardUserAPI.fetchDashboardUserProfile({});
      setData(_head(response.data));
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, "Failed to Dashboard User Profile");
      
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  return { loading, data, fetchUserProfile };
};

export default useFetchUserProfile;
