import React, { useCallback } from "react";
import BaseUpload from "../baseUpload";
import PreviewComponent from "../previewComponent/PreviewComponent";

const SingleUpload = (props) => {
  const { value, renderer, renderProps, onChange } = props;
  const handleOnDelete = useCallback(() => {
    onChange(null);
  }, [onChange]);

  if (value)
    return (
      <PreviewComponent
        {...props}
        src={value}
        renderer={renderer}
        renderProps={renderProps}
        onDelete={handleOnDelete}
      />
    );

  return <BaseUpload {...props} />;
};

export default SingleUpload;
