import React from 'react'
import CortexLogo from '../../../cortexSurvey-components/cortexLogo'

import styles from './surveyHomePage.module.scss'

const SurveyHomePage = () => {
  return (
    <div className={styles.container}>
     <CortexLogo/>
    </div>
  )
}

export default SurveyHomePage