import React, { useState } from "react";

import Segmented from "../../../segmented";
import { FILTER_OPTIONS } from "./constants/filterOptions";

const TableFilter = (props) => {
  const { onChange, options=FILTER_OPTIONS, initialValue=1 } = props;

  const [filterStatus, setFilterStatus] = useState(initialValue);

  const handleTableFilterChange = (value) => {
    onChange(value);
    setFilterStatus(value);
  };

  return (
    <Segmented
      options={options}
      value={filterStatus}
      onChange={handleTableFilterChange}
    />
  );
};

export default TableFilter;
