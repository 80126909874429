import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import StrcutureTypeReaders from "../../../../../cortexSurvey-business/appServices/structureType/readers/structureType";
import Table from "../../../../../cortexSurvey-components/table";

import {
  INACTIVE_ROW_ACTIONS,
  ROW_ACTIONS,
} from "./constants/structureTypeOverview.rowActions";
import { SEARCH_KEYS } from "./constants/structureTypeOverView.table";
import { getColumnConfig } from "./helpers/structureTypeOverview.columnConfig";
import { renderRowExpand } from "./helpers/strurctureTypeOverview.components";

const StructureTypeOverview = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchData = _noop,
  } = props;

  const columns = useMemo(() => getColumnConfig(onAction), [onAction]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      onAction={onAction}
      loading={loading}
      rowKey={StrcutureTypeReaders.id}
      rowActions={ROW_ACTIONS}
      searchKeys={SEARCH_KEYS}
      expandable={renderRowExpand}
      fetchData={fetchData}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

StructureTypeOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.func,
  fetchData: PropTypes.func,
};

export default memo(StructureTypeOverview);
