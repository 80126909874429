import React, { memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Header from "./components/header";
import { renderHeader } from "./helpers/tableHeader.components";

import styles from "./tableHeader.module.scss";
import { isMobile } from "react-device-detect";

const TableHeader = (props) => {
  const { config = [], showTableAction = true, className } = props;

  return (
    <div className={cx(styles.headerComponent, className)}>
      {!isMobile && renderHeader(config)}
      {isMobile && (
        <Header
          title="Input Field"
          width="100%"
          className={styles.actionContainer}
        />
      )}
      {showTableAction & !isMobile && (
        <Header
          title="Action"
          width="8rem"
          className={styles.actionContainer}
        />
      )}
    </div>
  );
};

TableHeader.propTypes = {
  config: PropTypes.array,
  showTableAction: PropTypes.bool,
};

export default memo(TableHeader);
