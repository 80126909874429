import React, { memo } from "react";

import _noop from "lodash";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable";

import StateOverview from "./components/stateOverview";
import { BANNER_CONFIG, CREATE_BUTTON_LABEL, HEADER_TITLE } from "./constants/state.general";
import { ACTION_TYPES } from "./constants/state.actionTypes";
import ACTION_HANDLERS from "./helpers/state.actionHandlers";

const State = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    stateNotSynced,
    onReload,
    fetchState = _noop,
  } = props;
  return (
    <HeaderWithTable
      title={HEADER_TITLE}
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={StateOverview}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      onReload={onReload}
      showBanner={stateNotSynced}
      fetchData={fetchState}
      bannerConfig={BANNER_CONFIG}
      showReload
    />
  );
};

State.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default compose(memo, withActions({}, ACTION_HANDLERS))(State);
