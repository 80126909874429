import _find from "lodash/find";
import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import _toInteger from "lodash/toInteger";

import MicrozonationReaders from "../../../../../../cortexSurvey-business/appServices/microZonation/readers/microzonation";
import { FIELD_IDS } from "../../microZonationForm/constants/microZonationForm.fieldId";

const findMicroZonationValue = (microZonation, key) => {
  const found = _find(microZonation, (item) => {
    return Number(key) === item.surveyObjectiveId;
  });
  return found
    ? found.value
    : [{ microzonationId: undefined, level: undefined }];
};

const createMicroZonationData = (microZonation, surveyObjective) => {
  const keyedSurveyObjectives = _keyBy(surveyObjective, "surveyObjectiveId");
  const result = _mapValues(keyedSurveyObjectives, (_, key) => {
    const micro = findMicroZonationValue(microZonation, key);
    return micro;
  });
  return result;
};

export const makeInitialValues = (formData, surveyObjective) => {
  const assesmentGuidelineId = _toInteger(
    MicrozonationReaders.assessmentGuidelineId(formData)
  );
  const microZonation = MicrozonationReaders.surveyObjective(formData);
  const microzonationData = createMicroZonationData(
    microZonation,
    surveyObjective
  );
  return {
    [FIELD_IDS.ASSESSMENT_GUIDELINE]: assesmentGuidelineId,
    [FIELD_IDS.MICORZONATION]: microzonationData,
  };
};
