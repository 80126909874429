import { FIELD_IDS } from "../constants/imageProcessingForm.fieldId";

import _property from "lodash/property";

const itemCode = _property(FIELD_IDS.ITEM_CODE);
const itemSearchCode = _property(FIELD_IDS.ITEM_SEARCH_CODE);
const distressPattern = _property(FIELD_IDS.DISTRESS_PATTERN);
const vulnerabilityScale = _property(FIELD_IDS.VULNERABILITY_SCALE);
const scoreReference = _property(FIELD_IDS.SCORE_REFERENCE);
const numericalScore = _property(FIELD_IDS.NUMERICAL_SCORE);
const capturedWithCameraType = _property(FIELD_IDS.CAPTURED_WITH_CAMERA_TYPE);
const lightAndWeatherCondition = _property(
  FIELD_IDS.LIGHT_AND_WEATHER_CONDITION
);
const capturingDistance = _property(FIELD_IDS.CAPTURING_DISTANCE);
const audio = _property(FIELD_IDS.AUDIO_CLIP);
const referenceImages = _property(FIELD_IDS.REFERENCE_IMAGE);

const READERS = {
  itemCode,
  itemSearchCode,
  distressPattern,
  vulnerabilityScale,
  scoreReference,
  numericalScore,
  capturedWithCameraType,
  lightAndWeatherCondition,
  capturingDistance,
  audio,
  referenceImages,
};

export default READERS;
