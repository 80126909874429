import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import { ACTION_TYPES } from "../constants/cityIndexMapping.actionTypes";
import { getPayloadFromFormValues } from "./cityIndexMappingCreateForm.payload";

import CityIndexMappingAPI from "../../../../../../cortexSurvey-business/appServices/cityIndexMapping/services/cityIndexMapping";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose , onSubmitCb} = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (error) return;

  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValues);
    await CityIndexMappingAPI.createCityIndexMapping(payload);
    onSubmitCb()
    toaster(TOASTER_TYPE.SUCCESS, "City Index Mapping Created Successfully");
  } catch (err) {
    console.log(err)
    toaster(
      TOASTER_TYPE.ERROR,
      `Failed to create City Index Mapping. ${err.response?.data?.errors[0]}`
    );
  } finally {
    setState({ isSubmitting: false });
    onClose();
  }
};
const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
