import React, { memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Modal from "../../../../../cortexSurvey-components/modal/Modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import { INITIAL_STATE } from "./contants/stateActiveModal.initialState";
import { ACTION_TYPES } from "./contants/stateActiveModal.actionTypes";
import { MODAL_TITLE, SAVE_TEXT } from "./contants/stateActiveModal.general";

import ACTION_HANDLERS from "./helpers/stateActiveModal.actionHandlers";

const StateActiveModal = (props) => {
  const { isVisible = false,
    onCancel = _noop,
    state,
    onAction = _noop,
    isSubmitting = false,} = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  };

  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to make ${state} State active?.`}
    </Modal>
  );
};

StateActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  state: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(StateActiveModal);
