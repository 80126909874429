import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";

import { FIELD_IDS } from "./assesmentGuidelineForm.fieldId";

export const ASSESSMENT_GUIDELINE = {
  id: FIELD_IDS.ASSESSMENT_GUIDELINE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Assessment Guideline",
    placeholder: "Assessment Guideline",
    required: true,
  },
};
