import React, { memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import TextArea from "../../textArea";

const TextAreaFieldRenderer = (props) => {
  const { onChange, ...restProps } = props;
  const handleChnage = (event) => {
    const value = event.target.value;
    onChange(value);
  };

  return <TextArea {...restProps} onChange={handleChnage} />;
};

TextAreaFieldRenderer.propTypes = {
  onChange: PropTypes.func,
};

TextAreaFieldRenderer.defaultProps = {
  onChange: _noop,
};

export default memo(TextAreaFieldRenderer);
