import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_ASSESSMENT_GUIDELINE: "/assessment-guideline-data",
  CREATE_ASSESSMENT_GUIDELINE: "/assessment-guideline-add",
  EDIT_ASSESSMENT_GUIDELINE: "/assessment-guideline-update",
  DELETE_ASSESSMENT_GUIDELINE: "/is-active",
};

const fetchAssessmentGuideline = (payload) =>
  HttpClient.post(API_PATHS.FETCH_ASSESSMENT_GUIDELINE, payload);

const createAssessmentGuideline = (payload) =>
  HttpClient.post(API_PATHS.CREATE_ASSESSMENT_GUIDELINE, payload);

const editAssessmentGuideline = (payload) =>
  HttpClient.post(API_PATHS.EDIT_ASSESSMENT_GUIDELINE, payload);

const deleteAssessmentGuideline = (payload) =>
  HttpClient.post(API_PATHS.DELETE_ASSESSMENT_GUIDELINE, payload);

const AssessmentGuidelineAPI = {
  fetchAssessmentGuideline,
  createAssessmentGuideline,
  editAssessmentGuideline,
  deleteAssessmentGuideline,
};

export default AssessmentGuidelineAPI;
