import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_SURVEYOR_RANK: "/surveyor-rank-data",
  CREATE_SURVEYOR_RANK: "/surveyor-rank-add",
  EDIT_SURVEYOR_RANK: "/surveyor-rank-update",
  DELETE_SURVEYOR_RANK: "/is-active",
};

const fetchSurveyorRank = (payload) =>
  HttpClient.post(API_PATHS.FETCH_SURVEYOR_RANK, payload);

const createSurveyorRank = (payload) =>
  HttpClient.post(API_PATHS.CREATE_SURVEYOR_RANK, payload);

const editSurveyorRank = (payload) =>
  HttpClient.post(API_PATHS.EDIT_SURVEYOR_RANK, payload);

const deleteSurveyorRank = (payload) =>
  HttpClient.post(API_PATHS.DELETE_SURVEYOR_RANK, payload);

const SurveyorRankAPI = {
  fetchSurveyorRank,
  createSurveyorRank,
  editSurveyorRank,
  deleteSurveyorRank,
};

export default SurveyorRankAPI;
