import StructureHelpFormReaders from "../readers";

export const getPayloadFromFormValues = (formValues, id) => {
  const structureImages = StructureHelpFormReaders.structureImages(formValues);
  const structureDescription =
    StructureHelpFormReaders.structureDescription(formValues);
  const structureVideo = StructureHelpFormReaders.structureVideo(formValues);

  return {
    structureId: id,
    details: structureDescription,
    images: structureImages,
    video: structureVideo,
  };
};
