import React, { memo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import TableHeader from "./components/tableHeader";
import TableBody from "./components/tableBody";

import styles from "./tableInput.module.scss";

const TableInput = (props) => {
  const {
    config = [],
    onChange = _noop,
    value = [],
    showTableAction = true,
    className,
    ...restProps
  } = props;
  return (
    <div className={cx(styles.container, className)}>
      <TableHeader
        className={styles.header}
        config={config}
        showTableAction={showTableAction}
      />
      <TableBody
        className={cx(styles.body,className)}
        config={config}
        value={value}
        onChange={onChange}
        {...restProps}
        showTableAction={showTableAction}
      />
    </div>
  );
};

TableInput.propTypes = {
  config: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  showTableAction: PropTypes.bool,
};

export default memo(TableInput);
