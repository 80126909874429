import {
  ReconciliationOutlined,
  SettingOutlined,
  HeatMapOutlined,
  QuestionCircleOutlined,
  FlagOutlined,
  FileImageOutlined,
  EnvironmentOutlined,
  NotificationOutlined
} from "@ant-design/icons";

export const dashboardRoutes = [
  {
    sectionName: "User List",
    icon: ReconciliationOutlined,
    components: [
      {
        label: "APP Users",
        navigateTo: "/users",
      },
      {
        label: "Dashboard Users",
        navigateTo: "/dashboardUsers",
      },
    ],
  },
  {
    sectionName: "Survey Setting",
    icon: SettingOutlined,
    components: [
      {
        label: "Assessment Guideline",
        navigateTo: "/survey/assessmentGuideline",
      },
      {
        label: "Survey Objective",
        navigateTo: "/survey/surveyObjective",
      },
      {
        label: "Microzonation",
        navigateTo: "/survey/microZonation",
      },
    ],
  },
  {
    sectionName: "City Index Settings",
    icon: EnvironmentOutlined,
    components: [
      {
        label: "Country",
        navigateTo: "/survey/country",
      },
      {
        label: "State",
        navigateTo: "/survey/state",
      },
      {
        label: "City",
        navigateTo: "/survey/city",
      },
      {
        label: "City Index Mapping",
        navigateTo: "/survey/cityIndexMapping",
      },
    ],
  },
  {
    sectionName: "Vulnerability Index Setting",
    icon: HeatMapOutlined,
    components: [
      {
        label: "Sueveyor Rank",
        navigateTo: "/survey/surveyorRank",
      },
      {
        label: "Structure Type",
        navigateTo: "/survey/structureType",
      },
    ],
  },
  
  {
    sectionName: "Questionnaire",
    icon: QuestionCircleOutlined,
    components: [
      {
        label: "Questions",
        icon: FlagOutlined,
        navigateTo: "/survey/questionnaire",
      },
    ],
  },
  {
    sectionName: "Image Processing",
    icon: FileImageOutlined,
    components: [
      {
        label: "Image Processing",
        navigateTo: "/survey/imageProcessing",
      },
    ],
  },
  {
    sectionName: "Notifications",
    icon: NotificationOutlined,
    components: [
      {
        label: "Notifications",
        navigateTo: "/survey/notifications",
      },
    ],
  },
];
