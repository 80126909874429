import React, { memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Modal from "../../../../../cortexSurvey-components/modal/Modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import { INITIAL_STATE } from "./contants/imageProcessingActiveModal..initialState";
import { ACTION_TYPES } from "./contants/imageProcessingActiveModal.actionTypes";

import ACTION_HANDLERS from "./helpers/imageProcessingActiveModal.actionHandlers";

const ImageProcessingActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    itemCode,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.ACTIVE_SUBMISSION });
  };

  return (
    <Modal
      title="Activate Image Process Data "
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText="Activate"
      centered
    >
      {`Do you want to Activate ${itemCode}.`}
    </Modal>
  );
};
ImageProcessingActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  itemCode: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(ImageProcessingActiveModal);
