import _noop from "lodash/noop";

import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import SurveyObjectiveAPI from "../../../../../../cortexSurvey-business/appServices/surveyObjective/services/surveyObjective";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/surveyObjectiveEditForm.actionTypes";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/surveyObjectveEditForm.general";
import { getPayloadFromFormValues } from "./surveyObjectiveEditForm.payload";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { surveyObjectiveId, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (error) return;

  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValues, surveyObjectiveId);
    await SurveyObjectiveAPI.editSurveyObjective(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onClose();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
