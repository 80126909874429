import React from 'react';

const NO_DATA="-";

const TextRenderer = (props) => {
    const { value } = props;
    if(!value) return <div>{NO_DATA}</div>
  return (
    <div>{value}</div>
  )
}

export default TextRenderer