import StructureTypeReaders from "../../../../../../cortexSurvey-business/appServices/structureType/readers/structureType";
import { FIELD_IDS } from "../constants/structureHelpForm.fieldId";

export const makeInitialValues = (formValues) => {
  return {
    [FIELD_IDS.STRUCTURE_DESCRIPTION]:
      StructureTypeReaders.structureDetails(formValues),
    [FIELD_IDS.STRUCTURE_IMAGES]:
      StructureTypeReaders.structureImages(formValues),
    [FIELD_IDS.STRUCTURE_VIDEO]:
      StructureTypeReaders.structureVideo(formValues),
  };
};
