import React from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Button, { TYPES } from "../button";

import styles from "./saveComponent.module.scss";

const SaveComponent = (props) => {
  const {
    label = "save",
    className,
    showCancel = false,
    handleSave = _noop,
    handleCancel = _noop,
    disabled,
    loading = false,
  } = props;

  return (
    <div className={className}>
      {showCancel && (
        <Button
          className={styles.cancelButton}
          type={TYPES.SECONDARY}
          onClick={handleCancel}
          disabled={disabled}
        >
          Cancel
        </Button>
      )}

      <Button
        className={styles.saveButton}
        type={TYPES.PRIMARY}
        onClick={handleSave}
        disabled={disabled}
        loading={loading}
      >
        {label}
      </Button>
    </div>
  );
};

SaveComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  showCancel: PropTypes.bool,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default SaveComponent;
