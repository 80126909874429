const questionId= "questionId";
const serialNumber = "serialNumber";
const help = "helps";
const surveyObjectives = "surveyObjectives";
const structureTypes = "structureType";
const surveyOrRanks = "surveyorRanks";
const assessmentGuidelines = "assessmentGuidelines";
const question = "question";
const questionSegment = "questionSegment";
const questionSegmentId="questionSegmentId";
const answers = "answers";
const answerPattern = "answerPattern";
const score = "score";
const mandatory = "isRequired";

const DATA_INDEX = {
  questionId,
  help,
  surveyObjectives,
  structureTypes,
  surveyOrRanks,
  assessmentGuidelines,
  question,
  questionSegment,
  answers,
  answerPattern,
  score,
  serialNumber,
  mandatory,
  questionSegmentId
};

export default DATA_INDEX;
