import React, { useCallback, useMemo } from "react";

import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";
import useFetchState from "../../../cortexSurvey-business/appServices/state/hooks/useFetchState";

import StateReaders from "../../../cortexSurvey-business/appServices/state/readers/state";
import State from "./State";
import StateCreateForm from "./components/stateCreateForm";
import StateDeleteModal from "./components/stateDeleteModal";
import StateEditForm from "./components/stateEditForm";
import StateActiveModal from "./components/stateActiveModal";
import useFetchCountry from "../../../cortexSurvey-business/appServices/country/hooks/useFetchCountry";
import { isStateNotSync } from "../../../cortexSurvey-business/appServices/state/helpers/stateSync";

const StateContainer = () => {
  const { loading, data, fetchState } = useFetchState();

  const { data: activeState, fetchState: fetchActiveState } = useFetchState();

  const {
    data: country,
    fetchCountry,
  } = useFetchCountry();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditFormData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isStateDeletionModalVisible,
    showModal: showStateDeletionModal,
    closeModal: closeStateDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isStateActiveModalVisible,
    showModal: showStateActiveModal,
    closeModal: closeStateActiveModal,
  } = useModalVisibility({});

  const stateNotSynced = useMemo(
    () => isStateNotSync(activeState),
    [activeState]
  );

  const handleReload = useCallback(() => {
    fetchActiveState();
    fetchState();
    fetchCountry();
  }, [fetchActiveState, fetchState, fetchCountry]);

  return (
    <div>
      <State
        data={data}
        loading={loading}
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showStateDeletionModal}
        showActiveModal={showStateActiveModal}
        fetchCountry={fetchCountry}
        fetchState={fetchState}
        onReload={handleReload}
        stateNotSynced={stateNotSynced}
      />

      <StateCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchState}
        country={country}
      />

      <StateEditForm
        formData={selectedEditFormData}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={handleReload}
        stateId={StateReaders.stateId(selectedEditFormData)}
        country={country}
      />
      <StateDeleteModal
        isVisible={isStateDeletionModalVisible}
        onCancel={closeStateDeletionModal}
        stateId={StateReaders.stateId(selectedDeleteData)}
        state={StateReaders.state(selectedDeleteData)}
        onSubmitCb={handleReload}
      />

      <StateActiveModal
        isVisible={isStateActiveModalVisible}
        onCancel={closeStateActiveModal}
        stateId={StateReaders.stateId(selectedActiveData)}
        state={StateReaders.state(selectedActiveData)}
        onSubmitCb={handleReload}
      />
    </div>
  );
};

export default StateContainer;
