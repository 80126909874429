import _noop from "lodash/noop";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/cityEditForn.actionTypes";
import {
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../constants/cityEditForm.general";

import CityAPI from "../../../../../../cortexSurvey-business/appServices/city/services/city";
import { getPayloadFromFormValues } from "./cityEditForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { cityId, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(formValues, cityId);
      await CityAPI.editCity(payload);
      onSubmitCb();
      toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
      onClose();
    } catch (err) {
      errorHandler(err, setState, ERROR_MESSAGE);
    } finally {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
