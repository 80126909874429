import _map from "lodash/map";

import MicrozonationDataIndex from "../../../../../../cortexSurvey-business/appServices/microZonation/constants/dataIndex/microzonation";
import SurveyObjectiveReaders from "../../../../../../cortexSurvey-business/appServices/surveyObjective/readers/surveyObjective";

import SurveyObjectiveColumn from "../components/surveyObjectiveColumn";
import SyncColumn from "../components/syncColumn";
import { ASSESSMENT_GUIDELINE_COLUMN } from "../constants/microZonationOverview.columns";
import { isMobile } from "react-device-detect";

const createSurveyObjectiveColumn = (surveyObjectiveData) => {
  const surveyObjective =
    SurveyObjectiveReaders.surveyObjective(surveyObjectiveData);
  const surveyObjectiveId =
    SurveyObjectiveReaders.surveyObjectiveId(surveyObjectiveData);
  return {
    title: surveyObjective,
    dataIndex: MicrozonationDataIndex.surveyObjective,
    key: surveyObjectiveId,
    width: 150,
    render: (value) => (
      <SurveyObjectiveColumn
        rowData={value}
        surveyObjectiveId={surveyObjectiveId}
      />
    ),
  };
};

const getSurveyObjectiveColumn = (surveyObjective) => {
  const surveyObjectiveColumnChildren = _map(
    surveyObjective,
    createSurveyObjectiveColumn
  );

  return {
    title: "Survey Objective",
    dataIndex: MicrozonationDataIndex.surveyObjective,
    children: surveyObjectiveColumnChildren,
  };
};

const getSyncColumn = (surveyObjective) => ({
  title: "Data Synced",
  dataIndex: "surveyObjective",
  key: "sync",
  width: 100,
  fixed: !isMobile && "right",
  render: (value, rowData) => (
    <SyncColumn surveyObjective={surveyObjective} value={value} rowData={rowData}/>
  ),
});

export const getColumns = (surveyObjective) => {
  const SURVEY_OBJECTIVE_COLUMN = getSurveyObjectiveColumn(surveyObjective);
  const SYNC_COLUMN = getSyncColumn(surveyObjective);
  return [ASSESSMENT_GUIDELINE_COLUMN, SURVEY_OBJECTIVE_COLUMN, SYNC_COLUMN];
};
