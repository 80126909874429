import React, { memo } from "react";

import TableInput from "../../../../../../../../../../../cortexSurvey-components/tableInputWithAdd/components/tableInput";

import styles from "./surveyObjectiveTable.module.scss";

const surveyObjectiveScoreTable = (props) => {
  const { data, columnConfig, onChange } = props;
  return (
    <TableInput
      className={styles.container}
      value={data}
      config={columnConfig}
      showTableAction={false}
      onChange={onChange}
    />
  );
};

export default memo(surveyObjectiveScoreTable);
