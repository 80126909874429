import TextAreaFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/TextAreaFieldRenderer";
import ImageUpload from "../../../../../../cortexSurvey-components/imageUpload";

import ReferenceImageDataIndex from "../../../../../../cortexSurvey-business/appServices/imageProcessing/constants/dataIndex/referenceImage";

import styles from "../referenceImageTable.module.scss";

export const getColumnConfig = (disabled, setStateLoading) => [
  {
    key: "image",
    dataIndex: ReferenceImageDataIndex.image,
    renderer: ImageUpload,
    headerName: "Upload Image",
    width: "12rem",
    renderProps: {
      disabled,
      className: styles.imageUpload,
      setStateLoading,
    },
  },
  {
    key: "Description",
    dataIndex: ReferenceImageDataIndex.description,
    renderer: TextAreaFieldRenderer,
    headerName: "Image Description",
    width: "55rem",
    renderProps: {
      autoSize: { minRows: 4, maxRows: 4 },
      disabled,
    },
  },
];
