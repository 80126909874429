import { FIELD_IDS } from "./structureTypeForm.fieldId";

export const SECTIONS = [
  {
    rows: [
      { columns: [FIELD_IDS.STRUCTURE_TYPE] },
      { columns: [FIELD_IDS.STRUCTURE_TYPE_IMAGE] },
    ],
  },
];
