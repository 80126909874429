import React from "react";

import styles from "../previewComponent.module.scss";
import Button, { TYPES } from "../../../../button";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const getPreviewConfig = (visible, onVisibleChange) => ({
  visible,
  onVisibleChange,
  disabled: true,
});

const getImagePreviewComponent = ({
  renderer,
  previewClassName,
  src,
  renderProps,
  visiblePreview,
  handlePreviewClose,
  handleOnDelete,
  handlePreviewVisible,
}) => {
  const Component = renderer;
  const preview = getPreviewConfig(visiblePreview, handlePreviewClose);

  return (
    <div className={styles.previewContainer}>
      <div className={styles.previewContent}>
        <Component
          className={previewClassName}
          value={src}
          {...renderProps}
          preview={preview}
        />
        <div className={styles.iconContainer}>
          <Button type={TYPES.INVERSE_TEXT} onClick={handleOnDelete}>
            <DeleteOutlined /> Delete
          </Button>
          <Button type={TYPES.INVERSE_TEXT} onClick={handlePreviewVisible}>
            <EyeOutlined /> Preview
          </Button>
        </div>
      </div>
    </div>
  );
};

const getAudioPreviewComponent = ({
  renderer,
  previewClassName,
  src,
  renderProps,
  handleOnDelete,
}) => {
  const Component = renderer;
  return (
    <div className={styles.otherpreviewContainer}>
      <Component className={previewClassName} value={src} {...renderProps} />
      <div className={styles.othericonContainer}>
        <Button type={TYPES.TERTIARY} onClick={handleOnDelete}>
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
};

const getVideoPreviewComponent = ({
  renderer,
  previewClassName,
  src,
  renderProps,
  handleOnDelete,
}) => {
  const Component = renderer;
  return (
    <div className={styles.videoContainer}>
      <Component className={previewClassName} value={src} {...renderProps} />
      <div className={styles.othericonContainer}>
        <Button type={TYPES.SECONDARY} onClick={handleOnDelete}>
          Delete Uploaded Video
        </Button>
      </div>
    </div>
  );
};

const UPLOAD_TYPE = {
  AUDIO: "audio",
  IMAGE: "image",
  VIDEO: "video",
};

const UPLOAD_TYPE_VS_RENDERER = {
  [UPLOAD_TYPE.AUDIO]: getAudioPreviewComponent,
  [UPLOAD_TYPE.IMAGE]: getImagePreviewComponent,
  [UPLOAD_TYPE.VIDEO]: getVideoPreviewComponent,
};

export const getPreviewComponent = ({
  type,
  renderer,
  previewClassName,
  src,
  renderProps,
  visiblePreview,
  handlePreviewClose,
  handleOnDelete,
  handlePreviewVisible,
}) => {
  const previewToExec = UPLOAD_TYPE_VS_RENDERER[type];
  return previewToExec({
    renderer,
    previewClassName,
    src,
    renderProps,
    visiblePreview,
    handlePreviewClose,
    handleOnDelete,
    handlePreviewVisible,
  });
};
