import _find from "lodash/find";
import _map from "lodash/map";

import MicrozonationReaders from "../readers/microzonation";
import { getSurveyObjectiveOptions } from "../../surveyObjective/helpers/surveyObjectiveOptions";

const isAssessmentGuidelinePresent =
  (assessmentGuidelineId) => (microzonatioData) => {
    const assessmentGuideline =
      MicrozonationReaders.assessmentGuidelineId(microzonatioData);
    return assessmentGuideline === assessmentGuidelineId;
  };

const getAssessmentGuideline = (microzonationData, assessmentGuidelineId) => {
  return _find(
    microzonationData,
    isAssessmentGuidelinePresent(assessmentGuidelineId)
  );
};

export const getSurveyObjectiveOptionsFromMicrozonationData = (
  microzonationData,
  assessmentGuidelineId
) => {
  const data = getAssessmentGuideline(microzonationData, assessmentGuidelineId);
  const surveyObjectives = MicrozonationReaders.surveyObjective(data);
  return getSurveyObjectiveOptions(surveyObjectives);
};

const isSurveyObjectivePresent = (surveyObjectiveId) => (data) => {
  const surveyObjective = MicrozonationReaders.surveyObjectiveId(data);
  return surveyObjective === surveyObjectiveId;
};

const getSurveyObjective = (surveyObjectives, surveyObjectiveId) => {
  return _find(surveyObjectives, isSurveyObjectivePresent(surveyObjectiveId));
};

const makeMicrozonationOption = (value) => {
  const { microzonationId, level } = value;
  return {
    label: level,
    value: microzonationId,
  };
};

const createMicrozonationOptions = (values) => {
  return _map(values, makeMicrozonationOption);
};

export const getMicrozonationOptions = (
  microzonationData,
  assessmentGuidelineId,
  surveyObjectiveId
) => {
  const data = getAssessmentGuideline(microzonationData, assessmentGuidelineId);
  const surveyObjectives = MicrozonationReaders.surveyObjective(data);
  const surveyObjectiveData = getSurveyObjective(
    surveyObjectives,
    surveyObjectiveId
  );

  const values = surveyObjectiveData?.value;
  return createMicrozonationOptions(values);
};
