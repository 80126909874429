import React from "react";
import PropTypes from "prop-types";

import _isEqual from "lodash/isEqual";
import _noop from "lodash/noop";
import _size from "lodash/size";

import Checkbox from "../../../checkbox";
import Label from "../../../label";

import styles from "./selectAll.module.scss";

const SelectAll = (props) => {
  const { options, value, onChange, selectAllLabel } = props;

  const optionSize = _size(options);
  const valueSize = _size(value);

  const isChecked = _isEqual(optionSize, valueSize);
  const isIndeterminate = !isChecked && valueSize;

  const handleSelectAll = () => {
    if (!valueSize) {
      onChange(options);
      return;
    }
    onChange(null);
  };

  return (
    <div className={styles.selectAll} onClick={handleSelectAll}>
      <Checkbox
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={() => null}
      />
      <Label label={selectAllLabel} />
    </div>
  );
};

SelectAll.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  selectAllLabel: PropTypes.string,
};

SelectAll.defaultProps = {
  options: [],
  value: null,
  onChange: _noop,
  selectAllLabel: "Select All",
};

export default SelectAll;
