import React, { memo } from "react";

import { compose } from "recompose";
import PropTypes from "prop-types";

import _noop from "lodash/noop";

import { SortableContainer } from "react-sortable-hoc";
import { renderSortableList } from "./helpers/sortableList.components";

import styles from "./sortableList.module.scss";

const SortableList = (props) => {
  const {
    data = [],
    onDelete = _noop,
    onPreview = _noop,
    tableClassName,
  } = props;

  return (
    <div className={`${styles.container} ${tableClassName}`}>
      {renderSortableList(data, onDelete, onPreview)}
    </div>
  );
};

SortableList.propTypes = {
  data: PropTypes.array,
  onDelete: PropTypes.func,
  onPreview: PropTypes.func,
  tableClassName: PropTypes.string,
};

export default compose(memo, SortableContainer)(SortableList);
