import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import _map from "lodash/map";
import _size from "lodash/size";

import Tabs from "../tabs";
import {
  renderSection,
  renderTabSections,
} from "./helpers/uiRenderer.components";

import styles from "./uiRenderer.module.scss";

const UiRenderer = (props) => {
  const {
    sections = [],
    fieldConfig = {},
    className,
    tabEnabled = false,
    ...restProps
  } = props;

  const sectionProps = useMemo(
    () => ({ fieldConfig, ...restProps }),
    [fieldConfig, restProps]
  );

  const sectionSize = useMemo(() => _size(sections), [sections]);

  if (tabEnabled) {
    return (
      <div className={className}>
        <Tabs
          tabs={sections}
          renderer={renderTabSections}
          renderOptions={sectionProps}
        />
      </div>
    );
  }

  return (
    <div className={cx(className, styles.container)}>
      {_map(sections, renderSection(sectionProps, sectionSize))}
    </div>
  );
};

UiRenderer.propTypes = {
  sections: PropTypes.array,
  fieldConfig: PropTypes.object,
  className: PropTypes.string,
  tabEnabled: PropTypes.bool,
};

export default memo(UiRenderer);
