import ShowHelpComponent from "../components/showHelpComponent";
import ShowAnswerComponent from "../components/showAnswerComponent";
import TableColumnComponent from "../components/tableColumnComponent/TableColumnComponent";
import QuestionaireScoreForm from "../../questionaireScoreForm/QuestionaireScoreForm";
import QuestionnaireDataIndex from "../../../../../../cortexSurvey-business/appServices/questionnaire/constants/dataIndex/questionnaire";
import { isMobile } from "react-device-detect";

const COLUMNS = [
  {
    title: "Serial Number",
    dataIndex: QuestionnaireDataIndex.serialNumber,
    key: "serialNumber",
    width: "10rem",
    fixed: !isMobile && "left",
  },
  {
    title: "Question",
    dataIndex: QuestionnaireDataIndex.question,
    key: "surveyObjective",
    width: "30rem",
    fixed: !isMobile && "left",
  },
  {
    title: "Question Segment",
    dataIndex: QuestionnaireDataIndex.questionSegment,
    key: "surveyObjective",
    width: "20rem",
  },
  {
    title: "Survey Objective",
    dataIndex: QuestionnaireDataIndex.surveyObjectives,
    key: "surveyObjective",
    width: "25rem",
    render: (surveyObjectives) => (
      <TableColumnComponent data={surveyObjectives} id="surveyObjective" />
    ),
  },
  {
    title: "Structure Type",
    dataIndex: QuestionnaireDataIndex.structureTypes,
    key: "structureType",
    width: "25rem",
    render: (structureTypes) => (
      <TableColumnComponent data={structureTypes} id="structureName" />
    ),
  },
  {
    title: "Surveyor Rank",
    dataIndex: QuestionnaireDataIndex.surveyOrRanks,
    key: "surveyOrRanks",
    width: "25rem",
    render: (surveyOrRanks) => (
      <TableColumnComponent data={surveyOrRanks} id="surveyorRank" />
    ),
  },
  {
    title: "Assessment Guideline",
    dataIndex: QuestionnaireDataIndex.assessmentGuidelines,
    key: "assessmentGuidelines",
    width: "25rem",
    render: (assessmentguidelines) => (
      <TableColumnComponent
        data={assessmentguidelines}
        id="assessmentGuideline"
      />
    ),
  },
  {
    title: "Answers Pattern",
    dataIndex: QuestionnaireDataIndex.answerPattern,
    key: "answersPattern",
    width: "20rem",
  },
  {
    title: "Answers",
    dataIndex: QuestionnaireDataIndex.answers,
    key: "surveyObjective",
    width: "15rem",
    render: (answers) => <ShowAnswerComponent data={answers} id="answer" />,
  },
  {
    title: "Help",
    dataIndex: QuestionnaireDataIndex.help,
    key: "help",
    width: "12rem",
    render: (help) => <ShowHelpComponent data={help} />,
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
    width: "13rem",
    render: (score, row) => (
      <QuestionaireScoreForm
        data={score}
        surveyObjectives={row?.surveyObjectives}
        assessmentGuidelines={row?.assessmentGuidelines}
        id="score"
      />
    ),
  },
];

export default COLUMNS;
