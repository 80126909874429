import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_QUESTIONNAIRE: "/questions-data",
  CREATE_QUESTIONNAIRE: "/question-add",
  EDIT_QUESTIONNAIRE: "/question-update",
  DELETE_QUESTIONNAIRE: "/is-active",
};

const fetchQuestionnaire = (payload) =>
  HttpClient.post(API_PATHS.FETCH_QUESTIONNAIRE, payload);

const createQuestionnaire = (payload) =>
  HttpClient.post(API_PATHS.CREATE_QUESTIONNAIRE, payload);

const editQuestionnaire = (payload) =>
  HttpClient.post(API_PATHS.EDIT_QUESTIONNAIRE, payload);

const deleteQuestionnaire = (payload) =>
  HttpClient.post(API_PATHS.DELETE_QUESTIONNAIRE, payload);

const QuestionnaireAPI = {
  fetchQuestionnaire,
  createQuestionnaire,
  editQuestionnaire,
  deleteQuestionnaire,
};

export default QuestionnaireAPI;
