import _map from "lodash/map";

const getStructureWithStructureTypeId = (structureId) => (structure) => ({
  ...structure,
  structureId,
});

export const addStructureTypeIdToStructures = (structureId) => (structures) => {
  const updatedStructures = _map(
    structures,
    getStructureWithStructureTypeId(structureId)
  );
  return updatedStructures;
};
