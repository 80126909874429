import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_CITY: "/city-data",
  CREATE_CITY: "/city-add",
  EDIT_CITY: "/city-update",
  DELETE_CITY: "/is-active",
};

const fetchCity = (payload) => HttpClient.post(API_PATHS.FETCH_CITY, payload);

const createCity = (payload) => HttpClient.post(API_PATHS.CREATE_CITY, payload);

const editCity = (payload) => HttpClient.post(API_PATHS.EDIT_CITY, payload);

const deleteCity = (payload) => HttpClient.post(API_PATHS.DELETE_CITY, payload);

const CityAPI = {
  fetchCity,
  createCity,
  editCity,
  deleteCity,
};

export default CityAPI;
