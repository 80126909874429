import StructureTypeFormReaders from "../../structureTypeForm/readers";

export const getPayloadFromFormValues = (formValues) => {
  const structureType = StructureTypeFormReaders.structureType(formValues);
  const structureTypeImage =
    StructureTypeFormReaders.structureTypeImage(formValues);

  return {
    structure: structureType,
    image: structureTypeImage,
  };
};
