const id = "structureId";
const structureName = "structureName";
const structureImage = "image";
const structureDetails = "details";
const structureImages = "images";
const structureVideo = "video";
const structureComponents = "components";
const structureProblem = "problems";

const DATA_INDEX = {
  id,
  structureComponents,
  structureDetails,
  structureImage,
  structureImages,
  structureName,
  structureVideo,
  structureProblem,
};

export default DATA_INDEX;
