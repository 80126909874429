import UserReaders from "../../../../../../cortexSurvey-business/appServices/dashboardUser/readers/user";

import { FIELD_IDS } from "../constants/dashboardUserProfileForm.fieldId";

const makeInitialValues = (formData) => {
  const initialValues = {
    [FIELD_IDS.FIRST_NAME]: UserReaders.firstName(formData),
    [FIELD_IDS.LAST_NAME]: UserReaders.lastName(formData),
    [FIELD_IDS.EMAIL]: UserReaders.email(formData),
    [FIELD_IDS.PHONE_NUMBER]: UserReaders.phone(formData),
    [FIELD_IDS.ROLE]: UserReaders.role(formData),
  };
  return initialValues;
};

export default makeInitialValues;
