import StructureTypeComponentDataIndex from "../../../../../../../../cortexSurvey-business/appServices/structureType/constants/dataIndex/structureComponent";
import InputFieldRenderer from "../../../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";

export const getColumnConfig = (disabled) => [
  {
    dataIndex: StructureTypeComponentDataIndex.component,
    key: "structureComponent",
    renderer: InputFieldRenderer,
    headerName: "Structure Component",
    width: "40rem",
    disabled,
  },
];
