import React from "react";
import Modal, {
  useModalVisibility,
} from "../../../../../../../cortexSurvey-components/modal";
import VideoPlayer from "../../../../../../../cortexSurvey-components/videoPlayer/VideoPlayer";
import Image from "../../../../../../../cortexSurvey-components/image/Image";
import Button, {
  TYPES,
} from "../../../../../../../cortexSurvey-components/button";
import { isMobile } from "react-device-detect";

const ShowNotification = ({ data }) => {
  const { type, media } = data;

  const { isModalVisible, showModal, closeModal } = useModalVisibility();

  if (!media) return <div>No Notification Available</div>;

  return (
    <div>
      <Button type={TYPES.SECONDARY} onClick={showModal}>
        show Notification
      </Button>
      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}
        centered
        width={isMobile ? "45rem" : "70rem"}
        title="Notification"
        maskClosable={false}
        destroyOnClose
      >
        {type === "image" ? (
          <Image value={media} />
        ) : (
          <VideoPlayer controls={true} url={media} />
        )}
      </Modal>
    </div>
  );
};

export default ShowNotification;
