import _mapValues from "lodash/mapValues";
import _keyBy from "lodash/keyBy";

import { FIELD_IDS } from "../../microZonationForm/constants/microZonationForm.fieldId";
import SurveyObjectiveDataIndex from "../../../../../../cortexSurvey-business/appServices/surveyObjective/constants/dataIndex/surveyObjective";
import MicroZonationDataIndex from "../../../../../../cortexSurvey-business/appServices/microZonation/constants/dataIndex/microzonation";

const createMicroZonationIntialValue = (surveyObjectives) => {
  const transformedData = _mapValues(
    _keyBy(surveyObjectives, SurveyObjectiveDataIndex.surveyObjectiveId),
    () => [
      {
        [MicroZonationDataIndex.microzonationId]: undefined,
        [MicroZonationDataIndex.level]: undefined,
      },
    ]
  );
  return transformedData;
};

export const getIntialValues = (surveyObjective) => {
  const microZonation = createMicroZonationIntialValue(surveyObjective);
  return {
    [FIELD_IDS.MICORZONATION]: microZonation,
  };
};
