import React, { useState } from 'react';
import { isFunction } from 'lodash';

const WithActions = (INITIAL_STATE = {}, ACTION_HANDLERS) => {
  const wrapComponent = (WrappedComponent) => {
    const WithActionHandlers = (props) => {
      const [state, setState] = useState(INITIAL_STATE);
     
      const getState = () => ({ ...props, ...state });

      const handleStateChange = (params) => {
        setState((prev) => ({ ...prev, ...params }));
      };

      const handleAction = ({ type, payload }) => {
        const funcToExec = ACTION_HANDLERS[type];
        if (isFunction(funcToExec)) {
          funcToExec({ getState, setState: handleStateChange, params: payload });
        }
      };

      return <WrappedComponent {...props} {...state} onAction={handleAction} />;
    };

    return WithActionHandlers;
  };

  return wrapComponent;
};

export default WithActions;