import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _get from "lodash/get";
import cityReaders from "../readers/city";
import CityDataIndex from "../constants/dataIndex/city";

const createStateOptions = (city) => {
  const stateId = cityReaders.stateId(_get(city,CityDataIndex.state));
  const stateName = cityReaders.stateName(_get(city,CityDataIndex.state));
  return { label: stateName, value: stateId };
};

const isSameCountry = (countryId) => (city) => {
  return cityReaders.countryId(_get(city,CityDataIndex.country)) === countryId;
};

export const getStateOptions = (cityData, countryId) => {
  const filteredCities = _filter(cityData, isSameCountry(countryId));
  const mappedStates = _map(filteredCities, createStateOptions);
  const stateOptions = _uniqBy(mappedStates, "value");
  return stateOptions;
};
