import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { SECTIONS } from "./constants/countryForm.sections";
import { getFormConfig } from "./helpers/countryForm.formConfig";

const CountryForm = (props) => {
  const { initialValues={}, onSubmit=_noop, formId, disabled = false } = props;

  const formConfig = useCallback(() => getFormConfig(), []);

  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={disabled}
    />
  );
};

CountryForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(CountryForm);
