import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _get from "lodash/get";
import CityDataIndex from "../constants/dataIndex/city";

import cityReaders from "../readers/city";

const createCountryOptions = (city) => {
  const countryId = cityReaders.countryId(_get(city,CityDataIndex.country));
  const countryName = cityReaders.countryName(_get(city,CityDataIndex.country));
  return { label: countryName, value: countryId };
};

export const getCountryOptions = (cityData) => {
  const mappedCountries = _map(cityData, createCountryOptions);
  const countryOptions = _uniqBy(mappedCountries, "value");
  return countryOptions;
};
