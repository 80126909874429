import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";

import AssessmentGuidelinesReaders from "../../../../../cortexSurvey-business/appServices/assessmentGuideline/readers/assessmentGuideline";
import Table from "../../../../../cortexSurvey-components/table";

import { COLUMNS } from "./constants/assesmentGuidelineOverview.columns";
import {
  INACTIVE_ROW_ACTIONS,
  ROW_ACTIONS,
} from "./constants/assesmentGuidelineOverview.rowActions";
import { SEARCH_KEYS } from "./constants/assessmentGuidelineOverview.table";


const AssesmentGuidelineOverview = (props) => {
  const {
    loading = false,
    data = [],
    onAction = _noop,
    fetchData = _noop,
  } = props;

  return (
    <Table
      rowKey={AssessmentGuidelinesReaders.assessmentGuidelineId}
      dataSource={data}
      columns={COLUMNS}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      rowActions={ROW_ACTIONS}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

AssesmentGuidelineOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
};

export default memo(AssesmentGuidelineOverview);
