import React from "react";

import Upload from "../upload";
import VideoPlayer from "../videoPlayer";

const VideoUpload = (props) => {
  return (
    <Upload
      {...props}
      type="video"
      renderer={VideoPlayer}
      renderProps={{ controls: true }}
    />
  );
};

export default VideoUpload;
