import Fuse from "fuse.js";
import _size from "lodash/size";
import _map from "lodash/map";
import _compact from "lodash/compact";

const getSearchResult=(item)=>item?.item;

const getSearchedComponents = (list, searchText) => {
  if (!searchText) return list;

  const options = {
    keys: ["label"],
    shouldSort: false,
    threshold: 0.4, 
    distance: 100,
  };

  const fuse = new Fuse(list, options);
  const searchResult = fuse.search(searchText);
  return _map(searchResult,getSearchResult);
};

const getNavigationConfig = (searchText) => (configValue) => {
  const { sectionName, components, icon } = configValue;
  const searchedComponents = getSearchedComponents(components, searchText);
  const searchedComponentsSize = _size(searchedComponents);
  const updatedNavigation = { sectionName, components: searchedComponents, icon };
  return searchedComponentsSize > 0 ? updatedNavigation : undefined;
};

export const extractNavigationfromSearch = ({
  navigationConfig,
  searchText,
}) => {
  const extractedNavigationConfig = _map(
    navigationConfig,
    getNavigationConfig(searchText)
  );
  return _compact(extractedNavigationConfig);
};
