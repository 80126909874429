import { getCountryOptions } from "../../../../../../cortexSurvey-base/helpers/places/country";
import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/SelectFieldRenderer";

import { FIELD_IDS } from "./countryForm.fieldId";

export const COUNTRY = {
  id: FIELD_IDS.COUNTRY,
  renderer: SelectFieldRenderer,
  renderOptions: {
    options: getCountryOptions(),
    label: "Country",
    placeholder: "Country",
    required: true,
  },
};
