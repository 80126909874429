import _slice from "lodash/slice";
import { isMobile } from "react-device-detect";

export const getUpdatedTableData = (dataSource, pageSize, currentPage) => {
  if (isMobile) return dataSource;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return _slice(dataSource, startIndex, endIndex);
};
