import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import cx from "classnames";

import TableInputWithAdd from "../../../../../cortexSurvey-components/tableInputWithAdd";

import { getColumnConfig } from "./helpers/referenceImageTable.columnConfig";
import { ADD_INITIAL_STATE } from "./constants/referenceImageTable.addInitialState";

import styles from "./referenceImageTable.module.scss";
import { isMobile } from "react-device-detect";

const ReferenceImageTable = (props) => {
  const {
    onChange = _noop,
    value = [],
    disabled = false,
    title,
    showTableAction = true,
    setStateLoading = _noop,
  } = props;

  const columnConfig = useMemo(
    () => getColumnConfig(disabled, setStateLoading),
    [disabled, setStateLoading]
  );

  return (
    <TableInputWithAdd
      tableClassName={cx(styles.container, {
        [styles.mobileTableClassName]: isMobile,
      })}
      config={columnConfig}
      addInitialState={ADD_INITIAL_STATE}
      onChange={onChange}
      value={value}
      title={title}
      disabled={disabled}
      showAction={false}
      showTableAction={showTableAction}
      required
    />
  );
};

ReferenceImageTable.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  showTableAction: PropTypes.bool,
};

export default memo(ReferenceImageTable);
