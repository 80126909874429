import _some from "lodash/some";
import _isEmpty from "lodash/isEmpty";

const isMicroZonationValueEmpty = (microZonationInput) => {
  const { level } = microZonationInput || {};
  return _isEmpty(level);
};

const isMicroZonationInputEmpty = (microZonationInput) => {
  const isEmptyMicroZonationValue = _some(
    microZonationInput,
    isMicroZonationValueEmpty
  );
  return isEmptyMicroZonationValue;
};

export const validateMicroZonationInput = (value) => {
  const isEmptyMicroZonationInput = _some(value, isMicroZonationInputEmpty);
  return {
    value: !isEmptyMicroZonationInput,
    message: "Please Fill All the MicroZonation Fields",
  };
};
