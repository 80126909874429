import _poperty from "lodash/property";

import StrcutreProblemDataIndex from "../constants/dataIndex/structureProblem";

const id = _poperty(StrcutreProblemDataIndex.id);
const structureProblem = _poperty(StrcutreProblemDataIndex.structureProblem);
const structureRange = _poperty(StrcutreProblemDataIndex.structureRange);
const stuctureProblemType = _poperty(
  StrcutreProblemDataIndex.stuctureProblemType
);

const READERS = {
  id,
  structureProblem,
  structureRange,
  stuctureProblemType,
};

export default READERS;
