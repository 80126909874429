import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import StructureTypeAPI from "../../../../../../cortexSurvey-business/appServices/structureType/services/structureType";

import { ACTION_TYPES } from "../contants/structureTypeDeleteModal.actionTypes";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { id, onCancel, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload = { structureId: id, isActive: 0 };
    StructureTypeAPI.deleteStructureType(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Structure Type Deleted Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to Delete Structure Type");
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
