import React, { memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Modal from "../../../../../cortexSurvey-components/modal/Modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import { INITIAL_STATE } from "./contants/countryDeleteModal.initialState";
import { ACTION_TYPES } from "./contants/countryDeleteModal.actionTypes";
import { MODAL_TITILE, SAVE_TEXT } from "./contants/countryDeleteModal.general";
import ACTION_HANDLERS from "./helpers/countryDeleteModal.actionHandlers";

const CountryDeleteModal = (props) => {
  const { isVisible=false, onCancel=_noop, country, onAction=_noop, isSubmitting=false } = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  };

  return (
    <Modal
      title={MODAL_TITILE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to deActivate ${country} Country.`}
    </Modal>
  );
};

CountryDeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  country: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CountryDeleteModal);
