import _property from "lodash/property";
import { FIELD_IDS } from "../constants/structureProblemForm.fieldId";

const structureProblem = _property(FIELD_IDS.STRUCTURE_PROBLEM);

const READERS = {
  structureProblem,
};

export default READERS;
