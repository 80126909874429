import CountryReaders from "../../../../../../cortexSurvey-business/appServices/country/readers/country";
import StateReaders from "../../../../../../cortexSurvey-business/appServices/state/readers/state";
import StateDataIndex from "../../../../../../cortexSurvey-business/appServices/state/constants/dataIndex/state";
import { FIELD_IDS } from "../../stateForm/constants/stateForm.fieldId";

import _get from 'lodash/get'

export const makeInitialValues = (formData) => {
  const country = CountryReaders.countryId(_get(formData,StateDataIndex.country));
  const state = StateReaders.state(formData);
  return {
    [FIELD_IDS.COUNTRY]: country,
    [FIELD_IDS.STATE]: state,
  };
};
