import _noop from "lodash/noop";

import DashboardUserAPI from "../../../../../../cortexSurvey-business/appServices/dashboardUser/services/dashboardUserProfile";
import {
  TOASTER_TYPE,
  toaster,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/dashbordUserProfile.actionTypes";
import { createPayloadFroUserProfile } from "./dashboardUserProfileForm.payload";

const handleSubmission = async ({ setState, getState, params }) => {
  const { userId, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;
  const { error } = formValues;
  if (error) return;

  setState({ isSubmitting: true });

  try {
    const payload = createPayloadFroUserProfile(formValues, userId);

    await DashboardUserAPI.updateDashboardUserProfile(payload);

    toaster(TOASTER_TYPE.SUCCESS, "User Profile Updated Successfully");
  } catch (error) {
    toaster(TOASTER_TYPE.ERROR, "Something Went Wrong");
  } finally {
    setState({ isSubmitting: false });
    onSubmitCb();
    onClose();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.SUBMIT]: handleSubmission,
};

export default ACTION_HANDLERS;
