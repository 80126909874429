import React, { useCallback, useEffect, useMemo } from "react";

import useFetchQuestionnaire from "../../../cortexSurvey-business/appServices/questionnaire/hooks/useFetchQuestionnaire";
import QuestionnaireReaders from "../../../cortexSurvey-business/appServices/questionnaire/readers/questionnaire";
import useFetchStructureType from "../../../cortexSurvey-business/appServices/structureType/hooks/useFetchStructureType";
import useFetchSurveyObjective from "../../../cortexSurvey-business/appServices/surveyObjective/hooks/useFetchSurveyObjective";
import useFetchSurveyorRank from "../../../cortexSurvey-business/appServices/surveyorRank/hooks/useFetchSurveyorRank";
import { isMicrozonationNotSynced } from "../../../cortexSurvey-business/appServices/microZonation/helpers/microZonationSync";
import useFetchMicroZonation from "../../../cortexSurvey-business/appServices/microZonation/hooks/useFetchMicroZonation";
import { getAssessmentGuidelineSurveyObjectiveMicroZonationOptionsFormMicroZonationData } from "../../../cortexSurvey-business/appServices/microZonation/helpers/assesmentGuidelineSurveyObjectiveMicroZonationOptions";
import { getStructureTypeOptions } from "../../../cortexSurvey-business/appServices/structureType/helpers/structureTypeOptions";
import { getSurveyorRankOptions } from "../../../cortexSurvey-business/appServices/surveyorRank/helpers/getSurveyorRankOptions";

import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import QuestionnaireActiveModal from "./components/questionnaireActiveModal/QuestionnaireActiveModal";
import QuestionnaireCreateForm from "./components/questionnaireCreateForm/QuestionnaireCreateForm";
import QuestionnaireDeleteModal from "./components/questionnaireDeleteModal/QuestionnaireDeleteModal";
import QuestionnaireEditForm from "./components/questionnaireEditForm";

import Questionnaire from "./Questionnaire";
import QuestionaireScoreForm from "./components/questionaireScoreForm/QuestionaireScoreForm";
import { getScoreDataFormMicroZonationData } from "./helpers/questionnaire.score";

import scoreMockData from "./score.json";
import { getQuestionnaireOptions } from "./helpers/questionnaire.options";

const QuestionnaireContainer = () => {
  const scoreData = scoreMockData;

  const { data: surveyObjective, fetchSurveyObjective } =
    useFetchSurveyObjective();

  const { data: surveyorRank, fetchSurveyorRank } = useFetchSurveyorRank();
  const { data: structureType, fetchStructureType } = useFetchStructureType();
  const { data: microzonationData, fetchMicroZonation } =
    useFetchMicroZonation();
  const {
    data: microzonationAllData,
    fetchMicroZonation: fetchAllMicroZonation,
  } = useFetchMicroZonation(false);

  const { loading, data, fetchQuestionnaire } = useFetchQuestionnaire();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedScoreData,
    isDrawerVisible: isScoreVisible,
    showDrawer: showScoreForm,
    closeDrawer: closeScoreForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isQuestionnaieDeleteModalVisible,
    showModal: showQuestionnaieDeleteModal,
    closeModal: closeQuestionnaireDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isQuestionnaieActiveModalVisible,
    showModal: showQuestionnaieActiveModal,
    closeModal: closeQuestionnaireActiveModal,
  } = useModalVisibility({});

  const isQuestionnaireNotSync = useMemo(
    () => isMicrozonationNotSynced(microzonationData, surveyObjective),
    [microzonationData, surveyObjective]
  );

  const {
    surveyObjectiveOptions,
    assessmentGuidelineOptions,
    structureTypeOptions,
    surveyorRankOptions,
  } = useMemo(
    () =>
      getQuestionnaireOptions({
        microzonationData,
        surveyObjective,
        structureType,
        surveyorRank,
        selectedEditData,
      }),
    [
      microzonationData,
      surveyObjective,
      structureType,
      surveyorRank,
      selectedEditData,
    ]
  );

  const formAdditionalConfig = useMemo(
    () => ({
      surveyObjectiveOptions,
      assessmentGuidelineOptions,
      structureTypeOptions,
      surveyorRankOptions,
    }),
    [
      surveyObjectiveOptions,
      assessmentGuidelineOptions,
      structureTypeOptions,
      surveyorRankOptions,
    ]
  );

  const handleReload = useCallback(() => {
    fetchSurveyObjective();
    fetchSurveyorRank();
    fetchStructureType();
    fetchMicroZonation();
    fetchQuestionnaire();
  }, [
    fetchMicroZonation,
    fetchQuestionnaire,
    fetchStructureType,
    fetchSurveyObjective,
    fetchSurveyorRank,
  ]);

  const handleScoreForm = useCallback(
    (rowData) => {
      const { assessmentGuidelines, surveyObjectives } = rowData;

      const scoreFormData = getScoreDataFormMicroZonationData(
        microzonationAllData,
        scoreData,
        assessmentGuidelines,
        surveyObjectives
      );
      showScoreForm(scoreFormData);
    },
    [showScoreForm, microzonationAllData, scoreData]
  );

  useEffect(() => {
    fetchAllMicroZonation(2);
  }, [fetchAllMicroZonation]);

  return (
    <div>
      <Questionnaire
        loading={loading}
        data={data}
        onReload={handleReload}
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showScoreForm={handleScoreForm}
        showDeleteModal={showQuestionnaieDeleteModal}
        showActiveModal={showQuestionnaieActiveModal}
        isQuestionnaireNotSync={isQuestionnaireNotSync}
        fetchQuestionnaire={fetchQuestionnaire}
      />

      <QuestionnaireCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        additional={formAdditionalConfig}
        onSubmitCb={fetchQuestionnaire}
      />

      <QuestionnaireEditForm
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        formValues={selectedEditData}
        additional={formAdditionalConfig}
        onSubmitCb={fetchQuestionnaire}
        questionId={QuestionnaireReaders.questionId(selectedEditData)}
      />

      <QuestionnaireDeleteModal
        isVisible={isQuestionnaieDeleteModalVisible}
        onCancel={closeQuestionnaireDeletionModal}
        onSubmitCb={fetchQuestionnaire}
        questionId={QuestionnaireReaders.questionId(selectedDeleteData)}
        question={QuestionnaireReaders.question(selectedDeleteData)}
      />

      <QuestionnaireActiveModal
        isVisible={isQuestionnaieActiveModalVisible}
        onCancel={closeQuestionnaireActiveModal}
        onSubmitCb={fetchQuestionnaire}
        questionId={QuestionnaireReaders.questionId(selectedActiveData)}
        question={QuestionnaireReaders.question(selectedActiveData)}
      />

      <QuestionaireScoreForm
        isVisible={isScoreVisible}
        onCancel={closeScoreForm}
        onSubmitCb={fetchQuestionnaire}
        scoreData={selectedScoreData}
      />
    </div>
  );
};

export default QuestionnaireContainer;
