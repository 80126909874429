import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';

export const getUpdatedValues = (values, value) => {
    const clonedValues = _cloneDeep(values);
    return _map(clonedValues, (assessment) => {
        if (assessment.assessmentGuidelineId === value.assessmentGuidelineId) {
            return value;
        }
        return assessment;
    });
};
