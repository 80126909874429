import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo } from "react";

import Table from "../../../../../cortexSurvey-components/table";
import StateReaders from "../../../../../cortexSurvey-business/appServices/state/readers/state";

import COLUMNS from "./constants/stateOverview.columns";

import {
  INACTIVE_ROW_ACTIONS,
} from "./constants/stateOverview.rowActions";
import { SEARCH_KEYS } from "./constants/stateOverview.table";

import { getRowActions } from "./helpers/stateOverview.rowActions";

const StateOverview = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchData = _noop,
  } = props;

  return (
    <Table
      dataSource={data}
      rowKey={StateReaders.stateId}
      columns={COLUMNS}
      rowActions={getRowActions}
      onAction={onAction}
      loading={loading}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

StateOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
};

export default memo(StateOverview);
