import React, {memo} from "react";
import cx from "classnames";
import { Button as ButtonField } from "antd";
import PropTypes from "prop-types";
import { TYPES } from "./constants/button.type";
import styles from "./button.module.scss";

const Button = React.memo((props) => {
  const {
    children,
    fullWidth = false,
    type = TYPES.SECONDARY,
    className,
    ...restProps
  } = props;

  return (
    <ButtonField
      {...restProps}
      block={fullWidth}
      className={cx(styles[type], className)}
    >
      {children}
    </ButtonField>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default memo(Button);
