import _property from "lodash/property";
import { FIELD_IDS } from "../constants/cityForm.fieldId";

const countryId = _property(FIELD_IDS.COUNTRY);
const stateId = _property(FIELD_IDS.STATE);
const city = _property(FIELD_IDS.CITY);
const soil = _property(FIELD_IDS.SOIL);

const READERS = {
  countryId,
  stateId,
  city,
  soil,
};

export default READERS;
