import { FIELD_IDS } from "../constants/cityIndexMappingForm.fieldId";
import { getSoilFromCity } from "../../../../../helpers/cortexSurveySetup.getCityOptions";

const getSoilValue = (cityData) => (cityId) =>
  getSoilFromCity(cityData, cityId);

export const getFieldValueMapper = (cityData) => {
  return {
    [FIELD_IDS.CITY]: {
      [FIELD_IDS.SOIL]: getSoilValue(cityData),
    },
    [FIELD_IDS.COUNTRY]: {
      [FIELD_IDS.STATE]: undefined,
      [FIELD_IDS.CITY]: undefined,
      [FIELD_IDS.SOIL]: undefined,
    },
    [FIELD_IDS.STATE]: {
      [FIELD_IDS.CITY]: undefined,
      [FIELD_IDS.SOIL]: undefined,
    },
    [FIELD_IDS.ASSESSMENT_GUIDELINE]: {
      [FIELD_IDS.SURVEY_OBJECTIVE]: undefined,
      [FIELD_IDS.MICROZONATION]: undefined,
    },
    [FIELD_IDS.SURVEY_OBJECTIVE]: {
      [FIELD_IDS.MICROZONATION]: undefined,
    },
  };
};
