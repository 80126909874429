import _property from "lodash/property";

import StructureTypeDataIndex from "../constants/dataIndex/structureType";

const id = _property(StructureTypeDataIndex.id);
const structureType = _property(StructureTypeDataIndex.structureName);
const structureName = _property(StructureTypeDataIndex.structureName);
const structureDetails = _property(StructureTypeDataIndex.structureDetails);
const structureImages = _property(StructureTypeDataIndex.structureImages);
const structureVideo = _property(StructureTypeDataIndex.structureVideo);
const structureImage = _property(StructureTypeDataIndex.structureImage);
const structureComponents = _property(
  StructureTypeDataIndex.structureComponents
);
const structureProblem = _property(StructureTypeDataIndex.structureProblem);

const READERS = {
  structureType,
  id,
  structureName,
  structureDetails,
  structureImages,
  structureVideo,
  structureImage,
  structureComponents,
  structureProblem,
};

export default READERS;
