import _map from "lodash/map";
import _filter from "lodash/filter";
import _includes from "lodash/includes";

import AssessmentGuidelineReaders from "../readers/assessmentGuideline";

const createAssessmentGuidelineOption = (option) => {
  const assessmentGuideline =
    AssessmentGuidelineReaders.assessmentGuideline(option);
  const assesmentGuidelineId =
    AssessmentGuidelineReaders.assessmentGuidelineId(option);

  return {
    label: assessmentGuideline,
    value: assesmentGuidelineId,
  };
};

const isFilteredAssesmentGuideline =
  (assessmentGuidelineIds) => (assessmentGuidelineOption) => {
    const { value } = assessmentGuidelineOption;

    return !_includes(assessmentGuidelineIds, value);
  };

export const getAssessmentGuidelineOptions = (assessmentGuidelineData) => {
  const AssesmentGuidelineOptions = _map(
    assessmentGuidelineData,
    createAssessmentGuidelineOption
  );
  return AssesmentGuidelineOptions;
};

export const getFilteredAssesmentGuidelineOptions = (
  assessmentGuidelineData,
  assessmentGuidelineIds
) => {
  const assesmentGuidelineOptions =
    getAssessmentGuidelineOptions(assessmentGuidelineData);
  const filteredAssessmentGuidelineOptions = _filter(
    assesmentGuidelineOptions,
    isFilteredAssesmentGuideline(assessmentGuidelineIds)
  );
  return filteredAssessmentGuidelineOptions;
};
