import { FIELD_IDS } from "./dashboardUserProfileForm.fieldId";

export const SECTIONS = [
  {
    label: "User Profile",
    rows: [
      { columns: [FIELD_IDS.FIRST_NAME] },
      { columns: [FIELD_IDS.LAST_NAME] },
      { columns: [FIELD_IDS.EMAIL] },
      { columns: [FIELD_IDS.PHONE_NUMBER] },
      { columns: [FIELD_IDS.ROLE] },
    ],
  },
  {
    label: "Click Here To Logout From Application",
    rows: [{ columns: [FIELD_IDS.LOGOUT] }],
  },
];
