import queryString from "query-string";

export const loginRedirectionOnSubmission = (location, navigate) => {
  const { search } = location || {};
  const parsed = queryString.parse(search) || {};
  const { redirect } = parsed;

  if (redirect) {
    navigate(redirect);
  } else {
    navigate("/");
  }
};
