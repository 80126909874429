import { DELETE, ROW_ACTIONS } from "../constants/cityOverview.rowActions";

import CityReaders from '../../../../../../cortexSurvey-business/appServices/city/readers/city'

export const getRowActions = (rowData) => {
    const country = CityReaders.country(rowData);
   const state = CityReaders.state(rowData);
 
 const { isActive: isCountryActive } = country || {};
 
 const {isActive: isStateActive } = state || {};
 
 if(isCountryActive && isStateActive) return ROW_ACTIONS;
 
 return [DELETE];
 
 }