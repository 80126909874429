import DashbaoardUsersReaders from "../../../../../../cortexSurvey-business/appServices/dashboardUser/readers/user";

import { FIELD_IDS } from "../../dashboardUsersForm/constants/dashboardUsersForm.fieldIds";

export const makeInitialValues = (formData) => {
  const firstName=DashbaoardUsersReaders.firstName(formData);
  const lastName=DashbaoardUsersReaders.lastName(formData);
  const email=DashbaoardUsersReaders.email(formData);
  const role=DashbaoardUsersReaders.roleId(formData);
  return {
    [FIELD_IDS.FIRST_NAME]:firstName,
    [FIELD_IDS.LAST_NAME]:lastName,
    [FIELD_IDS.EMAIL]:email,
    [FIELD_IDS.ROLE]:role
  };
};