import { FIELD_IDS } from "./cityForm.fieldId";

export const SECTIONS = [
  {
    rows: [
      { columns: [FIELD_IDS.COUNTRY] },
      { columns: [FIELD_IDS.STATE] },
      { columns: [FIELD_IDS.CITY] },
      { columns: [FIELD_IDS.SOIL] },
    ],
  },
];
