import React, { useCallback, useState, useEffect } from "react";
import _map from "lodash/map";
import _get from "lodash/get";
import cx from "classnames";
import MicroZonationMultiInput from "./components/microZonationMultiInput";
import SurveyObjectiveReaders from "../../../../../cortexSurvey-business/appServices/surveyObjective/readers/surveyObjective";

import styles from "./microZonationInput.module.scss";
import Label from "../../../../../cortexSurvey-components/label";
import { isMobile } from "react-device-detect";

const MicroZonationInput = (props) => {
  const { surveyObjective, onChange, value } = props;

  const [state, setState] = useState(value);

  const handleChange = useCallback(
    (id, value) => {
      setState((prev) => ({ ...prev, [id]: value }));
    },
    [setState]
  );

  useEffect(() => {
    onChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const renderMicroZonationRenderInput = useCallback(
    (surveyObjectiveData) => {
      const data = SurveyObjectiveReaders.surveyObjective(surveyObjectiveData);
      const id = SurveyObjectiveReaders.surveyObjectiveId(surveyObjectiveData);
      const surveyObjectiveValue = _get(state, id, []);
      return (
        <MicroZonationMultiInput
          surveyObjective={data}
          surveyObjectiveId={id}
          microZonationValue={surveyObjectiveValue}
          onChange={handleChange}
        />
      );
    },
    [state, handleChange]
  );

  return (
    <div>
      <Label className={styles.title} required={true}>
        MicroZonation
      </Label>
      <div
        className={cx(styles.multiContainer, {
          [styles.mobileMultiContainer]: isMobile,
        })}
      >
        {_map(surveyObjective, renderMicroZonationRenderInput)}
      </div>
    </div>
  );
};

export default MicroZonationInput;
