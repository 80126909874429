import {ACTION_TYPES} from '../../../constants/notification.actionTypes';

import {
    EditOutlined,
    DeleteOutlined,
    ExportOutlined,
  } from "@ant-design/icons";

  const EDIT = {
    id: ACTION_TYPES.EDIT,
    label: "Edit Notification",
    icon: <EditOutlined />,
  };

  const DELETE = {
    id: ACTION_TYPES.DELETE,
    label: "DeActivate Notification",
    icon: <DeleteOutlined />,
  };
  
  const ACTIVATE = {
    id: ACTION_TYPES.ACTIVE,
    label: "Activate Notification",
    icon: <ExportOutlined />,
  };

  export const ROW_ACTIONS = [
    EDIT,
    DELETE,
  ];

  export const INACTIVE_ROW_ACTIONS = [ACTIVATE];