import _property from 'lodash/property';

import ImageProcessingDataIndex from '../constants/dataIndex/imageProcessing';

const audio = _property(ImageProcessingDataIndex.audio);
const capturedWithCameraType = _property(ImageProcessingDataIndex.capturedWithCameraType);
const capturingDistance = _property(ImageProcessingDataIndex.capturingDistance);
const createdBy = _property(ImageProcessingDataIndex.createdBy);
const distressPattern = _property(ImageProcessingDataIndex.distressPattern);
const imageProcessingId = _property(ImageProcessingDataIndex.imageProcessingId);
const isActive = _property(ImageProcessingDataIndex.isActive);
const itemCode = _property(ImageProcessingDataIndex.itemCode);
const itemSearchCode = _property(ImageProcessingDataIndex.itemSearchCode);
const lightAndWeatherCondition = _property(ImageProcessingDataIndex.lightAndWeatherCondition);
const numericalScore = _property(ImageProcessingDataIndex.numericalScore);
const referenceImages = _property(ImageProcessingDataIndex.referenceImages);
const scoreReference = _property(ImageProcessingDataIndex.scoreReference);
const updatedBy = _property(ImageProcessingDataIndex.updatedBy);
const vulnerabilityScale = _property(ImageProcessingDataIndex.vulnerabilityScale);

const READERS = {
  audio,
  capturedWithCameraType,
  capturingDistance,
  createdBy,
  distressPattern,
  imageProcessingId,
  isActive,
  itemCode,
  itemSearchCode,
  lightAndWeatherCondition,
  numericalScore,
  referenceImages,
  scoreReference,
  updatedBy,
  vulnerabilityScale,
};

export default READERS;
