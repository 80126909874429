import { useCallback, useState, useEffect } from "react";

import _isEmpty from "lodash/isEmpty";

const useFormState = (initialValues) => {
  const [formState, setFormState] = useState(initialValues);

  const handleFormChange = useCallback(
    (id, value) => {
      setFormState((prev) => ({ ...prev, [id]: value }));
    },
    [setFormState]
  );

  const resetForm = useCallback(() => {
    setFormState(null);
  }, [setFormState]);

  useEffect(() => {
    if (!_isEmpty(initialValues)) {
      setFormState(initialValues);
    }
  }, [setFormState, initialValues]);

  return [formState, handleFormChange, resetForm];
};

export default useFormState;
