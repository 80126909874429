import React from "react";

import _map from "lodash/map";

import AssessmentScoreTable from "../components/assessmentScoreTable";

const geUpdatedSurveyObjectiveRows = (
  surveyObjectiveRows,
  updatedSurveyObjectiveRow
) => {
  return _map(surveyObjectiveRows, (surveyObjective) => {
    if (
      surveyObjective.surveyObjectiveId ===
      updatedSurveyObjectiveRow.surveyObjectiveId
    )
      return updatedSurveyObjectiveRow;
    return surveyObjective;
  });
};

export const renderAssesmentScoreTable = (onChange) => (scoreData) => {
  const { assessmentGuideline, assessmentGuidelineId, surveyObjectiveRows } =
    scoreData;

  const handleAssesmentChange = (updatedSurveyObjectiveRow) => {
    const updatedValue = geUpdatedSurveyObjectiveRows(
      surveyObjectiveRows,
      updatedSurveyObjectiveRow
    );
    onChange({ ...scoreData, surveyObjectiveRows: updatedValue });
  };

  return (
    <AssessmentScoreTable
      assessmentGuideline={assessmentGuideline}
      key={assessmentGuidelineId}
      surveyObjectiveRows={surveyObjectiveRows}
      onChange={handleAssesmentChange}
    />
  );
};
