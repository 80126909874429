import _property from "lodash/property";

import { FIELD_IDS } from "../constants/loginForm.fieldIds";

const email = _property(FIELD_IDS.LOGIN_EMAIL);
const password = _property(FIELD_IDS.LOGIN_PASSWORD);

const reader = {
  email,
  password,
};

export default reader;
