import DashboardUserFormReaders from "../../dashboardUsersForm/readers";

export const getPayloadFromFormValues = (formValues, userId) => {
  const firstName = DashboardUserFormReaders.firstName(formValues);
  const lastName = DashboardUserFormReaders.lastName(formValues);
  const email = DashboardUserFormReaders.email(formValues);
  const role = DashboardUserFormReaders.role(formValues);

  return {
    userId,
    firstName,
    lastName,
    email,
    role,
  };
};
