import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import NotificationAPI from "../../../../../../cortexSurvey-business/appServices/notification/services/notification";

import { ACTION_TYPES } from "../constants/notificationEditForm.actionTypes";
import { getPayloadFromFormValues } from "./notificationEditForm.payload";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { noticeId, onClose, onSubmitCb } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (error) return;
  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues, noticeId);
    await NotificationAPI.createNotice(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Notification Edited Successfully");
  } catch (err) {
    toaster(
      TOASTER_TYPE.ERROR,
      "Failed to Edit Notification. Please try again."
    );
  } finally {
    setState({ isSubmitting: false });
    onClose();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
