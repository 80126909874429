import React from "react";

import { components } from "react-select";

import { getFormatLabel } from "../../config/helper/select.label";

const { MultiValueContainer } = components;

const MultiValue = (props) => {
  const { data, selectProps } = props;

  const { value } = selectProps || {};
  const label = getFormatLabel(data, value);

  return <MultiValueContainer>{label}</MultiValueContainer>;
};

export default MultiValue;
