import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import _noop from "lodash/noop";

import StructureTypeAPI from "../../../../../../cortexSurvey-business/appServices/structureType/services/structureType";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

import { ACTION_TYPES } from "../constants/structureTypeCreateForm.actionTypes";
import { getPayloadFromFormValues } from "./structureTypeCreateForm.payload";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../constants/structureTypeCreateForm.general";

const handleCreateFormSubmission = async ({ getState, params, setState }) => {
  const { onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;

  setState({ error });

  if (error) return;

  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues);
    await StructureTypeAPI.createStructureType(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS,SUCCESS_MESSAGE);
    onClose();
  } catch (err) {
    errorHandler(
      err,
      setState,
     ERROR_MESSAGE
    );
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE_FORM_SUBMISSION]: handleCreateFormSubmission,
};

export default ACTION_HANDLERS;
