import _property from "lodash/property";

import { FIELD_IDS } from "../constants/assesmentGuidelineForm.fieldId";

const assessmentGuideline = _property(FIELD_IDS.ASSESSMENT_GUIDELINE);

const READERS = {
  assessmentGuideline,
};

export default READERS;
