import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";

import { SECTIONS } from "./constants/microZonationForm.sections";
import { getFormConfig } from "./helpers/microZonationFrom.formConfig";

const MicroZonationForm = (props) => {
  const {
    initialValues = {},
    onSubmit = _noop,
    formId,
    view = false,
    assesmentGuidelineOptions=[],
    disabled,
    surveyObjective,
    microZonationData,
  } = props;

  const formConfig = useCallback(
    () =>
      getFormConfig({
        assesmentGuidelineOptions,
        disabled,
        surveyObjective,
        microZonationData,
      }),
    [assesmentGuidelineOptions, disabled, surveyObjective, microZonationData]
  );

  return (
    <FormWithSubmission
      formConfig={formConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={view}
    />
  );
};

MicroZonationForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  assesmentGuidelineOptions: PropTypes.array,
  disabled: PropTypes.bool,
  surveyObjective: PropTypes.array,
};

MicroZonationForm.defaultProps = {
  initialValues: {},
  onSubmit: _noop,
  formId: undefined,
  assesmentGuidelineOptions: [],
  disabled: false,
  surveyObjective: [],
};

export default memo(MicroZonationForm);
