import React, { memo } from "react";

import Table from "../../../../../cortexSurvey-components/table";

import { COLUMNS } from "./constants/usersOverview.columns";
import { SEARCH_KEYS } from "./constants/usersOverview.table";

const UsersOverview = (props) => {
 
const {loading, data} = props;
  return (
    <Table
      dataSource={data}
      columns={COLUMNS}
      loading={loading}
      searchKeys={SEARCH_KEYS}
    />
  );
};

export default memo(UsersOverview);
