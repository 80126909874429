import ForgotPasswordFormReader from "../components/forgotPassword/readers";

export const getPayloadFromFormValues = (formValue) => {
  const email = ForgotPasswordFormReader.email(formValue);

  return {
    email,
  };
};

