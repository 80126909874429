import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo } from "react";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { SECTIONS } from "./constants/surveyObjectiveForm.sections";
import { getFormConfig } from "./helpers/surveyObjectiveForm.formConfig";

const SurveyObjectiveForm = (props) => {
  const { initialValues={}, onSubmit=_noop, formId, disabled=false } = props;

  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formId={formId}
      view={disabled}
    />
  );
};

SurveyObjectiveForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(SurveyObjectiveForm);
