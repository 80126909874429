import _map from "lodash/map";

import SurveyObjectiveReaders from "../readers/surveyObjective";

const createSurveyObjectiveOption = (option) => {
  const surveyObjective = SurveyObjectiveReaders.surveyObjective(option);

  const id = SurveyObjectiveReaders.surveyObjectiveId(option);

  return {
    label: surveyObjective,
    value: id,
  };
};

export const getSurveyObjectiveOptions = (surveyObjectiveData) => {
  const surveyObjectiveOptions = _map(
    surveyObjectiveData,
    createSurveyObjectiveOption
  );
  return surveyObjectiveOptions;
};
