import _map from "lodash/map";
import StateReader from "../../cortexSurvey-business/appServices/state/readers/state";
import { getFilteredStateWithCounty } from "../../cortexSurvey-business/appServices/state/helpers/state";

const createSurveySetupStateOption = (option) => {
  const State = StateReader.state(option);
  const stateId = StateReader.stateId(option);

  return {
    label: State,
    value: stateId,
  };
};

export const getSurveySetupStateOptions = (StateData, countryId) => {
  const filteredStateData = getFilteredStateWithCounty(StateData, countryId);
  return _map(filteredStateData, createSurveySetupStateOption);
};
