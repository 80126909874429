const url = "media";
const type = "type";
const title = "title";
const noticeId='noticeId'

const DATA_INDEX = {
  url,
  type,
  title,
  noticeId
};

export default DATA_INDEX;
