import { FIELD_IDS } from "./structureHelpForm.fieldId";
import TextAreaFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/TextAreaFieldRenderer";
import VideoUpload from "../../../../../../cortexSurvey-components/videoUpload";
import ImageUpload from "../../../../../../cortexSurvey-components/imageUpload/ImageUpload";

import styles from "../structureHelpFom.module.scss";

export const STRUCTURE_DESCRIPTION = {
  id: FIELD_IDS.STRUCTURE_DESCRIPTION,
  renderer: TextAreaFieldRenderer,
  renderOptions: {
    label: "Structure Description",
    required: true,
    autoSize: {
      minRows: 5,
      maxRows: 5,
    },
  },
};

export const STRUCTURE_IMAGES = {
  id: FIELD_IDS.STRUCTURE_IMAGES,
  renderer: ImageUpload,
  renderOptions: {
    label: "Structure Images",
    required: true,
    isMulti: true,
    tableClassName: styles.structureImageTableClassName,
  },
};

export const STRUCTURE_VIDEO = {
  id: FIELD_IDS.STRUCTURE_VIDEO,
  renderer: VideoUpload,
  renderOptions: {
    label: "Structure Video",
  },
};
