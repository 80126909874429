import React, { memo, useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import FormWithSubmissionV2 from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import { ACTION_TYPES } from "./constants/structureProblemForm.actionTypes";
import { FORM_CONFIG } from "./constants/structureProblemForm.formConfig";
import { FORM_ID } from "./constants/structureProblemForm.formId";
import { INITIAL_STATE } from "./constants/structureProblemForm.initialState";
import { SECTIONS } from "./constants/structureProblemForm.section";
import ACTION_HANDLERS from "./helpers/structureProblemForm.actionHandlers";
import { makeInitialValues } from "./helpers/structureProblemForm.initialValues";

const StructureProblemForm = (props) => {
  const {
    isVisible,
    onClose,
    formValues,
    view,
    onAction,
    isSubmitting,
    error = false,
  } = props;

  const formConfig = useCallback(() => FORM_CONFIG, []);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const initialValues = useMemo(
    () => makeInitialValues(formValues),
    [formValues]
  );

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.STRUCTURE_PROBLEM_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      title={view ? "Structure Problem" : "Edit Structure Problem"}
      maskClosable={false}
      onClose={onClose}
      width="120rem"
      destroyOnClose
      hideFooter={view}
      onSubmit={handleSave}
      loading={isSubmitting}
      error={error}
    >
      <FormWithSubmissionV2
        formId={FORM_ID}
        formConfig={formConfig}
        sections={SECTIONS}
        initialValues={initialValues}
        view={view || isSubmitting}
        onSubmit={handleFormSubmission}
      />
    </Drawer>
  );
};

StructureProblemForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};

StructureProblemForm.defaultProps = {
  isVisible: false,
  onClose: _noop,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(StructureProblemForm);
