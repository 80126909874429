import React, { memo } from "react";
import PropTypes from "prop-types";

import MicroZonationReaders from "../../../../../../../cortexSurvey-business/appServices/microZonation/readers/microzonation";
import Tag from "../../../../../../../cortexSurvey-components/tag";

import styles from "./assessmentGuidelineColumn.module.scss";

const AssessmentGuidelineColumn = (props) => {
  const { rowData = {} } = props;

  const AssesmentGuideline = MicroZonationReaders.assessmentGuideline(rowData);
  const AssesmentGuidelineStatus =
    MicroZonationReaders.assesmentGuidelineActiveStatus(rowData);

  if (AssesmentGuidelineStatus)
    return (
      <div className={styles.container}>
        <div>{AssesmentGuideline}</div>
        <Tag color="success">Active</Tag>
      </div>
    );

  return (
    <div className={styles.container}>
      <div>{AssesmentGuideline}</div>
      <Tag color="error">InActive</Tag>
    </div>
  );
};

AssessmentGuidelineColumn.propTypes = {
  rowData: PropTypes.object,
};

export default memo(AssessmentGuidelineColumn);
