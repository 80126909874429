import { useLocation, useNavigate } from "react-router-dom";

const useLogout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    const { pathname, search } = location;
    const redirectUrl = `/auth/login?redirect=${pathname + search}`;
    localStorage.removeItem("AUTH_TOKEN");
    navigate(redirectUrl);
  };

  return logout;
};

export default useLogout;
