import React, { memo } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";

import Button, {
  TYPES,
} from "../../../../../../../cortexSurvey-components/button";
import Drawer, {
  useDrawerVisibility,
} from "../../../../../../../cortexSurvey-components/drawer";
import Table from "../../../../../../../cortexSurvey-components/table/Table";

import { COLUMN_CONFIG } from "./constants/showHelpColumn.columnConfig";

const ShowHelpComponent = (props) => {
  const { data } = props;

  const { isDrawerVisible, showDrawer, closeDrawer } = useDrawerVisibility();

  if (_isEmpty(data)) return <div>Not Added</div>;

  return (
    <div>
      <Button type={TYPES.SECONDARY} onClick={showDrawer}>
        Show Help
      </Button>
      <Drawer
        open={isDrawerVisible}
        onClose={closeDrawer}
        title="Help"
        maskClosable={false}
        width="1000px"
        destroyOnClose
      >
        <Table
          dataSource={data}
          columns={COLUMN_CONFIG}
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0,
                }}
              >
                {record.help}
              </p>
            ),
          }}
        />
      </Drawer>
    </div>
  );
};

export default ShowHelpComponent;
