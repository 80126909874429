const createdBy = "createdBy";
const email = "email";
const firstName = "firstName";
const lastName = "lastName";
const image = "image";
const phone = "phone";
const role = "role";
const roleId = "roleId";
const status = "status";
const updatedBy = "updatedBy";
const userId = "userId";

const DATA_INDEX = {
  createdBy,
  email,
  firstName,
  lastName,
  image,
  phone,
  role,
  roleId,
  status,
  updatedBy,
  userId,
};

export default DATA_INDEX;
