import React from "react";

import _map from "lodash/map";
import { renderSurveyObjectiveScoreTable } from "./helpers/assessmentScoreTable.components";

const AssessmentScoreTable = (props) => {
  const { assessmentGuideline, surveyObjectiveRows, onChange } = props;
  
  return (
    <div>
      <div>{assessmentGuideline}</div>
      <div>
        {_map(surveyObjectiveRows, renderSurveyObjectiveScoreTable(onChange))}
      </div>
    </div>
  );
};

export default AssessmentScoreTable;
