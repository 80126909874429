import { FIELD_IDS } from "../../notificationForm/constants/notificationForm.fieldId";

import NotificationReaders from "../../../../../../cortexSurvey-business/appServices/notification/readers/notofication";

const getNotification = (type, formValues) => {
  if (type === "image")
    return {
      [FIELD_IDS.NOTIFICATION_IMAGE]: NotificationReaders.url(formValues),
    };
  return {
    [FIELD_IDS.NOTIFICATION_VIDEO]: NotificationReaders.url(formValues),
  };
};

export const makeInitialValues = (formValues) => {
  return {
    [FIELD_IDS.NOTIFICATION_TYPE]: NotificationReaders.type(formValues),
    [FIELD_IDS.NOTIFICATION_TITLE]: NotificationReaders.title(formValues),
    ...getNotification(NotificationReaders.type(formValues), formValues),
  };
};
