import forge from "node-forge";

const publicKey = process.env.REACT_APP_PUBLIC_KEY ? process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/g, '\n') : null;
if (!publicKey) {
  console.error('Public key is not defined.');
}

const encryptWithPublicKey = (publicKeyPem, data) => {
  console.log(publicKeyPem, data);
  
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  
  const encrypted = publicKey.encrypt(forge.util.encodeUtf8(data), "RSA-OAEP", {
    md: forge.md.sha1.create(), 
    mgf1: {
      md: forge.md.sha1.create(), 
    },
  });

  return forge.util.encode64(encrypted);
};

export const createAuthToken = (params) => {
  const { email } = params || {};
  const data = JSON.stringify({
    email,
    timestamp: Date.now(),
  });
  const encryptedData = encryptWithPublicKey(publicKey, data);
  return encryptedData;
};

