import _map from "lodash/map";
import _find from "lodash/find";
import _head from "lodash/head";
import _compact from "lodash/compact";
import _isEmpty from "lodash/isEmpty";

const getComponent = (value) => {
  const { component } = value;
  return component;
};

const isDuplicateComponent = (component, componentIndex) => (value, index) => {
  if (index === componentIndex) return false;
  if (component === value) return true;
  return false;
};

export const isComponentEmpty = (components) => (value, index) => {
  const component = getComponent(value);

  const duplicateComponent = _find(
    components,
    isDuplicateComponent(component, index)
  );

  if (duplicateComponent)
    return `Duplicate Component ${duplicateComponent} detected please remove`;

  if (!component) return `Please Fill Out Empty Inputs or Remove It`;

  return false;
};

export const strcutureComponentValiations = (value) => {
  const components = _map(value, getComponent);
  const componentErrors = _compact(_map(value, isComponentEmpty(components)));

  return {
    value: _isEmpty(componentErrors),
    message: _head(componentErrors),
  };
};
