import _property from "lodash/property";

import assessmentGuidelineDataIndex from "../constants/dataIndex/assessmentGuideline";

const assessmentGuidelineId = _property(
  assessmentGuidelineDataIndex.assessmentGuidelineId
);

const assessmentGuideline = _property(
  assessmentGuidelineDataIndex.assessmentGuideline
);

const READERS = { assessmentGuidelineId, assessmentGuideline };

export default READERS;
