export const FILTER_VALUES = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
  PENDING: "Pending",
  ALL: "ALL",
};

export const FILTER_OPTIONS = [
  { label: "Active", value: FILTER_VALUES.ACTIVE },
  { label: "InActive", value: FILTER_VALUES.INACTIVE },
  { label: "Pending", value: FILTER_VALUES.PENDING },
  { label: "All", value: FILTER_VALUES.ALL },
];
