import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../contants/imageProcessingDeleteModal.actionTypes";

import ImageProcessingAPI from "../../../../../../cortexSurvey-business/appServices/imageProcessing/services/imageProcessing";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { imageProcessingId, onCancel, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    await ImageProcessingAPI.deleteImageProcessing({
      imageProcessingId,
      isActive: 0,
    });
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Processed Image Data Deleted Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to Delete Processed Image Data");
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
