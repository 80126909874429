import _noop from "lodash/noop";
import propTypes from "prop-types";
import React, { memo } from "react";
import { compose } from "recompose";
import withActions from "../../../cortexSurvey-base/hocs/withActions";
import { ACTION_TYPES } from "./constants/surveyorRank.actionTypes";
import {
  HEADER_TITLE,
  CREATE_BUTTON_LABEL,
} from "./constants/surveyorRank.general";
import SurveyorRankOverview from "./components/surveyorRankOverview";
import ACTION_HANDLERS from "./helpers/surveyorRank.actionHandlers";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable";

const SurveyorRank = (props) => {
  const {
    loading = false,
    data = [],
    onAction = _noop,
    fetchSurveyorRank = _noop,
  } = props;
  return (
    <HeaderWithTable
      data={data}
      loading={loading}
      title={HEADER_TITLE}
      onAction={onAction}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      overviewComponent={SurveyorRankOverview}
      onReload={fetchSurveyorRank}
      fetchData={fetchSurveyorRank}
      showReload
    />
  );
};

SurveyorRank.propTypes = {
  onAction: propTypes.func,
  data: propTypes.array,
  loading: propTypes.bool,
};

export default compose(memo, withActions({}, ACTION_HANDLERS))(SurveyorRank);
