import { useCallback, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

import { getErrorMessage } from "../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../cortexSurvey-components/notificationWrapper";
import useFetchPayload from "../../../../hooks/useFetchPayload";
import MicroZonationAPI from "../../services/microZonation";

const useFetchMicroZonation = (shouldFetch = true) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchMicroZonation = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await MicroZonationAPI.fetchMicroZonation(payload);
        setData(response?.data);
      } catch (err) {
        toaster(
          TOASTER_TYPE.ERROR,
          getErrorMessage("Failed to fetch MicroZonation Data")
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchMicroZonation = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchMicroZonation, 1000);
      debounceFunction(isActive);
    },
    [fetchMicroZonation, setLoading]
  );

  useEffect(() => {
    if (shouldFetch) {
      handleDebounceFetchMicroZonation();
    }
  }, [handleDebounceFetchMicroZonation, shouldFetch]);

  return {
    loading,
    data,
    fetchMicroZonation: handleDebounceFetchMicroZonation,
  };
};

export default useFetchMicroZonation;
