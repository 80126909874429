import React from "react";

import useFetchSurveyObjective from "../../../cortexSurvey-business/appServices/surveyObjective/hooks/useFetchSurveyObjective";
import SurveyObjectiveReaders from "../../../cortexSurvey-business/appServices/surveyObjective/readers/surveyObjective";
import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import SurveyObjectiveActiveModal from "./components/surveyObjectiveActiveModal";
import SurveyObjectiveCreateForm from "./components/surveyObjectiveCreateForm";
import SurveyObjectiveDeleteModal from "./components/surveyObjectiveDeleteModal";
import SurveyObjectiveEditForm from "./components/surveyObjectiveEditForm";

import SurveyObjective from "./SurveyObjective";

const SurveyObjectiveContainer = () => {
  const { loading, data, fetchSurveyObjective } = useFetchSurveyObjective();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility({});

  const {
    selectedData: selectedEditForm,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility({});

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isSurveyObjectDeletionModalVisible,
    showModal: showSurveyObjectiveDeletionModal,
    closeModal: closeSurveyObjectiveDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isSurveyObjectiveActiveModalVisible,
    showModal: showSurveyObjectiveActiveModal,
    closeModal: closeSurveyObjectiveActiveModal,
  } = useModalVisibility({});

  return (
    <div>
      <SurveyObjective
        data={data}
        loading={loading}
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showSurveyObjectiveDeletionModal}
        showActiveModal={showSurveyObjectiveActiveModal}
        fetchSurveyObjective={fetchSurveyObjective}
      />

      <SurveyObjectiveCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchSurveyObjective}
      />

      <SurveyObjectiveEditForm
        formData={selectedEditForm}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={fetchSurveyObjective}
        surveyObjectiveId={SurveyObjectiveReaders.surveyObjectiveId(
          selectedEditForm
        )}
      />

      <SurveyObjectiveDeleteModal
        isVisible={isSurveyObjectDeletionModalVisible}
        onCancel={closeSurveyObjectiveDeletionModal}
        onSubmitCb={fetchSurveyObjective}
        surveyObjectiveId={SurveyObjectiveReaders.surveyObjectiveId(
          selectedDeleteData
        )}
        surveyObjective={SurveyObjectiveReaders.surveyObjective(
          selectedDeleteData
        )}
      />

      <SurveyObjectiveActiveModal
        isVisible={isSurveyObjectiveActiveModalVisible}
        onCancel={closeSurveyObjectiveActiveModal}
        onSubmitCb={fetchSurveyObjective}
        surveyObjectiveId={SurveyObjectiveReaders.surveyObjectiveId(
          selectedActiveData
        )}
        surveyObjective={SurveyObjectiveReaders.surveyObjective(
          selectedActiveData
        )}
      />
    </div>
  );
};

export default SurveyObjectiveContainer;
