import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_DASHBOARD_USERS: "/user-data",
  CREATE_DASHBOARD_USERS: "/user-add",
  EDIT_DASHBOARD_USERS: "/user-update",
  DELETE_DASHBOARD_USERS: "/user-update",
  RESEND_VERIFICATION_LINK: "/user-link",
};

const fetchDashboardUser = (payload) =>
  HttpClient.post(API_PATHS.FETCH_DASHBOARD_USERS, payload);

const createDashboardUser = (payload) =>
  HttpClient.post(API_PATHS.CREATE_DASHBOARD_USERS, payload);

const editDashboardUser = (payload) =>
  HttpClient.post(API_PATHS.EDIT_DASHBOARD_USERS, payload);

const deleteDashboardUser = (payload) =>
  HttpClient.post(API_PATHS.DELETE_DASHBOARD_USERS, payload);

const resendVerificationLink = (payload) => {
  HttpClient.post(API_PATHS.RESEND_VERIFICATION_LINK, payload);
};

const DashboardUserAPI = {
  fetchDashboardUser,
  createDashboardUser,
  editDashboardUser,
  deleteDashboardUser,
  resendVerificationLink,
};

export default DashboardUserAPI;
