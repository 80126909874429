import { FIELD_IDS } from "./microZonationForm.fieldId";

export const SECTIONS = [
  {
    rows: [
      { columns: [FIELD_IDS.ASSESSMENT_GUIDELINE] },
      { columns: [FIELD_IDS.MICORZONATION] },
    ],
  },
];
