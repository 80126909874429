import {
  SERIAL_NO,
  ANSWER_PATTERN,
  ASSESSMENT_GUIDELINE,
  SURVEYOR_RANK,
  SURVEY_OBJECTIVE,
  QUESTION,
  QUESTION_SEGMENT,
  ANSWER,
  STRUCTURE_TYPE,
  HELP,
  MANDATORY,
} from "./questionnaireForm.fields";

import { FIELD_IDS } from "./questionnaireForm.fieldId";

export const FORM_CONFIG = {
  [FIELD_IDS.ANSWER]: ANSWER,
  [FIELD_IDS.ANSWER_PATTERN]: ANSWER_PATTERN,
  [FIELD_IDS.ASSESSMENT_GUIDELINE]: ASSESSMENT_GUIDELINE,
  [FIELD_IDS.QUESTION]: QUESTION,
  [FIELD_IDS.QUESTION_SEGMENT]: QUESTION_SEGMENT,
  [FIELD_IDS.STRUCTURE_TYPE]: STRUCTURE_TYPE,
  [FIELD_IDS.SURVEYOR_RANK]: SURVEYOR_RANK,
  [FIELD_IDS.SURVEY_OBJECTIVE]: SURVEY_OBJECTIVE,
  [FIELD_IDS.SERIAL_NUMBER]: SERIAL_NO,
  [FIELD_IDS.HELP]: HELP,
  [FIELD_IDS.MANDATORY]: MANDATORY,
};
