import React from "react";

import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";

import { getFormConfig } from "./helpers/forgotPassword.formConfig";
import { SECTIONS } from "./constants/forgotPassword.section";

const ForgotPasswordForm = (props) => {
  const { formId, onSubmit, view } = props;

  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={SECTIONS}
      formId={formId}
      onSubmit={onSubmit}
      view={view}
    />
  );
};

export default ForgotPasswordForm;
