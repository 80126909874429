import StructureComponentFormReaders from "../readers";
import compose from "recompose/compose";
import { addStructureTypeIdToStructures } from "../../../helpers/structureType.general";

export const getPayloadFromFormValues = (formValues, id) => {
  const structureComponent = compose(
    addStructureTypeIdToStructures(id),
    StructureComponentFormReaders.structureComponent
  )(formValues);

  return structureComponent;
};
