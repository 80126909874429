import _map from "lodash/map";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _isEqual from "lodash/isEqual";
import CityReaders from "../../cortexSurvey-business/appServices/city/readers/city";

const getFilteredCity = (stateId) => (city) => {
  const stateCityId = CityReaders.stateId(city);
  return _isEqual(stateId, stateCityId);
};

const getFilteredCityWithState = (cityData, stateId) => {
  const filtererData = _filter(cityData, getFilteredCity(stateId));
  return filtererData;
};

const createSurveySetupCityOption = (cityData) => {
  const city = CityReaders.city(cityData);
  const cityId = CityReaders.cityId(cityData);
  return {
    label: city,
    value: cityId,
  };
};

export const getSyrveySetupCityOptions = (cityData, stateId) => {
  const filteredCityData = getFilteredCityWithState(cityData, stateId);
  const options = _map(filteredCityData, createSurveySetupCityOption);
  return options;
};

export const getSoilFromCity = (cityData, cityId) => {
  const city = _find(cityData, { cityId });
  console.log(cityData, cityId, city);
  return CityReaders.soil(city);
};
