import React, { memo, useMemo } from "react";

import UserReaders from "../../../cortexSurvey-business/appServices/dashboardUser/readers/user";
import Avatar from "../../../cortexSurvey-components/avatar";
import Button, { TYPES } from "../../../cortexSurvey-components/button";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";
import useFetchUserProfile from "../../../cortexSurvey-business/appServices/dashboardUser/hooks/useFetchUserProfile";

import DashboardUserProfileForm from "./components/dashboardUserProfileForm";
import { getDisplayName } from "./helpers/dashboardUserProfile.general";

import styles from "./dashboardUserProfile.module.scss";
import { isMobile } from "react-device-detect";

const DashboardUserProfile = () => {
  const { loading, data, fetchUserProfile } = useFetchUserProfile();

  const { isModalVisible, showModal, closeModal } = useModalVisibility();

  const displayName = useMemo(() => getDisplayName(data), [data]);

  if (loading) return null;

  return (
    <div>
      <Button type={TYPES.TERTIARY} onClick={showModal}>
        <div className={styles.avatarContainer}>
          <Avatar displayName={displayName} size={40} />
          {!isMobile && <div>{displayName}</div>}
        </div>
      </Button>
      <DashboardUserProfileForm
        userId={UserReaders.userId(data)}
        data={data}
        isVisible={isModalVisible}
        onClose={closeModal}
        onSubmitCb={fetchUserProfile}
      />
    </div>
  );
};

export default memo(DashboardUserProfile);
