const assessmentGuidelineId = "assessmentGuidelineId";
const assessmentGuideline = "assessmentGuideline";
const surveyObjective = "surveyObjective";
const surveyObjectiveId = "surveyObjectiveId";
const level = "level";
const microzonationId = "microzonationId";
const surveyObjectiveCount = "surveyObjectiveCount";
const value = "value";
const assesmentGuidelineActiveStatus = "isActive";

const DATA_INDEX = {
  assessmentGuidelineId,
  assessmentGuideline,
  microzonationId,
  surveyObjective,
  surveyObjectiveId,
  level,
  surveyObjectiveCount,
  value,
  assesmentGuidelineActiveStatus,
};

export default DATA_INDEX;
