import React, { memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";

import QuestionnaireOverview from "./components/questionnaireOverview/QuestionnaireOverview";

import { ACTION_TYPES } from "./constants/questionnaire.actionTypes";
import { INITIAL_STATE } from "./constants/questionnaire.initialStates";
import ACTION_HANDLERS from "./helpers/questionnaire.actionHandlers";
import { BANNER_CONFIG } from "./constants/questionnaire.general";

const Questionnaire = (props) => {
  const {
    onAction,
    data,
    loading,
    isQuestionnaireNotSync = false,
    fetchQuestionnaire = _noop,
    onReload = _noop,
  } = props;
  return (
    <HeaderWithTable
      title="Questionnaire"
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={QuestionnaireOverview}
      fetchData={fetchQuestionnaire}
      onReload={onReload}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel="Add New Question"
      showBanner={isQuestionnaireNotSync}
      bannerConfig={BANNER_CONFIG}
      disabled={isQuestionnaireNotSync}
      showReload
    />
  );
};

Questionnaire.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  isQuestionnaireNotSync: PropTypes.bool,
  fetchQuestionnaire: PropTypes.func,
  onReload: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(Questionnaire);
