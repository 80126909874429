import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/InputFieldRenderer";
import InputNumberFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputNumberFieldRenderer";
import Logout from "../../../../../../cortexSurvey-components/logout/Logout";

import { FIELD_IDS } from "./dashboardUserProfileForm.fieldId";

export const FIRST_NAME = {
  id: FIELD_IDS.FIRST_NAME,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "First Name",
    placeholder: "First Name",
    required: true,
  },
};

export const LAST_NAME = {
  id: FIELD_IDS.LAST_NAME,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Last Name",
    required: true,
  },
};

export const EMAIL = {
  id: FIELD_IDS.EMAIL,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Email",
    placeholder: "Email",
    required: true,
    disabled: true,
  },
};

export const PHONE_NUMBER = {
  id: FIELD_IDS.PHONE_NUMBER,
  renderer: InputNumberFieldRenderer,
  renderOptions: {
    label: "Phone Number",
    placeholder: "Phone Number",
    required: true,
  },
};

export const ROLE = {
  id: FIELD_IDS.ROLE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Role",
    placeholder: "Role",
    required: true,
    disabled: true,
  },
};

export const LOGOUT = {
  id: FIELD_IDS.LOGOUT,
  renderer: Logout,
};

