export const NOTIFICATION_TYPE_OPTIONS = [
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
];
