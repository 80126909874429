import _property from "lodash/property";

import { FIELD_IDS } from "../constants/stateForm.fieldId";

const country = _property(FIELD_IDS.COUNTRY);
const state = _property(FIELD_IDS.STATE);

const READERS = {
  country,
  state,
};

export default READERS;
