import { FIELD_IDS } from "./questionnaireForm.fieldId";
import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/SelectFieldRenderer";
import TextAreaFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/TextAreaFieldRenderer";
import InputNumberFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputNumberFieldRenderer";
import HelpTable from "../../helpTable/HelpTable";
import AnswerPatternTable from "../../answerPatternTable/AnswerPatternTable";


export const SERIAL_NO = {
  id: FIELD_IDS.SERIAL_NUMBER,
  renderer: InputNumberFieldRenderer,
  renderOptions: {
    label: "Serial Number",
    placeholder: "Serial Number",
    required: true,
  },
};

export const QUESTION = {
  id: FIELD_IDS.QUESTION,
  renderer: TextAreaFieldRenderer,
  renderOptions: {
    label: "Question",
    placeholder: "Question",
    required: true,
  },
};

export const QUESTION_SEGMENT = {
  id: FIELD_IDS.QUESTION_SEGMENT,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Question Segment",
    placeholder: "Question Segment",
    required: true,
  },
};

export const SURVEY_OBJECTIVE = {
  id: FIELD_IDS.SURVEY_OBJECTIVE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Survey Objective",
    placeholder: "Survey Objective",
    required: true,
    isMulti: true,
  },
};

export const STRUCTURE_TYPE = {
  id: FIELD_IDS.STRUCTURE_TYPE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Structure Type",
    placeholder: "Structure Type",
    required: true,
    isMulti: true,
  },
};

export const SURVEYOR_RANK = {
  id: FIELD_IDS.SURVEYOR_RANK,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Surveyor Rank",
    placeholder: "Surveyor Rank",
    required: true,
    isMulti: true,
  },
};

export const ASSESSMENT_GUIDELINE = {
  id: FIELD_IDS.ASSESSMENT_GUIDELINE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Assessment Guideline",
    placeholder: "Assessment Guideline",
    required: true,
    isMulti: true,
  },
};

export const ANSWER_PATTERN = {
  id: FIELD_IDS.ANSWER_PATTERN,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Answer Pattern",
    placeholder: "Answer Pattern",
    required: true,
  },
};

export const HELP = {
  id: FIELD_IDS.HELP,
  renderer: HelpTable,
  renderOptions: {
    title: "Add Help",
    showAction: true,
  },
};

export const ANSWER = {
  id: FIELD_IDS.ANSWER,
  renderer: AnswerPatternTable,
  renderOptions: {
    title: "Add Answers",
    required: true,
    label:"Answers"
  },
};

export const MANDATORY = {
  id: FIELD_IDS.MANDATORY,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Mandatory",
    placeholder: "Mandatory",
    options: [
      { label: "Mandatory", value: 1 },
      { label: "Non Mandatory", value: 0 },
    ],
    required: true,
  },
};
