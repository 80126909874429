export const FIELD_IDS = {
  SERIAL_NUMBER: "SERIAL_NUMBER",
  QUESTION: "QUESTION",
  QUESTION_SEGMENT: "QUESTION_SEGMENT",
  SURVEY_OBJECTIVE: "SURVEY_OBJECTIVE",
  STRUCTURE_TYPE: "STRUCTURE_TYPE",
  SURVEYOR_RANK: "SURVEYOR_RANK",
  ANSWER_PATTERN: "ANSWER_PATTERN",
  ASSESSMENT_GUIDELINE: "ASSESSMENT_GUIDELINE",
  ANSWER: "ANSWER",
  HELP: "HELP",
  MANDATORY:"MANDATORY"
};
