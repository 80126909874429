import _split from "lodash/split";
import _last from "lodash/last";

export const getUUID = (url) => {
  const parts = _split(url, "/");
  return _last(parts);
};

export const getSrc = (value) => {
  const baseUrl = process.env.REACT_APP_UPLOAD_URL;
  return `${baseUrl}${value}`;
};
