import { FIELD_IDS } from "./resetPasswordForm.fieldIds";

export const SECTION = [
  {
    rows: [
      { columns: [FIELD_IDS.PASSWORD] },
      { columns: [FIELD_IDS.CONFIRM_PASSWORD] },
    ],
  },
];
