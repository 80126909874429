import { FIELD_IDS } from "./surveyorRankForm.fieldId";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";

export const SURVEY_RANK = {
  id: FIELD_IDS.SURVEYOR_RANK,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Surveyor Rank",
    placeholder: "Surveyor Rank",
    required: true,
  },
};
