import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard";

import Questionnaire from "../pages/questionnaire";

const surveyQuestionnaireRoute = {
  path: "survey",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "questionnaire",
      element: Questionnaire,
    },
  ],
};

export default surveyQuestionnaireRoute;
