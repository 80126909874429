import React, { useCallback } from "react";
import { useLocation, useNavigate} from "react-router";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import Button, { TYPES } from "../../../cortexSurvey-components/button";
import { triggerSubmit } from "../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import ResetPasswordForm from "./components/resetPasswordForm";
import { ACTION_TYPE } from "./constants/resetPassword.actionType";
import { FORM_ID } from "./constants/resetPassword.formId";
import { INITIAL_STATE } from "./constants/resetPassword.initialState";
import ACTION_HANDLERS from "./helpers/resetPassword.actionHandler";

import styles from "./resetPassword.module.scss";

const ResetPassword = (props) => {
  const { onAction } = props;
  const location=useLocation();
  const navigate=useNavigate();



  const handleResetPassword = () => {
    triggerSubmit(FORM_ID);
  };

  const handleResetPasswordSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPE.RESET_PASSWORD_FORM_SUBMISSION,
        payload: { formValues, location ,navigate},
      });
    },
    [onAction, location,navigate]
  );

  return (
    <div className={styles.container}>
      <ResetPasswordForm
        formId={FORM_ID}
        onSubmit={handleResetPasswordSubmission}
      />
      <Button
        type={TYPES.PRIMARY}
        onClick={handleResetPassword}
        className={styles.button}
      >
        Submit
      </Button>
    </div>
  );
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(ResetPassword);
