import React, { memo } from "react";

import PropTypes from "prop-types";
import cx from "classnames";

import _noop from "lodash/noop";

import TableInputWithAdd from "../../../../../../../cortexSurvey-components/tableInputWithAdd/TableInputWithAdd";
import {
  COLUMN_CONFIG,
  TABLE_COLUMN_CONFIG,
} from "./constants/structureProblemForm.columnConfig";

import styles from "./strcuctureProblemTable.module.scss";
import Table from "../../../../../../../cortexSurvey-components/table";
import { isMobile } from "react-device-detect";

const StrcutureProblemTable = (props) => {
  const { value = [], onChange = _noop, disabled = false } = props;

  if (disabled) {
    return <Table dataSource={value} columns={TABLE_COLUMN_CONFIG} />;
  }

  return (
    <TableInputWithAdd
      tableClassName={cx(styles.tableClassName, {
        [styles.mobileTableClassName]: isMobile,
      })}
      value={value}
      onChange={onChange}
      config={COLUMN_CONFIG}
      title="Structure problem"
      disabled={disabled}
      isDisabled={disabled}
      showTableAction={!disabled}
    />
  );
};

StrcutureProblemTable.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(StrcutureProblemTable);
