import _get from "lodash/get";

export const addRenderOptions = (field, additionalOptions = {}) => {
  const renderOptions = _get(field, "renderOptions", {});

  return {
    ...field,
    renderOptions: { ...renderOptions, ...additionalOptions },
  };
};
