import React from "react";
import { components } from "react-select";
import _isEmpty from "lodash/isEmpty";

import SelectAll from "../selectAll";

import styles from '../../select.module.scss';

const { Menu: MenuContainer, MenuList } = components;

const Menu = (props) => {
  const { selectProps } = props;
  const { options, value, onChange, isMulti, selectAllLabel, inputValue } =
    selectProps || {};

  const showSelectAll = isMulti && !inputValue && !_isEmpty(options);

  return (
    <MenuContainer {...props} className={styles.menuContainer}>
      {showSelectAll && (
        <MenuList {...props}>
          <SelectAll
            selectAllLabel={selectAllLabel}
            options={options}
            value={value}
            onChange={onChange}
          />
        </MenuList>
      )}
      {props.children}
    </MenuContainer>
  );
};

export default Menu;
