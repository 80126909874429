import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _map from "lodash/map";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _property  from 'lodash/property';

const filterAssessmentGuidelines = (
  microZonationData,
  assessmentGuidelineIds
) => {
  return _filter(microZonationData, (guideline) =>
    _includes(assessmentGuidelineIds, guideline.assessmentGuidelineId)
  );
};

const filterAndSortSurveyObjectives = (
  surveyObjectives,
  surveyObjectiveIds
) => {
  return _sortBy(
    _filter(surveyObjectives, (objective) =>
      _includes(surveyObjectiveIds, objective.surveyObjectiveId)
    ),
    'isActive'
  );
};

const createZoneScores = (
  value,
  scoreData,
  surveyObjectiveId,
  assessmentGuidelineId
) => {
  return _map(value, (zone) => {
    const score = _find(scoreData, {
      microZonationId: zone.microzonationId,
      surveyObjectiveId,
      assessmentGuidelineId,
    });
    return score ? score.score : 0; // Default to 0 if no score is found
  });
};

const createMicroZonations = (value) => {
  return _map(value, (zone) => ({
    microZonationId: zone.microzonationId,
    label: zone.level,
  }));
};

const createSurveyObjectiveRows = (
  surveyObjectives,
  scoreData,
  assessmentGuidelineId
) => {
  return _map(surveyObjectives, (objective) => {
    const { surveyObjectiveId, surveyObjective, value } = objective;

    const zoneScores = createZoneScores(
      value,
      scoreData,
      surveyObjectiveId,
      assessmentGuidelineId
    );
    const microZonations = createMicroZonations(value);

    return {
      surveyObjectiveId,
      surveyObjective,
      microZonations,
      zoneScores,
    };
  });
};

export const getScoreDataFormMicroZonationData = (
  microZonationData,
  scoreData,
  assessmentGuidelines,
  surveyObjectives
) => {
    const assessmentGuidelineIds = _map(
        assessmentGuidelines,
        _property("assessmentGuidelineId")
      );
      const surveyObjectiveIds = _map(
        surveyObjectives,
        _property("surveyObjectiveId")
      );

  const filteredGuidelines = filterAssessmentGuidelines(
    microZonationData,
    assessmentGuidelineIds
  );

  return _map(filteredGuidelines, (guideline) => {
    const { assessmentGuidelineId, assessmentGuideline, surveyObjective } = guideline;
    const filteredObjectives = filterAndSortSurveyObjectives(
      surveyObjective,
      surveyObjectiveIds
    );
    const surveyObjectiveRows = createSurveyObjectiveRows(
      filteredObjectives,
      scoreData,
      assessmentGuidelineId
    );

    return {
      assessmentGuidelineId,
      assessmentGuideline,
      surveyObjectiveRows,
    };
  });
};
