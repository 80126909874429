import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";
import isEmail from "../../../../../../cortexSurvey-components/formWithSubmissionV2/validators/isEmail";
import { FIELD_IDS } from "./loginForm.fieldIds";

export const LOGIN_EMAIL = {
  id: FIELD_IDS.LOGIN_EMAIL,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Email",
    type: "email",
    placeholder: "email",
    required: true,
    validations: isEmail,
  },
};

export const LOGIN_PASSWORD = {
  id: FIELD_IDS.LOGIN_PASSWORD,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Password",
    type: "password",
    placeholder: "password",
    required: true,
  },
};
