import { ACTION_TYPES } from "../constants/structureType.actionTypes";

const handleCreateClick = ({ getState }) => {
  const { showCreateForm } = getState();
  showCreateForm();
};

const handleEditRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showEditForm } = getState();
  showEditForm(rowData);
};

const handleDeleteRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showDeleteModal } = getState();
  showDeleteModal(rowData);
};

const handleActiveRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showActiveModal } = getState();
  showActiveModal(rowData);
};

const handleShowProblemRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showStructureProblem, setDisableEditForm } = getState();
  setDisableEditForm(false);
  showStructureProblem(rowData);
};

const handleShowHelpRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showStructureHelp } = getState();
  showStructureHelp(rowData);
};

const handleShowComponentRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showStructureComponent } = getState();
  showStructureComponent(rowData);
};

const handleViewProblemRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showStructureProblem, setDisableEditForm } = getState();
  setDisableEditForm(true);
  showStructureProblem(rowData);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE]: handleCreateClick,
  [ACTION_TYPES.EDIT]: handleEditRowClick,
  [ACTION_TYPES.DELETE]: handleDeleteRowClick,
  [ACTION_TYPES.EDIT_STRUCTURE_PROBLEM]: handleShowProblemRowClick,
  [ACTION_TYPES.EDIT_STRUCTURE_HELP]: handleShowHelpRowClick,
  [ACTION_TYPES.EDIT_STRUCTURE_COMPONENT]: handleShowComponentRowClick,
  [ACTION_TYPES.VIEW_STRUCTURE_PROBLEM]: handleViewProblemRowClick,
  [ACTION_TYPES.ACTIVATE]: handleActiveRowClick,
};

export default ACTION_HANDLERS;
