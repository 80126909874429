import _debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";
import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";
import useFetchPayload from "../../../hooks/useFetchPayload";

import SurveyorRankAPI from "../services/surveyorRank";

const useFetchSurveyorRank = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchSurveyorRank = useCallback(async (active) => {
    setLoading(true);
    try {
      const payload = getFetchPayload(active);
      const response = await SurveyorRankAPI.fetchSurveyorRank(payload);
      setData(response?.data);
    } catch (err) {
      toaster(TOASTER_TYPE.ERROR, getErrorMessage("Failed to fetch Surveyor Rank"));
    } finally {
      setLoading(false);
    }
  }, [setLoading, setData,getFetchPayload ]);

  const handledebounceFetchSurveyorRank = useCallback((isActive) => {
    setLoading(true);
    const debounceFunction = _debounce(fetchSurveyorRank, 2000);
    debounceFunction(isActive);
  }, [fetchSurveyorRank, setLoading]);

  useEffect(() => {
    fetchSurveyorRank();
  }, [fetchSurveyorRank]);

  return { loading, data, fetchSurveyorRank: handledebounceFetchSurveyorRank };
};

export default useFetchSurveyorRank;
