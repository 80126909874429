export const ACTION_TYPES = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  CREATE: "CREATE",
  EDIT_STRUCTURE_PROBLEM: "EDIT_STRUCTURE_PROBLEM",
  EDIT_STRUCTURE_HELP: "EDIT_STRUCTURE_HELP",
  EDIT_STRUCTURE_COMPONENT: "EDIT_STRUCTURE_COMPONENT",
  VIEW_STRUCTURE_PROBLEM: "VIEW_STRUCTURE_PROBLEM",
  ACTIVATE: 'ACTIVATE',
};
