import { ACTION_TYPES } from "../constants/questionnaire.actionTypes";

const handleCreateClick = ({ getState }) => {
  const { showCreateForm } = getState();
  showCreateForm();
};

const handleEditRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showEditForm } = getState();
  showEditForm(rowData);
};

const handleDeleteRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showDeleteModal } = getState();
  showDeleteModal(rowData);
};

export const handleActiveClick = ({ params, getState }) => {
  const { showActiveModal } = getState();
  const { rowData } = params;
  showActiveModal(rowData);
};

export const handleScoreForm = ({ params, getState }) => {
  const { showScoreForm } = getState();
  const { rowData } = params;
  showScoreForm(rowData);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.CREATE]: handleCreateClick,
  [ACTION_TYPES.EDIT]: handleEditRowClick,
  [ACTION_TYPES.DELETE]: handleDeleteRowClick,
  [ACTION_TYPES.ACTIVE]: handleActiveClick,
  [ACTION_TYPES.SCORE]: handleScoreForm,
};

export default ACTION_HANDLERS;
