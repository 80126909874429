import React from "react";
import { Empty } from "antd";
import PropTypes from "prop-types";

const EmptyData = (props) => {
  const { type } = props;
  return <Empty image={type} />;
};

EmptyData.propTypes = {
  type: PropTypes.string,
};

EmptyData.defaultProps = {
  type: Empty.PRESENTED_IMAGE_SIMPLE,
};

export default EmptyData;
