import React, { memo, useCallback } from "react";

import { ReloadOutlined } from "@ant-design/icons";
import _noop from "lodash/noop";
import PropTypes from "prop-types";

import Button, { TYPES } from "../button";

import styles from "./header.module.scss";

const Header = (props) => {
  const {
    onAction = _noop,
    createActionType,
    createButtonLabel,
    title,
    disabled = false,
    onReload = _noop,
    showReload,
  } = props;

  const handleClick = useCallback(() => {
    onAction({ type: createActionType });
  }, [onAction, createActionType]);

  const handleReload = useCallback(() => {
    onReload();
  }, [onReload]);

  return (
    <div className={styles.container}>
      <div>{title}</div>

      <div className={styles.rightContainer}>
        {createButtonLabel && (
          <Button
            className={styles.button}
            type={TYPES.PRIMARY}
            onClick={handleClick}
            disabled={disabled}
          >
            {createButtonLabel}
          </Button>
        )}
        <Button type={TYPES.TERTIARY} onClick={handleReload}>
          {showReload && <ReloadOutlined style={{ fontSize: "2rem" }} />}
        </Button>
      </div>
    </div>
  );
};

Header.propTypes = {
  onAction: PropTypes.func,
  createActionType: PropTypes.string,
  title: PropTypes.string,
  createButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onReload: PropTypes.func,
  showReload: PropTypes.bool,
};

export default memo(Header);
