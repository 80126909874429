import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import MicroZonationAPI from "../../../../../../cortexSurvey-business/appServices/microZonation/services/microZonation";

import { ACTION_TYPES } from "../constants/microZonationDeleteModal.actionTypes";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/microZonationDeleteModal.general";
import { getPayload } from "./microzonationDeleteModal.payload";

export const handleDeleteSubmission = async ({ getState, setState }) => {
  const { microzonationIds, onCancel = _noop, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload = getPayload(microzonationIds);
    await MicroZonationAPI.deleteMicroZonation(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
