import React, { memo, useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import FormWithSubmissionV2 from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import { ACTION_TYPES } from "./constants/structureHelpForm.actionTypes";
import { FORM_CONFIG } from "./constants/structureHelpForm.formConfig";
import { FORM_ID } from "./constants/structureHelpForm.formId";
import { INITIAL_STATE } from "./constants/structureHelpForm.initialState";
import { SECTIONS } from "./constants/structureHelpForm.section";
import ACTION_HANDLERS from "./helpers/structureHelpForm.actionHandlers";
import { makeInitialValues } from "./helpers/structureHelpForm.initialValues";

const StructureHelpForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    formValues = {},
    onAction = _noop,
    isSubmitting = false,
    error = false,
  } = props;

  const formConfig = useCallback(() => FORM_CONFIG, []);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.HELP_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  const initialValues = useMemo(
    () => makeInitialValues(formValues),
    [formValues]
  );

  return (
    <Drawer
      title="Edit Structure Help"
      width="90rem"
      open={isVisible}
      maskClosable={false}
      onClose={onClose}
      onSubmit={handleSave}
      loading={isSubmitting}
      error={error}
      destroyOnClose
    >
      <FormWithSubmissionV2
        formId={FORM_ID}
        formConfig={formConfig}
        sections={SECTIONS}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        tabEnabled
      />
    </Drawer>
  );
};

StructureHelpForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  formValues: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  error: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(StructureHelpForm);
