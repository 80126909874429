import CityIndexFormReaders from "../../cityIndexMappingForm/readers";

export const getPayloadFromFormValues = (formValues, id) => {
  const assessmentGuidelineId =
    CityIndexFormReaders.assessmentGuidelineId(formValues);
  const surveyObjectiveId = CityIndexFormReaders.surveyObjectiveId(formValues);
  const microzonationId = CityIndexFormReaders.microzonationId(formValues);
  const countryId = CityIndexFormReaders.countryId(formValues);
  const stateId = CityIndexFormReaders.stateId(formValues);
  const cityId = CityIndexFormReaders.cityId(formValues);

  return {
    scaleMappingId: id,
    assessmentGuidelineId,
    surveyorObjectiveId: surveyObjectiveId,
    microzonationId,
    countryId,
    stateId,
    cityId,
  };
};
