import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Modal from "../../../../../cortexSurvey-components/modal/Modal";

import { ACTION_TYPES } from "./constants/surveyObjectiveActiveModal.actionTypes";
import {
  MODAL_TITLE,
  SAVE_TEXT,
} from "./constants/surveyObjectiveActiveModal.general";
import { INITIAL_STATE } from "./constants/surveyObjectiveActiveModal.initialState";
import ACTION_HANDLERS from "./helpers/surveyObjectiveActiveModal.actionHandlers";

const SurveyObjectiveActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    surveyObjective,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.ACTIVE_SUBMISSION });
  }, [onAction]);

  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to Activate ${surveyObjective} Survey Objective.`}
    </Modal>
  );
};

SurveyObjectiveActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  surveyObjective: PropTypes.string,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default compose(memo)(
  withActions(INITIAL_STATE, ACTION_HANDLERS)(SurveyObjectiveActiveModal)
);
