import React, { memo } from "react";

import PropTypes from "prop-types";
import Tag from "../../../tag";

const InActiveAndActiveStatus = (props) => {
  const { rowData = {} } = props;

  const { isActive } = rowData;

  if (isActive) return <Tag color="success">Active</Tag>;

  return <Tag color="error">InActive</Tag>;
};

InActiveAndActiveStatus.propTypes = {
  rowData: PropTypes.object,
};

export default memo(InActiveAndActiveStatus);
