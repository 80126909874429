import { FIELD_IDS } from "./questionaireScoreForm.fieldIds";

export const SECTIONS = [
  {
    rows: [
      {
        columns: [FIELD_IDS.SCORE],
      },
    ],
  },
];
