export const HEADER_TITLE = "State";
export const CREATE_BUTTON_LABEL = "Create State";

const BANNER_TYPE = "error";

const BANNER_MESSAGE =
  "Data Sync Issue : Data is not synchronized with active State due to an inactive Country.";

export const BANNER_CONFIG = {
  type: BANNER_TYPE,
  message: BANNER_MESSAGE,
};