import SurveyObjectiveReaders from "../../../../../../cortexSurvey-business/appServices/surveyObjective/readers/surveyObjective";

import { FIELD_IDS } from "../../surveyObjectiveForm/constants/surveyObjectiveForm.fieldId";

export const makeInitialValues = (formData) => {
  const surveyObjective = SurveyObjectiveReaders.surveyObjective(formData);
  return {
    [FIELD_IDS.SURVEY_OBJECTIVE]: surveyObjective,
  };
};
