import { ACTION_TYPES } from "../../../constants/city.actionTypes";

import {
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
} from "@ant-design/icons";

export const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit City Details",
  icon: <EditOutlined />,
};

export const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate City",
  icon: <DeleteOutlined />,
};

export const ACTIVATE = {
  id: ACTION_TYPES.ACTIVE,
  label: "Activate City",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];
export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
