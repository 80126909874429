import MicroZonationReaders from "../../../../cortexSurvey-business/appServices/microZonation/readers/microzonation";

export const createOptions = (selectedEditForm) => {
  const assesmentGuidelineId =
    MicroZonationReaders.assessmentGuidelineId(selectedEditForm);
  const assesmentGuideline =
    MicroZonationReaders.assessmentGuideline(selectedEditForm);

  return [{ value: assesmentGuidelineId, label: assesmentGuideline }];
};
