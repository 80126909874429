import React, { useCallback } from "react";
import BaseUpload from "../baseUpload";
import MultiUploadPreview from "./components/multiUploadPreview";
import { getUpdatedValueOnDelete } from "./helpers/multiUpload.general";
import "react-multi-carousel/lib/styles.css";
import styles from "./multiUpload.module.scss";

const MultiUpload = (props) => {
  const { value = [], onChange, tableClassName } = props;

  const handleOnchange = useCallback(
    (imgurl) => {
      const updatedValue = [imgurl, ...value];
      onChange(updatedValue);
    },
    [onChange, value]
  );

  const handleDelete = useCallback(
    (index) => {
      const updatedValue = getUpdatedValueOnDelete(value, index);
      onChange(updatedValue);
    },
    [value, onChange]
  );

  return (
    <div className={styles.container}>
      <BaseUpload {...props} onChange={handleOnchange} />
      <MultiUploadPreview
        data={value}
        onDelete={handleDelete}
        onSort={onChange}
        tableClassName={tableClassName}
      />
    </div>
  );
};

export default MultiUpload;
