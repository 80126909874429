import _map from "lodash/map";

import { getFilteredAssesmentGuidelineOptions } from "../../assessmentGuideline/helpers/assessmentGuidelineOptions";
import MicroZonationReaders from "../readers/microzonation";

export const getFilteredAssesmentGuidelineOptionsFormMicorZonationData = (
  microZonationData,
  assessmentGuidelineData
) => {
  const assesmentGuidelineIds = _map(
    microZonationData,
    MicroZonationReaders.assessmentGuidelineId
  );
  return getFilteredAssesmentGuidelineOptions(
    assessmentGuidelineData,
    assesmentGuidelineIds
  );
};

const createMicroZonisedAssessmentGuidelineOptions = (microZonationData) => {
  const assessmentGuidelineId =
    MicroZonationReaders.assessmentGuidelineId(microZonationData);
  const assessmentGuideline =
    MicroZonationReaders.assessmentGuideline(microZonationData);

  return { label: assessmentGuideline, value: assessmentGuidelineId };
};

export const getMicroZonisedAssessmentGuidelineOptions = (
  microZonationData
) => {
  const assessmentGuidelineOptons = _map(
    microZonationData,
    createMicroZonisedAssessmentGuidelineOptions
  );
  return assessmentGuidelineOptons;
};
