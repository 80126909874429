import { DeleteOutlined, EditOutlined, ExportOutlined } from "@ant-design/icons";

import { ACTION_TYPES } from "../../../constants/surveyObjective.actionTypes";

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit Survey Objective",
  icon: <EditOutlined />,
};

const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate Survey Objective",
  icon: <DeleteOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVATE,
  label: "Activate Survey Objective",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
