import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/dashboardUsersDeleteModal.actionTypes";

import DashboardUserAPI from "../../../../../../cortexSurvey-business/appServices/dashboardUser/services/dashboardUsers";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { userId, onCancel=_noop, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });
  try {
    const payload = { status: "InActive", userId };
    await DashboardUserAPI.deleteDashboardUser(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Dashboard User DeActivated Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to DeActivate Dashboard User");
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
