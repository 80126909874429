import React, { useMemo, memo } from "react";

import ReactAudioPlayer from "react-audio-player";

import { getSrc } from "../../cortexSurvey-base/helpers/upload/general";

import styles from "./audioPlayer.module.scss";
import Label from "../label";

const AudioPlayer = (props) => {
  const { url, value, containerClassName, title, required } = props;

  const audioSrcURL = useMemo(() => getSrc(url || value), [value, url]);

  if (!url && !value) return <div>No Audio Data</div>;

  return (
    <div className={containerClassName}>
      {title && (
        <Label className={styles.title} required={required}>
          {title}
        </Label>
      )}
      <ReactAudioPlayer
        className={styles.audioPlayer}
        src={audioSrcURL}
        controls
      />
    </div>
  );
};

export default memo(AudioPlayer);
