import React, { useCallback, useMemo, memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import { FORM_ID } from "./constants/dashboardUserEditForm.form";
import { INITIAL_STATE } from "./constants/dashboardUserEditForm.initialState";
import { ACTION_TYPES } from "./constants/dashboardUserEditForm.actionTypes";
import DashboardUsersForm from "../dashboardUsersForm";
import ACTION_HANDLERS from './helpers/dashboardUserEditForm.actionHandlers'
import { makeInitialValues } from "./helpers/dashboardUserEditForm.initialValues";

const DashboardUserEditForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    formData={},
    isSubmitting = false,
    error
  } = props;

  const initialValues=useMemo(()=>makeInitialValues(formData),[formData]);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.EDIT_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );


  return (
    <Drawer
    loading={isSubmitting}
      open={isVisible}
      onClose={onClose}
      title="Edit Dashboard User"
      onSubmit={handleSave}
      maskClosable={false}
      width="60rem"
      error={error}
      destroyOnClose

    >
      <DashboardUsersForm 
         formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        disabled={isSubmitting}/>
    </Drawer>
  );
};

export default withActions(INITIAL_STATE, ACTION_HANDLERS)(DashboardUserEditForm);
