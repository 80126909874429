import React from "react";

import SurveyorRank from "./SurveyorRank";
import SurveyorRankCreateForm from "./components/surveyorRankCreateForm";
import SurveyorRankDeleteModal from "./components/surveyorRankDeleteModal";
import SurveyorRankActiveModal from "./components/surveyorRankActiveModal/SurveyorRankActiveModal";
import SurveyorRankEditForm from "./components/surveyorRankEditForm";

import { useDrawerVisibility } from "../../../cortexSurvey-components/drawer";
import { useModalVisibility } from "../../../cortexSurvey-components/modal";

import useFetchSurveyorRank from "../../../cortexSurvey-business/appServices/surveyorRank/hooks/useFetchSurveyorRank";
import SurveyorRankReaders from "../../../cortexSurvey-business/appServices/surveyorRank/readers/surveyorRank";

const SurveyorRankContainer = () => {
  const { loading, data, fetchSurveyorRank } = useFetchSurveyorRank();

  const {
    isDrawerVisible: isCreateFormVisible,
    showDrawer: showCreateForm,
    closeDrawer: closeCreateForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedEditFormData,
    isDrawerVisible: isEditFormVisible,
    showDrawer: showEditForm,
    closeDrawer: closeEditForm,
  } = useDrawerVisibility();

  const {
    selectedData: selectedDeleteData,
    isModalVisible: isSurveyorRankDeletionModalVisible,
    showModal: showSurveyorRankDeletionModal,
    closeModal: closeSurveyorRankDeletionModal,
  } = useModalVisibility({});

  const {
    selectedData: selectedActiveData,
    isModalVisible: isSurveyorRankActiveModalVisible,
    showModal: showSurveyorRankActiveModal,
    closeModal: closeSurveyorRankActiveModal,
  } = useModalVisibility({});

  return (
    <div>
      <SurveyorRank
        showCreateForm={showCreateForm}
        showEditForm={showEditForm}
        showDeleteModal={showSurveyorRankDeletionModal}
        showActiveModal={showSurveyorRankActiveModal}
        data={data}
        loading={loading}
        fetchSurveyorRank={fetchSurveyorRank}
      />
      <SurveyorRankCreateForm
        isVisible={isCreateFormVisible}
        onClose={closeCreateForm}
        onSubmitCb={fetchSurveyorRank}
      />
      <SurveyorRankEditForm
        formData={selectedEditFormData}
        isVisible={isEditFormVisible}
        onClose={closeEditForm}
        onSubmitCb={fetchSurveyorRank}
        surveyorRankId={SurveyorRankReaders.surveyorRankId(
          selectedEditFormData
        )}
      />
      <SurveyorRankDeleteModal
        isVisible={isSurveyorRankDeletionModalVisible}
        onCancel={closeSurveyorRankDeletionModal}
        surveyorRankId={SurveyorRankReaders.surveyorRankId(selectedDeleteData)}
        surveyorRank={SurveyorRankReaders.surveyorRank(selectedDeleteData)}
        onSubmitCb={fetchSurveyorRank}
      />
      <SurveyorRankActiveModal
        isVisible={isSurveyorRankActiveModalVisible}
        onCancel={closeSurveyorRankActiveModal}
        surveyorRankId={SurveyorRankReaders.surveyorRankId(selectedActiveData)}
        surveyorRank={SurveyorRankReaders.surveyorRank(selectedActiveData)}
        onSubmitCb={fetchSurveyorRank}
      />
    </div>
  );
};

export default SurveyorRankContainer;
