import StateReaders from "../../stateForm/readers";

export const getPayloadFromFormValues = (formValues, id) => {
  const countryId = StateReaders.country(formValues);
  const stateName = StateReaders.state(formValues);
  return {
    countryId,
    stateId: id,
    stateName
  };
};
