import { isMobile } from "react-device-detect";
import MicrozonationDataIndex from "../../../../../../cortexSurvey-business/appServices/microZonation/constants/dataIndex/microzonation";

import AssessmentGuidelineColumn from "../components/assessmentGuidelineColumn";

export const ASSESSMENT_GUIDELINE_COLUMN = {
  title: "Assessment Guideline",
  dataIndex: MicrozonationDataIndex.assessmentGuideline,
  key: "assessmentGuideline",
  width: 200,
  fixed: !isMobile && "left",
  align: "baseline",
  render: (_, rowData) => <AssessmentGuidelineColumn rowData={rowData} />,
};
