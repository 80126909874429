import _some from "lodash/some";
import StateReaders from "../readers/state";

const isNotSync=(state)=>{
    const country=StateReaders.country(state);
    const {isActive}=country || {};
    return !isActive;
}

export const isStateNotSync = (stateData) => {
  return _some(stateData, isNotSync);
};
