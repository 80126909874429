import React from "react";
import _map from "lodash/map";
import TableRow from "../components/tableRow/TableRow";

const renderRowComponent =
  (config, onChange, value, restProps, showTableAction) => (rowData, index) => {
    return (
      <TableRow
        key={`tableRow_${index}`}
        rowData={rowData}
        index={index}
        tableData={value}
        onChange={onChange}
        config={config}
        showTableAction={showTableAction}
        {...restProps}
      />
    );
  };

export const renderRow = (config, value, onChange, restProps, showTableAction) =>
  _map(
    value,
    renderRowComponent(config, onChange, value, restProps, showTableAction)
  );
