import { FIELD_IDS } from "./surveyObjectiveForm.fieldId";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";

export const SURVEY_OBJECTIVE = {
  id: FIELD_IDS.SURVEY_OBJECTIVE,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Survey Objective",
    placeholder: "Survey Objective",
    required: true,
  },
};
