import React, { memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import Modal from "../../../../../cortexSurvey-components/modal/Modal";
import withActions from "../../../../../cortexSurvey-base/hocs/withActions";

import { ACTION_TYPES } from "./constants/microZonationActiveModal.actionTypes";
import { INITIAL_STATE } from "./constants/microZonationActiveModal.initialState";
import ACTION_HANDLERS from "./helpers/microZonationActiveModal.actionHandlers";
import {
  SAVE_TEXT,
  MODAL_TITLE,
} from "./constants/microZonationActiveModal.general";

const MicroZonationActiveModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    assessmentGuideline,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = () => {
    onAction({ type: ACTION_TYPES.ACTIVE_SUBMISSION});
  };

  return (
    <Modal
      title={MODAL_TITLE}
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText={SAVE_TEXT}
      centered
    >
      {`Do you want to activate ${assessmentGuideline} MicroZonation.`}
    </Modal>
  );
};

MicroZonationActiveModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  assessmentGuideline: PropTypes.string,
  onAction: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(MicroZonationActiveModal);
