const country="country";
const email="email";
const firstName="firstName";
const lastName="lastName";
const phone="phone";
const surveyorRank="surveyorRank";
const image="image";
const status="status";

const DATA_INDEX={
    country,email,firstName,lastName,phone,surveyorRank,image,status
}

export default DATA_INDEX;