import ForgotPassword from "../pages/forgotPassword";
import Login from "../pages/login";
import ResetPassword from "../pages/resetPassword";
import AuthTemplate from "../../cortesSurvey-template/authTemplate";

const authRoutes = {
  path: "auth",
  wrapper: AuthTemplate,
  isAuthRoute:true,
  children: [
    {
      path: "login",
      element: Login,
    },
    {
      path: "forgot-password",
      element: ForgotPassword,
    },
    {
      path: "reset-password",
      element: ResetPassword,
    },
  ],
};

export default authRoutes;