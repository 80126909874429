import React, { memo } from "react";

import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import WithActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";
import ImageProcessingOverview from "./components/imageProcessingOverview/ImageProcessingOverview";

import { ACTION_TYPES } from "./constants/imageProcessing.actionTypes";
import { INITIAL_STATE } from "./constants/imageProcessing.initialStates";
import ACTION_HANDLERS from "./helpers/imageProcessing.actionHandlers";

const ImageProcessing = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchImageProcessing = _noop,
  } = props;

  return (
    <HeaderWithTable
      title="Image Processing"
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={ImageProcessingOverview}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel="Add Refernce Image"
      onReload={fetchImageProcessing}
      fetchData={fetchImageProcessing}
      showReload
    />
  );
};

ImageProcessing.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  fetchImageProcessing: PropTypes.bool,
};

export default compose(
  memo,
  WithActions(INITIAL_STATE, ACTION_HANDLERS)
)(ImageProcessing);
