import React, { useMemo, memo } from "react";

import PropTypes from "prop-types";
import cx from "classnames";

import _noop from "lodash/noop";

import TableInputWithAdd from "../../../../../../../cortexSurvey-components/tableInputWithAdd/TableInputWithAdd";
import { getColumnConfig } from "./helpers/structureComponentForm.columnConfig";

import styles from "./structureComponentTable.module.scss";
import { isMobile } from "react-device-detect";

const StrcutureComponentTable = (props) => {
  const { value = [], onChange = _noop, disabled = false } = props;

  const columnConfig = useMemo(() => getColumnConfig(disabled), [disabled]);

  return (
    <TableInputWithAdd
      title="Structure Components"
      tableClassName={cx(styles.container, {
        [styles.mobileTableClassName]: isMobile,
      })}
      value={value}
      onChange={onChange}
      config={columnConfig}
      disabled={disabled}
      isDisabled={disabled}
      showTableAction={!disabled}
    />
  );
};

StrcutureComponentTable.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(StrcutureComponentTable);
