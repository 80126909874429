import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";

export const getErrorMessage = (error, defaultError) => {
  const errormessage = _get(error, "response.data.errors", defaultError);

  if (!_isEmpty(errormessage)) return _head(errormessage);

  return _get(error, "response.data.message", defaultError);
};
