import {
  TOASTER_TYPE,
  toaster,
} from "../../../../cortexSurvey-components/notificationWrapper";
import { ACTION_TYPE } from "../constants/resetPassword.actionType";
import { getPayloadFromFormValues } from "./resetPassword.payload";

import AuthApi from "../../../services/auth";

import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";

const handleFormSubmission = async ({ params, setState }) => {
  const { formValues, location, navigate } = params;
  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(formValues, location);
      const response = await AuthApi.resetPassword(payload);
      if (response?.success) {
        navigate("/auth/login");
        toaster(TOASTER_TYPE.SUCCESS, "Password Changed Successfully.");
      }
    } catch (err) {
      toaster(
        TOASTER_TYPE.ERROR,
        getErrorMessage(err, "Failed to reset Password")
      );
    } finally {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPE.RESET_PASSWORD_FORM_SUBMISSION]: handleFormSubmission,
};

export default ACTION_HANDLERS;
