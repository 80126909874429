import { FIELD_IDS } from "./structureTypeForm.fieldId";
import {
  STRUCTURE_TYPE,
  STRUCTURE_TYPE_IMAGE,
} from "./structureTypeForm.field";

export const FORM_CONFIG = {
  [FIELD_IDS.STRUCTURE_TYPE]: STRUCTURE_TYPE,
  [FIELD_IDS.STRUCTURE_TYPE_IMAGE]: STRUCTURE_TYPE_IMAGE,
};
