import React from "react";

import _omit from "lodash/omit";
import _toPairs from "lodash/toPairs";
import _sortBy from "lodash/sortBy";
import _map from "lodash/map";
import _head from 'lodash/head';

import InputNumberFieldRenderer from "../../../../../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputNumberFieldRenderer";
import TextRenderer from "../../../../../../../../../../cortexSurvey-components/textRenderer/TextRenderer";
import SurveyObjectiveScoreTable from "../components/surveyObjectiveScoreTable";

const transformScores = (scores) => {
  const withoutSurveyObjective = _omit(scores, "surveyObjective");
  const pairs = _toPairs(withoutSurveyObjective);
  const sortedPairs = _sortBy(pairs, 0);
  const output = _map(sortedPairs, 1);
  return output;
};

const createSurveyObjectiveScoreData = (surveyObjective, zoneScores) => {
  return [{ surveyObjective, ...zoneScores }];
};

const createMicroZonationColumn = (microZonation, index) => {
  const { microZonationId, label } = microZonation;

  return {
    dataIndex: index,
    key: microZonationId,
    renderer: InputNumberFieldRenderer,
    headerName: label,
    width: "15rem",
  };
};

const createSurveyObjectiveScoreDataColumnConfig = (microZonations) => {
  const microZonationColumns = _map(microZonations, createMicroZonationColumn);
  return [
    {
      dataIndex: "surveyObjective",
      key: "surveyObjective",
      renderer: TextRenderer,
      headerName: "Survey Objective",
      width: "20rem",
    },
    ...microZonationColumns,
  ];
};

export const renderSurveyObjectiveScoreTable = (onChange) => (scoreData) => {
  const { microZonations, surveyObjective, surveyObjectiveId, zoneScores } =
    scoreData;

  const handleSurveyObjectiveScoreChange = (updatedZoneScores) => {
    const score = _head(updatedZoneScores);
    const scores = transformScores(score);
    const updatedScores  = { ...scoreData, zoneScores: scores };
    onChange(updatedScores);
  };

  const columnConfig =
    createSurveyObjectiveScoreDataColumnConfig(microZonations);

  const surveyObjectiveScoreData = createSurveyObjectiveScoreData(
    surveyObjective,
    zoneScores
  );

  return (
    <SurveyObjectiveScoreTable
      key={surveyObjectiveId}
      data={surveyObjectiveScoreData}
      columnConfig={columnConfig}
      onChange={handleSurveyObjectiveScoreChange}
    />
  );
};
