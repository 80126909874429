import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard/SurveyDashboard";
import SurveyHomePage from "../pages/surveyHomePage/SurveyHomePage";

const homeRoute = {
  path: "survey",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "",
      element: SurveyHomePage,
    },
  ],
  
};

export default homeRoute;
