import SurveyorRankDataIndex from ".././../../../../../cortexSurvey-business/appServices/surveyorRank/constants/dataIndex/surveyorRank";

const COLUMNS = [
  {
    title: "Surveyor Rank ID",
    dataIndex: SurveyorRankDataIndex.surveyorRankId,
    key: "id",
    width: "20rem",
  },
  {
    title: "Surveyor Rank",
    dataIndex: SurveyorRankDataIndex.surveyorRank,
    key: "surveyorRank",
    width: "30rem",
  },
];

export default COLUMNS;
