import { useState, useCallback } from "react";

const useFetchPayload = (iniitalActiveState = 1) => {
  const [isActive, setIsActive] = useState(iniitalActiveState);

  const getIsActivePayload = useCallback((active) => {
    if (active === 2) return {};
    return { isActive: active };
  }, []);

  const getFetchPayload = useCallback(
    (active) => {
      if (active!==0 && !active) {
        return getIsActivePayload(isActive);
      }

      setIsActive(active);
      return getIsActivePayload(active);
    },
    [isActive, setIsActive, getIsActivePayload]
  );

  return { getFetchPayload };
};

export default useFetchPayload;
