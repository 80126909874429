import React, { memo, useCallback, useMemo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer/Drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";

import {
  DRAWER_TITLE,
  DRAWER_WIDTH,
} from "./constants/assesmentGuidelineEditForm.general";
import { ACTION_TYPES } from "./constants/assessmentGuidelineEditForm.actionTypes";
import { FORM_ID } from "./constants/assessmentGuidelineEditForm.form";
import { INITIAL_STATE } from "./constants/assessmentGuidelineEditForm.initialStates";
import ACTION_HANDLERS from "./helpers/assessmentGuidelineEditForm.actionHandlers";
import { makeInitialValues } from "./helpers/assessmentGuidelineEditForm.initialValue";

import AssessmentGuidelineForm from "../assesmentGuidelineForm";

const AssesmentGuidelineEditForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    formData = {},
    onAction = _noop,
    isSubmitting = false,
    error=false
  } = props;

  const initialValues = useMemo(() => makeInitialValues(formData), [formData]);

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.EDIT_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      loading={isSubmitting}
      open={isVisible}
      onClose={onClose}
      title={DRAWER_TITLE}
      onSubmit={handleSave}
      maskClosable={false}
      width={DRAWER_WIDTH}
      error={error}
      destroyOnClose
    >
      <AssessmentGuidelineForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        disabled={isSubmitting}
      />
    </Drawer>
  );
};

AssesmentGuidelineEditForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  formData: PropTypes.object,
  isSubmitting: PropTypes.bool,
  error: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(AssesmentGuidelineEditForm);
