import usersRoute from "../../../cortesSurvey-users/routes";
import authRoutes from "../../../cortexSurvey-auth/routes";
import homeRoute from "../../../cortexSurvey-home/routes";
import surveySetupRoutes from "../../../cortexSurvey-setup/routes";
import surveyQuestionnaireRoute from "../../../cortexSurvey-questionnaire/routes";

import dashboardUserRoute from "../../../cortexSurvey-dashboardUsers/routes";

import imageProcessingRoutes from "../../../cortexSurvey-imageProcessing/routes";

import notificationRoutes from "../../../surveyNotification/routes";

const appRoutes = {
  surveySetupRoutes,
  authRoutes,
  homeRoute,
  surveyQuestionnaireRoute,
  usersRoute,
  dashboardUserRoute,
  imageProcessingRoutes,
  notificationRoutes,
};

export default appRoutes;
