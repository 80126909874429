import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard/SurveyDashboard";
import Users from "../pages/users";

const usersRoute = {
  path: "users",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "",
      element: Users,
    },
  ],
};

export default usersRoute;
