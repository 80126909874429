import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/SelectFieldRenderer";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";

import { FIELD_IDS } from "./stateForm.fieldId";

export const COUNTRY = {
  id: FIELD_IDS.COUNTRY,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Country",
    placeholder: "Country",
    required: true,
  },
};

export const STATE={
  id:FIELD_IDS.STATE,
  renderer:InputFieldRenderer,
  renderOptions:{
    label:"State",
    placeholder: "State",
    required: true,

  }
}
