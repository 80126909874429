import { ACTION_TYPES } from "../../../constants/imageProcessing.actionTypes";

import {
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
} from "@ant-design/icons";

const EDIT = {
  id: ACTION_TYPES.EDIT,
  label: "Edit Reference Image",
  icon: <EditOutlined />,
};

const DELETE = {
  id: ACTION_TYPES.DELETE,
  label: "DeActivate Processed Data",
  icon: <DeleteOutlined />,
};

const ACTIVATE = {
  id: ACTION_TYPES.ACTIVATE,
  label: "Activate Processed Data",
  icon: <ExportOutlined />,
};

export const ROW_ACTIONS = [EDIT, DELETE];

export const INACTIVE_ROW_ACTIONS = [ACTIVATE];
