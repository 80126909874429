import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable/HeaderWithTable";

import DashboardUsersOverview from "./components/dashboardUsersOverview";
import { ACTION_TYPES } from "./constants/dashboardUsers.actionTypes";
import ACTION_HANDLERS from "./helpers/dashboardUsers.actionHandlers";

const DashboardUsers = (props) => {
  const { data=[], loading=false, onAction=_noop, fetchDashboardUsers=_noop } = props;
  return (
    <HeaderWithTable
      title="Cortex Survey Dashboard Users"
      onAction={onAction}
      data={data}
      loading={loading}
      overviewComponent={DashboardUsersOverview}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel="Add User"
      fetchData={fetchDashboardUsers}
      onReload={fetchDashboardUsers}
      showReload
    />
  );
};

DashboardUsers.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default compose(memo, withActions({}, ACTION_HANDLERS))(DashboardUsers);
