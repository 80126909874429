import { ACTION_TYPES } from "../constants/dashboardUsers.actionTypes";

export const handleDeleteRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showDeleteModal } = getState();
  showDeleteModal(rowData);
};

export const handleEditUser = ({ params, getState }) => {
  const { rowData } = params;
  const { showEditForm } = getState();
  showEditForm(rowData);
};

export const handleResendLink = ({ params, getState }) => {
  const { rowData } = params;
  const { showVerificationModal } = getState();
  showVerificationModal(rowData);
};

export const handleCreateClick = ({ getState }) => {
  const { showCreateForm } = getState();
  showCreateForm();
};

export const handleActiveClick = ({ params, getState }) => {
  const { showActiveModal } = getState();
  const { rowData } = params;
  showActiveModal(rowData);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE]: handleDeleteRowClick,
  [ACTION_TYPES.CREATE]: handleCreateClick,
  [ACTION_TYPES.EDIT]: handleEditUser,
  [ACTION_TYPES.RESEND]: handleResendLink,
  [ACTION_TYPES.ACTIVE]: handleActiveClick,
};

export default ACTION_HANDLERS;
