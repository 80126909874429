import React, { useCallback, memo, useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";
import cx from "classnames";

import styles from "./tableComponent.module.scss";
import { isMobile } from "react-device-detect";

const TableComponent = (props) => {
  const {
    value,
    renderer: Component,
    width,
    onChange,
    renderProps,
    dataIndex,
    headerName,
    ...restProps
  } = props;

  const handleOnChange = useCallback(
    (componentValue) => {
      onChange(componentValue, dataIndex);
    },
    [onChange, dataIndex]
  );

  const acutalWidth = useMemo(() => {
    if (isMobile) return "-webkit-fill-available";
    return width;
  }, [width]);

  return (
    <div
      style={{ "--header-width": acutalWidth }}
      className={cx(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      <Component
        {...renderProps}
        value={value}
        onChange={handleOnChange}
        label={isMobile && headerName}
        {...restProps}
      />
    </div>
  );
};

TableComponent.propTypes = {
  value: PropTypes.any,
  renderer: PropTypes.any,
  width: PropTypes.string,
  onChange: PropTypes.func,
  renderProps: PropTypes.object,
  dataIndex: PropTypes.string,
};

TableComponent.defaultProps = {
  value: undefined,
  renderer: _noop,
  width: "200px",
  onChange: _noop,
  renderProps: {},
  dataIndex: undefined,
};

export default memo(TableComponent);
