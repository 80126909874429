import {
  DELETE,
  ROW_ACTIONS,
} from "../constants/questionnaireOverview.rowActions";

export const getRowActions = (disabled) => {
  if (disabled) return [DELETE];

  return ROW_ACTIONS;
};
