import _noop from "lodash/noop";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../contants/stateDeleteModal.actionTypes";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../contants/stateDeleteModal.general";

import StateAPI from "../../../../../../cortexSurvey-business/appServices/state/services/state";

const handleDeleteSubmission = async ({ getState, setState }) => {
  const { stateId, onCancel=_noop, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload={ stateId: stateId, isActive: 0 }
    await StateAPI.deleteState(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err,ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
