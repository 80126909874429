import _noop from "lodash/noop";

import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";
import StructureTypeAPI from "../../../../../../cortexSurvey-business/appServices/structureType/services/structureType";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/structureComponentForm.actionTypes";
import { getPayloadFromFormValues } from "./structureComponentForm.payload";

const handleStructureComponentEditFormSubmission = async ({
  getState,
  params,
  setState,
}) => {
  const { id, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;

  setState({ error });

  if (error) return;

  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValues, id);
    await StructureTypeAPI.editStructureComponent(payload);
    onClose();
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Structure Type Component Created");
  } catch (err) {
    errorHandler(err, setState, "Failed to Create Structure Component");
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_STRUCTURE_COMPONENT_FORM_SUBMISSION]:
    handleStructureComponentEditFormSubmission,
};

export default ACTION_HANDLERS;
