import Image from "../../../../../../cortexSurvey-components/image";
import PaymentTag from "../components/paymentTag";
import StatusTag from "../components/statusTag";
import UserDataIndex from "../../../../../../cortexSurvey-business/constants/dataIndex/users";
import TextRenderer from "../../../../../../cortexSurvey-components/textRenderer/TextRenderer";

export const COLUMNS = [
  {
    title: "Image",
    dataIndex: UserDataIndex.image,
    key: "image",
    render: (value) => (
      <Image src={value} width={40} height={40} />
    ),
    width: "12rem",
  },
  {
    title: "First Name",
    dataIndex: UserDataIndex.firstName,
    key: "first_name",
    width: "20rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Last Name",
    dataIndex: UserDataIndex.lastName,
    key: "last_name",
    width: "20rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Email",
    dataIndex: UserDataIndex.email,
    key: "email",
    width: "28rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Mobile Number",
    dataIndex: UserDataIndex.phone,
    key: "mobile_no",
    width: "15rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Surveyour Rank",
    dataIndex: UserDataIndex.surveyorRank,
    key: "survey_type",
    width: "15rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Status",
    dataIndex: UserDataIndex.status,
    key: "status",
    width: "12rem",
    render: (value) => <StatusTag status={value} />,
  },
  {
    title: "Payment",
    dataIndex: "payment",
    key: "payment",
    width: "12rem",
    render: (value) => <PaymentTag payment={value} />,
  },
  {
    title: "Expiry Data",
    dataIndex: "expiry_date",
    key: "expiry_date",
    width: "15rem",
    render: (value) => <TextRenderer value={value} />,
  },
  {
    title: "Grace Period",
    dataIndex: "grace_period",
    key: "grace_period",
    width: "15rem",
    render: (value) => <TextRenderer value={value} />,
  },
];
