import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import StructureTypeAPI from "../../../../../../cortexSurvey-business/appServices/structureType/services/structureType";

import { ACTION_TYPES } from "../contants/structureTypeActiveModal.actionTypes";

const handleActiveSubmission = async ({ getState, setState }) => {
  const { id, onCancel, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload = { structureId: id, isActive: 1 };
    StructureTypeAPI.deleteStructureType(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Structure Type Deleted Successfully");
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, "Failed to Delete Structure Type");
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ACTIVE_SUBMISSION]: handleActiveSubmission,
};

export default ACTION_HANDLERS;
