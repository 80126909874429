import { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";

import useFetchPayload from "../../../hooks/useFetchPayload";

import CityAPI from "../services/city";

const useFetchCity = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchCity = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await CityAPI.fetchCity(payload);
        setData(response?.data);
      } catch (err) {
        toaster(TOASTER_TYPE.ERROR, getErrorMessage("Failed to fetch City"));
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchCity = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchCity, 1000);
      debounceFunction(isActive);
    },
    [fetchCity, setLoading]
  );

  useEffect(() => {
    fetchCity();
  }, [fetchCity]);

  return { loading, data, fetchCity: handleDebounceFetchCity };
};

export default useFetchCity;
