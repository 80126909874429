import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

import Table from "../../../../../cortexSurvey-components/table/Table";

import NotificationReaders from "../../../../../cortexSurvey-business/appServices/notification/readers/notofication";
import { SEARCH_KEYS } from "./constants/notificationOverview.general";
import COLUMNS from "./constants/notificationOverview.columns";
import {
  ROW_ACTIONS,
  INACTIVE_ROW_ACTIONS,
} from "./constants/notificationOverview.rowActions";

const NotificationOverview = (props) => {
  const {
    onAction = _noop,
    loading = false,
    data = [],
    disabled = false,
    fetchData = _noop,
  } = props;
  return (
    <Table
      rowKey={NotificationReaders.noticeId}
      dataSource={data}
      columns={COLUMNS}
      loading={loading}
      fetchData={fetchData}
      onAction={onAction}
      searchKeys={SEARCH_KEYS}
      rowActions={ROW_ACTIONS}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

NotificationOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fetchData: PropTypes.func,
};

export default memo(NotificationOverview);
