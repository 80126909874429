import { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { getErrorMessage } from "../../../../cortexSurvey-base/utils/error";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../cortexSurvey-components/notificationWrapper";
import useFetchPayload from "../../../hooks/useFetchPayload";
import StateAPI from "../services/state";

const useFetchState = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { getFetchPayload } = useFetchPayload();

  const fetchState = useCallback(
    async (active) => {
      setLoading(true);
      try {
        const payload = getFetchPayload(active);
        const response = await StateAPI.fetchState(payload);
        setData(response.data);
      } catch (err) {
        toaster(TOASTER_TYPE.ERROR, getErrorMessage("Failed to fetch State"));
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, getFetchPayload]
  );

  const handleDebounceFetchAssessmentGuideline = useCallback(
    (isActive) => {
      setLoading(true);
      const debounceFunction = _debounce(fetchState, 1000);
      debounceFunction(isActive);
    },
    [fetchState, setLoading]
  );

  useEffect(() => {
    fetchState();
  }, [fetchState]);

  return { loading, data, fetchState: handleDebounceFetchAssessmentGuideline };
};

export default useFetchState;
