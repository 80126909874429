import { ACTION_TYPES } from "../constants/state.actionTypes";

export const handleEditRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showEditForm } = getState();
  showEditForm(rowData);
};

export const handleDeleteRowClick = ({ params, getState }) => {
  const { rowData } = params;
  const { showDeleteModal } = getState();
  showDeleteModal(rowData);
};

export const handleCreateClick = ({ getState }) => {
  const { showCreateForm } = getState();
  showCreateForm();
};

export const handleActiveClick = ({ params, getState }) => {
  const { showActiveModal } = getState();
  const { rowData } = params;
  showActiveModal(rowData);
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT]: handleEditRowClick,
  [ACTION_TYPES.DELETE]: handleDeleteRowClick,
  [ACTION_TYPES.CREATE]: handleCreateClick,
  [ACTION_TYPES.ACTIVE]: handleActiveClick,
};

export default ACTION_HANDLERS;
