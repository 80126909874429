import StateDataIndex from "../../../../../../cortexSurvey-business/appServices/state/constants/dataIndex/state";
import ActiveColumn from "../../../../../../cortexSurvey-components/activeColumn/ActiveColumn";

import StateReaders from "../../../../../../cortexSurvey-business/appServices/state/readers/state";

const COLUMNS = [
  {
    title: "State Id",
    dataIndex: StateDataIndex.stateId,
    key: "state",
    width: "20rem",
  },
  {
    title: "State",
    dataIndex: StateDataIndex.state,
    key: "countryCode",
    width: "20rem",
  },
  {
    title: "Country",
    dataIndex: StateDataIndex.country,
    key: "country",
    width: "20rem",
    render: (value) => (
      <ActiveColumn data={value} getDisplayName={StateReaders.countryName} />
    ),
  },
];

export default COLUMNS;
