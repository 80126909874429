import React, { useCallback, memo } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";
import SurveyorRankForm from "../surveyorRankForm";

import { ACTION_TYPES } from "./constants/surveyorRankCreateForm.actionTypes";
import { FORM_ID } from "./constants/surveyorRankCreateForm.form";
import { INITIAL_STATE } from "./constants/surveyorRankCreateForm.initialStates";
import {
  DRAWER_TITLE,
  DRAWER_WIDTH,
} from "./constants/surveyorRankCreateForm.general";
import ACTION_HANDLERS from "./helpers/surveyorRankCreateForm.actionHandlers";

const SurveyorRankCreateForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    error=false
  } = props;

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      title={DRAWER_TITLE}
      width={DRAWER_WIDTH}
      loading={isSubmitting}
      open={isVisible}
      maskClosable={false}
      onSubmit={handleSave}
      onClose={onClose}
      error={error}
      destroyOnClose
    >
      <SurveyorRankForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        disabled={isSubmitting}
      />
    </Drawer>
  );
};

SurveyorRankCreateForm.prototype = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  error:PropTypes.bool
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(SurveyorRankCreateForm);
