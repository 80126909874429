import _property from "lodash/property";
import { FIELD_IDS } from "../constants/structureTypeForm.fieldId";

const structureType = _property(FIELD_IDS.STRUCTURE_TYPE);
const structureTypeImage = _property(FIELD_IDS.STRUCTURE_TYPE_IMAGE);

const READERS = {
  structureType,
  structureTypeImage,
};

export default READERS;
