import { addRenderOptions } from "../../../../../../cortexSurvey-components/formWithSubmission/utils/addRenderOptions";

import {
  ASSESSMENT_GUIDELINE ,
  MICROZONATION,
} from "../constants/microZonationForm.field";
import { FIELD_IDS } from "../constants/microZonationForm.fieldId";
import { FORM_CONFIG } from "../constants/microZonationForm.formConfig";

export const getFormConfig = ({
  assesmentGuidelineOptions,
  disabled,
  surveyObjective,
  microZonationData,
}) => ({
  ...FORM_CONFIG,
  [FIELD_IDS.ASSESSMENT_GUIDELINE]: addRenderOptions(ASSESSMENT_GUIDELINE, {
    options: assesmentGuidelineOptions,
    isDisabled: disabled,
  }),
  [FIELD_IDS.MICORZONATION]: addRenderOptions(MICROZONATION, {
    surveyObjective,
    microZonationData,
  }),
});
