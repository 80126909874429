import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard/SurveyDashboard";
import City from "../pages/city";
import CityIndexMapping from "../pages/cityIndexMapping";
import Country from "../pages/country";
import AssesmentGuideline from "../pages/assesmentGuideline";
import SurveyObjective from "../pages/surveyObjective";
import SurveyorRank from "../pages/surveyorRank";
import StructureType from "../pages/structureType";
import MicroZonation from "../pages/microzonation";
import State from '../pages/state';

const surveySetupRoutes = {
  path: "survey",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "surveyObjective",
      element: SurveyObjective,
    },
    {
      path: "surveyorRank",
      element: SurveyorRank,
    },
    {
      path: "country",
      element: Country,
    },
    {
      path: "city",
      element: City,
    },
    {
      path: "cityIndexMapping",
      element: CityIndexMapping,
    },
    {
      path: "structureType",
      element: StructureType,
    },
    {
      path: "assessmentGuideline",
      element: AssesmentGuideline,
    },
    {
      path: 'microZonation',
      element: MicroZonation
    },
    {
      path: 'state',
      element: State,
    }
  ],
};

export default surveySetupRoutes;
