import {
  DELETE,
  ACTIVE,
  EDIT,
} from "../constants/microZonationOverview.rowActions";

export const getRowActions = (rowData, activeState) => {
  const { isActive } = rowData;
  // if (activeState===0 && !isActive) return [];
  if (activeState===0) return [ACTIVE];
  if (activeState===1 && !isActive) return [DELETE];

  return [EDIT, DELETE];
};
