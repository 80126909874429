import React, { memo } from "react";
import FormWithSubmission from "../../../../../cortexSurvey-components/formWithSubmissionV2";
import { getFormConfig } from "./helpers/notificationForm.formConfig";
import { getSections } from "./helpers/notificationForm.section";

const NotificationForm = (props) => {
  const { initialValues, formId, onSubmit } = props;
  return (
    <FormWithSubmission
      formConfig={getFormConfig}
      sections={getSections}
      initialValues={initialValues}
      formId={formId}
      onSubmit={onSubmit}
    />
  );
};

export default memo(NotificationForm);
