import React from "react";

import Upload from "../upload";
import AudioPlayer from "../audioPlayer/AudioPlayer";

import styles from "./audioUpload.module.scss";
import Label from "../label";

const AudioUpload = (props) => {
  const { containerClassName, title, required, ...restProps } = props;
  return (
    <div className={containerClassName}>
      {title && (
        <Label className={styles.title} required={required}>
          {title}
        </Label>
      )}
      <Upload
        {...restProps}
        type="audio"
        renderer={AudioPlayer}
        renderProps={{ controls: true }}
      />
    </div>
  );
};

export default AudioUpload;
