const id = "problemId";
const structureProblem = "problem";
const structureRange = "levels";
const stuctureProblemType = "damageType";

const DATA_INDEX = {
  id,
  structureProblem,
  structureRange,
  stuctureProblemType,
};

export default DATA_INDEX;
