import { Avatar as AntdAvatar } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

import {
  getDisplayBackgroundColor,
  getDisplayName,
} from "./helpers/avatar.displayName";

const Avatar = (props) => {
  const {
    className,
    size = 48,
    count,
    shape,
    displayName,
    isBlack = false,
    ...restProps
  } = props;

  const displayClassName = useMemo(
    () => getDisplayBackgroundColor(isBlack),
    [isBlack]
  );

  const avatarDisplayName = useMemo(
    () => getDisplayName(displayName),
    [displayName]
  );

  return (
    <AntdAvatar
      className={cx(className, { [displayClassName]: !!displayName })}
      {...restProps}
      size={size}
      shape={shape}
    >
      {avatarDisplayName}
    </AntdAvatar>
  );
};

Avatar.propTypes = {
  size: PropTypes.any,
  shape: PropTypes.string,
  isBlack: PropTypes.bool,
  count: PropTypes.bool,
  displayName: PropTypes.string,
};

export default memo(Avatar);
