import SurveyDashboard from "../../cortexSurvey-components/surveyDashboard/SurveyDashboard";
import DashboardUsers from "../pages/dashboardUsers";

const dashboardUserRoute = {
  path: "dashboardUsers",
  wrapper: SurveyDashboard,
  isProtected: true,
  children: [
    {
      path: "",
      element: DashboardUsers,
    },
  ],
};

export default dashboardUserRoute;
