import _noop from "lodash/noop";

import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";
import AssessmentGuidelineAPI from "../../../../../../cortexSurvey-business/appServices/assessmentGuideline/services/assessmentGuideline";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/assessmentGuidelineDeleteModal.actionTypes";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/assessmentGuidelineDeleteModal.general";

export const handleDeleteSubmission = async ({ getState, setState }) => {
  const { assessmentGuidelineId, onCancel=_noop, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload = { assessmentGuidelineId, isActive: 0 };
    await AssessmentGuidelineAPI.deleteAssessmentGuideline(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.DELETE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
