import HttpClient from "../../cortexSurvey-base/utils/unProtectedHttp";

import PreHttpClient from "../../cortexSurvey-base/utils/preHttp";

const API_PATHS = {
  LOGIN:"/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD:"/reset-password",
};


const login = (payload) => HttpClient.post(API_PATHS.LOGIN, payload);

const forgotPassword = (payload) =>
  PreHttpClient.post(API_PATHS.FORGOT_PASSWORD, payload);

const resetPassword = (payload) =>
  PreHttpClient.post(API_PATHS.RESET_PASSWORD, payload);

const AuthApi = {
  login,
  forgotPassword,
  resetPassword,
};

export default AuthApi;
