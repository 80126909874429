import React from "react";

import { Segmented as AntdSegmented, ConfigProvider } from "antd";

const Segmented = (props) => {
  const { options, onChange, value } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            itemSelectedColor: "#fff",
            itemSelectedBg: "#4285F4",
          },
        },
      }}
    >
      <AntdSegmented options={options} onChange={onChange} value={value} />
    </ConfigProvider>
  );
};

export default Segmented;
