import React, { memo } from "react";

import _noop from "lodash/noop";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withActions from "../../../cortexSurvey-base/hocs/withActions";
import HeaderWithTable from "../../../cortexSurvey-components/headerWithTable";

import AssesmentGuidelineOverview from "./components/assesmentGuidelineOverview";
import { ACTION_TYPES } from "./constants/assesmentGuideline.actionTypes";
import {
  CREATE_BUTTON_LABEL,
  HEADER_TITLE,
} from "./constants/assesmentGuideline.general";
import ACTION_HANDLERS from "./helpers/assesmentGuideline.actionHandler";

const AssesmentGuideline = (props) => {
  const {
    loading = false,
    data = [],
    onAction = _noop,
    fetchAssesmentGuideline = _noop,
  } = props;

  return (
    <HeaderWithTable
      data={data}
      loading={loading}
      title={HEADER_TITLE}
      onAction={onAction}
      createActionType={ACTION_TYPES.CREATE}
      createButtonLabel={CREATE_BUTTON_LABEL}
      overviewComponent={AssesmentGuidelineOverview}
      onReload={fetchAssesmentGuideline}
      fetchData={fetchAssesmentGuideline}
      showReload
    />
  );
};

AssesmentGuideline.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  onAction: PropTypes.func,
  fetchAssesmentGuideline: PropTypes.func,
};

export default compose(
  memo,
  withActions({}, ACTION_HANDLERS)
)(AssesmentGuideline);
