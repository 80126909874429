import _mapValues from "lodash/mapValues";
import _get from "lodash/get";
import _isNumber from "lodash/isNumber";
import _isEmpty from "lodash/isEmpty";
import { addRenderOptions } from "./formWithSubmission.addRenderOptions";
import _some from "lodash/some";
import _map from "lodash/map";

const validateRequiredField = ({
  field,
  formValue,
  required,
  validations,
  formState,
}) => {
  if (_isEmpty(formValue) && required && !_isNumber(formValue)) {
    const updatedField = addRenderOptions(field, {
      error: "Field is required",
    });
    return updatedField;
  }

  if (validations) {
    const { value, message } = validations(formValue, formState);

    if (!value) {
      const updatedField = addRenderOptions(field, {
        error: message,
      });
      return updatedField;
    }
  }

  const updatedField = addRenderOptions(field, {
    error: null,
  });

  return updatedField;
};

const getValidatedFormField = (formState) => (field, fieldId) => {
  const formValue = _get(formState, fieldId);
  const { renderOptions } = field;
  const { required, validations } = renderOptions || {};
  return validateRequiredField({
    formValue,
    field,
    required,
    validations,
    formState,
  });
};

const hasFormFieldError = (field) => {
  const { renderOptions } = field;
  const { error } = renderOptions || {};
  return !_isEmpty(error);
};

export const validateFormField = (formState, fieldConfig) => {
  const updatedFieldConfig = _mapValues(
    fieldConfig,
    getValidatedFormField(formState)
  );
  const hasError = _some(updatedFieldConfig, hasFormFieldError);
  return { updatedFieldConfig, hasError };
};

const isColumnError = (fieldConfig) => (columnId) => {
  const field = _get(fieldConfig, columnId, {});
  const error = _get(field, "renderOptions.error", false);
  return !!error;
};

const isRowError = (fieldConfig) => (row) => {
  const { columns } = row;
  return _some(columns, isColumnError(fieldConfig));
};

const getErrorsFromRows = (rows, fieldConfig) => {
  const error = _some(rows, isRowError(fieldConfig));
  return error;
};

const getModifiedSections = (fieldConfig) => (section) => {
  const { rows } = section;
  const error = getErrorsFromRows(rows, fieldConfig);
  return {
    ...section,
    error,
  };
};

export const getUiSectionsWithErrors = (sections, fieldConfig) => {
  const uiSectionsWithErrors = _map(sections, getModifiedSections(fieldConfig));
  return uiSectionsWithErrors;
};
