import React, { memo, useCallback } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";
import CityIndexMappingForm from "../cityIndexMappingForm";

import { ACTION_TYPES } from "./constants/cityIndexMapping.actionTypes";
import { INITIAL_STATE } from "./constants/cityIndexMappingCreateForm.initialState";
import ACTION_HANDLERS from "./helpers/cityIndexMappingCreateForm.actionHandlers";

import { FORM_ID } from "./constants/cityIndexMappingCreateForm.formId";

const CityIndexMappingCreateForm = (props) => {
  const {
    isVisible,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    getCountryStateAndCityOptions = _noop,
    getAssessmentGuidelineSurveyObjectiveMicroZonationOptions = _noop,
    cityData
  } = props;

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.CREATE_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      open={isVisible}
      title="Add City Index Mapping"
      maskClosable={false}
      onSubmit={handleSave}
      loading={isSubmitting}
      onClose={onClose}
      width="105rem"
      destroyOnClose
    >
      <CityIndexMappingForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        getCountryStateAndCityOptions={getCountryStateAndCityOptions}
        getAssessmentGuidelineSurveyObjectiveMicroZonationOptions={getAssessmentGuidelineSurveyObjectiveMicroZonationOptions}
        cityData={cityData}
      />
    </Drawer>
  );
};

CityIndexMappingCreateForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  getCountryStateAndCityOptions: PropTypes.func,
  getAssessmentGuidelineSurveyObjectiveMicroZonationOptions: PropTypes.func,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CityIndexMappingCreateForm);
