import React, { memo, useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";
import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Drawer from "../../../../../cortexSurvey-components/drawer";
import { triggerSubmit } from "../../../../../cortexSurvey-components/formWithSubmissionV2/helpers/formWithSubmission.formAction";
import ImageProcessingForm from "../imageProcessingForm";

import { ACTION_TYPES } from "./constants/ImageProcessingEditForm.actionTypes";
import { FORM_ID } from "./constants/ImageProcessingEditForm.formId";
import { INITIAL_STATE } from "./constants/ImageProcessingEditForm.initialState";
import ACTION_HANDLERS from "./helpers/imageProcessingEditForm.actionHandlers";
import { makeInitialValues } from "./helpers/imageProcessingEditForm.initalValues";

const ImageProcessingEditForm = (props) => {
  const {
    isVisible = false,
    onClose = _noop,
    onAction = _noop,
    isSubmitting = false,
    formValues = {},
    error=false
  } = props;

  const initialValues = useMemo(
    () => makeInitialValues(formValues),
    [formValues]
  );

  const handleSave = useCallback(() => {
    triggerSubmit(FORM_ID);
  }, []);

  const handleFormSubmission = useCallback(
    (formValues) => {
      onAction({
        type: ACTION_TYPES.EDIT_FORM_SUBMISSION,
        payload: { formValues },
      });
    },
    [onAction]
  );

  return (
    <Drawer
      title="Edit Reference Image"
      width="90rem"
      open={isVisible}
      onClose={onClose}
      loading={isSubmitting}
      onSubmit={handleSave}
      maskClosable={false}
      error={error}
      destroyOnClose
    >
      <ImageProcessingForm
        formId={FORM_ID}
        onSubmit={handleFormSubmission}
        initialValues={initialValues}
        view={isSubmitting}
      />
    </Drawer>
  );
};

ImageProcessingEditForm.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
  formValues: PropTypes.array,
  error:PropTypes.bool
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(ImageProcessingEditForm);
