const audio = "audio";
const capturedWithCameraType = "capturedWithCameraType";
const capturingDistance = "capturingDistance";
const createdBy = "createdBy";
const distressPattern = "distressPattern";
const imageProcessingId = "imageProcessingId";
const isActive = "isActive";
const itemCode = "itemCode";
const itemSearchCode = "itemSearchCode";
const lightAndWeatherCondition = "lightAndWeatherCondition";
const numericalScore = "numericalScore";
const referenceImages = "referenceImages";
const scoreReference = "scoreReference";
const updatedBy = "updatedBy";
const vulnerabilityScale = "vulnerabilityScale";

const DATA_INDEX = {
  audio,
  capturedWithCameraType,
  capturingDistance,
  createdBy,
  distressPattern,
  imageProcessingId,
  isActive,
  itemCode,
  itemSearchCode,
  lightAndWeatherCondition,
  numericalScore,
  referenceImages,
  scoreReference,
  updatedBy,
  vulnerabilityScale,
};

export default DATA_INDEX;
