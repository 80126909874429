import _noop from "lodash/noop";
import PropTypes from "prop-types";
import React, { memo, useEffect, useMemo, useState } from "react";
import UiRenderer from "../uiRenderer";
import useFormState from "./hooks/useFormState";
import useFormSubmission from "./hooks/useFormSubmission";
import Field from "./component/field";
import _isFunction from "lodash/isFunction";

const FormWithSubmissionV2 = (props) => {
  const {
    formConfig=_noop,
    sections=[],
    onSubmit=_noop,
    formId,
    initialValues={},
    view=false,
    fieldValueMapper={},
    tabEnabled=false,
  } = props;

  const [disableSubmit, setDisableSubmit] = useState(false);

  const [formState, handleFormChange, resetForm] = useFormState(initialValues);
  const [stateLoading, setStateLoading] = useState(false);

  const fields = useMemo(() => formConfig(formState), [formState, formConfig]);

  const uiSections = useMemo(() => {
    if (_isFunction(sections)) return sections(formState);
    return sections;
  }, [formState, sections]);

  const { fieldConfig, uiSectionsWithErrors } = useFormSubmission({
    formState,
    formId,
    onSubmit,
    fields,
    view,
    disableSubmit,
    uiSections,
    tabEnabled,
    stateLoading,
  });

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  return (
    <UiRenderer
      fieldConfig={fieldConfig}
      sections={uiSectionsWithErrors}
      formState={formState}
      onChange={handleFormChange}
      component={Field}
      disabled={view}
      fieldValueMapper={fieldValueMapper}
      setDisableSubmit={setDisableSubmit}
      tabEnabled={tabEnabled}
      setStateLoading={setStateLoading}
    />
  );
};

FormWithSubmissionV2.propTypes = {
  formConfig: PropTypes.func,
  sections: PropTypes.array,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  formId: PropTypes.string,
  view: PropTypes.bool,
  fieldValueMapper: PropTypes.any,
  tabEnabled: PropTypes.bool,
};

export default memo(FormWithSubmissionV2);
