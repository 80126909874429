import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import _isEmpty from "lodash/isEmpty";
import _size from "lodash/size";
import _get from "lodash/get";
import _map from "lodash/map";
import _every from "lodash/every";
import _forEach from 'lodash/forEach';
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = vfsFonts.pdfMake.vfs;

export const generateExcel = (data, exportColumns, fileName) => {
    if (_isEmpty(data)) {
      console.error('Data is missing.');
      return;
    }
  
    if (!validateExportConfig(exportColumns)) {
      console.error('Export columns are invalid.');
      return;
    }
  
    console.log('Exporting data to Excel with columns:', exportColumns);
  
    const headers = getHeaders(exportColumns);
    console.log('Excel headers:', headers);
  
    const formattedData = formatDataForExcel(data, exportColumns);
    console.log('Formatted data for Excel:', formattedData);
  
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(excelBlob, `${fileName}.xlsx`);
  };

  export const generatePdf = (data, exportColumns, fileName) => {
    if (_isEmpty(data)) {
      console.error("Data is missing.");
      return;
    }
  
    if (!validateExportConfig(exportColumns)) {
      console.error("Export columns are invalid.");
      return;
    }
  
    const headers = getHeaders(exportColumns);
    const pdfContent = {
      pageSize: "A4",
      pageOrientation: "landscape",
      content: [
        {
          text: fileName,
          style: "header",
          margin: [0, 0, 0, 10],
        },
        {
          table: {
            headerRows: 1,
            body: [
              headers, // Add headers as the first row
              ..._map(data, (item) =>
                _map(exportColumns, (col) => _get(item, col.key, ""))
              ),
            ],
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex === 0 ? "#CCCCCC" : null;
            },
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: "center",
          color: "#333333",
        },
      },
    };
  
    pdfMake.createPdf(pdfContent).download(`${fileName}.pdf`);
  };
  
  

export const validateExportConfig = (exportColumns) => {
    return (
      !_isEmpty(exportColumns) &&
      _size(exportColumns) > 0 &&
      _every(exportColumns, (col) => _get(col, 'key') && _get(col, 'label'))
    );
  };
  
const getHeaders = (exportColumns) => {
  return _map(exportColumns, (col) => _get(col, 'label'));
};


const formatDataForExcel = (data, exportColumns) => {
    return _map(data, (item) => {
      const formattedItem = {};
      _forEach(exportColumns, (col) => {
        formattedItem[_get(col, 'label')] = _get(item, _get(col, 'key', ''));
      });
      return formattedItem;
    });
  };
  