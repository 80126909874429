import React, { memo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import CityReaders from "../../../../../cortexSurvey-business/appServices/city/readers/city";

import Table from "../../../../../cortexSurvey-components/table/Table";

import COLUMNS from "./constants/cityOverview.columns";
import {
  INACTIVE_ROW_ACTIONS,
} from "./constants/cityOverview.rowActions";

import { SEARCH_KEYS } from "./constants/cityOverview.table";

import { getRowActions } from "./helpers/cityOverview.rowActions";

const CityOverview = (props) => {
  const {
    onAction = _noop,
    data = [],
    loading = false,
    fetchData = _noop,
  } = props;

  return (
    <Table
      rowKey={CityReaders.cityId}
      dataSource={data}
      columns={COLUMNS}
      onAction={onAction}
      loading={loading}
      rowActions={getRowActions}
      searchKeys={SEARCH_KEYS}
      fetchData={fetchData}
      inActiveRowActions={INACTIVE_ROW_ACTIONS}
      showFilter
    />
  );
};

CityOverview.propTypes = {
  onAction: PropTypes.func,
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default memo(CityOverview);
