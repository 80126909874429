import _noop from "lodash/noop";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/dashboardUserEditForm.actionTypes";

import DashboardUserAPI from "../../../../../../cortexSurvey-business/appServices/dashboardUser/services/dashboardUsers";
import { getPayloadFromFormValues } from "./dashboardUserEditForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleEditSubmission = async ({ getState, params, setState }) => {
  const { userId, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;
  const { error } = formValues;

  if (!error) {
    setState({ isSubmitting: true });
    try {
      const payload = getPayloadFromFormValues(formValues, userId);
      await DashboardUserAPI.editDashboardUser(payload);
      onSubmitCb();
      toaster(TOASTER_TYPE.SUCCESS, "Dashboard User Updated Successfully");
      onClose();
    } catch (err) {
      errorHandler(err, setState, "Failed to Update Dashboard User");
    } finally {
      setState({ isSubmitting: false });
    }
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_SUBMISSION]: handleEditSubmission,
};

export default ACTION_HANDLERS;
