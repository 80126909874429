import ImageProcessingFormReaders from "../../imageProcessingForm/readers";

export const getPayloadFronFormValues = (formValues) => {
  const itemCode = ImageProcessingFormReaders.itemCode(formValues);
  const itemSearchCode = ImageProcessingFormReaders.itemSearchCode(formValues);
  const distressPattern =
    ImageProcessingFormReaders.distressPattern(formValues);
  const vulnerabilityScale =
    ImageProcessingFormReaders.vulnerabilityScale(formValues);
  const scoreReference = ImageProcessingFormReaders.scoreReference(formValues);
  const numericalScore = ImageProcessingFormReaders.numericalScore(formValues);
  const capturingDistance =
    ImageProcessingFormReaders.capturingDistance(formValues);
  const lightAndWeatherCondition =
    ImageProcessingFormReaders.lightAndWeatherCondition(formValues);

  const audio = ImageProcessingFormReaders.audio(formValues);
  const referenceImages =
    ImageProcessingFormReaders.referenceImages(formValues);

  const capturedWithCameraType =
    ImageProcessingFormReaders.capturedWithCameraType(formValues);

  return {
    itemCode,
    itemSearchCode,
    distressPattern,
    vulnerabilityScale,
    scoreReference,
    numericalScore,
    capturingDistance,
    lightAndWeatherCondition,
    audio,
    referenceImages,
    capturedWithCameraType,
  };
};
