import React, { memo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _noop from "lodash/noop";

const Column = (props) => {
  const {
    id,
    fieldConfig = {},
    component: Comp,
    formState = {},
    onChange = _noop,
    className,
    ...restProps
  } = props;

  const field = _get(fieldConfig, id, {});

  const { renderer: Component, renderOptions } = field;

  if (Comp) {
    return (
      <Comp
        componentClassName={className}
        {...field}
        formState={formState}
        onChange={onChange}
        fieldId={id}
        {...restProps}
      />
    );
  }

  return <Component componentClassName={className} {...renderOptions} {...restProps} />;
};

Column.propTypes = {
  id: PropTypes.string,
  fieldConfig: PropTypes.object,
  component: PropTypes.any,
  formState: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default memo(Column);
