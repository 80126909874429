import _property from "lodash/property";

import { FIELD_IDS } from "../constants/dashboardUsersForm.fieldIds";

const firstName = _property(FIELD_IDS.FIRST_NAME);
const lastName = _property(FIELD_IDS.LAST_NAME);
const email = _property(FIELD_IDS.EMAIL);
const role = _property(FIELD_IDS.ROLE);

const READERS = {
  firstName,
  lastName,
  email,
  role,
};

export default READERS;
