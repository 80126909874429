import _noop from "lodash/noop";
import { getErrorMessage } from "../../../../../../cortexSurvey-base/utils/error";

import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import { ACTION_TYPES } from "../contants/surveyorRankActiveModal.actionTypes";

import SurveyorRankAPI from "../../../../../../cortexSurvey-business/appServices/surveyorRank/services/surveyorRank";
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from "../contants/surveyorRankActiveModal.general";

export const handleDeleteSubmission = async ({ getState, setState }) => {
  const { surveyorRankId, onCancel, onSubmitCb = _noop } = getState();

  setState({ isSubmitting: true });

  try {
    const payload = { surveyorRankId, isActive: 1 };
    await SurveyorRankAPI.deleteSurveyorRank(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
  } catch (err) {
    toaster(TOASTER_TYPE.ERROR, getErrorMessage(err, ERROR_MESSAGE));
  } finally {
    setState({ isSubmitting: false });
    onCancel();
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.ACTIVE_SUBMISSION]: handleDeleteSubmission,
};

export default ACTION_HANDLERS;
