import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_STATE: "/state-data",
  CREATE_STATE: "/state-add",
  EDIT_STATE: "/state-update",
  DELETE_STATE: "/is-active",
};

const fetchState = (payload) => HttpClient.post(API_PATHS.FETCH_STATE, payload);

const createState = (payload) =>
  HttpClient.post(API_PATHS.CREATE_STATE, payload);

const editState = (payload) => HttpClient.post(API_PATHS.EDIT_STATE, payload);

const deleteState = (payload) =>
  HttpClient.post(API_PATHS.DELETE_STATE, payload);

const stateAPI = {
  fetchState,
  createState,
  editState,
  deleteState,
};

export default stateAPI;
