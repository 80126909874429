import React, { memo, useCallback } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import _noop from "lodash/noop";

import withActions from "../../../../../cortexSurvey-base/hocs/withActions";
import Modal from "../../../../../cortexSurvey-components/modal";
import { ACTION_TYPES } from "./constants/cityIndexMappingDeleteModal.actionTypes";
import { INITIAL_STATE } from "./constants/cityIndexMappingDeleteModal.initialState";
import ACTION_HANDLERS from "./helpers/cityIndexMappingDeletionModal.actionHandler";

const CityIndexMappingDeleteModal = (props) => {
  const {
    isVisible = false,
    onCancel = _noop,
    onAction = _noop,
    isSubmitting = false,
  } = props;

  const handleSubmit = useCallback(() => {
    onAction({ type: ACTION_TYPES.DELETE_SUBMISSION });
  }, [onAction]);

  return (
    <Modal
      title="Delete City Index Mapping"
      open={isVisible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      okText="DeActivate"
      centered
    >
      {`Do you want to De-Activate this City Index Mapping`}
    </Modal>
  );
};

CityIndexMappingDeleteModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onAction: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default compose(
  memo,
  withActions(INITIAL_STATE, ACTION_HANDLERS)
)(CityIndexMappingDeleteModal);
