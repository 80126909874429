import NotificationDataIndex from "../../../../../../cortexSurvey-business/appServices/notification/constants/dataIndex/notification";
import ShowNotification from "../component/showNotification";

const COLUMNS = [
  {
    title: "Notification Title",
    dataIndex: NotificationDataIndex.title,
    width: "20rem",
    key: "title",
  },
  {
    title: "Notification Type",
    dataIndex: NotificationDataIndex.type,
    width: "20rem",
    key: "type",
  },
  {
    title: "Notification",
    dataIndex: NotificationDataIndex.url,
    key: "url",
    width: "30rem",
    render: (_, row) => <ShowNotification data={row} />,
  },
];

export default COLUMNS;
