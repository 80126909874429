import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { IMAGE_LINK } from "../../cortexSurvey-base/assets/images/imageLink";
import { dashboardRoutes } from "../../cortexSurvey-base/constants/routes/dashboardRoutes";
import Image from "../image";
import Input from "../input";
import NavigationContainer from "./components/navigationContainer";
import { extractNavigationfromSearch } from "./helpers/dashboardNavigationPanel.naviagtion";

import styles from "./dashboardNavigationPanel.module.scss";
import { isMobile } from "react-device-detect";
import Button from "../button/Button";
import { TYPES } from "../button";
import { navigationEmitter } from "../surveyDashboard/SurveyDashboard";

const DashboardNavigationPanel = (props) => {
  const { onClose, setQuickNavigation } = props;

  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const onSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const dashboardNavigationConfig = extractNavigationfromSearch({
    navigationConfig: dashboardRoutes,
    searchText,
  });

  const redirectToHome = () => {
    onClose();
    navigate("/survey");
  };

  return (
    <div className={styles.container}>
      <div className={styles.navbarContainer}>
        {!isMobile ? (
          <div className={styles.imageContainer}>
            <Image
              src={IMAGE_LINK.LOGO_BG}
              preview={false}
              onClick={redirectToHome}
            />
          </div>
        ) : (
          <Button
            onClick={() => navigationEmitter.emit("close")}
            type={TYPES.TERTIARY}
          >
            <CloseOutlined style={{ fontSize: "26px", color: "white" }} />
          </Button>
        )}

        <div className={styles.searchInput}>
          <Input
            value={searchText}
            onChange={onSearch}
            placeholder="search"
            suffix={<SearchOutlined />}
          />
        </div>
      </div>
      <div className={styles.mainContainer}>
        <NavigationContainer
          config={dashboardNavigationConfig}
          onClose={onClose}
          setQuickNavigation={setQuickNavigation}
        />
      </div>
    </div>
  );
};

export default DashboardNavigationPanel;
