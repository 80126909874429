import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_SURVEY_OBJECTIVE: "/survey-objective-data",
  CREATE_SURVEY_OBJECTIVE: "/survey-objective-add",
  EDIT_SURVEY_OBJECTIVE: "/survey-objective-update",
  DELETE_SURVEY_OBJECTIVE: "/is-active",
};

const fetchSurveyObjective = (payload) =>
  HttpClient.post(API_PATHS.FETCH_SURVEY_OBJECTIVE, payload);

const createSurveyObjective = (payload) =>
  HttpClient.post(API_PATHS.CREATE_SURVEY_OBJECTIVE, payload);

const editSurveyObjective = (payload) =>
  HttpClient.post(API_PATHS.EDIT_SURVEY_OBJECTIVE, payload);

const deleteSurveyObjective = (payload) =>
  HttpClient.post(API_PATHS.DELETE_SURVEY_OBJECTIVE, payload);

const SurveyObjectiveAPI = {
  fetchSurveyObjective,
  createSurveyObjective,
  editSurveyObjective,
  deleteSurveyObjective,
};

export default SurveyObjectiveAPI;
