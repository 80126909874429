import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmission/fieldRenderers/InputFieldRenderer";

import RadioButton from "../../../../../../cortexSurvey-components/radioButton";

export const getColumnConfig = (disabled) => [
  {
    key: "answerInput",
    dataIndex: "answer",
    renderer: InputFieldRenderer,
    width: "420px",
    headerName: "Answer Input",
    renderProps: {
      disabled,
    },
  },
  {
    key: "answerBox",
    dataIndex: "value",
    renderer: RadioButton,
    width: "200px",
    headerName: "Answer",
    renderProps: {
      disabled,
      options: [
        { label: "True", value: 1 },
        { label: "False", value: 0 },
      ],
    },
  },
];
