import _noop from "lodash/noop";
import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from "../constants/stateEditForm.general";
import StateAPI from "../../../../../../cortexSurvey-business/appServices/state/services/state";
import { ACTION_TYPES } from "../constants/stateEditForm.actionTypes";
import { getPayloadFromFormValues } from "./stateEditForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { stateId, onClose = _noop, onSubmitCb = _noop } = getState();
  const { formValues } = params;

  const { error } = formValues;

  if (error) return;

  setState({ isSubmitting: true });

  try {
    const payload = getPayloadFromFormValues(formValues, stateId);
    await StateAPI.editState(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, SUCCESS_MESSAGE);
    onClose();
  } catch (err) {
    errorHandler(err, setState, ERROR_MESSAGE);
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.EDIT_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
