import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOGOUT,
  PHONE_NUMBER,
  ROLE,
} from "./dashboardUserProfileForm.field";
import { FIELD_IDS } from "./dashboardUserProfileForm.fieldId";

export const FORM_CONFIG = {
  [FIELD_IDS.FIRST_NAME]: FIRST_NAME,
  [FIELD_IDS.LAST_NAME]: LAST_NAME,
  [FIELD_IDS.EMAIL]: EMAIL,
  [FIELD_IDS.PHONE_NUMBER]: PHONE_NUMBER,
  [FIELD_IDS.ROLE]: ROLE,
  [FIELD_IDS.LOGOUT]: LOGOUT,
};
