import SelectFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/SelectFieldRenderer";
import InputFieldRenderer from "../../../../../../cortexSurvey-components/formWithSubmissionV2/fieldRenderer/InputFieldRenderer";

import { FIELD_IDS } from "./cityIndexMappingForm.fieldId";

export const COUNTRY = {
  id: FIELD_IDS.COUNTRY,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Country",
    placeholder: "Country",
    required: true,
    options: [],
  },
};

export const STATE = {
  id: FIELD_IDS.STATE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "State",
    placeholder: "State",
    required: true,
    options: [],
  },
};

export const CITY = {
  id: FIELD_IDS.CITY,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "City",
    placeholder: "City",
    required: true,
    options: [],
  },
};

export const SURVEY_OBJECTIVE = {
  id: FIELD_IDS.SURVEY_OBJECTIVE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Survey Objective",
    placeholder: "Survey Objective",
    required: true,
    options: [],
  },
};

export const ASSESSMENT_GIDELINE = {
  id: FIELD_IDS.ASSESSMENT_GUIDELINE,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Assessment Guideline",
    placeholder: "Assessment Guideline",
    required: true,
    options: [],
  },
};

export const MICROZONATION = {
  id: FIELD_IDS.MICROZONATION,
  renderer: SelectFieldRenderer,
  renderOptions: {
    label: "Microzonation",
    placeholder: "Microzonation",
    required: true,
    options: [],
  },
};

export const SOIL = {
  id: FIELD_IDS.SOIL,
  renderer: InputFieldRenderer,
  renderOptions: {
    label: "Soil",
    placeholder: "Soil",
    disabled:true,
 
  },
};
