import {
  toaster,
  TOASTER_TYPE,
} from "../../../../../../cortexSurvey-components/notificationWrapper";

import { ACTION_TYPES } from "../constants/structureHelpForm.actionTypes";

import StructureTypeAPI from "../../../../../../cortexSurvey-business/appServices/structureType/services/structureType";

import { getPayloadFromFormValues } from "./structureHelpForm.payload";
import { errorHandler } from "../../../../../../cortexSurvey-business/helpers/errors/handleError";

const handleEditFormSubmission = async ({ getState, params, setState }) => {
  const { id, onClose, onSubmitCb } = getState();
  const { formValues } = params;

  const { error } = formValues;

  setState({ error });

  if (error) return;

  setState({ isSubmitting: true });
  try {
    const payload = getPayloadFromFormValues(formValues, id);
    await StructureTypeAPI.editStructureHelp(payload);
    onSubmitCb();
    toaster(TOASTER_TYPE.SUCCESS, "Structure Type Help Edited Successfully");
    onClose();
  } catch (err) {
    errorHandler(err, setState, "Failed to Edit Structure Type");
  } finally {
    setState({ isSubmitting: false });
  }
};

const ACTION_HANDLERS = {
  [ACTION_TYPES.HELP_FORM_SUBMISSION]: handleEditFormSubmission,
};

export default ACTION_HANDLERS;
