import AssessmentGuidelineReaders from "../../../../../../cortexSurvey-business/appServices/assessmentGuideline/readers/assessmentGuideline";

import { FIELD_IDS } from "../../assesmentGuidelineForm/constants/assesmentGuidelineForm.fieldId";

export const makeInitialValues = (formData) => {
  const assessmentGuideline =
    AssessmentGuidelineReaders.assessmentGuideline(formData);
  return {
    [FIELD_IDS.ASSESSMENT_GUIDELINE]: assessmentGuideline,
  };
};
