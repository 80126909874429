import React, { memo, useCallback } from "react";

import PropTypes from "prop-types";

import _map from "lodash/map";
import _noop from "lodash/noop";

import { renderAssesmentScoreTable } from "./helpers/questionaireScoreTable.components";
import { getUpdatedValues } from "./helpers/questionaireScoreTable.general";

const QuestionaireScoreTable = (props) => {
  const { value = [], onChange = _noop } = props;

  const handleValueChange = useCallback(
    (assesmentValue) => {
      const updatedValue = getUpdatedValues(value, assesmentValue);
      console.log(value, updatedValue);
      onChange(updatedValue);
    },
    [onChange, value]
  );

  return <div>{_map(value, renderAssesmentScoreTable(handleValueChange))}</div>;
};

QuestionaireScoreTable.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default memo(QuestionaireScoreTable);
