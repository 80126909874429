import HttpClient from "../../../../cortexSurvey-base/utils/http";

const API_PATHS = {
  FETCH_NOTICE: "/notice-data",
  CREATE_NOTICE: "/notice-add",
  EDIT_NOTICE: "/notice-update",
  DELETE_NOTICE: "/is-active",
};

const fetchNotice = (payload) =>
  HttpClient.post(API_PATHS.FETCH_NOTICE, payload);

const createNotice = (payload) =>
  HttpClient.post(API_PATHS.CREATE_NOTICE, payload);

const editNotice = (payload) => HttpClient.post(API_PATHS.EDIT_NOTICE, payload);

const deleteNotice = (payload) =>
  HttpClient.post(API_PATHS.DELETE_NOTICE, payload);

const NoticeAPI = {
  fetchNotice,
  createNotice,
  editNotice,
  deleteNotice,
};

export default NoticeAPI;
