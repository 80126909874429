export const getImagePayload = (fileList) => {
    // Assuming fileList contains files uploaded via Ant Design Upload component
    if (fileList && fileList.length > 0) {
      // Extract the originFileObj from the first file in fileList
      const imageFile = fileList[0].originFileObj;
      
      // Ensure imageFile is not empty before constructing the payload
      if (imageFile) {
        return {
          image: imageFile,
        };
      } else {
        throw new Error("No file found in fileList");
      }
    } else {
      throw new Error("fileList is empty or undefined");
    }
  };
  