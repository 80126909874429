import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

import { renderRow } from "./helpers/tableBody.components";

const TableBody = (props) => {
  const {
    config = [],
    value = [],
    onChange = _noop,
    showTableAction = true,
    className,
    ...restProps
  } = props;

  const tableRow = useMemo(
    () => renderRow(config, value, onChange, restProps, showTableAction),
    [config, value, onChange, restProps, showTableAction]
  );

  return <div className={className}>{tableRow}</div>;
};

TableBody.propTypes = {
  config: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.array,
  showTableAction: PropTypes.bool,
};

export default memo(TableBody);
