import _map from "lodash/map";

import StructureTypeReaders from "../readers/structureType";

const createStructureTypeOption = (option) => {
  const structureType = StructureTypeReaders.structureType(option);

  const id = StructureTypeReaders.id(option);

  return {
    label: structureType,
    value: id,
  };
};

export const getStructureTypeOptions = (structureTypeData) =>
  _map(structureTypeData, createStructureTypeOption);
