import _map from "lodash/map";

import { Country } from "country-state-city";

const createCountryOption = (option) => {
  const { name } = option;
  return { label: name, value: name };
};

export const getCountryOptions = () =>
  _map(Country.getAllCountries(), createCountryOption);
