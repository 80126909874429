import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import _filter from "lodash/filter";
import StateReader from "../readers/state";
import CityDataIndex from "../../city/constants/dataIndex/city";

const getFilteredState = (countryId) => (state) => {
  const stateCountryId = StateReader.countryId(
    _get(state, CityDataIndex.country)
  );

  return _isEqual(countryId, stateCountryId);
};

export const getFilteredStateWithCounty = (stateData, countryId) =>
  _filter(stateData, getFilteredState(countryId));
