import React, { memo } from "react";

import PropTypes from "prop-types";
import { SortableElement } from "react-sortable-hoc";
import { compose } from "recompose";

import _noop from "lodash/noop";

import Button, { TYPES } from "../../../../../../../button";
import Image from "../../../../../../../image";

import styles from "./sortableItem.module.scss";

const SortableItem = (props) => {
  const { data, onDelete = _noop, onPreview = _noop } = props;

  const handlePreview = () => {
    onPreview(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Image src={data} className={styles.image} preview={false} />
        <Button type={TYPES.TERTIARY} onClick={handlePreview}>
          Preview
        </Button>
      </div>
      <div className={styles.actionItem}>
        <Button onClick={onDelete}>Delete</Button>
      </div>
    </div>
  );
};

SortableItem.propTypes = {
  data: PropTypes.string,
  onDelete: PropTypes.func,
  onPreview: PropTypes.func,
};

export default compose(memo, SortableElement)(SortableItem);
